import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useDeclineComplaintMutation } from "shared/api";
import { ID } from "shared/types";

export const useDeclineComplaint = ({ id }: ID) => {
  const [decline] = useDeclineComplaintMutation();

  const { open } = useShowConfirmModal();

  const { show } = useNotification();

  const declineComplaint = () => {
    decline({ id })
      .unwrap()
      .then(() => {
        show({ text: "Жалоба отклонена!" });
      });
  };

  const declineComplaintWithConfirm = () => {
    open({
      title: `Отклонить жалобу?`,
      buttonText: "Отклонить",
      onConfirmCallback: declineComplaint,
    });
  };

  return {
    declineComplaint: declineComplaintWithConfirm,
  };
};
