import {
  ArrowDirection,
  DEFAULT_ICON_SIZE,
  IArrowIcon,
} from "./icon.interface";

export const ArrowShortIcon = ({
  height,
  width,
  size = DEFAULT_ICON_SIZE,
  className,
  color = "#4E51BF",
  direction = ArrowDirection.down,
  onClick,
}: IArrowIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 28"
      width={width || size}
      height={height || size}
      className={className}
      style={{ transform: `rotate(${direction})` }}
      fill="none"
      onClick={onClick}
    >
      <path
        d="M14.0627 25.2507L2.81268 14.0007L14.0627 2.75073M4.37518 14.0007L27.1877 14.0007"
        stroke={color}
        strokeWidth="3.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
