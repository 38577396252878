import { useNavbar } from "entities/navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowShortIcon } from "shared/ui/Icons/ArrowShortIcon";
import styles from "./GoBackButton.module.scss";

export const GoBackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { links } = useNavbar();

  const withGoBackButton = !links
    .map((link) => link.path)
    .includes(location.pathname);

  const goPreviosPage = () => {
    navigate(-1);
  };

  return withGoBackButton ? (
    <button className={styles.goBackButton} onClick={goPreviosPage}>
      <ArrowShortIcon className={styles.goBackButton__icon} />
    </button>
  ) : null;
};
