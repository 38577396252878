import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { ModalsSelector, modalActions, ModalType } from "shared/store";

export const useLoadPhotoModal = () => {
  const dispatch = useAppDispatch();

  const { imageUrl, imageFile } = useAppSelector(ModalsSelector.photo);

  const openPhotoModal = () => {
    dispatch(modalActions.openModal(ModalType.photoModal));
  };

  useEffect(() => {
    return () => {
      dispatch(modalActions.resetPhotoData());
    };
  }, [dispatch]);

  return { openPhotoModal, imageFile, imageUrl };
};
