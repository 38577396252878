import { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Button } from "../Buttons";
import { EditIcon } from "../Icons";
import { markdownToDraft, draftToMarkdown } from "markdown-draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from "./HtmlEditor.module.scss";

interface HtmlEditorProps {
  description: string;
  saveText: (value: string) => void;
  title?: ReactNode;
  withControls?: boolean;
  onChange?: (value: string) => void;
  height?: string | number;
}

export const HtmlEditor = ({
  description,
  title,
  withControls = true,
  saveText,
  onChange,
  height = "50vh",
}: HtmlEditorProps) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [text, setText] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const mdContent = draftToMarkdown(
      convertToRaw(editorState.getCurrentContent())
    );
    setText(mdContent);
    onChange && onChange(mdContent);
  };

  const uploadText = () => {
    saveText(text);
    setIsDisabled(true);
  };

  const toggleDisdabled = () => {
    setIsDisabled((disabled) => !disabled);
  };

  useEffect(() => {
    const rawData = markdownToDraft(description);
    const contentState = convertFromRaw(rawData);
    const editorState = EditorState.createWithContent(contentState);

    setEditorState(editorState);
  }, [description]);

  const toolbar = {
    options: ["inline", "blockType", "list", "link"],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough", "monospace"],
      bold: { className: styles.toolbar__button },
      italic: { className: styles.toolbar__button },
      underline: { className: styles.toolbar__button },
      strikethrough: { className: styles.toolbar__button },
      monospace: { className: styles.toolbar__button },
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
      className: classNames(
        styles.toolbar__button,
        styles.toolbar__selectBlockType
      ),
    },
    fontSize: {
      className: classNames(
        styles.toolbar__button,
        styles.toolbar__selectFontSize
      ),
    },
    link: {
      link: { className: styles.toolbar__button },
      unlink: { className: styles.toolbar__button },
    },
    list: {
      unordered: { className: styles.toolbar__button },
      ordered: { className: styles.toolbar__button },
      indent: { className: styles.toolbar__button },
      outdent: { className: styles.toolbar__button },
    },
  };

  return (
    <div className={styles.textEditor}>
      {title && <div className={styles.textEditor__title}>{title}</div>}
      <div className={styles.textEditor__content}>
        <div
          className={classNames(styles.textEditor__text, {
            [styles.textEditor_disabled]: isDisabled && withControls,
            [styles.textEditor__textWithControls]: withControls,
          })}
        >
          <Editor
            editorState={editorState}
            wrapperClassName={styles.wrapper}
            toolbarClassName={styles.toolbar}
            editorClassName={styles.editor}
            onEditorStateChange={onEditorStateChange}
            toolbar={toolbar}
            editorStyle={{ height }}
          />
        </div>
        {withControls && (
          <div className={styles.textEditor__controls}>
            <Button
              type="gray"
              icon={<EditIcon />}
              onClick={toggleDisdabled}
              className={styles.textEditor__controlsEditButton}
            >
              Редактировать
            </Button>
            <Button
              type="solid"
              onClick={uploadText}
              disabled={text === description || !text}
            >
              Сохранить
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
