import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryData } from "shared/types";
import { IMarketplacesState } from "shared/store/reducers/types";

const initialState: IMarketplacesState = {
  selectedItems: [],
  info: {
    total: 0,
    pages: 0,
    is_last: true,
  },
  searchParams: {
    currentPage: 1,
    itemsPerPage: 50,
  },
};

export const marketplacesSlice = createSlice({
  name: "marketplaces",
  initialState,
  reducers: {
    setQueryData: (state, action: PayloadAction<QueryData<[]>>) => {
      state.info.is_last = action.payload.is_last;
      state.info.pages = action.payload.pages;
      state.info.total = action.payload.total;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.searchParams.currentPage = action.payload;
    },
    setMessagesPerPage: (state, action: PayloadAction<number>) => {
      state.searchParams.itemsPerPage = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchParams.searchTerm = action.payload;
    },
    setDate: (state, action: PayloadAction<Date | undefined>) => {
      state.searchParams.createdAt = action.payload;
    },
    // TODO: добавить тип
    addMarketplacesInSelected: (state, action: PayloadAction<any>) => {
      // state.selectedItems.push(action.payload);
    },
    removeMarketplacesFromSelected: (state, action: PayloadAction<any>) => {
      // state.selectedItems = state.selectedItems.filter(
      //   (marketplace) => marketplace.id !== action.payload.id
      // );
    },
    resetSelectedMarketplaces: (state) => {
      // state.selectedItems = [];
    },
    setShowBanned: (state, action: PayloadAction<boolean | undefined>) => {
      state.searchParams.showBanned = action.payload;
    },
    setShowVerified: (state, action: PayloadAction<boolean | undefined>) => {
      state.searchParams.showVerified = action.payload;
    },
  },
});

export const marketplacesActions = marketplacesSlice.actions;

export default marketplacesSlice.reducer;
