import {
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "shared/hooks/shared";
import { verificationsSelector, verificationsActions } from "shared/store";

export const useUserVerificationsPagination = () => {
  const { info, currentPage, usersPerPage } = useAppSelector(
    verificationsSelector
  );
  const dispatch = useAppDispatch();

  const setPage = (page: number) => {
    dispatch(verificationsActions.setCurrentPage(page));
  };

  const setItemsPerPage = (itemsCount: number) => {
    dispatch(verificationsActions.setUsersPerPage(itemsCount));
  };

  const pagination = usePagination({
    info,
    setPage,
    currentPage,
    items: usersPerPage,
    setItemsPerPage,
  });

  return { pagination };
};
