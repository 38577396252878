import { useWallet, useWalletPagination } from "./model";
import { Pagination } from "shared/ui/Pagination";
import { SearchActiveApplications } from "features/wallet/search-active-applications";
import { SearchApplicationByAuthor } from "features/wallet/search-by-author";
import { SearchApplicationByDate } from "features/wallet/search-by-date";
import { WalletTable, WalletTableRow } from "entities/wallet/WalletTable";
import { IWalletApplication } from "shared/types";
import { Button } from "shared/ui/Buttons";
import { useApproveWalletAplication } from "features/wallet/approve";
import { useDeclineWalletApplication } from "features/wallet/decline";
import styles from "./wallet.module.scss";

interface WalletTableProps {
  data: IWalletApplication;
}

const WalletControls = ({ data }: WalletTableProps) => {
  const { approveApplication } = useApproveWalletAplication({ id: data.id });
  const { declineApplication } = useDeclineWalletApplication({ id: data.id });

  return (
    <div className={styles.walletRowOptions}>
      <Button
        type="solid"
        className={styles.walletRowOptions__button}
        onClick={approveApplication}
      >
        отправить
      </Button>
      <Button
        className={styles.walletRowOptions__button}
        onClick={declineApplication}
      >
        отклонить
      </Button>
    </div>
  );
};

export const WalletPage = () => {
  const { applications, is_active } = useWallet();
  const { pagination } = useWalletPagination();

  return (
    <div>
      <div className={styles.wallet__paginationTop}>
        <div className={styles.walletSort}>
          <div className={styles.walletSort__block}>
            <SearchApplicationByAuthor />
            <SearchApplicationByDate />
          </div>
          <div className={styles.walletSort__sortOptions}>
            <SearchActiveApplications />
          </div>
        </div>
        <Pagination isShort withSelector {...pagination} />
      </div>
      <WalletTable is_active={is_active}>
        {applications &&
          applications.map((item) => (
            <WalletTableRow
              is_active={is_active}
              data={item}
              controls={<WalletControls data={item} />}
              key={item.id}
            />
          ))}
      </WalletTable>
    </div>
  );
};
