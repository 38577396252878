import "./RechartStyles.scss";

const fontStyle = {
  fontSize: 16,
  fontWeight: 300,
  fontFamily: "Montserrat",
};

export const XAxisTick = ({ x, y, payload, tickFormatter }: any) => {
  return (
    <g transform={`translate(${x - 2},${y})`}>
      <text x={0} y={0} dy={24} textAnchor="middle" style={fontStyle}>
        {tickFormatter ? tickFormatter(payload.value) : payload.value}
      </text>
    </g>
  );
};

export const YAxisTick = ({ x, y, payload, tickFormatter }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-16} y={0} textAnchor="end" style={fontStyle}>
        {tickFormatter ? tickFormatter(payload.value) : payload.value}
      </text>
    </g>
  );
};
