import classNames from "classnames";
import { useRef, useState, MouseEvent } from "react";
import { Flipper, Flipped } from "react-flip-toolkit";
import { ArrowIcon, ArrowDirection } from "../Icons";
import { SelectProps } from "./select.interface";
import styles from "./select.module.scss";

export const Select = ({
  title,
  children,
  className,
  iconSize,
  overflow = false,
  closeAfterClick = true,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const stopPropagation = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleSelectOpenClick = () => {
    setIsOpen((open) => !open);
  };

  const handleBodyClick = () => {
    closeAfterClick && handleSelectOpenClick();
  };

  return (
    <div className={styles.select}>
      <div
        onClick={handleSelectOpenClick}
        className={classNames(styles.select__button, {
          [styles.select__buttonOpen]: isOpen,
          [className as string]: className,
        })}
      >
        <span className={styles.select__text}>{title}</span>
        <div className={styles.select__icon} onClick={stopPropagation}>
          <ArrowIcon
            size={iconSize}
            onClick={handleSelectOpenClick}
            direction={isOpen ? ArrowDirection.up : ArrowDirection.down}
          />
        </div>
      </div>
      <Flipper flipKey={isOpen}>
        <Flipped flipId={"select-body"}>
          <div
            ref={ref}
            onClick={handleBodyClick}
            className={classNames(styles.select__body, {
              [styles.select__bodyOverflow]: overflow,
            })}
            style={{
              height:
                isOpen && ref.current ? ref.current?.scrollHeight || 100 : 0,
            }}
          >
            <div className={styles.select__bodyContent}>{children}</div>
          </div>
        </Flipped>
      </Flipper>
    </div>
  );
};
