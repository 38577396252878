import { AgreementType } from "shared/types";
import { HtmlEditor } from "shared/ui/HtmlEditor";
import { useAgreements } from "shared/hooks/core";
import * as Layout from "shared/ui/layout";

export const UserAgreement = () => {
  const { userAgreement, setAgreements } = useAgreements();

  const handleSaveClick = (text: string) => {
    if (text !== userAgreement.description) {
      setAgreements({ type: AgreementType.userAgreement, description: text });
    }
  };

  return (
    <Layout.Content
      title={
        <>
          Редактирование <br />
          пользовательского соглашения
        </>
      }
    >
      <HtmlEditor
        description={userAgreement.description || ""}
        saveText={handleSaveClick}
      />
    </Layout.Content>
  );
};
