import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Select } from "shared/ui/Select";
import { EditButton } from "shared/ui/Buttons/EditButton";
import { ContentSelector } from "widgets/ContentSelector";
import { useDeleteCardBindingBenefit } from "features/card-binding/delete-benefit";
import {
  useGetConnectCardBenefitsQuery,
  useGetOnboardingsQuery,
} from "shared/api";
import { useDeleteOnboarding } from "features/onboardings/delete-onboarding";
import * as Layout from "shared/ui/layout";
import styles from "./Menu.module.scss";

const path = "/app-content";

type ContentNavLink = {
  title: string;
  link: string;
};

const MenuButton = memo(({ title, link }: ContentNavLink) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <EditButton key={link} onClick={handleClick}>
      {title}
    </EditButton>
  );
});

const LeftMenuPannel = () => {
  const benefitsQuery = useGetConnectCardBenefitsQuery();
  const { deleteCardbindingBenefit } = useDeleteCardBindingBenefit();

  const onboardingsQuery = useGetOnboardingsQuery();
  const { deleteOnboarding } = useDeleteOnboarding();

  return (
    <>
      <ContentSelector
        title="Onboardings"
        titleLink="Onboarding"
        data={onboardingsQuery.isSuccess ? onboardingsQuery.data : []}
        basePath="/app-content/onboarding/"
        addNewElementTitle="Добавить Onboarding"
        deleteElement={deleteOnboarding}
        deleteFirstElement={false}
      />

      <MenuButton
        title="Категории пользователей (теги)"
        link={`${path}/tags`}
      />

      <ContentSelector
        title="Преимущества привязки карты"
        titleLink="Преимущество"
        data={benefitsQuery.isSuccess ? benefitsQuery.data : []}
        basePath="/app-content/card-binding/benefit/"
        addNewElementTitle="Добавить преимущество"
        deleteElement={deleteCardbindingBenefit}
      />

      <MenuButton
        title="Процесс удержания комиссии"
        link={`${path}/commission`}
      />

      <MenuButton title="Кошелёк (информация)" link={`${path}/balance`} />
      <MenuButton
        title="Процесс привязки карты"
        link={`${path}/card-binding`}
      />

      <MenuButton title="Матрица ленты" link={`${path}/feed-settings`} />
    </>
  );
};

const RightMenuPannel = () => {
  return (
    <>
      <MenuButton
        title="Политика обработки конфиденциальности"
        link={`${path}/sensitive-data`}
      />

      <MenuButton
        title="Пользовательское соглашение"
        link={`${path}/user-agreement`}
      />
      <MenuButton
        title="Информация о верификации"
        link={`${path}/verifications`}
      />

      <Select
        title="Причины отклонения заказа"
        className={styles.contentMenu__select}
      >
        Часть будущего функционала маркетплейсов.
      </Select>

      <MenuButton
        title="Предупреждение от администрации"
        link={`${path}/warning`}
      />
      <MenuButton title="Курс конвертации" link={`${path}/currency-rate`} />
    </>
  );
};

export const Menu = () => {
  return (
    <Layout.Content
      title={
        <>
          Управление контентом <br /> мобильного приложения
        </>
      }
    >
      <div className={styles.contentMenu}>
        <div className={styles.contentMenu__left}>
          <LeftMenuPannel />
        </div>
        <div className={styles.contentMenu__right}>
          <RightMenuPannel />
        </div>
      </div>
    </Layout.Content>
  );
};
