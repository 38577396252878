import { DescriptionBlockProps } from "./DescriptionBlock.interface";
import styles from "./DescriptionBlock.module.scss";

export const DescriptionBlock = ({ title, content }: DescriptionBlockProps) => {
  return (
    <div className={styles.textBlock}>
      <p className={styles.textBlock__title}>{title}</p>
      <pre className={styles.textBlock__content}>{content}</pre>
    </div>
  );
};
