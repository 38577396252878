import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { walletSelector, walletActions } from "shared/store";

export const useSearchApplicationsByAuthor = () => {
  const dispatch = useAppDispatch();
  const { searchTerm } = useAppSelector(walletSelector);

  const setSearchText = (value: string) => {
    dispatch(walletActions.setSearchTerm(value));
  };

  return {
    setSearchText,
    searchTerm,
  };
};
