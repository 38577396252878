import { Api, tags } from "../..";
import { ID } from "shared/types";
import {
  IAddConnectCardBenefitResponseDto,
  IChangeWalletHelpTextDto,
  IRequestWalletApplicationsDto,
  IResponseWalletApplicationsDto,
} from "shared/api/dto";

export const walletApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getWalletApplications: builder.query<
      IResponseWalletApplicationsDto,
      IRequestWalletApplicationsDto
    >({
      query: (data) => ({ url: "api/admin-wallet/", params: data }),
      providesTags: [tags.walletApplications],
    }),
    approveWalletApplication: builder.mutation<void, ID>({
      query: (data) => ({
        url: `api/admin-wallet/${data.id}/approve/`,
        method: "POST",
      }),
      invalidatesTags: [tags.walletApplications],
    }),
    declineWalletApplication: builder.mutation<void, ID>({
      query: (data) => ({
        url: `api/admin-wallet/${data.id}/decline/`,
        method: "POST",
      }),
      invalidatesTags: [tags.walletApplications],
    }),
    deleteConnectCardBenefit: builder.mutation<void, ID>({
      query: (data) => ({
        url: `api/admin-wallet/benefits/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [tags.walletConnectCardBenefits],
    }),
    addConnectCardBenefit: builder.mutation<
      IAddConnectCardBenefitResponseDto,
      FormData
    >({
      query: (data) => ({
        url: `api/admin-wallet/benefits/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [tags.walletConnectCardBenefits],
    }),
    changeConnectCardBenefit: builder.mutation<
      void,
      { formData: FormData } & ID
    >({
      query: (data) => ({
        url: `api/admin-wallet/benefits/${data.id}/`,
        method: "PATCH",
        body: data.formData,
      }),
      invalidatesTags: [tags.walletConnectCardBenefits],
    }),

    changeWalletHelpText: builder.mutation<void, IChangeWalletHelpTextDto>({
      query: (data) => ({
        url: `api/admin-wallet/help-texts/${data.id}/`,
        method: "PATCH",
        body: { description: data.description },
      }),
      invalidatesTags: [tags.walletHelpText],
    }),
  }),
});

export const {
  useGetWalletApplicationsQuery,
  useDeclineWalletApplicationMutation,
  useApproveWalletApplicationMutation,
  useDeleteConnectCardBenefitMutation,
  useAddConnectCardBenefitMutation,
  useChangeConnectCardBenefitMutation,
  useChangeWalletHelpTextMutation,
} = walletApi;
