import { memo, useContext } from "react";
import classNames from "classnames";
import { Menu } from "entities/Menu";
import { Router } from "./Router";
import { MenuContext } from "shared/context/MenuContext";
import styles from "./ui.module.scss";

export const PrivatePages = memo(() => {
  const { isMobileMenu } = useContext(MenuContext);

  return (
    <div
      className={classNames(styles.app, { [styles.appMobile]: isMobileMenu })}
    >
      <menu
        className={classNames(styles.menu, {
          [styles.menuMobile]: isMobileMenu,
        })}
      >
        <nav
          className={classNames(styles.menuFixed, {
            [styles.menuMobileFixed]: isMobileMenu,
          })}
        >
          <Menu />
        </nav>
      </menu>
      <Router />
    </div>
  );
});
