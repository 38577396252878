import { useNotification } from "shared/hooks/shared";
import { useChangeConnectCardBenefitMutation } from "shared/api";

interface IChangeOndoarding {
  id: number;
  imageFile?: File | string;
  bodyText?: string;
  headerText?: string;
}

export const useChangeCardBindingBenefit = () => {
  const [changeBenefitMutation, resultChange] =
    useChangeConnectCardBenefitMutation();
  const { show } = useNotification();

  const changeBenefit = ({
    imageFile,
    bodyText,
    headerText,
    id,
  }: IChangeOndoarding) => {
    const formData = new FormData();
    if (imageFile) {
      formData.append("image", imageFile);
    }
    if (bodyText) {
      formData.append("body", bodyText);
    }
    if (headerText) {
      formData.append("header", headerText);
    }

    if (formData && id) {
      changeBenefitMutation({
        id,
        formData,
      })
        .unwrap()
        .then(() => show({ text: "Преимущество изменено" }))
        .catch((err) => {
          if (err?.data?.errors?.header) {
            show({ text: "Заголовок длинее 30 символов", isError: true });
          }
        });
    }
  };

  return {
    changeBenefit,
    isChanged: resultChange.isSuccess,
    isLoading: resultChange.isLoading,
  };
};
