import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { complaintsSelector, complaintsActions } from "shared/store";

export const useSearchComplaintsByPublicationDate = () => {
  const dispatch = useAppDispatch();
  const { searchParams } = useAppSelector(complaintsSelector);

  const setPublicationDate = (date: Date) => {
    dispatch(complaintsActions.setPublicationDate(date));
  };

  return {
    setPublicationDate,
    publicationDate: searchParams.publicationDate,
  };
};
