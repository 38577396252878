import { Routes, Route } from "react-router-dom";
import { Complaints } from "widgets/Complaints";
import { ComplaintType } from "shared/types";
import * as Layout from "shared/ui/layout";

const basePath = "/complaints";

export const ComplaintsLinks = [
  { title: "Пользователи", path: `${basePath}/` },
  { title: "Публикации", path: `${basePath}/posts` },
  { title: "Истории", path: `${basePath}/stories` },
  { title: "Комментарии", path: `${basePath}/comments` },
  { title: "Звонки", path: `${basePath}/calls` },
];

export const Router = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <Layout.Content
            title={"Жалобы"}
            navigation={<Layout.Navigation links={ComplaintsLinks} />}
          />
        }
      >
        <Route path="" element={<Complaints type={ComplaintType.PROFILE} />} />
        <Route
          path="posts"
          element={<Complaints type={ComplaintType.POST} />}
        />
        <Route
          path="stories"
          element={<Complaints type={ComplaintType.STORY} />}
        />
        <Route
          path="comments"
          element={<Complaints type={ComplaintType.COMMENT} />}
        />
        <Route
          path="calls"
          element={<Complaints type={ComplaintType.CALL} />}
        />

        <Route path="*" element={<Complaints type={ComplaintType.PROFILE} />} />
      </Route>
    </Routes>
  );
};
