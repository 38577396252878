import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryData } from "shared/types";
import { CallType, ICallsState } from "shared/store/reducers/types";

const initialState: ICallsState = {
  info: {
    total: 0,
    pages: 0,
    is_last: true,
  },
  searchParams: {
    currentPage: 1,
    itemsPerPage: 50,
  },
  is_video: false,
  selectedItems: [],
};

export const callsSlice = createSlice({
  name: "calls",
  initialState,
  reducers: {
    setQueryData: (state, action: PayloadAction<QueryData<any>>) => {
      state.info.is_last = action.payload.is_last;
      state.info.pages = action.payload.pages;
      state.info.total = action.payload.total;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.searchParams.currentPage = action.payload;
    },
    setCallsPerPage: (state, action: PayloadAction<number>) => {
      state.searchParams.itemsPerPage = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchParams.searchText = action.payload;
    },
    setCallDate: (state, action: PayloadAction<Date | undefined>) => {
      state.searchParams.callDate = action.payload;
    },
    setCallType: (state, action: PayloadAction<CallType>) => {
      state.is_video = action.payload === "video";
    },
    addCallInSelected: (state, action: PayloadAction<{ id: number }>) => {
      state.selectedItems.push(action.payload.id);
    },
    removeCallFromSelected: (state, action: PayloadAction<{ id: number }>) => {
      state.selectedItems = state.selectedItems.filter(
        (id) => id !== action.payload.id
      );
    },
    resetSelectedCalls: (state) => {
      state.selectedItems = [];
    },

    resetSearchParams: (state) => {
      state.searchParams = {
        currentPage: 1,
        itemsPerPage: state.searchParams.itemsPerPage,
      };
    },
  },
});

export const callsActions = callsSlice.actions;

export default callsSlice.reducer;
