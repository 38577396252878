export interface RangeProps {
  direction?: RangeHintDirection;
  min?: number;
  max: number;
  step?: number;
  defaultValue?: number;
  onChange?: (count: number) => void;
}

export enum RangeHintDirection {
  top = "top",
  down = "down",
}
