import { useCallback } from "react";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { decOfWhitelistedDomains } from "shared/lib";
import { useDeleteWhitelistedDomainMutation } from "shared/api/endpoints/admin/whitelisted-domains.api";
import { sleep } from "shared/lib/sleep";

interface DeleteLinksParams {
  ids: number[];
  onMutate: () => void;
}

interface DeleteLinkParams {
  id: number;
  name: string;
  onMutate: () => void;
}

export const useDeletePaymentLink = () => {
  const { open } = useShowConfirmModal();
  const { show, hide } = useNotification();

  const [deleteLink] = useDeleteWhitelistedDomainMutation();

  const deletePaymentLinks = useCallback(
    ({ ids, onMutate }: DeleteLinksParams) => {
      open({
        title: `Удалить ${ids.length} ${decOfWhitelistedDomains(ids.length)}?`,
        buttonText: "Удалить",
        onConfirmCallback: async () => {
          show({
            text: "Удаление",
            withLoader: true,
            autoClose: false,
          });
          let promises = ids.map((id) => deleteLink({ id }).unwrap());
          let results = await Promise.allSettled([...promises, sleep(550)]);

          hide();
          if (results.every((promise) => promise.status === "fulfilled")) {
            show({ text: "Ссылки удалены!" });
          } else {
            show({ text: "Часть ссылок не была удалена" });
          }

          onMutate();
        },
      });
    },
    [deleteLink, hide, open, show]
  );

  const deletePaymentLink = useCallback(
    ({ id, name, onMutate }: DeleteLinkParams) => {
      open({
        title: `Удалить ${decOfWhitelistedDomains(1)}: ${name}?`,
        buttonText: "Удалить",
        onConfirmCallback: async () => {
          show({
            text: "Удаление",
            withLoader: true,
            autoClose: false,
          });
          try {
            await Promise.all([deleteLink({ id }).unwrap(), sleep(550)]);
            hide();
            show({ text: "Ссылка удалена!" });
          } catch {
            hide();
            show({ text: "Ошибка удаления!", isError: true });
          }

          onMutate();
        },
      });
    },
    [deleteLink, hide, open, show]
  );

  return {
    deletePaymentLinks,
    deletePaymentLink,
  };
};
