import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useLoadPhotoModal } from "entities/Modals/LoadPhotoModal";
import * as Layout from "shared/ui/layout";
import styles from "./EditBanner.module.scss";
import { DownloadIconPng } from "shared/ui/Icons";
import { Input } from "shared/ui/Inputs";
import { Button } from "shared/ui/Buttons";
import { TextEditor } from "shared/ui/TextEditor";
import { EditLabel } from "shared/ui/Label";
import { useEditBanner } from "features/promotions/banner/edit-banner";
import { useGetAdvertisementByIdQuery } from "shared/api/endpoints/admin/advertisements.api";
import { Calendar } from "shared/ui/Calendar";
import {
  ICreateAdvertisementsDto,
  ICreateAdvertisementsError,
} from "shared/api/dto/adverstisements.dto";
import { ErrorMessage } from "shared/ui/ErrorMessage";
import { useNotification } from "shared/hooks/shared";
import { SBCoin } from "shared/ui/Icons/Coins";

export const EditBannerPage = () => {
  const { id, order } = useParams();
  const { imageUrl, imageFile, openPhotoModal } = useLoadPhotoModal();
  const { create, update } = useEditBanner();
  const { show } = useNotification();

  const advertisementData = useGetAdvertisementByIdQuery(
    { id: id as string },
    { skip: !id }
  );

  const [data, setData] = useState<ICreateAdvertisementsDto>({
    price: "",
    title: "",
    description: "",
    url: "",
    content_url: "",
    image: "",
    tag: "",
    start_date: "",
    end_date: "",
    is_active: true,
  });

  const [errors, setErrors] = useState<ICreateAdvertisementsError>({});

  const pageTitle =
    id && order ? (
      <>
        Редактирование <br /> рекламного баннера №{order}
      </>
    ) : (
      <>
        Добавление <br /> рекламного баннера
      </>
    );

  const changeLink = (event: ChangeEvent<HTMLInputElement>) => {
    setData((data) => ({ ...data, url: event.target.value }));
  };

  const changePrice = (event: ChangeEvent<HTMLInputElement>) => {
    setData((data) => ({ ...data, price: event.target.value }));
  };

  const changeTag = (event: ChangeEvent<HTMLInputElement>) => {
    setData((data) => ({ ...data, tag: event.target.value }));
  };

  const changeDescription = (value: string) => {
    setData((data) => ({ ...data, description: value }));
  };

  const changeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setData((data) => ({ ...data, title: event.target.value }));
  };

  const changeEndDate = (date: Date) => {
    const dateString = date.toISOString();
    setData((data) => ({ ...data, end_date: dateString }));
  };

  const handleSaveBanner = () => {
    const bannerData = { ...data };
    if (imageFile) {
      bannerData.image = imageFile;
    }
    if (order && id) {
      update({ ...bannerData }, id)
        .then(() => {
          setErrors({});
          show({ text: "Баннер изменен" });
        })
        .catch((err) => {
          const errors = err?.data as ICreateAdvertisementsError;
          if (errors) {
            setErrors(errors);
          }
        });
    } else {
      create({ ...bannerData })
        .then(() => {
          setErrors({});
          show({ text: "Баннер добавлен" });
        })
        .catch((err) => {
          const errors = err?.data as ICreateAdvertisementsError;
          if (errors) {
            setErrors(errors);
          }
        });
    }
  };

  useEffect(() => {
    if (advertisementData.isSuccess) {
      const { url, title, description, end_date, tag, price, image } =
        advertisementData.data;
      setData((data) => ({
        ...data,
        url,
        title,
        description,
        end_date,
        tag,
        price,
        image,
      }));
    }
  }, [advertisementData]);

  const isSaveBannerDisabled = useMemo(() => {
    if (!(id && order)) {
      return (
        !data.description ||
        !data.price ||
        !data.title ||
        !data.url ||
        !data.end_date ||
        !(data.image || imageFile)
      );
    } else if (advertisementData.isSuccess) {
      const { url, title, description, end_date, tag, price, image } =
        advertisementData.data;
      return !(
        (data.description && data.description !== description) ||
        (data.price && data.price !== price) ||
        (data.url && data.url !== url) ||
        (data.title && data.title !== title) ||
        (data.end_date && data.end_date !== end_date) ||
        (imageFile && imageFile !== image) ||
        (data.tag && data.tag !== tag)
      );
    }
    return true;
  }, [data, imageFile, id, order, advertisementData]);

  return (
    <Layout.Content title={pageTitle}>
      <div className={styles.editBanner}>
        <div className={styles.editBanner__content}>
          <div className={styles.editBanner__contentBlock}>
            <div className={styles.editBanner__loadImage}>
              <div
                className={styles.editBanner__loadImageTitle}
                onClick={openPhotoModal}
              >
                <span>Загрузить изображение</span>
                <div className={styles.editBanner__loadImageIcon}>
                  <DownloadIconPng size="46px" />
                </div>
              </div>
              <div className={styles.editBanner__image}>
                {(imageUrl || data.image) && (
                  <img src={imageUrl || (data.image as string)} alt="" />
                )}
              </div>
              {errors.errors?.image && (
                <ErrorMessage>{errors.errors?.image}</ErrorMessage>
              )}
            </div>
            <div className={styles.editBanner__inputBlock}>
              <span className={styles.editBanner__label}>Ссылка</span>
              <Input
                className={styles.editBanner__input}
                onChange={changeLink}
                value={data.url}
              />
              {errors.errors?.url && (
                <ErrorMessage>{errors.errors?.url}</ErrorMessage>
              )}
            </div>
            <div className={styles.editBanner__inputBlock}>
              <span className={styles.editBanner__label}>Стоимость</span>
              <Input
                className={styles.editBanner__input}
                onChange={changePrice}
                value={data.price}
                icon={<SBCoin />}
              />
              {errors.errors?.price && (
                <ErrorMessage>{errors.errors?.price}</ErrorMessage>
              )}
            </div>
            <div className={styles.editBanner__inputBlock}>
              <span className={styles.editBanner__label}>
                Дата окончания продвижения:
              </span>
              <Calendar
                className={styles.editBanner__input}
                value={data?.end_date ? new Date(data.end_date) : null}
                setDate={changeEndDate}
              />
              {errors.errors?.end_date && (
                <ErrorMessage>{errors.errors?.end_date}</ErrorMessage>
              )}
            </div>

            <Button
              disabled={isSaveBannerDisabled}
              type="solid"
              className={styles.editBanner__button}
              onClick={handleSaveBanner}
            >
              Сохранить
            </Button>
          </div>
          <div className={styles.editBanner__contentBlock}>
            <div className={styles.editBanner__inputBlock}>
              <span className={styles.editBanner__label}>Заголовок</span>
              <Input
                className={styles.editBanner__input}
                onChange={changeTitle}
                value={data.title}
              />
              {errors.errors?.title && (
                <ErrorMessage>{errors.errors?.title}</ErrorMessage>
              )}
            </div>
            <div className={styles.editBanner__inputBlock}>
              <span className={styles.editBanner__label}>
                Тэг (используется только внутри админ.панели)
              </span>
              <Input
                className={styles.editBanner__input}
                onChange={changeTag}
                value={data.tag}
              />
            </div>
            <div className={styles.editBanner__editor}>
              <TextEditor
                description={data.description}
                title={<EditLabel text="Описание рекламного баннера" />}
                onChange={changeDescription}
                withControls={false}
                saveText={() => {}}
                height="300px"
              />
              {errors.errors?.description && (
                <ErrorMessage>{errors.errors?.description}</ErrorMessage>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout.Content>
  );
};
