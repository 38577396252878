import { ChangeEvent, useState } from "react";
import { EyeIcon } from "shared/ui/Icons";
import styles from "./form.module.scss";
import classnames from "classnames";

interface FormPasswordInputProps {
  id?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const FormPasswordInput = ({
  onChange,
  placeholder,
  id,
}: FormPasswordInputProps) => {
  const [hide, setHide] = useState(true);

  const setPassword = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const inputClasses = classnames(
    styles.loginForm__input,
    styles.loginForm__inputPassword
  );

  return (
    <div className={styles.loginForm__passwordInputWrapper}>
      <input
        id={id}
        type={hide ? "password" : "text"}
        placeholder={placeholder || ""}
        className={inputClasses}
        onChange={setPassword}
      />
      <div className={styles.loginForm__hidePasswordIcon}>
        <EyeIcon
          isClose={!hide}
          size={32}
          color={"#aaaaaa"}
          onClick={() => setHide((hide) => !hide)}
        />
      </div>
    </div>
  );
};
