import { ID } from "shared/types";
import { useApproveUserMutation } from "shared/api";
import { useNotification } from "shared/hooks/shared";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";

export const useApproveVerification = ({ id }: ID) => {
  const [approveUser] = useApproveUserMutation();
  const { show } = useNotification();
  const { open } = useShowConfirmModal();

  const approve = (cb?: () => void) => {
    open({
      title: `Принять заявку?`,
      buttonText: "Принять",
      onConfirmCallback: () => {
        id &&
          approveUser({ id })
            .unwrap()
            .then(() => {
              show({ text: "Пользователь верифицирован!" });
            })
            .finally(() => cb?.());
      },
    });
  };

  return { approve };
};
