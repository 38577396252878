import { ChangeEvent } from "react";
import { InputSearch } from "shared/ui/Inputs";
import { useSearchMessageByAuthor } from "features/feedback/search-by-author/model";

export const SearchMessagesByAuthorInput = () => {
  const { setSearch } = useSearchMessageByAuthor();

  const changeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <InputSearch placeholder={"Найти обращение"} onChange={changeSearchText} />
  );
};
