import { ChangeEvent } from "react";
import { InputSearch } from "shared/ui/Inputs";
import { useSearchCall } from "../model";

export const SearchCall = () => {
  const { search } = useSearchCall();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    search(event.target.value);
  };

  return <InputSearch placeholder="Найти звонок" onChange={handleChange} />;
};
