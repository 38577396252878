import { NavigationLink } from "./ui";
import styles from "./navigation.module.scss";

export interface NavigationProps {
  links: { title: string; path: string }[];
}

export const Navigation = ({ links }: NavigationProps) => {
  return (
    <div className={styles.navigation}>
      {links &&
        links.map((link) => (
          <NavigationLink link={link.path} text={link.title} key={link.path} />
        ))}
    </div>
  );
};
