import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryData, IFeedbackMessage } from "shared/types";
import { IFeedbackState } from "shared/store/reducers/types";

const initialState: IFeedbackState = {
  info: {
    total: 0,
    pages: 0,
    is_last: true,
  },
  searchParams: {
    currentPage: 1,
    itemsPerPage: 50,
  },
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setQueryData: (
      state,
      action: PayloadAction<QueryData<IFeedbackMessage[]>>
    ) => {
      state.info.is_last = action.payload.is_last;
      state.info.pages = action.payload.pages;
      state.info.total = action.payload.total;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.searchParams.currentPage = action.payload;
    },
    setMessagesPerPage: (state, action: PayloadAction<number>) => {
      state.searchParams.itemsPerPage = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string | undefined>) => {
      state.searchParams.searchText = action.payload;
    },
    setDate: (state, action: PayloadAction<Date | undefined>) => {
      state.searchParams.createdAt = action.payload;
    },
  },
});

export const feedbackActions = feedbackSlice.actions;

export default feedbackSlice.reducer;
