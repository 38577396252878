import { Api, tags } from "../..";
import { IVerificationsRequirement, IUserVerification, ID } from "shared/types";
import {
  IDeclineUserVerificationRequestDto,
  IUsersVerificationReponseDto,
  IUsersVerificationRequestDto,
} from "shared/api/dto";

const basePath = "api/admin-verifications/";
const pathWithId = (id: number | string) => `${basePath}${id}/`;
const approve = (id: number | string) =>
  `api/admin-verifications/${id}/approve/`;
const decline = (id: number | string) =>
  `api/admin-verifications/${id}/decline/`;
const requirements = (id: number | string) =>
  `api/admin-verifications/requirements/${id}/`;

export const verificationsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    setVerifications: builder.mutation<void, IVerificationsRequirement>({
      query: (data) => ({
        url: requirements(data.name),
        method: "PATCH",
        body: { description: data.description },
      }),
      invalidatesTags: [tags.verificationsRequirements],
    }),

    getUserVerificationInfo: builder.query<IUserVerification, ID>({
      query: (data) => pathWithId(data.id),
    }),

    approveUser: builder.mutation<void, ID>({
      query: (data) => ({
        url: approve(data.id),
        method: "PATCH",
      }),
      invalidatesTags: [tags.usersForVerification],
    }),

    declineUser: builder.mutation<void, IDeclineUserVerificationRequestDto>({
      query: (data) => ({
        url: decline(data.id),
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [tags.usersForVerification],
    }),

    getUsersForVerification: builder.query<
      IUsersVerificationReponseDto,
      IUsersVerificationRequestDto
    >({
      query: (data) => ({ url: basePath, params: data }),
      providesTags: [tags.usersForVerification],
    }),
  }),
});

export const {
  useSetVerificationsMutation,
  useGetUserVerificationInfoQuery,
  useApproveUserMutation,
  useDeclineUserMutation,
  useGetUsersForVerificationQuery,
} = verificationsApi;
