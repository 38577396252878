import {
  useAppSelector,
  useAppDispatch,
  usePagination,
} from "shared/hooks/shared";
import { promotionSelector, promotionActions } from "shared/store";

export const usePromotionsPagination = () => {
  const { info, currentPage, promotionsPerPage } =
    useAppSelector(promotionSelector);
  const dispatch = useAppDispatch();

  const setPage = (page: number) => {
    dispatch(promotionActions.setCurrentPage(page));
  };

  const setItemsPerPage = (itemsCount: number) => {
    dispatch(promotionActions.setPromotionsPerPage(itemsCount));
  };

  const pagination = usePagination({
    info,
    setPage,
    currentPage,
    items: promotionsPerPage,
    setItemsPerPage,
  });

  return { pagination };
};
