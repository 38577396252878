import { InputSearch } from "shared/ui/Inputs";
import { useSearchUser } from "../model";

export const SearchUser = () => {
  const { searchTerm, setSearchText } = useSearchUser();
  return (
    <InputSearch
      placeholder="Найти пользователя"
      value={searchTerm}
      onChange={setSearchText}
    />
  );
};
