import classNames from "classnames";
import { Modal } from "shared/ui/Modal";
import { useComplaintModal } from "features/complaints/show-complaint-info";
import { ComplaintReasons, ComplaintReasonType } from "shared/types";
import styles from "./ComplaintInfoModal.module.scss";

export const ComplaintInfoModal = () => {
  const { description, type, comment, post_url } = useComplaintModal();

  return (
    <Modal>
      <div className={styles.complaintModal}>
        <p className={styles.complaintModal__title}>Описание жалобы</p>
        <div className={styles.complaintModal__content}>
          <div className={styles.complaintModal__block}>
            <p className={styles.complaintModal__blockTitle}>тип жалобы</p>
            <p
              className={classNames([
                styles.complaintModal__blockDescription,
                styles.complaintModal__blockBold,
              ])}
            >
              {ComplaintReasons[type as ComplaintReasonType] || ""}
            </p>
          </div>
          <div className={styles.complaintModal__block}>
            <p className={styles.complaintModal__blockTitle}>описание</p>
            <p className={styles.complaintModal__blockDescription}>
              {description}
            </p>
          </div>
          {comment && (
            <div className={styles.complaintModal__block}>
              <p className={styles.complaintModal__blockTitle}>
                текст комментария
              </p>
              <p className={styles.complaintModal__blockDescription}>
                {comment.text}
              </p>
            </div>
          )}
          {post_url && (
            <div className={styles.complaintModal__block}>
              <p className={styles.complaintModal__blockTitle}>
                ссылка на публикацию
              </p>
              <a
                href={post_url}
                className={styles.complaintModal__blockDescription}
              >
                {post_url}
              </a>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
