import { DeleteButton } from "shared/ui/Buttons";
import { Modal } from "shared/ui/Modal";
import { DescriptionBlock } from "shared/ui/DescriptionBlock";
import { AudioPlayer } from "shared/ui/AudioPlayer";
import styles from "./CallDataModal.module.scss";
import { useGetCallRecordQuery } from "shared/api/endpoints/admin/calls.api";
import { useDeleteRecord } from "features/calls/delete-record";

interface CallDataModalProps {
  closeModal?: () => void;
  id: number;
  user: string;
}

export const CallDataModal = ({ id, user, closeModal }: CallDataModalProps) => {
  const callData = useGetCallRecordQuery({ id });
  const { deleteRecordWithCofirm } = useDeleteRecord(id);

  const audioSrc = callData.isSuccess ? callData.data?.audio?.url : "";
  const videoSrc = callData.isSuccess ? callData.data?.video?.url : "";

  const deleteRecord = () => {
    deleteRecordWithCofirm();
  };

  const isRecordExists = audioSrc || videoSrc;

  return (
    <Modal onClose={closeModal}>
      <div className={styles.callDataModal}>
        <p className={styles.callDataModal__title}>
          {isRecordExists ? "Запись звонка" : "Записи звонка нет"}
        </p>
        <div className={styles.callDataModal__player}>
          {audioSrc && <AudioPlayer audioUrl={audioSrc} />}
          {videoSrc && (
            <video
              controls
              className={styles.callDataModal__video}
              src={videoSrc}
            />
          )}
        </div>
        {isRecordExists && (
          <DeleteButton onClick={deleteRecord}>Удалить запись</DeleteButton>
        )}
        <div className={styles.callDataModal__info}>
          <DescriptionBlock title="имя пользователя" content={user || ""} />
          <DescriptionBlock
            title="количество жалоб"
            content={
              callData.isSuccess
                ? callData.data?.complaints_count.toString()
                : "0"
            }
          />
        </div>
      </div>
    </Modal>
  );
};
