import { memo } from "react";
import { Link } from "react-router-dom";

import { IUser } from "shared/types";
import { Tooltip } from "shared/ui/Tooltip";
import { Pagination } from "shared/ui/Pagination";
import { useStickyMenu } from "shared/hooks/shared";
import {
  AlertIcon,
  ImagesIcon,
  BanIcon,
  EditIcon,
  DeleteIcon,
} from "shared/ui/Icons";

import { UsersTable, UsersTableRow } from "entities/users/UsersTable";

import { AddNewUser } from "features/users/add-new-user";
import { BanSelectedUsers } from "features/users/ban-selected-users";
import { DeleteSelectedUsers } from "features/users/delete-selected-users";
import { FilterActiveUsers } from "features/users/filter-active-users";
import { FilterVerifiedUsers } from "features/users/filter-verified-users";
import { SearchUser } from "features/users/search-user";
import { SelectUser } from "features/users/select-user";
import { useShowUserInfo } from "features/users/show-user-info";
import { useShowUserPosts } from "features/users/show-user-posts";
import { useDeleteUser } from "features/users/delete-user";
import { useBanUser } from "features/users/ban-user";

import { useUsers, useUsersPagination } from "./model";

import styles from "./users.module.scss";
import { TableStickyMenu } from "shared/ui/TableStickyMenu";

interface UserControlsProps {
  user: IUser;
}

const UserControls = memo(({ user }: UserControlsProps) => {
  const { openUserDataModal } = useShowUserInfo(user);
  const { showUserPosts } = useShowUserPosts(user.username);
  const { deleteUser } = useDeleteUser(user);
  const { banUser } = useBanUser(user);

  return (
    <div className={styles.userTableRowOptions}>
      <div className={styles.userTableRowOptions__group}>
        <Tooltip tooltip="Информация">
          <AlertIcon onClick={openUserDataModal} />
        </Tooltip>
        <Tooltip tooltip="Публикации">
          <ImagesIcon onClick={showUserPosts} />
        </Tooltip>
      </div>
      <div className={styles.userTableRowOptions__group}>
        <Tooltip tooltip="Удалить">
          <DeleteIcon onClick={deleteUser} />
        </Tooltip>
        <Tooltip tooltip={user.is_active ? "Забанить" : "Разбанить"}>
          <BanIcon onClick={banUser} />
        </Tooltip>

        <Link to={`/users/edit-profile/user/${user.id}`}>
          <Tooltip tooltip="Редактировать">
            <EditIcon />
          </Tooltip>
        </Link>
      </div>
    </div>
  );
});

export const Users = memo(() => {
  const { users, selectedUsersCount, loadingStatus } = useUsers();
  const { pagination } = useUsersPagination();
  const { ref, wrapperRef, size, isStickyMenuVisible } = useStickyMenu();

  return (
    <div className={styles.usersTable} ref={wrapperRef}>
      <div className={styles.usersTable__paginationTop}>
        <div className={styles.container}>
          <div className={styles.usersSort}>
            <SearchUser />
            <AddNewUser />
            <DeleteSelectedUsers />
            <BanSelectedUsers />
          </div>
          <div className={styles.usersSearchPannel}>
            <FilterActiveUsers />
            <FilterVerifiedUsers />
          </div>
        </div>
        <Pagination isShort withSelector {...pagination} />
      </div>

      <TableStickyMenu
        isVisible={!!(isStickyMenuVisible && selectedUsersCount > 0)}
        width={size.clientWidth}
        label={`Выбрано пользователей: ${selectedUsersCount}`}
        controls={
          <>
            <DeleteSelectedUsers />
            <BanSelectedUsers />
          </>
        }
      />

      <UsersTable headerRef={ref} dataLoading={loadingStatus.isLoading}>
        {users &&
          users.map((user, ind) => (
            <UsersTableRow
              selectOption={<SelectUser user={user} />}
              controls={<UserControls user={user} />}
              data={user}
              even={!!(ind % 2)}
              key={user.id}
            />
          ))}
      </UsersTable>
      {loadingStatus.isSuccess && <Pagination {...pagination} />}
    </div>
  );
});
