import { OptionButton } from "shared/ui/Buttons";
import { useDeclineSelectedVerifications } from "./model";

export const DeclineSelectedUsers = () => {
  const { declineSelected, selectedItemsCount } =
    useDeclineSelectedVerifications();
  return (
    <OptionButton disabled={!selectedItemsCount} onClick={declineSelected}>
      Отклонить заявки
    </OptionButton>
  );
};
