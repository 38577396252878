import { ReactNode } from "react";
import { IUser } from "shared/types";
import { TableRowDataProps, TableRow, TableData } from "shared/ui/Table";

interface UsersTableRowProps extends TableRowDataProps<IUser> {
  selectOption?: ReactNode;
  controls?: ReactNode;
}

export const UsersTableRow = ({
  even,
  data,
  controls,
  selectOption,
}: UsersTableRowProps) => {
  return (
    <TableRow even={even} animationKey={data?.id}>
      <TableData>{selectOption}</TableData>
      <TableData width={200}>{data?.username}</TableData>
      <TableData width={200}>{data?.email}</TableData>
      <TableData>{data?.phone_number}</TableData>
      <TableData>{data?.is_active ? "нет" : "да"}</TableData>
      <TableData>{data?.is_verified ? "да" : "нет"}</TableData>
      <TableData width={300}>{controls}</TableData>
    </TableRow>
  );
};
