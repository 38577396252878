import { ReactNode } from "react";
import { getRuLocaleTimeString } from "shared/lib";
import { TableData, TableRow } from "shared/ui/Table";
import styles from "./CallsTable.module.scss";

interface CallsTableRowProps {
  call: {
    id: number;
    call_id: number;
    user: string;
    star: string;
    is_video: boolean;
    start_time: string;
    payment_url: string | null;
  };
  selectOption: ReactNode;
  controlsOptions: ReactNode;
  infoOption: ReactNode;
}

export const CallsTableRow = ({
  call,
  selectOption,
  controlsOptions,
  infoOption,
}: CallsTableRowProps) => {
  return (
    <TableRow even={false}>
      <TableData width={40}>{selectOption}</TableData>
      <TableData width={240}>{call.star}</TableData>
      <TableData width={240}>{call.user}</TableData>
      <TableData>
        <div className={styles.callsRow__dateAndTime}>
          <span className={styles.callsRow__date}>
            {getRuLocaleTimeString(call.start_time)}
          </span>
        </div>
      </TableData>
      <TableData width={40}>{infoOption}</TableData>
      <TableData>
        <div className={styles.callsRow__icon}>{controlsOptions}</div>
      </TableData>
      <TableData width={220}>
        {call.payment_url ? (
          <div className={styles.callsRow__links}>
            <a
              href={call.payment_url}
              target="_blank"
              className={styles.callsRow__link}
              rel="noreferrer"
            >
              {call.payment_url}
            </a>
          </div>
        ) : (
          "---"
        )}
      </TableData>
    </TableRow>
  );
};
