import { useAppSelector } from "shared/hooks/shared";
import { Modal } from "shared/ui/Modal";
import styles from "./style.module.scss";

export const ImageModal = () => {
  const { image } = useAppSelector((state) => state.modal.showImageModalData);
  return (
    <Modal>
      <div className={styles.imageModal}>
        <div className={styles.imageModal__content}>
          <div className={styles.imageModal__media}>
            <img
              src={image || ""}
              alt=""
              className={styles.imageModal__contentImage}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
