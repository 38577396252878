import classNames from "classnames";
import { useState } from "react";
import { ArrowIcon, ArrowDirection } from "shared/ui/Icons";
import styles from "./pagination.module.scss";

export interface PaginationContolsProps {
  viewed: number;
  total: number;
  itemsPerPage: number;
  nextPage: () => void;
  prevPage: () => void;
  setItemsPerPage?: (itemsCount: number) => void;
  isShort?: boolean;
  withSelector?: boolean;
  pages?: number;
}

export const Pagination = ({
  viewed,
  total,
  itemsPerPage,
  prevPage,
  nextPage,
  isShort,
  withSelector,
  setItemsPerPage,
}: PaginationContolsProps) => {
  const [isOpenSelector, setIsOpenSelector] = useState(false);

  const toogleOpenSelector = () => {
    setIsOpenSelector((isOpen) => !isOpen);
  };

  const selectorOptions = [50, 100, 300, 500];

  const lastPage = viewed === total;

  return (viewed && total) || isShort ? (
    <div className={styles.paginationControls}>
      <div className={styles.paginationControls__controls}>
        {withSelector && (
          <div className={styles.selector}>
            <div className={styles.selector__icon} onClick={toogleOpenSelector}>
              <ArrowIcon
                direction={
                  isOpenSelector ? ArrowDirection.up : ArrowDirection.down
                }
              />
            </div>
            <div
              className={classNames(styles.selector__body, {
                [styles.selector__bodyOpen]: isOpenSelector,
              })}
            >
              {selectorOptions.map((option) => (
                <button
                  className={classNames(styles.selector__button, {
                    [styles.selector__buttonActive]: option === itemsPerPage,
                  })}
                  key={option}
                  onClick={() => {
                    setItemsPerPage && setItemsPerPage(option);
                  }}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        )}
        <span
          className={styles.paginationControls__totalPages}
        >{`${viewed}/${total}`}</span>
        <ArrowIcon
          direction={ArrowDirection.left}
          className={classNames({
            [styles.paginationControls__controlsDisabled]:
              viewed === itemsPerPage || (viewed < itemsPerPage && lastPage),
          })}
          onClick={prevPage}
        />
        <ArrowIcon
          direction={ArrowDirection.right}
          className={classNames({
            [styles.paginationControls__controlsDisabled]: lastPage,
          })}
          onClick={nextPage}
        />
      </div>
    </div>
  ) : null;
};
