import { ChangeEvent, KeyboardEvent, useId, useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "shared/ui/Logo";
import { Button } from "shared/ui/Buttons";
import { useAuthUserMutation } from "shared/api";
import { useNotification } from "shared/hooks/shared";
import { FormPasswordInput } from "./FormPasswordInput";
import styles from "./form.module.scss";

export const LoginForm = () => {
  const [login] = useAuthUserMutation();
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [data, setData] = useState({
    login: "",
    password: "",
  });

  const loginId = useId();
  const passwordId = useId();

  const { show } = useNotification();

  const handleSubmit = () => {
    if (!data.login || !data.password) {
      setIsIncomplete(true);
    } else {
      setIsIncomplete(false);
      login({ username: data.login, password: data.password })
        .unwrap()
        .then(() => {})
        .catch(() => {
          if (!isIncomplete) {
            show({
              text: (
                <>
                  Неправильный логин <br /> или пароль
                </>
              ),
              timeout: 5000,
              isError: true,
            });
          }
        });
    }
  };

  const setLogin = (event: ChangeEvent<HTMLInputElement>) => {
    setData((data) => ({ ...data, login: event.target.value }));
  };

  const setPassword = (password: string) => {
    setData((data) => ({ ...data, password }));
  };

  const pressEnter = (event: KeyboardEvent) => {
    if (event.key === "Enter" && data.login && data.password) {
      handleSubmit();
    }
  };

  return (
    <div className={styles.loginForm} onKeyDown={pressEnter}>
      <div className={styles.loginForm__logo}>
        <Logo scale={2} />
      </div>
      <div className={styles.formItem}>
        <label htmlFor={loginId} className={styles.formItem__label}>
          Логин
        </label>
        <input
          id={loginId}
          type="text"
          className={styles.loginForm__input}
          onChange={setLogin}
        />
      </div>

      <div className={styles.formItem}>
        <label htmlFor={passwordId} className={styles.formItem__label}>
          Пароль
        </label>
        <FormPasswordInput id={passwordId} onChange={setPassword} />
      </div>

      <Link to="/reset" className={styles.loginForm__restoreButton}>
        Восстановление пароля
      </Link>

      <Button
        disabled={!(data.login && data.password)}
        type="solid"
        className={styles.loginForm__submitButton}
        onClick={handleSubmit}
      >
        Войти
      </Button>
    </div>
  );
};
export default LoginForm;
