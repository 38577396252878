import { DeleteButton } from "shared/ui/Buttons";
import { useDeleteCall } from "../model";

export const DeleteCall = () => {
  const { deleteCalls, selectedCallsCount } = useDeleteCall();
  return (
    <DeleteButton disabled={selectedCallsCount === 0} onClick={deleteCalls}>
      Удалить
    </DeleteButton>
  );
};
