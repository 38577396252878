import { ICreateAdvertisementsDto } from "shared/api/dto/adverstisements.dto";
import {
  useCreateAdvertisementMutation,
  useUpdateAdvertisementMutation,
} from "shared/api/endpoints/admin/advertisements.api";

export const useEditBanner = () => {
  const [createAdvertisement] = useCreateAdvertisementMutation();
  const [updateAdvertisement] = useUpdateAdvertisementMutation();

  const create = async (data: ICreateAdvertisementsDto) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      const dataKey = key as keyof ICreateAdvertisementsDto;
      const dataItem = data[dataKey];
      if (dataItem) {
        if (!(dataItem instanceof File)) {
          formData.append(key, dataItem.toString());
        } else {
          formData.append(key, dataItem);
        }
      }
    });

    return createAdvertisement(formData).unwrap();
  };

  const update = async (data: ICreateAdvertisementsDto, id: string) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      const dataKey = key as keyof ICreateAdvertisementsDto;
      const dataItem = data[dataKey];
      if (dataItem) {
        if (!(dataItem instanceof File)) {
          if (key !== "image") {
            formData.append(key, dataItem.toString());
          }
        } else {
          formData.append(key, dataItem);
        }
      }
    });

    return updateAdvertisement({ form: formData, id }).unwrap();
  };

  return { create, update };
};
