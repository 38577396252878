import { useCommission } from "shared/hooks/core";
import { HtmlEditor } from "shared/ui/HtmlEditor";
import * as Layout from "shared/ui/layout";

export const Commission = () => {
  const { commission, changeCommissionDescription } = useCommission();

  return (
    <Layout.Content
      title={
        <>
          Редактирование процесса <br /> удержания комиссии
        </>
      }
    >
      {commission && (
        <HtmlEditor
          description={commission.description || ""}
          saveText={changeCommissionDescription}
        />
      )}
    </Layout.Content>
  );
};
