import { CommentCard } from "entities/comments/CommentCard";

import { IPostComment } from "shared/types";
import { useNestedComments } from "./model/useNestedComments";

interface PostCommentCardProps {
  comment: IPostComment;
}

export const PostCommentCard = ({ comment }: PostCommentCardProps) => {
  const { nestedComments } = useNestedComments(comment);

  return (
    <CommentCard
      avatar={comment.user.avatar}
      username={comment.user.username}
      text={comment.text}
      id={comment.id}
      nestedComments={nestedComments.map((nestedComment) => (
        <CommentCard
          key={nestedComment.id}
          avatar={nestedComment.user.avatar}
          username={nestedComment.user.username}
          text={nestedComment.text}
          id={nestedComment.id}
          isNestedComment={true}
        />
      ))}
    />
  );
};
