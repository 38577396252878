import {
  memo,
  useState,
  useMemo,
  useCallback,
  useEffect,
  ChangeEvent,
} from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { Select } from "shared/ui/Select";
import { Button } from "shared/ui/Buttons";
import { Calendar } from "shared/ui/Calendar";
import { Selector } from "shared/ui/Selector";
import { getFullDateFormat } from "shared/lib";
import { ScrollBar } from "shared/ui/ScrollBar";
import { InputWithOptions } from "shared/ui/Inputs";
import { IContentCategory, IUserFullInfo } from "shared/types";
import { Input } from "shared/ui/Inputs";

import { AvatarLoadForm } from "widgets/AvatarLoadForm";
import { useTags } from "shared/hooks/core";
import { useEditUserProfile } from "features/users/edit-user-profile";

import { InfoInputProps, UserCategoryOption } from "../EditProfile.interface";
import styles from "../EditProfile.module.scss";

export const UserInfoInput = memo(
  ({
    title,
    value,
    field,
    onChange,
    onDelete,
  }: InfoInputProps<IUserFullInfo>) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value, field);
    };

    const handleDelete = () => {
      onDelete(field);
    };

    return (
      <div className={styles.editProfile__row}>
        <span className={styles.editProfile__title}>{title}</span>
        <div className={styles.editProfile__inputWrapper}>
          <InputWithOptions
            value={value || ""}
            onChange={handleChange}
            deleteHandler={handleDelete}
            className={styles.editProfile__input}
          />
        </div>
      </div>
    );
  }
);

const UserCategory = memo(({ category, setCategory }: UserCategoryOption) => {
  const handleClick = () => {
    setCategory(category);
  };
  return (
    <p onClick={handleClick} className={styles.editProfile__option}>
      {category.name}
    </p>
  );
});

export const EditUser = () => {
  const { id } = useParams();

  const { tags } = useTags();
  const { userData, deleteAvatar, saveAvatar, saveUserInfo } =
    useEditUserProfile({ id });

  const [imageFile, setImageFile] = useState<File | FormData | null>(null);

  const [data, setData] = useState<IUserFullInfo>({
    avatars: [
      {
        image: "",
        id: 0,
      },
    ],
    username: "",
    first_name: "",
    last_name: "",
    gender: 3,
    description: "",
    email: "",
    phone_number: "",
    birth_date: "",
    is_verified: false,
    category: {
      id: 0,
      name: "",
    },
    created_at: "",
  });

  const genderOptions = useMemo(
    () => [
      { title: "муж", value: 1, isDefaultOption: data.gender === 1 },
      { title: "жен", value: 2, isDefaultOption: data.gender === 2 },
    ],
    [data.gender]
  );

  const birthdayDate = useMemo(
    () => (data.birth_date ? new Date(data.birth_date) : null),
    [data.birth_date]
  );

  const setGenderOption = useCallback((gender: number) => {
    setData((data) => ({ ...data, gender }));
  }, []);

  const handleSetBirthdayDate = useCallback((date: Date) => {
    setData((data) => ({ ...data, birth_date: getFullDateFormat(date) }));
  }, []);

  const handleCategoryClick = useCallback((category: IContentCategory) => {
    setData((data) => ({ ...data, category }));
  }, []);

  const handleSaveImage = () => {
    saveAvatar(imageFile as FormData);
  };

  const submit = () => {
    handleSaveImage();
    saveUserInfo(data);
  };

  const changeDataField = useCallback(
    (value: string, field: keyof IUserFullInfo) => {
      setData((data) => ({ ...data, [field]: value }));
    },
    []
  );

  const deleteDataField = useCallback((field: keyof IUserFullInfo) => {
    setData((data) => ({ ...data, [field]: "" }));
  }, []);

  useEffect(() => {
    if (userData) {
      setData((data) => ({ ...data, ...userData }));
    }
  }, [userData]);

  return (
    <div className={styles.editProfile}>
      <div>
        <AvatarLoadForm
          image={data?.avatars[0]?.image}
          setImageFile={setImageFile}
          deleteImageFile={deleteAvatar}
        />
        <Button
          type="solid"
          className={styles.editProfile__button}
          onClick={submit}
        >
          Сохранить
        </Button>
      </div>
      <div className={styles.editProfile__info}>
        <UserInfoInput
          title="Имя пользователя"
          value={data.username}
          field="username"
          onChange={changeDataField}
          onDelete={deleteDataField}
        />
        <UserInfoInput
          title="Имя"
          value={data.first_name}
          field="first_name"
          onChange={changeDataField}
          onDelete={deleteDataField}
        />
        <UserInfoInput
          title="Фамилия"
          value={data.last_name}
          field="last_name"
          onChange={changeDataField}
          onDelete={deleteDataField}
        />
        <div className={styles.editProfile__row}>
          <span>Пол</span>
          <div className={styles.editProfile__inputWrapper}>
            <Selector<number>
              options={genderOptions}
              setFunc={setGenderOption}
            />
          </div>
        </div>
        <UserInfoInput
          title="Описание профиля"
          value={data.description}
          field="description"
          onChange={changeDataField}
          onDelete={deleteDataField}
        />
        <div className={styles.editProfile__row}>
          <span>Электронная почта</span>
          <div className={styles.editProfile__inputWrapper}>
            <Input
              value={data.email}
              className={styles.editProfile__input}
              disabled
            />
          </div>
        </div>
        <UserInfoInput
          title="Номер телефона"
          value={data.phone_number}
          field="phone_number"
          onChange={changeDataField}
          onDelete={deleteDataField}
        />
        <div className={styles.editProfile__row}>
          <span>Дата рождения</span>
          <div className={styles.editProfile__inputWrapper}>
            <Calendar
              className={styles.editProfile__input}
              value={birthdayDate}
              setDate={handleSetBirthdayDate}
            />
          </div>
        </div>
        <div className={styles.editProfile__row}>
          <span
            className={classNames(
              styles.editProfile__title,
              styles.editProfile__titleOnTop
            )}
          >
            Категория
          </span>
          <div className={styles.editProfile__inputWrapper}>
            <Select
              iconSize={22}
              title={data?.category?.name}
              className={styles.editProfile__select}
              overflow
            >
              <ScrollBar height={160}>
                {tags &&
                  tags.map((category) => (
                    <UserCategory
                      category={category}
                      setCategory={handleCategoryClick}
                      key={category.id}
                    />
                  ))}
              </ScrollBar>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};
