export function isValidEmail(email: string) {
  const regex = new RegExp(
    /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
  );
  return regex.test(email);
}

/**
 * Форматирование для передачи её в параметрах сортировки/фильтрации по дате
 * и для изменения дня рождения пользователя.
 * @return строка формата "год-месяц-день"
 * */
export function getFullDateFormat(date: Date | string | number) {
  const [day, month, year] = (
    typeof date === "string" || typeof date === "number" ? new Date(date) : date
  )
    .toLocaleDateString("ru-RU", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split(".");
  if (year && day && month) {
    return `${year}-${month}-${day}`;
  }
  return `Некорректная дата`;
}

/**
 * Форматирование даты для вывода в таблицах.
 * Дата прихолит с бека в ISO 8601 формате.
 * @return строка формата "день-месяц-год"
 * */
export function getRuLocaleDateString(date: string) {
  try {
    const tmpDate = new Date(date).toISOString();
    const [year, month, day] = tmpDate.slice(0, 10).split("-");
    return `${day}-${month}-${year}`;
  } catch (err) {
    return "Некорректная дата";
  }
}

export function getRuLocaleTimeString(date: string) {
  try {
    const tmpDate = new Date(date).toLocaleString("ru-RU");
    return tmpDate;
  } catch (err) {
    return "Некорректная дата";
  }
}

/**
 * @return строка формата "день-месяц-год"
 * */
export function getYestedayDateString() {
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  return yesterday.toLocaleDateString("ru-RU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

/**
 * @secondsNum количество секунд
 * @return строка формата "hh:mm:ss" либо "mm:ss"
 * */
export const getFormatTimeFromSeconds = (secondsNum: number) => {
  const hours = Math.floor(secondsNum / 3600);
  const minutes = Math.floor((secondsNum - hours * 3600) / 60);
  const seconds = Math.floor(secondsNum - hours * 3600 - minutes * 60);

  let hoursStr = "",
    minutesStr = "",
    secondsStr = "";

  if (hours > 0) {
    hoursStr = String(hours).padStart(2, "0") + ":";
  }

  minutesStr = String(minutes).padStart(2, "0") + ":";

  secondsStr = String(seconds).padStart(2, "0");

  return hoursStr + minutesStr + secondsStr;
};

/**
 * Функция для определения правильного склонения
 * */
export const decOfNum = (number: number, titles: string[]) => {
  const decCases = [2, 0, 1, 1, 1, 2];
  const decCache: number[] = [];

  if (!decCache[number])
    decCache[number] =
      number % 100 > 4 && number % 100 < 20
        ? 2
        : decCases[Math.min(number % 10, 5)];
  return titles[decCache[number]];
};

export const decOfUsers = (count: number) => {
  return decOfNum(count, ["пользователя", "пользователей", "пользователей"]);
};

export const decOfWhitelistedDomains = (count: number) => {
  return decOfNum(count, [
    "платежную ссылку",
    "платежные ссылки",
    "платежных ссылок",
  ]);
};

export const decOfCallsFeedback = (count: number) => {
  return decOfNum(count, ["отзыв", "отзыва", "отзывов"]);
};

export const decOfAdmins = (count: number) => {
  return decOfNum(count, [
    "aдминистратора",
    "администраторов",
    "администраторов",
  ]);
};

export const decOfPosts = (count: number) => {
  return decOfNum(count, ["публикацию", "публикации", "публикаций"]);
};

export const decOfVerefications = (count: number) => {
  return decOfNum(count, ["заявку", "заявки", "заявок"]);
};

export const getMonthTitle = (date: Date, full?: boolean) => {
  const month = date.toLocaleString("ru-RU", { month: "long" });
  return full ? month : `${month.slice(0, 3)}.`;
};

export const getMonthByWeek = (week: number, full?: boolean) => {
  return getMonthTitle(new Date(1000 * 60 * 60 * 24 * 7 * week), full);
};

export const getCurrentDateInterval = () => {
  const now = new Date();
  const week = new Date(now).setDate(now.getDate() - 6);
  const month = new Date(now).setDate(now.getDate() - 30);
  const year = new Date(now.getFullYear(), 0, 1);

  return {
    now: getFullDateFormat(now),
    week: getFullDateFormat(week),
    month: getFullDateFormat(month),
    year: getFullDateFormat(year),
  };
};

export function getDateOfISOWeek(
  week: number,
  year: number,
  day: number,
  config: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
  }
) {
  const firstDayOfYear = new Date(year, 0, 1);
  const days = 2 + day + (week - 1) * 7 - firstDayOfYear.getDay();
  return new Date(year, 0, days).toLocaleDateString("ru-RU", config);
}

export function getDateObjectOfISOWeek(
  week: number,
  year: number,
  day: number
) {
  const firstDayOfYear = new Date(year, 0, 1);
  const days = 2 + day + (week - 1) * 7 - firstDayOfYear.getDay();
  return new Date(year, 0, days);
}

export function daysDiff(date1: Date | null, date2: Date | null) {
  if (!date1 || !date2) {
    return 0;
  }
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
}

export function valueFormat(val: any, index: number) {
  const value = Number(val);

  return value > 1000 ? Math.floor(value / 1000) + "к" : value.toString();
}
