import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import WaveSurfer from "wavesurfer.js";
import { getFormatTimeFromSeconds } from "shared/lib";
import { Range } from "shared/ui/Range";
import PauseIcon from "./assets/pause.png";
import PlayIcon from "./assets/play.png";
import { Spinner } from "shared/ui/Spinner";
import styles from "./AudioWave.module.scss";

interface AudioPlayerProps {
  audioUrl: string;
}

/**
 * Принимает ссылку на аудиозапись и рендерит компонент
 * плеера, с кнопкой play/pause, регулирование громкости
 * и canvas с визуализацией звука.
 */
export const AudioPlayer = ({ audioUrl }: AudioPlayerProps) => {
  const waveRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const [playing, setIsPlaying] = useState(false);
  const [timings, setTimings] = useState({
    duration: "",
    played: "",
  });

  const play = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.play();
    }
  };

  const playOrPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();

      if (wavesurferRef.current.isPlaying()) {
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
      }
    }
  };

  const changeTimings = () => {
    const secondsDuration = wavesurferRef.current?.getDuration() || 0;
    const secondsPlayed = wavesurferRef.current?.getCurrentTime() || 0;

    const duration = getFormatTimeFromSeconds(secondsDuration);
    const played = getFormatTimeFromSeconds(secondsPlayed);
    setTimings({ duration, played });

    if (wavesurferRef.current?.isPlaying()) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  };

  const changeVolume = (volume: number) => {
    if (
      waveRef.current &&
      wavesurferRef.current?.isReady &&
      wavesurferRef.current?.setVolume
    ) {
      try {
        wavesurferRef.current.setVolume(volume / 100);
      } catch {}
    }
  };

  useEffect(() => {
    if (waveRef.current && audioRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveRef.current,
        barWidth: 4,
        cursorWidth: 1,
        backend: "WebAudio",
        height: 160,
        progressColor: "#4E51BF",
        responsive: true,
        waveColor: "#F2F2F2",
        cursorColor: "transparent",
        closeAudioContext: true,
      });

      wavesurferRef.current?.load(audioRef.current);

      wavesurferRef.current?.on("ready", changeTimings);
      wavesurferRef.current?.on("audioprocess", changeTimings);
      wavesurferRef.current?.setVolume(0.5);
    }
    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
        wavesurferRef.current?.un("ready", changeTimings);
        wavesurferRef.current?.un("audioprocess", changeTimings);
      }
    };
  }, []);

  return (
    <div className={styles.audioWave}>
      <div className={styles.audioWave__audio}>
        <span className={styles.audioWave__duration}>{timings.played}</span>
        <div className={styles.audioWave__wave}>
          <div data-container="wavesurfer" ref={waveRef} onClick={play}></div>
          <audio ref={audioRef} src={audioUrl} />
        </div>
        <span className={styles.audioWave__duration}>{timings.duration}</span>
      </div>
      {!timings.duration && (
        <div className={styles.audioWave__spinner}>
          <Spinner />
        </div>
      )}
      <div
        className={classNames(styles.audioWave__controls, {
          [styles.audioWave__controlsDisabled]: !timings.duration,
        })}
      >
        <button onClick={playOrPause} className={styles.audioWave__playButton}>
          <img src={playing ? PauseIcon : PlayIcon} alt="play/pause control" />
        </button>
        <Range min={0} max={100} defaultValue={50} onChange={changeVolume} />
      </div>
    </div>
  );
};
