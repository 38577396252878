import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { confirmSelector } from "shared/store";
import { confirmModalActions } from "shared/store/reducers/confirmModalReducer";

export const useConfirmModal = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(confirmSelector);

  const closeModal = () => {
    dispatch(confirmModalActions.closeModal());
  };

  return { ...data, closeModal };
};
