import { DeleteIcon, EditIcon } from "../Icons";
import styles from "./style.module.scss";

interface EditLabelProps {
  text: string;
  editHandler?: () => void;
  deleteHandler?: () => void;
}

export const EditLabel = ({
  text,
  editHandler,
  deleteHandler,
}: EditLabelProps) => {
  return (
    <div className={styles.editLabel}>
      <label className={styles.editLabel__text}>{text}</label>
      <div className={styles.editLabel__icon}>
        {editHandler && <EditIcon size={24} onClick={editHandler} />}
        {deleteHandler && <DeleteIcon size={24} onClick={deleteHandler} />}
      </div>
    </div>
  );
};
