import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const PlusIcon = ({
  height,
  width,
  className,
  size = DEFAULT_ICON_SIZE,
  color = "black",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <path
        d="M19.6251 10.4H1.17505M10.4 1.17505V19.6251V1.17505Z"
        stroke={color}
        strokeWidth="2.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
