import { Api, tags } from "../..";
import {
  IRequestPostCommentsDto,
  IResponsePostCommentsDto,
} from "shared/api/dto";

export const postsCommonApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getPostComments: builder.query<
      IResponsePostCommentsDto,
      IRequestPostCommentsDto
    >({
      query: (data) => ({ url: `api/posts/${data.id}/comments/` }),
      providesTags: [tags.postComments],
    }),
  }),
});

export const { useGetPostCommentsQuery } = postsCommonApi;
