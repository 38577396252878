import { useNotification } from "shared/hooks/shared";
import { useAddConnectCardBenefitMutation } from "shared/api";

interface IAddOndoarding {
  imageFile?: File | string;
  bodyText?: string;
  headerText?: string;
}

export const useAddCardBindingBenefit = () => {
  const [addBenefit, resultAdd] = useAddConnectCardBenefitMutation();
  const { show, hide } = useNotification();

  const addNewBenefit = ({
    imageFile,
    bodyText,
    headerText,
  }: IAddOndoarding) => {
    if (imageFile && bodyText && headerText) {
      const formData = new FormData();
      formData.append("image", imageFile);
      formData.append("body", bodyText);
      formData.append("header", headerText);

      if (formData) {
        show({
          text: "Преимущество добавляется",
          withLoader: true,
          autoClose: false,
        });
        addBenefit(formData)
          .unwrap()
          .then(() => {
            hide();
            show({ text: "Преимущество добавлено" });
          })
          .catch(() => {
            hide();
          });
      }
    }
  };

  return {
    addNewBenefit,
    isAdded: resultAdd.isSuccess,
    resultId: resultAdd.isSuccess ? resultAdd.data.id : null,
    isLoading: resultAdd.isLoading,
  };
};
