import { IUser } from "shared/types";
import { Checkbox } from "shared/ui/Checkbox";
import { useSelectUser } from "../model";

interface SelectUserProps {
  user: IUser;
}

export const SelectUser = ({ user }: SelectUserProps) => {
  const { selected, selectUser } = useSelectUser(user);
  return <Checkbox checked={selected} onChange={selectUser} />;
};
