import { AgreementType } from "shared/types";
import { useGetAgreementsQuery, useSetAgreementsMutation } from "shared/api";

export const useAgreements = () => {
  const agreement = useGetAgreementsQuery(AgreementType.userAgreement);
  const sensitive = useGetAgreementsQuery(AgreementType.sensitiveData);

  const [setAgreements] = useSetAgreementsMutation();

  const userAgreement = agreement.isSuccess
    ? agreement.data
    : { type: AgreementType.userAgreement, description: "" };

  const sensitiveData = sensitive.isSuccess
    ? sensitive.data
    : { type: AgreementType.sensitiveData, description: "" };

  return { userAgreement, sensitiveData, setAgreements };
};
