import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { feedbackSelector, feedbackActions } from "shared/store";

export const useSearchMessageByDate = () => {
  const dispatch = useAppDispatch();
  const { searchParams } = useAppSelector(feedbackSelector);

  const setDate = (date: Date) => {
    dispatch(feedbackActions.setDate(date));
  };

  return { created_at: searchParams.createdAt, setDate };
};
