import { useAppDispatch } from "shared/hooks/shared";
import { modalActions, ModalType } from "shared/store";
import { ID } from "shared/types";

export const useVerificationModal = ({ id }: ID) => {
  const dispatch = useAppDispatch();

  const openVerificationModal = () => {
    dispatch(modalActions.openModal(ModalType.verificationModal));
    dispatch(modalActions.setVerificationData(id));
  };

  return { openVerificationModal };
};
