import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useCallback } from "react";
import { useDeleteAdminMutation } from "shared/api";
import { IAdmin } from "shared/types";

export const useDeleteAdmin = (admin: IAdmin) => {
  const [deleteAdminProfile] = useDeleteAdminMutation();
  const { open } = useShowConfirmModal();
  const { show } = useNotification();

  const deleteAdminWithCofirm = useCallback(() => {
    open({
      title: `Удалить администратора?`,
      buttonText: "Удалить",
      onConfirmCallback: () =>
        deleteAdminProfile({ id: admin.id })
          .unwrap()
          .then(() => {
            show({ text: "Администратор удален!" });
          }),
    });
  }, [admin.id, deleteAdminProfile, open, show]);

  return deleteAdminWithCofirm;
};
