import { useMemo, useState } from "react";
import { useGetSatisticsQuery } from "shared/api";
import {
  daysDiff,
  getCurrentDateInterval,
  getFullDateFormat,
  getMonthByWeek,
} from "shared/lib";
import { IDashboardStats, IFinanceStat } from "shared/types";
import { FinancesPeriod } from "../../finances/model";
import { dayFormatter } from "../../formaters";

const normalizeValues = (stat: IFinanceStat) => {
  return { key: stat.key, value: Number(stat.value) };
};

const yearFormat = (data: IDashboardStats) => {
  const visits: any[] = [];
  let month = { date: "", values: [] as any[] };

  const normalizeData = data?.rows_stat?.visits.map(normalizeValues) || [];

  normalizeData?.forEach((item, ind, arr) => {
    const [, week] = item.key.split("-").map(Number);
    const monthName = getMonthByWeek(week, true);

    if (month.date && month.date !== monthName) {
      if (month.values.length > 4) {
        while (month.values.length > 4) {
          month.values = [
            month.values[0] + month.values[1],
            ...month.values.splice(2),
          ];
        }
      }
      visits.push(month);
      month = { date: "", values: [] };
    }
    month.date = monthName;
    month.values.push(item.value);

    if (arr.length - 1 === ind) {
      visits.push(month);
    }
  });

  return {
    ...data,
    visits,
  };
};

const format = (data: IDashboardStats, formatter: (val: string) => string) => {
  const normalizeData = data?.rows_stat?.visits.map(normalizeValues) || [];

  return {
    ...data,
    visits: normalizeData.map((item) => ({
      date: formatter(item.key),
      values: [Number(item.value)],
    })),
  };
};

export const useOverallStats = () => {
  const dates = useMemo(() => getCurrentDateInterval(), []);
  const [period, setPeriod] = useState<FinancesPeriod>("year");
  const [customPeriod, setCustomPeriod] = useState<Record<string, Date | null>>(
    {
      start: null,
      end: null,
    }
  );

  const weekData = useGetSatisticsQuery(
    { style: "DAY", start: dates.week, finish: dates.now },
    {
      selectFromResult: ({ data }) => data ?? ({} as IDashboardStats),
    }
  );

  const monthData = useGetSatisticsQuery(
    { style: "DAY", start: dates.month, finish: dates.now },
    {
      selectFromResult: ({ data }) => data ?? ({} as IDashboardStats),
    }
  );

  const yearData = useGetSatisticsQuery(
    { style: "WEEK", start: dates.year, finish: dates.now },
    {
      selectFromResult: ({ data }) => data ?? ({} as IDashboardStats),
    }
  );

  const customData = useGetSatisticsQuery(
    {
      style: period === "custom" ? "DAY" : "WEEK",
      start: getFullDateFormat(customPeriod.start || ""),
      finish: getFullDateFormat(customPeriod.end || ""),
    },
    {
      skip: !customPeriod.start || !customPeriod.end,
      selectFromResult: ({ data }) => data ?? ({} as IDashboardStats),
    }
  );

  const weekDataFormatted = useMemo(
    () => format(weekData, dayFormatter),
    [weekData]
  );
  const monthDataFormatted = useMemo(
    () => format(monthData, dayFormatter),
    [monthData]
  );
  const yearDataFormatted = useMemo(() => {
    const [year] = dates.year.split("-");
    const visits =
      yearData?.rows_stat?.visits.filter(
        (item) => item.key.split("-")[0] === year
      ) || [];

    return yearFormat({ ...yearData, rows_stat: { visits } });
  }, [dates.year, yearData]);
  const customDataFormatted = useMemo(
    () =>
      period === "custom"
        ? format(customData, dayFormatter)
        : yearFormat(customData),
    [customData, period]
  );

  const selectPeriod = (
    period: FinancesPeriod,
    custom?: { start: Date | null; end: Date | null }
  ) => {
    if (period === "custom" && custom) {
      const diff = daysDiff(customPeriod.start, customPeriod.end);
      if (diff > 30) {
        setPeriod("custom-year");
      } else {
        setPeriod("custom");
      }
      setCustomPeriod(custom);
    } else {
      setPeriod(period);
    }
  };

  const data = useMemo(() => {
    switch (period) {
      case "week":
        return weekDataFormatted;
      case "month":
        return monthDataFormatted;
      case "year":
        return yearDataFormatted;
      case "custom":
      case "custom-year":
      case "custom-week":
        return customDataFormatted;
      default:
        return weekDataFormatted;
    }
  }, [
    customDataFormatted,
    monthDataFormatted,
    period,
    weekDataFormatted,
    yearDataFormatted,
  ]);

  const startDate = useMemo(() => {
    switch (period) {
      case "week":
        return dates.week;
      case "month":
        return dates.month;
      case "year":
        return dates.year;
      default:
        return getFullDateFormat(new Date());
    }
  }, [dates, period]);

  return { data, startDate, selectPeriod, period, customPeriod };
};
