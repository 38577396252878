import { ID } from "shared/types";
import { Api, tags } from "../..";
import {
  ICallsFeedbackListDto,
  ICallsFeedbackListRequestDto,
  IFeeadbackMessagesRequestDto,
  IFeedbackMessageReplyDto,
  IFeedbackMessagesDto,
} from "shared/api/dto";

export const feedbackApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbackList: builder.query<
      IFeedbackMessagesDto,
      IFeeadbackMessagesRequestDto
    >({
      query: (data) => ({ url: "api/admin-feedback/", params: data }),
      providesTags: [tags.feedbackList],
    }),
    getFeedbackMessages: builder.mutation<
      IFeedbackMessagesDto,
      IFeeadbackMessagesRequestDto
    >({
      query: (data) => ({
        url: "api/admin-feedback/",
        params: data,
        method: "GET",
      }),
    }),
    replyFeedback: builder.mutation<void, IFeedbackMessageReplyDto>({
      query: (data) => ({
        url: `api/admin-feedback/${data.id}/reply/`,
        method: "POST",
        body: data,
      }),
    }),
    getCallsFeedbackList: builder.query<
      ICallsFeedbackListDto,
      ICallsFeedbackListRequestDto
    >({
      query: (data) => ({ url: "api/admin-feedback/calls/", params: data }),
      providesTags: [tags.callsFeedbackList],
    }),
    deleteCallsFeedback: builder.mutation<void, ID>({
      query: (data) => ({
        url: `api/admin-feedback/calls/${data.id}/`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: [tags.callsFeedbackList],
    }),
    publishCallsFeedback: builder.mutation<void, ID>({
      query: (data) => ({
        url: `api/admin-feedback/calls/${data.id}/publish/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [tags.callsFeedbackList],
    }),
  }),
});

export const {
  useGetFeedbackListQuery,
  useReplyFeedbackMutation,
  useGetFeedbackMessagesMutation,
  useGetCallsFeedbackListQuery,
  useDeleteCallsFeedbackMutation,
  usePublishCallsFeedbackMutation,
} = feedbackApi;
