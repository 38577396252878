import { useState } from "react";
import { useGetWhitelistedDomainsQuery } from "shared/api/endpoints/admin/whitelisted-domains.api";
import { usePagination } from "shared/hooks/shared";
import { Checkbox } from "shared/ui/Checkbox";
import { ThrashIcon } from "shared/ui/Icons";
import * as Layout from "shared/ui/layout";
import { Pagination } from "shared/ui/Pagination";
import {
  Table,
  TableHeader,
  TableBody,
  TableData,
  TableRow,
} from "shared/ui/Table";

import styles from "./PaymentLinks.module.scss";
import { useIsomorphicLayoutEffect, useSet } from "react-use";
import { Button } from "shared/ui/Buttons";
import { useAddNewPaymentLinkModal } from "features/payments-links/add-new-payment-link";
import { useDeletePaymentLink } from "features/payments-links/delete-payment-link";

export const PaymentsLinksPage = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [selectedIds, selectedIdsActions] = useSet<number>();

  const { data, isLoading, isSuccess } = useGetWhitelistedDomainsQuery({
    page,
    page_size: itemsPerPage,
  });

  const { openAddNewPaymentLinkModal } = useAddNewPaymentLinkModal();
  const { deletePaymentLinks, deletePaymentLink } = useDeletePaymentLink();

  const pagination = usePagination({
    info: {
      total: data?.total || 0,
      pages: data?.pages || 0,
      is_last: data?.is_last ?? true,
    },
    setPage,
    currentPage: page,
    setItemsPerPage,
    items: itemsPerPage,
  });

  useIsomorphicLayoutEffect(() => {
    selectedIdsActions.reset();
  }, [page, itemsPerPage]);

  const deleteSelectedLinks = () => {
    deletePaymentLinks({
      ids: Array.from(selectedIds),
      onMutate: () => selectedIdsActions.reset(),
    });
  };

  return (
    <>
      <Layout.Content title="Управление платежными ссылками">
        <div className={styles.root}>
          <div className={styles.controls}>
            {selectedIds.size > 0 && (
              <Button
                type="solid"
                className={styles.addLinkButton}
                onClick={deleteSelectedLinks}
              >
                Удалить выбранные
              </Button>
            )}
            <Button
              type="solid"
              className={styles.addLinkButton}
              onClick={openAddNewPaymentLinkModal}
            >
              Добавить ссылку
            </Button>
          </div>

          <Table>
            <TableHeader className={styles.table__header}>
              <tr>
                <TableData />
                <TableData alignStart>Ссылка</TableData>
                <TableData>
                  <button
                    className={styles.removeAllLinksButton}
                    onClick={deleteSelectedLinks}
                  >
                    Удалить все выбранные
                  </button>
                </TableData>
              </tr>
            </TableHeader>
            {!isLoading && data && (
              <TableBody>
                {data.results.map((result, index) => (
                  <TableRow even={!(index % 2)} className={styles.table__row}>
                    <TableData width={60}>
                      <Checkbox
                        checked={selectedIds.has(result.id)}
                        onChange={() => selectedIdsActions.toggle(result.id)}
                      />
                    </TableData>
                    <TableData alignStart>{result.domain}</TableData>
                    <TableData width={232}>
                      <button
                        className={styles.removeLinkButton}
                        onClick={() =>
                          deletePaymentLink({
                            id: result.id,
                            name: result.domain,
                            onMutate: () =>
                              selectedIdsActions.remove(result.id),
                          })
                        }
                      >
                        <ThrashIcon />
                      </button>
                    </TableData>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {isSuccess && <Pagination {...pagination} />}
        </div>
      </Layout.Content>
    </>
  );
};
