import { useAppSelector } from "shared/hooks/shared";
import { useGetCommentDataQuery } from "shared/api";
import { ModalsSelector } from "shared/store";

export const useComplaintModal = () => {
  const { description, type, id, post_url } = useAppSelector(
    ModalsSelector.complaints
  );
  const commentQuery = useGetCommentDataQuery(
    { id: id as number },
    { skip: !id }
  );

  return {
    description,
    type,
    post_url,
    comment: commentQuery.isSuccess ? commentQuery.data : null,
  };
};
