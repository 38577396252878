import { ContentType } from "shared/api/dto";
import {
  useLoadAudioMutation,
  useLoadFileMutation,
  useLoadImageMutation,
  useLoadVideoMutation,
} from "shared/api";

export namespace LoaderHooks {
  export const useImageLoader = () => {
    const [loadImage] = useLoadImageMutation();
    const [loadVideo] = useLoadVideoMutation();
    const [loadAudio] = useLoadAudioMutation();
    const [loadFile] = useLoadFileMutation();

    const getLoader = (type: string) => {
      switch (type) {
        case "image":
          return loadImage;
        case "video":
          return loadVideo;
        case "audio":
          return loadAudio;
        case "file":
          return loadFile;
        default:
          return null;
      }
    };

    const loadMedia = async (file: File, type: ContentType) => {
      if (file) {
        const formData = new FormData();
        formData.append(type, file);

        const loader = getLoader(type);

        if (!loader) {
          return null;
        }

        try {
          const result = await loader(formData).unwrap();
          return result.id;
        } catch {
          return null;
        }
      }
    };

    return { loadMedia };
  };
}
