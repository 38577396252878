import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { postsActions } from "shared/store";
import { useAppDispatch } from "shared/hooks/shared";

export const useShowUserPosts = (username: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const showUserPosts = useCallback(() => {
    if (username) {
      dispatch(postsActions.setSearchTerm(username));
      navigate("/publications/");
    }
  }, [dispatch, navigate, username]);

  return { showUserPosts };
};
