import { Api, tags } from "../..";
import { ICurrencyRate, ICurrencyRatesList } from "shared/api/dto";

const basePath = "api/admin-currency/";

export const currencyApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencyRates: builder.query<ICurrencyRatesList, void>({
      query: () => basePath,
      providesTags: [tags.currencyRates],
    }),
    changeCurrencyRate: builder.mutation<void, ICurrencyRate>({
      query: (data) => ({
        url: `${basePath}${data.code}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [tags.currencyRates],
    }),
  }),
});

export const { useGetCurrencyRatesQuery, useChangeCurrencyRateMutation } =
  currencyApi;
