import "./RechartStyles.scss";

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="graphTooltip">
        <p className="graphTooltip__label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
