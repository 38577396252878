import { Selector } from "shared/ui/Selector";
import { useFilterActiveUsers } from "../model";
import styles from "./FilterActiveUsers.module.scss";

export const FilterActiveUsers = () => {
  const { isActiveUsers, showActiveUsers } = useFilterActiveUsers();

  const optionsActiveUsers = [
    {
      title: "да",
      value: false,
      isDefaultOption: !isActiveUsers && isActiveUsers !== undefined,
    },
    {
      title: "нет",
      value: true,
      isDefaultOption: isActiveUsers,
    },
    {
      title: "все",
      value: undefined,
      isDefaultOption: isActiveUsers === undefined,
    },
  ];

  return (
    <div className={styles.filterUsers}>
      <span className={styles.filterUsers__title}>Забанен</span>
      <Selector<boolean | undefined>
        options={optionsActiveUsers}
        setFunc={showActiveUsers}
        withBorder
      />
    </div>
  );
};
