import { ChangeEvent } from "react";
import { InputSearch } from "shared/ui/Inputs";
import { useSearchAdmin } from "features/admins/search-admins/model";

export const SearchAdminInput = () => {
  const search = useSearchAdmin();

  const setSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    search(event.target.value);
  };

  return (
    <InputSearch placeholder="Найти администратора" onChange={setSearchText} />
  );
};
