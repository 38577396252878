import { useCreateAdminMutation } from "shared/api";

interface Error {
  data: {
    errors: {
      non_field_errors: string;
      email: string;
      username: string;
      password: string;
    };
  };
}

export const useAddAdmin = () => {
  const [addNewAdmin, result] = useCreateAdminMutation();

  const isSuccess = result.isSuccess;
  const isError = result.isError;

  const errors = isError ? (result.error as Error) : null;

  return { addNewAdmin, isSuccess, isError, errors: errors?.data?.errors };
};
