import { useNotification } from "shared/hooks/shared";
import { useCallback } from "react";
import {
  useGetAdminDataQuery,
  useEditAdminDataMutation,
  useAddAvatarMutation,
  useDeleteAvatarMutation,
} from "shared/api";
import { IEditAdmin } from "widgets/EditProfile";

export const useEditAdmin = ({ id }: { id: string | undefined }) => {
  const userQuery = useGetAdminDataQuery({ id: Number(id) }, { skip: !id });
  const [setInfo] = useEditAdminDataMutation();
  const [addAdminAvatar] = useAddAvatarMutation();
  const [deleteAdminAvatar] = useDeleteAvatarMutation();

  const { show } = useNotification();

  const userData = userQuery.isSuccess ? userQuery.data : null;

  const addAvatar = (avatarFile: FormData) => {
    if (id && avatarFile) {
      addAdminAvatar({ id, formData: avatarFile });
    }
  };

  const deleteAvatar = useCallback(() => {
    if (id && userData?.avatars && userData?.avatars[0]?.id) {
      deleteAdminAvatar({ user_id: id, id: userData.avatars[0].id })
        .unwrap()
        .then(() => {
          show({ text: "Изображение удалено!" });
        });
    }
  }, [deleteAdminAvatar, id, show, userData?.avatars]);

  const saveAdminInfo = (data: IEditAdmin) => {
    if (id && userData) {
      const newData = {
        username:
          userData.username === data.username ? undefined : data.username,
        first_name:
          userData.first_name === data.first_name ? undefined : data.first_name,
        last_name:
          userData.last_name === data.last_name ? undefined : data.last_name,
        email: userData.email === data.email ? undefined : data.email,
        password: data.password || undefined,
        confirm_password: data.confirm_password || undefined,
        permissions:
          Object.keys(data.permissions).length > 0
            ? data.permissions
            : undefined,
      };

      setInfo({ id, data: newData })
        .unwrap()
        .then(() => {
          show({ text: "Профиль изменен!" });
        });
    }
  };

  return { userData, addAvatar, deleteAvatar, saveAdminInfo };
};
