import { useEffect } from "react";
import { getFullDateFormat } from "shared/lib";
import { useGetUsersForVerificationQuery } from "shared/api";
import { verificationsSelector, verificationsActions } from "shared/store";
import {
  useAppSelector,
  useAppDispatch,
  useIsNewDataFetching,
} from "shared/hooks/shared";

export const useVerifications = () => {
  const {
    usersPerPage,
    currentPage,
    searchTerm,
    applicationDate,
    selectedItems,
  } = useAppSelector(verificationsSelector);
  const usersQuery = useGetUsersForVerificationQuery({
    page: currentPage,
    page_size: usersPerPage,
    search: searchTerm || undefined,
    created_at: applicationDate
      ? getFullDateFormat(applicationDate)
      : undefined,
  });
  const dispacth = useAppDispatch();

  useEffect(() => {
    if (usersQuery.isSuccess) {
      dispacth(verificationsActions.setQueryData(usersQuery.data));
    }
  }, [usersQuery, dispacth]);

  const loadingStatus = useIsNewDataFetching({
    ...usersQuery,
    params: {
      page: currentPage,
      page_size: usersPerPage,
      search: searchTerm || undefined,
      created_at: applicationDate
        ? getFullDateFormat(applicationDate)
        : undefined,
    },
    newParams: usersQuery.originalArgs,
  });

  return {
    verefications: usersQuery.isSuccess ? usersQuery.data.results : [],
    selectedItemsCount: selectedItems.length,
    loadingStatus,
  };
};
