import { useNavigate } from "react-router-dom";
import { EditButton } from "shared/ui/Buttons/EditButton";
import * as Layout from "shared/ui/layout";
import styles from "./verifications.module.scss";

export const Verifications = () => {
  const navigate = useNavigate();

  return (
    <Layout.Content title={"Информация о верификации"}>
      <div className={styles.verifications}>
        <div className={styles.verifications__buttons}>
          <EditButton
            overflow
            onClick={() => navigate("/app-content/verifications/requirements")}
          >
            Требования к верификации
          </EditButton>
          <EditButton
            overflow
            onClick={() => navigate("/app-content/verifications/flow")}
          >
            Как проходит верификация
          </EditButton>
        </div>
      </div>
    </Layout.Content>
  );
};
