import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useApproveWalletApplicationMutation } from "shared/api";
import { ID } from "shared/types";

export const useApproveWalletAplication = ({ id }: ID) => {
  const [approve] = useApproveWalletApplicationMutation();

  const { open } = useShowConfirmModal();
  const { show } = useNotification();

  const approveApplication = () => {
    open({
      title: `Поддтвердить заявку?`,
      buttonText: "Подтвердить",
      onConfirmCallback: () => {
        approve({ id })
          .unwrap()
          .then(() => {
            show({ text: "Вывод средст одобрен!" });
          });
      },
    });
  };

  return {
    approveApplication,
  };
};
