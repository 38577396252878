import { PropsWithChildren } from "react";
import { Toggle } from "shared/ui/Toggle";
import styles from "./checks.module.scss";

interface RuleCardProps extends PropsWithChildren {}

const RuleCard = ({ children }: RuleCardProps) => {
  return (
    <div className={styles.ruleCard}>
      <div className={styles.ruleCard__toggle}>
        <Toggle />
      </div>

      <div>{children}</div>
    </div>
  );
};

export const ChecksPage = () => {
  return (
    <div className={styles.checksPage}>
      <div className={styles.checksPage__title}>
        Данные, отображаемые в чеке
      </div>
      <div className={styles.checksPage__list}>
        <div className={styles.checksPage__listShorts}>
          <RuleCard>Юр.лицо</RuleCard>
          <RuleCard>Название услуги</RuleCard>
          <RuleCard>Длительность</RuleCard>
          <RuleCard>Логины участников</RuleCard>
        </div>
        <div className={styles.checksPage__listLongs}>
          <RuleCard>
            Затраченная/ заработанная сумма во внутренней валюте
          </RuleCard>
          <RuleCard>
            Затраченная/ заработанная сумма в валюте выбранной страны
          </RuleCard>
        </div>
      </div>
    </div>
  );
};
