import * as Layout from "shared/ui/layout";
import { RadioButton } from "shared/ui/RadioButton";
import { Select } from "shared/ui/Select";
import styles from "./feed-settings.module.scss";

const LayoutCard = () => {
  return (
    <div className={styles.layoutCard}>
      <div className={styles.layoutCard__header}>
        <RadioButton />
        <span>Матрица №1</span>
      </div>
      <div>
        <svg
          width="338"
          height="534"
          viewBox="0 80 338 454"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="24"
            y="76.1534"
            width="87.1779"
            height="165.46"
            fill="#D9D9D9"
          />
          <rect
            x="125.414"
            y="76.1534"
            width="87.1779"
            height="165.46"
            fill="#D9D9D9"
          />
          <rect
            x="226.82"
            y="76.1534"
            width="87.1779"
            height="165.46"
            fill="#D9D9D9"
          />
          <rect
            x="24"
            y="269.847"
            width="87.1779"
            height="87.1779"
            fill="#D9D9D9"
          />
          <rect
            x="24"
            y="371.258"
            width="87.1779"
            height="87.1779"
            fill="#D9D9D9"
          />
          <rect
            x="125.414"
            y="269.847"
            width="188.589"
            height="188.589"
            fill="#D9D9D9"
          />
          <rect
            x="24"
            y="472.669"
            width="87.1779"
            height="87.1779"
            fill="#D9D9D9"
          />
          <rect
            x="125.414"
            y="472.669"
            width="87.1779"
            height="87.1779"
            fill="#D9D9D9"
          />
          <rect
            x="226.82"
            y="472.669"
            width="87.1779"
            height="87.1779"
            fill="#D9D9D9"
          />
          <path
            d="M24 256.847H314V254.847H24V256.847Z"
            fill="#A8A8A8"
            mask="url(#path-8-inside-1_2084_15763)"
          />
        </svg>
      </div>
    </div>
  );
};

export const FeedSettings = () => {
  return (
    <Layout.Content title={"Редактирование матрицы ленты"}>
      <div className={styles.feedSettings}>
        <div className={styles.feedSettings__layouts}>
          <LayoutCard />
        </div>

        <div className={styles.feedSettings__selects}>
          <div className={styles.feedSettings__category}>
            <span className={styles.feedSettings__categoryTitle}>
              Категория историй
            </span>
            <Select
              title="Выбор категории"
              className={styles.feedSettings__select}
            >
              что-то
            </Select>
          </div>

          <div className={styles.feedSettings__category}>
            <span className={styles.feedSettings__categoryTitle}>
              Категория публикаций
            </span>
            <Select
              title="Выбор категории"
              className={styles.feedSettings__select}
            >
              что-то
            </Select>
          </div>
        </div>
      </div>
    </Layout.Content>
  );
};
