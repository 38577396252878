import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const HeartIcon = ({
  height,
  width,
  className,
  size = DEFAULT_ICON_SIZE,
  color = "#FE85A2",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 40"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <path
        d="M22 40C21.3207 39.9992 20.6573 39.797 20.0963 39.4198C11.7843 33.8625 8.18516 30.0521 6.19998 27.6698C1.96943 22.5917 -0.0559379 17.3781 0.00117442 11.7323C0.0678055 5.2625 5.33801 0 11.7494 0C16.4115 0 19.6404 2.58646 21.5209 4.74062C21.5805 4.80818 21.6541 4.86234 21.7367 4.89947C21.8194 4.93659 21.9092 4.9558 22 4.9558C22.0908 4.9558 22.1806 4.93659 22.2633 4.89947C22.3459 4.86234 22.4195 4.80818 22.4791 4.74062C24.3596 2.58438 27.5886 0 32.2506 0C38.662 0 43.9322 5.2625 43.9988 11.7333C44.0559 17.3802 42.0285 22.5938 37.8 27.6708C35.8148 30.0531 32.2157 33.8635 23.9037 39.4208C23.3425 39.7977 22.6792 39.9995 22 40Z"
        fill={color}
      />
    </svg>
  );
};
