export const SBCoin = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.5" cy="15.5" r="15" fill="#333333" />
      <mask id="path-2-inside-1_1908_15029" fill="white">
        <path d="M13.863 9.15322C13.8133 8.85709 14.0135 8.57313 14.3135 8.56126C15.1838 8.52685 16.0489 8.75785 16.7908 9.2303C17.5327 9.70276 18.1079 10.3889 18.4449 11.1922C18.561 11.469 18.3884 11.7705 18.0991 11.8508C17.8098 11.9311 17.5142 11.7586 17.3863 11.487C17.1293 10.9412 16.7218 10.4754 16.2068 10.1474C15.6918 9.81949 15.0974 9.64731 14.4942 9.64527C14.1939 9.64425 13.9127 9.44935 13.863 9.15322Z" />
      </mask>
      <path
        d="M13.863 9.15322C13.8133 8.85709 14.0135 8.57313 14.3135 8.56126C15.1838 8.52685 16.0489 8.75785 16.7908 9.2303C17.5327 9.70276 18.1079 10.3889 18.4449 11.1922C18.561 11.469 18.3884 11.7705 18.0991 11.8508C17.8098 11.9311 17.5142 11.7586 17.3863 11.487C17.1293 10.9412 16.7218 10.4754 16.2068 10.1474C15.6918 9.81949 15.0974 9.64731 14.4942 9.64527C14.1939 9.64425 13.9127 9.44935 13.863 9.15322Z"
        stroke="white"
        strokeWidth="2.18693"
        mask="url(#path-2-inside-1_1908_15029)"
      />
      <mask id="path-3-inside-2_1908_15029" fill="white">
        <path d="M14.484 16.6057C14.4841 16.9059 14.2397 17.1529 13.9418 17.115C13.2477 17.0267 12.5828 16.7697 12.0066 16.363C11.2818 15.8514 10.7331 15.1279 10.436 14.2919C10.1389 13.4559 10.1079 12.5485 10.3472 11.6942C10.5375 11.0152 10.8911 10.3963 11.3738 9.88983C11.581 9.67248 11.9264 9.70987 12.1159 9.94281C12.3054 10.1757 12.2659 10.5156 12.0682 10.7416C11.755 11.0999 11.5239 11.5251 11.3942 11.9877C11.2155 12.6257 11.2386 13.3035 11.4606 13.9278C11.6825 14.5522 12.0923 15.0926 12.6336 15.4747C13.0261 15.7518 13.4738 15.9358 13.9429 16.016C14.2389 16.0667 14.484 16.3054 14.484 16.6057Z" />
      </mask>
      <path
        d="M14.484 16.6057C14.4841 16.9059 14.2397 17.1529 13.9418 17.115C13.2477 17.0267 12.5828 16.7697 12.0066 16.363C11.2818 15.8514 10.7331 15.1279 10.436 14.2919C10.1389 13.4559 10.1079 12.5485 10.3472 11.6942C10.5375 11.0152 10.8911 10.3963 11.3738 9.88983C11.581 9.67248 11.9264 9.70987 12.1159 9.94281C12.3054 10.1757 12.2659 10.5156 12.0682 10.7416C11.755 11.0999 11.5239 11.5251 11.3942 11.9877C11.2155 12.6257 11.2386 13.3035 11.4606 13.9278C11.6825 14.5522 12.0923 15.0926 12.6336 15.4747C13.0261 15.7518 13.4738 15.9358 13.9429 16.016C14.2389 16.0667 14.484 16.3054 14.484 16.6057Z"
        stroke="white"
        strokeWidth="2.18693"
        mask="url(#path-3-inside-2_1908_15029)"
      />
      <mask id="path-4-inside-3_1908_15029" fill="white">
        <path d="M17.0145 13.7925C17.0145 13.4906 17.2602 13.2423 17.5596 13.2806C18.2801 13.3728 18.9687 13.6467 19.5587 14.0804C20.2963 14.6225 20.8415 15.386 21.1147 16.2596C21.388 17.1332 21.375 18.0712 21.0778 18.9369C20.84 19.6295 20.4302 20.2468 19.8907 20.733C19.6664 20.9351 19.323 20.871 19.151 20.6229C18.979 20.3748 19.0448 20.0371 19.2601 19.8255C19.6125 19.479 19.8818 19.0539 20.0438 18.5818C20.2654 17.9364 20.275 17.2371 20.0713 16.5859C19.8676 15.9346 19.4612 15.3654 18.9114 14.9612C18.5091 14.6656 18.0456 14.4696 17.5586 14.3857C17.261 14.3344 17.0145 14.0944 17.0145 13.7925Z" />
      </mask>
      <path
        d="M17.0145 13.7925C17.0145 13.4906 17.2602 13.2423 17.5596 13.2806C18.2801 13.3728 18.9687 13.6467 19.5587 14.0804C20.2963 14.6225 20.8415 15.386 21.1147 16.2596C21.388 17.1332 21.375 18.0712 21.0778 18.9369C20.84 19.6295 20.4302 20.2468 19.8907 20.733C19.6664 20.9351 19.323 20.871 19.151 20.6229C18.979 20.3748 19.0448 20.0371 19.2601 19.8255C19.6125 19.479 19.8818 19.0539 20.0438 18.5818C20.2654 17.9364 20.275 17.2371 20.0713 16.5859C19.8676 15.9346 19.4612 15.3654 18.9114 14.9612C18.5091 14.6656 18.0456 14.4696 17.5586 14.3857C17.261 14.3344 17.0145 14.0944 17.0145 13.7925Z"
        stroke="white"
        strokeWidth="2.18693"
        mask="url(#path-4-inside-3_1908_15029)"
      />
      <mask id="path-5-inside-4_1908_15029" fill="white">
        <path d="M17.5837 21.2474C17.6295 21.5458 17.4243 21.8285 17.1225 21.836C16.251 21.8578 15.3886 21.6137 14.6532 21.1296C13.9177 20.6455 13.3526 19.9498 13.028 19.1406C12.9156 18.8604 13.0941 18.5601 13.3863 18.4842C13.6785 18.4083 13.9728 18.5866 14.0972 18.8616C14.3455 19.4102 14.7451 19.8813 15.2542 20.2165C15.7633 20.5516 16.354 20.7324 16.956 20.7436C17.2579 20.7491 17.5379 20.949 17.5837 21.2474Z" />
      </mask>
      <path
        d="M17.5837 21.2474C17.6295 21.5458 17.4243 21.8285 17.1225 21.836C16.251 21.8578 15.3886 21.6137 14.6532 21.1296C13.9177 20.6455 13.3526 19.9498 13.028 19.1406C12.9156 18.8604 13.0941 18.5601 13.3863 18.4842C13.6785 18.4083 13.9728 18.5866 14.0972 18.8616C14.3455 19.4102 14.7451 19.8813 15.2542 20.2165C15.7633 20.5516 16.354 20.7324 16.956 20.7436C17.2579 20.7491 17.5379 20.949 17.5837 21.2474Z"
        stroke="white"
        strokeWidth="2.18693"
        mask="url(#path-5-inside-4_1908_15029)"
      />
      <circle
        r="1.35654"
        transform="matrix(1 0 0.000102141 1 14.4973 12.8618)"
        fill="white"
      />
      <circle
        r="1.35654"
        transform="matrix(1 0 0.000102141 1 17.0207 17.5399)"
        fill="white"
      />
    </svg>
  );
};
