import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { useMemo, ChangeEvent } from "react";
import { postsSelector, postsActions } from "shared/store";
import { IPost } from "shared/types";

export const useSelectPost = (post: IPost) => {
  const dispatch = useAppDispatch();

  const { selectedPosts } = useAppSelector(postsSelector);

  const selected = useMemo(() => {
    return selectedPosts.some((selectedPost) => post.id === selectedPost.id);
  }, [post.id, selectedPosts]);

  const changeSelected = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      dispatch(postsActions.removeSelectedPost(post));
    } else if (event.target.checked) {
      dispatch(postsActions.addSelectedPost(post));
    }
  };

  return {
    changeSelected,
    selected,
  };
};
