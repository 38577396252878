import { Link } from "react-router-dom";
import { AddButton } from "shared/ui/Buttons";
import { DeleteIcon, EditIcon } from "shared/ui/Icons";
import { Select } from "shared/ui/Select";
import { Tooltip } from "shared/ui/Tooltip";
import styles from "./ContentSelector.module.scss";

interface ISelectLinksData {
  id: number;
  title?: string;
}

interface SelectLinksProps {
  title: string;
  addNewElementTitle: string;
  basePath: string;
  titleLink: string;
  data: ISelectLinksData[];
  deleteElement: (title: string, id: number) => void;
  deleteFirstElement?: boolean;
}

export const ContentSelector = ({
  title,
  data,
  basePath,
  addNewElementTitle,
  titleLink,
  deleteElement,
  deleteFirstElement = true,
}: SelectLinksProps) => {
  return (
    <Select title={title} className={styles.select} closeAfterClick={false}>
      <div className={styles.selectLinks__add}>
        <span>{addNewElementTitle}</span>
        <Link to={basePath}>
          <AddButton />
        </Link>
      </div>
      <div className={styles.selectLinks__links}>
        {data &&
          data.map((item, ind) => (
            <div className={styles.selectLinks__link} key={item.id}>
              <Link to={`${basePath}${item.id}`}>
                {item.title || `${titleLink} - ${ind + 1}`}
              </Link>
              <div className={styles.selectLinks__icons}>
                {!deleteFirstElement && ind === 0 ? (
                  <div />
                ) : (
                  <Tooltip tooltip="Удалить">
                    <DeleteIcon
                      onClick={() =>
                        deleteElement(
                          item.title || `${titleLink} - ${ind + 1}`,
                          item.id
                        )
                      }
                    />
                  </Tooltip>
                )}

                <Link to={`${basePath}${item.id}`}>
                  <Tooltip tooltip="Редактировать">
                    <EditIcon color={"#4E51BF"} size={32} />
                  </Tooltip>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </Select>
  );
};
