import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const AlertIcon = ({
  width,
  height,
  size = DEFAULT_ICON_SIZE,
  className,
  color = "black",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="1.5 1.8 17.5 17.5"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      stroke={color}
      onClick={onClick}
      cursor="pointer"
    >
      <g>
        <path
          d="M18.375 10.5C18.375 6.15234 14.8477 2.625 10.5 2.625C6.15234 2.625 2.625 6.15234 2.625 10.5C2.625 14.8477 6.15234 18.375 10.5 18.375C14.8477 18.375 18.375 14.8477 18.375 10.5Z"
          stroke={color}
          strokeWidth="1.3125"
          strokeMiterlimit="10"
        />
        <path
          d="M10.2644 6.81066L10.4998 11.8125L10.7349 6.81066C10.7363 6.77868 10.7312 6.74674 10.7199 6.7168C10.7086 6.68685 10.6913 6.65953 10.6691 6.6365C10.6468 6.61346 10.6202 6.5952 10.5906 6.58284C10.5611 6.57047 10.5294 6.56425 10.4974 6.56456V6.56456C10.4657 6.56486 10.4345 6.57153 10.4055 6.58417C10.3765 6.59681 10.3504 6.61516 10.3286 6.63813C10.3069 6.6611 10.29 6.68821 10.2789 6.71786C10.2679 6.7475 10.263 6.77906 10.2644 6.81066V6.81066Z"
          stroke={color}
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 15.0901C10.3378 15.0901 10.1792 15.0419 10.0443 14.9518C9.90936 14.8617 9.80422 14.7336 9.74213 14.5837C9.68004 14.4338 9.6638 14.2688 9.69545 14.1097C9.7271 13.9506 9.80523 13.8044 9.91995 13.6897C10.0347 13.575 10.1808 13.4968 10.34 13.4652C10.4991 13.4335 10.664 13.4498 10.8139 13.5119C10.9638 13.574 11.0919 13.6791 11.1821 13.814C11.2722 13.9489 11.3203 14.1075 11.3203 14.2697C11.3203 14.4873 11.2339 14.696 11.08 14.8498C10.9262 15.0036 10.7176 15.0901 10.5 15.0901Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
