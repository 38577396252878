import { Selector } from "shared/ui/Selector";
import { useFilterVerifiedUsers } from "../model";
import styles from "./FilterVerifiedUsers.module.scss";

export const FilterVerifiedUsers = () => {
  const { isVerifiedUsers, showVerifiedUsers } = useFilterVerifiedUsers();

  const optionsVerifiedUsers = [
    {
      title: "да",
      value: true,
      isDefaultOption: isVerifiedUsers,
    },
    {
      title: "нет",
      value: false,
      isDefaultOption: !isVerifiedUsers && isVerifiedUsers !== undefined,
    },
    {
      title: "все",
      value: undefined,
      isDefaultOption: isVerifiedUsers === undefined,
    },
  ];

  return (
    <div className={styles.filterUsers}>
      <span className={styles.filterUsers__title}>Верифицирован</span>
      <Selector<boolean | undefined>
        options={optionsVerifiedUsers}
        setFunc={showVerifiedUsers}
        withBorder
      />
    </div>
  );
};
