export const DEFAULT_ICON_SIZE = "1em";

export interface SVGIconProps {
  width?: number | string;
  height?: number | string;
  size?: number | string;
  className?: string;
  color?: string;
  fill?: string;
  onClick?: () => void;
}

export enum ArrowDirection {
  down = "0deg",
  up = "180deg",
  left = "90deg",
  right = "-90deg",
  downLeft = "-45deg",
  topRight = "135deg",
}

export interface IArrowIcon extends SVGIconProps {
  direction?: ArrowDirection;
}

export interface MenuIconProps extends SVGIconProps {
  isActive?: boolean;
}
