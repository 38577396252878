import { DeleteButton } from "shared/ui/Buttons";
import { useDeleteSelectedUsers } from "../model";

export const DeleteSelectedUsers = () => {
  const { selectedUsersCount, deleteUsers } = useDeleteSelectedUsers();
  return (
    <DeleteButton disabled={!selectedUsersCount} onClick={deleteUsers}>
      Удалить
    </DeleteButton>
  );
};
