import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import classNames from "classnames";
import { Logo } from "shared/ui/Logo";
import { Button } from "shared/ui/Buttons";
import {
  RestoreFormStep1Props,
  RestoreFormStep2Props,
  RestoreFormStep3Props,
  submitResetPassword,
} from "./form.interface";
import {
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
} from "shared/api";
import { ResetPasswordStep } from "shared/types";
import { useNotification } from "shared/hooks/shared";
import { FormPasswordInput } from "./FormPasswordInput";
import styles from "./form.module.scss";

const RestoreFormStep1 = ({
  setLogin,
  submit,
  login,
}: RestoreFormStep1Props) => {
  const [restoreLogin, setRestoreLogin] = useState(login || "");

  const changeLogin = (event: ChangeEvent<HTMLInputElement>) => {
    setRestoreLogin(event.target.value);
  };

  const handleSubmit = () => {
    if (restoreLogin) {
      setLogin(restoreLogin);
      submit(restoreLogin);
    }
  };

  const pressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && restoreLogin) {
      handleSubmit();
    }
  };

  return (
    <>
      <div className={styles.loginForm__label}>Восстановление пароля</div>
      <div className={styles.formItem}>
        <label className={styles.formItem__label}>Email</label>
        <input
          type="text"
          defaultValue={login}
          placeholder="mail@example.com"
          className={styles.loginForm__input}
          onKeyDown={pressEnter}
          onChange={changeLogin}
        />
      </div>

      <Button
        disabled={!restoreLogin}
        type="solid"
        className={styles.loginForm__submitButton}
        onClick={handleSubmit}
      >
        Восстановить
      </Button>
    </>
  );
};

const RestoreFormStep2 = ({ setCode }: RestoreFormStep2Props) => {
  const [restoreCode, setRestoreCode] = useState("");

  const changeCode = (event: ChangeEvent<HTMLInputElement>) => {
    setRestoreCode(event.target.value);
  };

  const handleSubmit = () => {
    restoreCode && setCode(restoreCode);
  };

  const pressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && restoreCode) {
      handleSubmit();
    }
  };

  return (
    <div className={styles.restorePassword}>
      <div className={styles.loginForm__label}>Введите код</div>
      <input
        type="text"
        placeholder="- - - -"
        className={classNames([
          styles.loginForm__input,
          styles.loginForm__inputCode,
        ])}
        onKeyDown={pressEnter}
        onChange={changeCode}
      />
      <Button
        disabled={!restoreCode}
        type="solid"
        className={styles.loginForm__submitButton}
        onClick={handleSubmit}
      >
        Продолжить
      </Button>
    </div>
  );
};

const RestoreFormStep3 = ({
  setPassword,
  setPasswordConfirm,
  submit,
}: RestoreFormStep3Props) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const changeNewPassword = (password: string) => {
    setNewPassword(password);
  };

  const changeConfirmNewPassword = (password: string) => {
    setConfirmNewPassword(password);
  };

  const handleSubmit = () => {
    if (newPassword && confirmNewPassword) {
      setPassword && setPassword(newPassword);
      setPasswordConfirm && setPasswordConfirm(confirmNewPassword);
      submit(newPassword, confirmNewPassword);
    }
  };

  return (
    <>
      <div className={styles.formItem}>
        <label className={styles.formItem__label}>Новый пароль</label>
        <FormPasswordInput placeholder="" onChange={changeNewPassword} />
      </div>

      <div className={styles.formItem}>
        <label className={styles.formItem__label}>Повторите пароль</label>
        <FormPasswordInput placeholder="" onChange={changeConfirmNewPassword} />
      </div>

      <Button
        disabled={!(newPassword && confirmNewPassword)}
        type="solid"
        className={styles.loginForm__submitButton}
        onClick={handleSubmit}
      >
        Продолжить
      </Button>
    </>
  );
};

export const RestoreForm = () => {
  const [login, setLogin] = useState("");
  const [code, setCode] = useState("");

  const [step, setStep] = useState(ResetPasswordStep.LoginStep);

  const [requestResetPassword] = useRequestResetPasswordMutation();
  const [resetPassword] = useResetPasswordMutation();

  const { show } = useNotification();

  const submitSendCode = (email: string) => {
    email &&
      requestResetPassword({ email })
        .unwrap()
        .then(() => {
          setStep(ResetPasswordStep.CodeStep);
        })
        .catch((err) => {
          const error = err as {
            data: {
              detail: string;
              errors: {
                email: string;
              };
            };
          };

          show({
            text: <>{error?.data?.errors?.email || error?.data?.detail}</>,
            timeout: 5000,
            isError: true,
          });
        });
  };

  const submitRestorePassword: submitResetPassword = (
    password,
    passwordConfirm
  ) => {
    if (password && passwordConfirm && code && login) {
      resetPassword({
        email: login,
        code,
        password,
        confirm_password: passwordConfirm,
      })
        .unwrap()
        .then(() => {
          setStep(ResetPasswordStep.SuccessStep);
        })
        .catch((err) => {
          const error = err as {
            data: {
              detail: string;
              errors: {
                password: string;
                non_field_errors: string;
              };
            };
          };
          if (error?.data?.detail === "Введен неверный код") {
            setStep(ResetPasswordStep.LoginStep);
            setCode("");
          } else {
            show({
              text: (
                <>
                  {error?.data?.errors?.password ||
                    error?.data?.errors?.non_field_errors ||
                    error?.data?.detail ||
                    "Сервер не отвечает"}
                </>
              ),
              timeout: 5000,
              isError: true,
            });
          }
        });
    }
  };

  useEffect(() => {
    if (code.length === 4) {
      setStep(ResetPasswordStep.PasswordStep);
    }
  }, [code]);

  return (
    <div className={styles.loginForm}>
      <div className={styles.loginForm__logo}>
        <Logo scale={2} />
      </div>
      <div className={styles.restorePassword}>
        {step === ResetPasswordStep.LoginStep && (
          <RestoreFormStep1
            setLogin={setLogin}
            submit={submitSendCode}
            login={login}
          />
        )}
        {step === ResetPasswordStep.CodeStep && (
          <RestoreFormStep2 setCode={setCode} />
        )}
        {step === ResetPasswordStep.PasswordStep && (
          <RestoreFormStep3 submit={submitRestorePassword} />
        )}
        {step === ResetPasswordStep.SuccessStep && <Navigate to="/login" />}
      </div>
    </div>
  );
};
export default RestoreForm;
