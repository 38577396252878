import { ChangeCurrencyRateModal } from "entities/currency-rate/ChangeCurrencyRateModal";
import { CurrencyRateCard } from "entities/currency-rate/CurrencyRateCard";
import { useState } from "react";
import { ICurrencyRate } from "shared/api/dto";
import { useGetCurrencyRatesQuery } from "shared/api/endpoints/admin/currency.api";
import { EditIcon } from "shared/ui/Icons";
import * as Layout from "shared/ui/layout";
import styles from "./CurrencyRate.module.scss";

export const СurrencyRate = () => {
  const { data, isSuccess } = useGetCurrencyRatesQuery();
  const [modalData, setModalData] = useState<{
    isOpen: boolean;
    rate: ICurrencyRate | null;
  }>({
    isOpen: false,
    rate: null,
  });

  const closeModal = () => {
    setModalData({ isOpen: false, rate: null });
  };

  const openModal = (rate: ICurrencyRate) => {
    setModalData({ isOpen: true, rate });
  };

  return (
    <Layout.Content title={<>Управление курсом конвертации валют</>}>
      <div className={styles["currencyRate"]}>
        {isSuccess &&
          data.results.map((rate) => (
            <div
              className={styles["currencyRate__row"]}
              key={rate.code + rate.exchange_rate}
            >
              <CurrencyRateCard
                disabled
                name={rate.name}
                code={rate.code}
                icon={rate.icon.image}
                rate={rate.exchange_rate}
              />
              <div className={styles["currencyRate__icon"]}>
                <EditIcon
                  onClick={() => openModal(rate)}
                  color="#4E51BF"
                  size={32}
                />
              </div>
            </div>
          ))}
      </div>
      <>
        {modalData.isOpen && modalData.rate && (
          <ChangeCurrencyRateModal rate={modalData.rate} onClose={closeModal} />
        )}
      </>
    </Layout.Content>
  );
};
