import { useEffect } from "react";
import { useGetUsersQuery } from "shared/api";
import { usersSelector, usersActions } from "shared/store";
import {
  useAppSelector,
  useAppDispatch,
  useIsNewDataFetching,
} from "shared/hooks/shared";

export const useUsers = () => {
  const {
    usersPerPage,
    currentPage,
    searchTerm,
    isActiveUsers,
    isVerifiedUsers,
    selectedUsers,
  } = useAppSelector(usersSelector);

  const usersQuery = useGetUsersQuery({
    page: currentPage,
    page_size: usersPerPage,
    is_active: isActiveUsers,
    is_verified: isVerifiedUsers,
    search: searchTerm || undefined,
  });
  const dispacth = useAppDispatch();

  const users = usersQuery.isSuccess ? usersQuery.data.results : [];

  useEffect(() => {
    if (usersQuery.isSuccess) {
      dispacth(usersActions.setQueryData(usersQuery.data));
    }
  }, [usersQuery, dispacth]);

  const loadingStatus = useIsNewDataFetching({
    ...usersQuery,
    params: {
      page: currentPage,
      page_size: usersPerPage,
      is_active: isActiveUsers,
      is_verified: isVerifiedUsers,
      search: searchTerm || undefined,
    },
    newParams: usersQuery.originalArgs,
  });

  return {
    users,
    selectedUsersCount: selectedUsers.length,
    loadingStatus,
  };
};
