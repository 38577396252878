import { useGetConnectCardBenefitsQuery } from "shared/api";

export const useCardBindingBenefits = () => {
  const benefitsQuery = useGetConnectCardBenefitsQuery();

  const connect_card_benefits = benefitsQuery.isSuccess
    ? benefitsQuery.data
    : [];

  return {
    connect_card_benefits,
  };
};
