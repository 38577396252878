import { PropsWithChildren } from "react";
import styles from "./WalletTable.module.scss";

interface WalletTableProps extends PropsWithChildren {
  is_active: boolean;
}

export const WalletTable = ({ children, is_active }: WalletTableProps) => {
  return (
    <div className={styles.walletTable}>
      <p className={styles.walletTable__title}>Заявки</p>

      <table className={styles.walletTable__table}>
        <thead className={styles.walletTable__thead}>
          <tr>
            <td>ID</td>
            <td>логин</td>
            <td>e-mail</td>
            <td>фамилия и имя</td>
            <td>номер банк. карты</td>
            {!is_active && <td>количество монет</td>}
            <td>{is_active ? "количество монет" : "дата операции"}</td>
            {is_active && <td></td>}
          </tr>
        </thead>

        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
