import { useState } from "react";
import { useIsomorphicLayoutEffect, useSet } from "react-use";
import classNames from "classnames";

import { useDebouncedSearch, usePagination } from "shared/hooks/shared";
import { Checkbox } from "shared/ui/Checkbox";
import * as Layout from "shared/ui/layout";
import { Pagination } from "shared/ui/Pagination";
import {
  Table,
  TableHeader,
  TableBody,
  TableData,
  TableRow,
} from "shared/ui/Table";
import { Button } from "shared/ui/Buttons";
import { Input } from "shared/ui/Inputs";
import { Calendar } from "shared/ui/Calendar";
import { CalendarIcon, LensIcon, MinusIcon } from "shared/ui/Icons";
import { useGetCallsFeedbackListQuery } from "shared/api";
import styles from "./ReviewsModeration.module.scss";
import { getFullDateFormat } from "shared/lib";
import { useDeleteCallsFeedback } from "features/reviews-moderation/delete-calls-feedback";
import { usePublishCallsFeedback } from "features/reviews-moderation/publish-calls-feedback";

const INITIAL_PAGE = 1;

const dateFormatter = Intl.DateTimeFormat("ru-RU", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
});

const timeFormatter = Intl.DateTimeFormat("ru-RU", {
  minute: "2-digit",
  hour: "2-digit",
  hour12: false,
});

const getDateTimeString = (date: string) => {
  try {
    const tmpDate = new Date(date);
    const dateString = dateFormatter.format(tmpDate);
    const timeString = timeFormatter.format(tmpDate);

    return `${dateString}, в ${timeString}`;
  } catch (err) {
    return "Некорректная дата";
  }
};

export const ReviewsModeration = () => {
  const [page, setPage] = useState(INITIAL_PAGE);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [selectedIds, selectedIdsActions] = useSet<number>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { searchTerm, setSearchTerm, debouncedSearchTerm } =
    useDebouncedSearch();

  const { data, isLoading, isSuccess } = useGetCallsFeedbackListQuery({
    page,
    page_size: itemsPerPage,
    search: debouncedSearchTerm || undefined,
    created_at: selectedDate ? getFullDateFormat(selectedDate) : undefined,
  });

  const { deleteCallsFeedback, deleteCallsFeedbacks } =
    useDeleteCallsFeedback();
  const { publishCallsFeedback } = usePublishCallsFeedback();

  const pagination = usePagination({
    info: {
      total: data?.total || 0,
      pages: data?.pages || 0,
      is_last: data?.is_last ?? true,
    },
    setPage,
    currentPage: page,
    setItemsPerPage,
    items: itemsPerPage,
  });

  useIsomorphicLayoutEffect(() => {
    setPage(INITIAL_PAGE);
  }, [debouncedSearchTerm, selectedDate]);

  useIsomorphicLayoutEffect(() => {
    selectedIdsActions.reset();
  }, [page, itemsPerPage, searchTerm, selectedDate]);

  useIsomorphicLayoutEffect(() => {
    if (data && data.pages < page) {
      setPage(data.pages);
    }
  }, [page, data]);

  const deleteSelectedFeedbacks = () => {
    if (selectedIds.size === 0) {
      return;
    }

    deleteCallsFeedbacks({
      ids: Array.from(selectedIds),
      onMutate: () => selectedIdsActions.reset(),
    });
  };

  return (
    <>
      <Layout.Content title="Модерация отзывов">
        <div className={styles.root}>
          <div className={styles.controls}>
            <Input
              placeholder="Найти отзыв"
              icon={<LensIcon size={24} color={"#888888"} />}
              className={styles.controls__searchInput}
              value={searchTerm}
              onChange={(event) => setSearchTerm(event?.target.value || "")}
            />

            <Button
              type="solid"
              icon={<MinusIcon color="#fff" />}
              className={styles.controls__deleteButton}
              onClick={deleteSelectedFeedbacks}
            >
              Удалить
            </Button>

            <Calendar
              value={selectedDate}
              setDate={setSelectedDate}
              placeholderText={"Дата отзыва"}
              className={styles.controls__calendarInput}
              calendarIcon={<CalendarIcon size={24} color={"#888888"} />}
              withClearIcon={!!selectedDate}
            />
          </div>

          <Table>
            <TableHeader className={styles.table__header}>
              <tr>
                <TableData />
                <TableData>автор отзыва</TableData>
                <TableData>продавец</TableData>
                <TableData>рейтинг</TableData>
                <TableData>текст отзыва</TableData>
                <TableData>дата и время</TableData>
                <TableData>тип звонка</TableData>
                <TableData>статус</TableData>
              </tr>
            </TableHeader>

            <TableBody>
              {!isLoading &&
                data &&
                data.results.map((result, index) => (
                  <TableRow even={!(index % 2)} className={styles.table__row}>
                    <TableData width={60}>
                      <Checkbox
                        checked={selectedIds.has(result.id)}
                        onChange={() => selectedIdsActions.toggle(result.id)}
                      />
                    </TableData>

                    <TableData>{result.user.username}</TableData>
                    <TableData>{result.seller.username}</TableData>
                    <TableData>{result.rating}</TableData>
                    <TableData className={styles.table__reviewText}>
                      {result.text}
                    </TableData>
                    <TableData>
                      {getDateTimeString(result.created_at)}
                    </TableData>
                    <TableData>
                      <p className={styles.table__status}>
                        {result.call.is_paid ? "платный" : "бесплатный"}
                      </p>
                      <p className={styles.table__status}>
                        {result.call.is_queue ? "звонок в очереди" : "особый"}
                      </p>
                    </TableData>
                    <TableData>
                      {result.is_published ? (
                        <button
                          type="button"
                          className={classNames(
                            styles.changeStatusButton,
                            styles.changeStatusButton_outline
                          )}
                          onClick={() => {
                            deleteCallsFeedback({
                              id: result.id,
                              onMutate: () => {
                                selectedIdsActions.remove(result.id);
                              },
                            });
                          }}
                        >
                          удалить
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={classNames(
                            styles.changeStatusButton,
                            styles.changeStatusButton_solid
                          )}
                          onClick={() => {
                            publishCallsFeedback({
                              id: result.id,
                            });
                          }}
                        >
                          опубликовать
                        </button>
                      )}
                    </TableData>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          {data?.total === 0 && (
            <Layout.PlaceholderWithOptions
              text={"Нет данных для отображения"}
            />
          )}

          {isSuccess && <Pagination {...pagination} />}
        </div>
      </Layout.Content>
    </>
  );
};
