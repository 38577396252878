import { verificationsActions } from "shared/store";
import { useAppSelector, useAppDispatch } from "shared/hooks/shared";

export const useSearchByDate = () => {
  const { applicationDate } = useAppSelector((state) => state.verifications);
  const dispacth = useAppDispatch();

  const setApplicationDate = (date: Date) => {
    dispacth(verificationsActions.setApplicationDate(date));
  };

  return { applicationDate, setApplicationDate };
};
