import { PropsWithChildren } from "react";
import { ComplaintType } from "shared/types";
import { Table, TableHeader, TableBody, TableData } from "shared/ui/Table";

interface ComplaintsTableProps extends PropsWithChildren {
  complaintType: ComplaintType;
  dataLoading?: boolean;
}

export const ComplaintsTable = ({
  complaintType,
  children,
  dataLoading,
}: ComplaintsTableProps) => {
  const publicationTitle = `дата ${
    complaintType === ComplaintType.CALL ? "звонка" : "публикации"
  }`;

  return (
    <Table animationKey={children?.toString().length} withLoader={dataLoading}>
      <TableHeader>
        <tr>
          <TableData>тип жалобы</TableData>
          <TableData width={240}>от кого</TableData>
          <TableData width={240}>на кого / верифицирован</TableData>
          <TableData>дата подачи жалобы</TableData>
          {complaintType !== ComplaintType.PROFILE && (
            <TableData>{publicationTitle}</TableData>
          )}
          <TableData width={380} />
        </tr>
      </TableHeader>
      {dataLoading ? null : <TableBody>{children}</TableBody>}
    </Table>
  );
};
