import { useCallback } from "react";
import { IUser } from "shared/types";
import { usersActions } from "shared/store";
import { useDeleteUserMutation } from "shared/api";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useAppDispatch } from "shared/hooks/shared";
import { useNotification } from "shared/hooks/shared";

export const useDeleteUser = (user: IUser) => {
  const dispatch = useAppDispatch();

  const { open } = useShowConfirmModal();
  const { show } = useNotification();

  const [deleteUserById] = useDeleteUserMutation();

  const deleteUser = useCallback(() => {
    open({
      title: "Удалить пользователя?",
      buttonText: "Удалить",
      onConfirmCallback: () => {
        deleteUserById({ id: user.id })
          .unwrap()
          .then(() => {
            show({ text: "Пользователь удален!" });
          });
        dispatch(usersActions.resetSelectedUsers());
      },
    });
  }, [deleteUserById, dispatch, open, show, user.id]);

  return { deleteUser };
};
