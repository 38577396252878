import { ChangeEvent, memo, useState } from "react";
import { Button } from "shared/ui/Buttons";
import { SendIcon } from "shared/ui/Icons";
import { Textarea } from "shared/ui/Textarea";
import { Tooltip } from "shared/ui/Tooltip";
import styles from "./FeedbackReply.module.scss";

interface FeedbackReplyProps {
  submit: (value: string) => void;
}

export const FeedbackReply = memo(({ submit }: FeedbackReplyProps) => {
  const [replyText, setReplyText] = useState("");

  const changeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReplyText(event.target.value);
  };

  const onHandleReplyClick = () => {
    submit(replyText);
  };

  return (
    <div className={styles.feedbackMessage__reply}>
      <Textarea
        placeholder={"Ответ..."}
        className={styles.feedbackMessage__textarea}
        onChange={changeText}
      />
      <Tooltip tooltip={replyText ? "Отправить" : "Заполните ответ!"}>
        <Button
          type="gray"
          icon={<SendIcon />}
          className={styles.feedbackMessage__button}
          onClick={onHandleReplyClick}
          disabled={!replyText}
        />
      </Tooltip>
    </div>
  );
});
