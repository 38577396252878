import { Api, tags } from "../..";
import { ID, IUserAvatar } from "shared/types";
import { IDeleteAvatarDto, ICreateAvatarRequestDto } from "shared/api/dto";

const basePath = "api/admin-avatars";

const pathWithId = (id: number | string) => `${basePath}/${id}/`;

const avatarPath = (userId: number | string, avatarId: number | string) =>
  `${basePath}/${userId}/${avatarId}/`;

export const avatarsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    addAvatar: builder.mutation<void, ICreateAvatarRequestDto>({
      query: (data) => ({
        url: pathWithId(data.id),
        method: "POST",
        body: data.formData,
      }),
    }),
    getAvatars: builder.query<IUserAvatar[], ID>({
      query: (data) => pathWithId(data.id),
    }),
    deleteAvatar: builder.mutation<void, IDeleteAvatarDto>({
      query: (data) => ({
        url: avatarPath(data.user_id, data.id),
        method: "DELETE",
      }),
      invalidatesTags: [tags.admins, tags.userInfo],
    }),
  }),
});

export const {
  useAddAvatarMutation,
  useGetAvatarsQuery,
  useDeleteAvatarMutation,
} = avatarsApi;
