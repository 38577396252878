import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useDeclineWalletApplicationMutation } from "shared/api";
import { ID } from "shared/types";

export const useDeclineWalletApplication = ({ id }: ID) => {
  const [decline] = useDeclineWalletApplicationMutation();

  const { open } = useShowConfirmModal();
  const { show } = useNotification();

  const declineApplication = () => {
    open({
      title: `Отклонить заявку?`,
      buttonText: "Отклонить",
      onConfirmCallback: () => {
        decline({ id })
          .unwrap()
          .then(() => {
            show({ text: "Заявка на вывод средст отклонена!" });
          });
      },
    });
  };

  return {
    declineApplication,
  };
};
