import { useEffect } from "react";
import { useGetAdminsQuery } from "shared/api";
import { adminsSelector, adminsActions } from "shared/store";
import {
  useAppDispatch,
  useAppSelector,
  useIsNewDataFetching,
} from "shared/hooks/shared";

export const useAdmins = () => {
  const dispatch = useAppDispatch();
  const { searchParams } = useAppSelector(adminsSelector);

  const adminsQuery = useGetAdminsQuery({
    page: searchParams.currentPage,
    page_size: searchParams.itemsPerPage,
    search: searchParams.searchText,
  });

  useEffect(() => {
    if (adminsQuery.isSuccess) {
      dispatch(adminsActions.setQueryData(adminsQuery.data));
    }
  }, [adminsQuery, dispatch]);

  const loadingStatus = useIsNewDataFetching({
    ...adminsQuery,
    params: {
      page: searchParams.currentPage,
      page_size: searchParams.itemsPerPage,
      search: searchParams.searchText,
    },
    newParams: adminsQuery.originalArgs,
  });

  return {
    admins: adminsQuery.isSuccess ? adminsQuery.data.results : [],
    loadingStatus,
  };
};
