import { MenuIconProps } from "./icon.interface";

export namespace MenuIcons {
  const MENU_ICON_SIZE = "32px";

  export const Dashboards = ({
    width,
    height,
    size = MENU_ICON_SIZE,
    className,
    color = "black",
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        stroke={color}
        onClick={onClick}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" fill="#B5B5B5" />
        <rect x="-60" y="-168" width="568" height="1080" fill="#F2F2F2" />
        <g>
          <path
            d="M29.1507 4.74951H10.8497C7.48056 4.74951 4.74934 7.48072 4.74934 10.8498V29.1509C4.74934 32.52 7.48055 35.2512 10.8497 35.2512H29.1507C32.5198 35.2512 35.251 32.52 35.251 29.1509V10.8498C35.251 7.48073 32.5198 4.74951 29.1507 4.74951Z"
            stroke="black"
            strokeWidth="2.83174"
            strokeLinecap="round"
          />
          <path
            d="M11.7261 20H15.7209C16.3415 20 16.9368 19.7534 17.3756 19.3146L20.827 15.8632C21.7409 14.9493 23.2226 14.9493 24.1364 15.8632L28.2732 20"
            stroke="black"
            strokeWidth="2.83174"
            strokeLinecap="round"
          />
        </g>
        <path
          d="M29.1509 4.74951H10.8499C7.48074 4.74951 4.74953 7.48072 4.74953 10.8498V29.1509C4.74953 32.52 7.48074 35.2512 10.8499 35.2512H29.1509C32.52 35.2512 35.2512 32.52 35.2512 29.1509V10.8498C35.2512 7.48073 32.52 4.74951 29.1509 4.74951Z"
          stroke="black"
          strokeWidth="2.83174"
          strokeLinecap="round"
        />
        <path
          d="M11.7264 20H15.7213C16.3419 20 16.9371 19.7534 17.376 19.3146L20.8274 15.8632C21.7412 14.9493 23.2229 14.9493 24.1368 15.8632L28.2736 20"
          stroke={isActive ? "#4E51BF" : "black"}
          strokeWidth="2.83174"
          strokeLinecap="round"
        />
      </svg>
    );
  };

  export const Content = ({
    width,
    height,
    size = MENU_ICON_SIZE,
    className,
    color = "black",
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        stroke={color}
        onClick={onClick}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M35.3715 20.3576V11.0437C35.3715 7.61445 32.5915 4.83447 29.1622 4.83447H11.0434C7.61413 4.83447 4.83415 7.61445 4.83415 11.0437V29.6715C4.83415 33.1008 7.61413 35.8807 11.0434 35.8807H20.1028"
            stroke="black"
            strokeWidth="2.88231"
            strokeLinecap="round"
          />
          <path
            d="M11.88 20.3572H16.9678"
            stroke="black"
            strokeWidth="2.88231"
            strokeLinecap="round"
          />
          <path
            d="M11.88 13.5752H22.0528"
            stroke="black"
            strokeWidth="2.88231"
            strokeLinecap="round"
          />
          <path
            d="M35.2692 26.9436L31.9559 23.5751C31.6199 23.2335 31.0751 23.2335 30.739 23.5751L24.3666 30.0537C24.1821 30.2413 24.0913 30.5038 24.1197 30.7675L24.4996 34.296C24.5125 34.4162 24.6059 34.5111 24.7241 34.5243L28.1947 34.9105C28.4541 34.9393 28.7123 34.847 28.8968 34.6594L35.2692 28.1808C35.6053 27.8392 35.6053 27.2853 35.2692 26.9436Z"
            stroke={isActive ? "#4E51BF" : "black"}
            strokeWidth="2.88231"
            strokeLinecap="round"
          />
        </g>
      </svg>
    );
  };

  export const Accounts = ({
    width,
    height,
    size = MENU_ICON_SIZE,
    className,
    color = "black",
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        onClick={onClick}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M20.3733 4.83667C24.183 4.83667 27.2713 7.925 27.2713 11.7346C27.2713 15.5443 24.183 18.6326 20.3733 18.6326C16.5637 18.6326 13.4753 15.5443 13.4753 11.7346C13.4753 7.925 16.5637 4.83667 20.3733 4.83667Z"
            stroke="black"
            strokeWidth="2.88767"
          />
          <path
            d="M31.3506 32.1274L29.9645 32.5317L31.3506 32.1274ZM30.674 29.8075L29.2879 30.2118L30.674 29.8075ZM10.7815 32.5317L11.4581 30.2118L8.68596 29.4032L8.00933 31.7231L10.7815 32.5317ZM29.2879 30.2118L29.9645 32.5317L32.7367 31.7231L32.0601 29.4032L29.2879 30.2118ZM28.5379 34.4339H12.2082V37.3216H28.5379V34.4339ZM29.9645 32.5317C30.242 33.4828 29.5286 34.4339 28.5379 34.4339V37.3216C31.4537 37.3216 33.5531 34.5223 32.7367 31.7231L29.9645 32.5317ZM20.373 23.5256C24.5003 23.5256 28.1323 26.2496 29.2879 30.2118L32.0601 29.4032C30.5451 24.209 25.7837 20.638 20.373 20.638V23.5256ZM11.4581 30.2118C12.6138 26.2496 16.2457 23.5256 20.373 23.5256V20.638C14.9623 20.638 10.2009 24.209 8.68596 29.4032L11.4581 30.2118ZM8.00933 31.7231C7.19289 34.5223 9.29231 37.3216 12.2082 37.3216V34.4339C11.2174 34.4339 10.5041 33.4828 10.7815 32.5317L8.00933 31.7231Z"
            stroke={isActive ? "#4E51BF" : "black"}
            fill={isActive ? "#4E51BF" : "black"}
          />
        </g>
      </svg>
    );
  };

  export const Wallet = ({
    width,
    height,
    size = MENU_ICON_SIZE,
    className,
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        onClick={onClick}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.24762 6.29443C5.92012 6.29443 3.22266 8.9919 3.22266 12.3194V27.6804C3.22266 31.0079 5.92012 33.7053 9.24761 33.7053H30.753C34.0805 33.7053 36.778 31.0079 36.778 27.6804V13.8555V12.3194C36.778 8.9919 34.0805 6.29443 30.753 6.29443H9.24762ZM6.05599 12.3194C6.05599 10.5567 7.48493 9.12777 9.24762 9.12777H30.753C32.5157 9.12777 33.9446 10.5567 33.9446 12.3194V13.8555V13.9749H29.2167C25.8892 13.9749 23.1917 16.6724 23.1917 19.9999C23.1917 23.3273 25.8892 26.0248 29.2167 26.0248H33.9446V27.6804C33.9446 29.4431 32.5157 30.872 30.753 30.872H9.24761C7.48493 30.872 6.05599 29.4431 6.05599 27.6804V12.3194ZM33.9446 23.1915V16.8082H29.2167C27.454 16.8082 26.0251 18.2372 26.0251 19.9999C26.0251 21.7625 27.454 23.1915 29.2167 23.1915H33.9446Z"
            fill={isActive ? "#4E51BF" : "black"}
          />
        </g>
      </svg>
    );
  };

  export const Chat = ({
    width,
    height,
    size = MENU_ICON_SIZE,
    className,
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        onClick={onClick}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.35715 10.6308C3.35715 8.19572 5.33119 6.22168 7.7663 6.22168H32.5194C34.9545 6.22168 36.9286 8.19572 36.9286 10.6308V26.1015C36.9286 28.5366 34.9545 30.5107 32.5194 30.5107H27.8782H27.0297C26.6258 30.5107 26.2338 30.6482 25.9183 30.9006L22.8972 33.3174C21.2869 34.6057 18.9988 34.6057 17.3885 33.3174L14.3674 30.9006C14.0519 30.6482 13.66 30.5107 13.256 30.5107H12.4075H7.7663C5.33119 30.5107 3.35715 28.5366 3.35715 26.1015V10.6308ZM7.7663 8.8517C6.78371 8.8517 5.98717 9.64824 5.98717 10.6308V26.1015C5.98717 27.0841 6.78371 27.8807 7.7663 27.8807H12.4075H13.256C14.2572 27.8807 15.2286 28.2214 16.0104 28.8469L19.0314 31.2637C19.6812 31.7835 20.6045 31.7835 21.2543 31.2637L24.2754 28.8469C25.0572 28.2214 26.0286 27.8807 27.0297 27.8807H27.8782H32.5194C33.502 27.8807 34.2986 27.0841 34.2986 26.1015V10.6308C34.2986 9.64824 33.502 8.8517 32.5194 8.8517H7.7663ZM17.7005 18.1219C17.7005 19.471 16.6069 20.5646 15.2578 20.5646C13.9087 20.5646 12.815 19.471 12.815 18.1219C12.815 16.7728 13.9087 15.6792 15.2578 15.6792C16.6069 15.6792 17.7005 16.7728 17.7005 18.1219ZM25.0284 20.5646C26.3775 20.5646 27.4711 19.471 27.4711 18.1219C27.4711 16.7728 26.3775 15.6792 25.0284 15.6792C23.6793 15.6792 22.5857 16.7728 22.5857 18.1219C22.5857 19.471 23.6793 20.5646 25.0284 20.5646Z"
            fill={isActive ? "#4E51BF" : "black"}
          />
        </g>
      </svg>
    );
  };

  export const Complaints = ({
    width,
    height,
    size = MENU_ICON_SIZE,
    className,
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        onClick={onClick}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.70432 19.1543L20.3222 6.5364C20.7564 6.1022 21.4604 6.1022 21.8946 6.5364L28.9045 13.5463C29.3387 13.9805 29.3387 14.6845 28.9045 15.1187L16.2866 27.7366C15.8524 28.1708 15.1485 28.1708 14.7143 27.7366L7.70432 20.7267C7.27012 20.2925 7.27012 19.5885 7.70432 19.1543ZM5.68654 22.7444C4.13795 21.1958 4.13795 18.6851 5.68654 17.1365L18.3044 4.51862C19.853 2.97003 22.3638 2.97003 23.9124 4.51862L30.9223 11.5285C32.4709 13.0771 32.4709 15.5879 30.9223 17.1365L28.4181 19.6407L34.0343 25.257C36.1356 27.3582 36.1356 30.7651 34.0343 32.8663C31.9331 34.9676 28.5263 34.9676 26.425 32.8663L20.8087 27.25L18.3044 29.7544C16.7558 31.303 14.2451 31.303 12.6965 29.7544L5.68654 22.7444ZM22.8265 25.2323L28.4428 30.8485C29.4297 31.8354 31.0297 31.8354 32.0166 30.8485C33.0034 29.8617 33.0034 28.2616 32.0166 27.2748L26.4003 21.6585L22.8265 25.2323Z"
            fill="black"
          />
          <path
            d="M4.86523 35.4204H12.7961"
            stroke={isActive ? "#4E51BF" : "black"}
            strokeWidth="4"
            strokeLinecap="round"
          />
        </g>
      </svg>
    );
  };

  export const Calls = ({
    width,
    height,
    size = MENU_ICON_SIZE,
    className,
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        onClick={onClick}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.623 8.17924V16.216C21.623 17.3032 20.7416 18.1845 19.6545 18.1845C18.5673 18.1845 17.686 17.3032 17.686 16.216V8.17924C17.686 7.09207 18.5673 6.21075 19.6545 6.21075C20.7416 6.21075 21.623 7.09207 21.623 8.17924ZM14.8324 8.17924C14.8324 5.51609 16.9913 3.35718 19.6545 3.35718C22.3176 3.35718 24.4765 5.51609 24.4765 8.17924V16.216C24.4765 18.8792 22.3176 21.0381 19.6545 21.0381C16.9913 21.0381 14.8324 18.8792 14.8324 16.216V8.17924ZM11.4374 16.2161C11.4374 15.4281 10.7986 14.7893 10.0106 14.7893C9.22263 14.7893 8.58383 15.4281 8.58383 16.2161C8.58383 20.5388 10.4169 23.3782 12.7935 25.0893C14.555 26.3576 16.5607 26.9712 18.228 27.1898V34.0776H12.4217C11.6337 34.0776 10.9949 34.7164 10.9949 35.5044C10.9949 36.2924 11.6337 36.9312 12.4217 36.9312H19.6548H26.8878C27.6758 36.9312 28.3146 36.2924 28.3146 35.5044C28.3146 34.7164 27.6758 34.0776 26.8878 34.0776H21.0815V27.1898C22.7488 26.9712 24.7545 26.3576 26.516 25.0893C28.8926 23.3782 30.7257 20.5388 30.7257 16.2161C30.7257 15.4281 30.0869 14.7893 29.2989 14.7893C28.5109 14.7893 27.8721 15.4281 27.8721 16.2161C27.8721 19.6088 26.4905 21.5914 24.8487 22.7735C23.1341 24.008 21.0338 24.4335 19.6548 24.4335C18.2757 24.4335 16.1754 24.008 14.4608 22.7735C12.819 21.5914 11.4374 19.6088 11.4374 16.2161Z"
            fill={isActive ? "#4E51BF" : "black"}
          />
        </g>
      </svg>
    );
  };

  export const Posts = ({
    width,
    height,
    size = MENU_ICON_SIZE,
    className,
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        onClick={onClick}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.24523 10.8604C3.24523 6.65474 6.65461 3.24536 10.8603 3.24536H29.4251C33.6308 3.24536 37.0402 6.65474 37.0402 10.8604V13.9543V29.4253C37.0402 33.6309 33.6308 37.0403 29.4251 37.0403H10.8603C6.65461 37.0403 3.24523 33.6309 3.24523 29.4253V20.1758C3.24471 20.1537 3.24471 20.1316 3.24523 20.1096V10.8604ZM34.1866 10.8604V12.5595C25.9461 12.9343 20.5671 16.554 17.2402 21.0889C13.3814 18.037 8.99007 17.8104 6.0988 18.3059V10.8604C6.0988 8.23072 8.23059 6.09893 10.8603 6.09893H29.4251C32.0548 6.09893 34.1866 8.23072 34.1866 10.8604ZM6.0988 29.4253V21.2101C8.34462 20.7035 12.3082 20.7002 15.6946 23.5107C13.6723 27.1695 12.7841 31.0891 12.5767 34.1868H10.8603C8.23059 34.1868 6.0988 32.055 6.0988 29.4253ZM18.7205 23.9905C16.598 27.4012 15.6634 31.2202 15.4374 34.1868H29.4251C32.0548 34.1868 34.1866 32.055 34.1866 29.4253V15.4163C26.1815 15.8183 21.4505 19.6037 18.7205 23.9905ZM13.1815 13.9544C14.4632 13.9544 15.5021 12.9154 15.5021 11.6338C15.5021 10.3522 14.4632 9.31321 13.1815 9.31321C11.8999 9.31321 10.8609 10.3522 10.8609 11.6338C10.8609 12.9154 11.8999 13.9544 13.1815 13.9544Z"
            fill={isActive ? "#4E51BF" : "black"}
          />
        </g>
      </svg>
    );
  };

  export const Marketplace = ({
    width,
    height,
    size = MENU_ICON_SIZE,
    className,
    color = "black",
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        onClick={onClick}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M4.71924 5.81079C3.89081 5.81079 3.21924 6.48236 3.21924 7.31079C3.21924 8.13922 3.89081 8.81079 4.71924 8.81079V5.81079ZM17.7662 8.83956C16.9378 8.83956 16.2662 9.51113 16.2662 10.3396C16.2662 11.168 16.9378 11.8396 17.7662 11.8396V8.83956ZM31.5885 26.3525L30.1767 25.8457L31.5885 26.3525ZM13.796 26.2097L12.3624 26.6508L13.796 26.2097ZM4.71924 8.81079H5.57194V5.81079H4.71924V8.81079ZM7.25579 10.0544L12.3624 26.6508L15.2297 25.7685L10.1231 9.17218L7.25579 10.0544ZM16.9135 30.0122H28.5186V27.0122H16.9135V30.0122ZM33.0003 26.8593L36.3988 17.392L33.5752 16.3784L30.1767 25.8457L33.0003 26.8593ZM30.3821 8.83956H17.7662V11.8396H30.3821V8.83956ZM36.3988 17.392C37.8939 13.2271 34.8072 8.83956 30.3821 8.83956V11.8396C32.7306 11.8396 34.3687 14.1681 33.5752 16.3784L36.3988 17.392ZM28.5186 30.0122C30.5281 30.0122 32.3214 28.7506 33.0003 26.8593L30.1767 25.8457C29.9255 26.5454 29.2621 27.0122 28.5186 27.0122V30.0122ZM12.3624 26.6508C12.9771 28.6488 14.8231 30.0122 16.9135 30.0122V27.0122C16.1401 27.0122 15.4571 26.5077 15.2297 25.7685L12.3624 26.6508ZM5.57194 8.81079C6.34536 8.81079 7.02834 9.31522 7.25579 10.0544L10.1231 9.17218C9.50836 7.17419 7.66237 5.81079 5.57194 5.81079V8.81079Z"
            fill="black"
          />
          <circle
            cx="16.9515"
            cy="34.2205"
            r="3"
            fill={isActive ? "#4E51BF" : "black"}
            stroke={isActive ? "#4E51BF" : "black"}
          />
          <circle
            cx="28.3675"
            cy="34.2205"
            r="3"
            fill={isActive ? "#4E51BF" : "black"}
            stroke={isActive ? "#4E51BF" : "black"}
          />
        </g>
      </svg>
    );
  };

  export const Promotion = ({
    width,
    height,
    size = MENU_ICON_SIZE,
    className,
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        onClick={onClick}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M1.40186 18.6751V33.8945C1.40186 34.7821 2.12142 35.5017 3.00906 35.5017H11.0451V18.6751C11.0451 17.7874 10.3255 17.0679 9.43789 17.0679H3.00906C2.12143 17.0679 1.40186 17.7874 1.40186 18.6751Z"
            stroke={isActive ? "#4E51BF" : "black"}
            strokeWidth="2.6"
            strokeLinecap="round"
          />
          <path
            d="M36.7245 21.5396H30.2957C29.408 21.5396 28.6885 22.2591 28.6885 23.1468V35.5068H36.7245C37.6121 35.5068 38.3317 34.7873 38.3317 33.8996V23.1468C38.3317 22.2591 37.6121 21.5396 36.7245 21.5396Z"
            stroke={isActive ? "#4E51BF" : "black"}
            strokeWidth="2.6"
            strokeLinecap="round"
          />
          <path
            d="M15.0449 6.38626V17.0683V35.5021H24.6882V6.38626C24.6882 5.49862 23.9686 4.77905 23.081 4.77905H16.6521C15.7645 4.77905 15.0449 5.49862 15.0449 6.38626Z"
            stroke={isActive ? "#4E51BF" : "black"}
            strokeWidth="2.6"
            strokeLinecap="round"
          />
        </g>
      </svg>
    );
  };

  export const WhiteList = ({
    width,
    height,
    color = "black",
    size = MENU_ICON_SIZE,
    className,
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        stroke={color}
        onClick={onClick}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9978 6.49386C22.5286 6.49352 23.0379 6.70426 23.4133 7.07965L24.9195 8.5859C25.2949 8.96127 25.5056 9.47048 25.5053 10.0013C25.505 10.5322 25.2936 11.0411 24.9178 11.4161L8.28253 28.0105C8.09671 28.1958 7.87622 28.3428 7.63363 28.443L5.13863 29.4736C4.39163 29.7822 3.53234 29.6108 2.96084 29.0393C2.38935 28.4678 2.21799 27.6085 2.52656 26.8616L3.55718 24.3666C3.65737 24.124 3.80432 23.9035 3.98965 23.7177L20.583 7.08147C20.9579 6.7056 21.4669 6.4942 21.9978 6.49386ZM23.0801 5.41345C23.0802 4.88287 23.2911 4.37408 23.6664 3.99906C23.6837 3.98185 23.7079 3.95661 23.7383 3.92484C23.9603 3.69313 24.5148 3.11432 25.1255 2.77509C25.5326 2.54894 26.1345 2.3189 26.8635 2.38509C27.6237 2.4541 28.2464 2.81923 28.714 3.28693C29.1821 3.75534 29.5477 4.37939 29.616 5.14032C29.6815 5.87001 29.4505 6.47202 29.2238 6.87889C28.8841 7.48864 28.3043 8.04306 28.0741 8.26324C28.0425 8.29349 28.0174 8.31743 28.0005 8.33434C27.2195 9.11539 25.9531 9.11539 25.1721 8.33434L23.6658 6.82809C23.2907 6.45291 23.0799 5.94403 23.0801 5.41345ZM6.87006 26.5945L4.37506 27.6251L5.40568 25.1301L21.9991 8.49386L23.5053 10.0001L6.87006 26.5945ZM26.5863 6.92013C26.5861 6.92032 26.5865 6.91993 26.5863 6.92013L25.0801 5.41393C25.0819 5.41217 25.0836 5.41035 25.0854 5.40858C25.1314 5.36245 25.1788 5.31408 25.2273 5.26449C25.3301 5.15945 25.4383 5.04898 25.551 4.94295C25.9644 4.55402 26.4393 4.22494 26.9383 4.44365C27.0575 4.49593 27.1782 4.57951 27.2997 4.70106C27.4212 4.82257 27.5047 4.94318 27.557 5.06238C27.774 5.55705 27.453 6.02743 27.0693 6.43709C26.9601 6.55378 26.8457 6.66554 26.7372 6.77154C26.6854 6.82222 26.6343 6.87218 26.5863 6.92013Z"
          fill={isActive ? "#4E51BF" : "black"}
        />
      </svg>
    );
  };

  export const ChatBubble = ({
    width,
    height,
    color = "black",
    size = MENU_ICON_SIZE,
    className,
    onClick,
    isActive,
  }: MenuIconProps) => {
    return (
      <svg
        width={width || size}
        height={height || size}
        className={className}
        fill="none"
        stroke={color}
        onClick={onClick}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.00006 15.7103L2.00007 15.7119L2.00006 15.7087L2.00006 15.7103Z"
          fill={isActive ? "#4E51BF" : "black"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.9894 2C8.26636 2 1.97726 8.11855 2.00006 15.7103C2.00028 18.3496 2.77481 20.9308 4.22772 23.1342L4.48996 23.5562L3.28034 27.9113C3.11596 28.4803 3.24509 29.0959 3.66309 29.5214C4.13675 30.0034 4.84335 30.107 5.46207 29.8532L9.69875 28.3252L9.72369 28.3146C9.94542 28.2207 10.2006 28.2183 10.4251 28.3044C12.2591 29.0124 14.2371 29.5263 16.2132 29.5263C23.9699 29.5263 30.0001 23.2849 30.0001 15.7178C29.825 5.64845 22.0001 2 15.9894 2ZM15.9894 4C9.32554 4 3.97998 9.26686 4.00006 15.7056L4.00007 15.7088C3.99998 17.9486 4.65464 20.1393 5.8832 22.0117C6.29643 22.5761 6.62314 23.3131 6.4332 24.0122L6.43203 24.0165L5.39976 27.7504L8.97152 26.4614C9.35294 26.3325 10.3213 26.1474 11.1432 26.4378L11.1447 26.4384C12.7483 27.0577 14.4841 27.5263 16.2132 27.5263C22.8438 27.5263 27.9999 22.2024 28.0001 15.7185C27.9976 9.26514 22.4111 4 15.9894 4Z"
          fill={isActive ? "#4E51BF" : "black"}
        />
      </svg>
    );
  };
}
