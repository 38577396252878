import { useAppSelector } from "shared/hooks/shared";
import { ModalType } from "shared/store";
import { LoadPhotoModal } from "entities/Modals/LoadPhotoModal";
import { TextModal } from "entities/Modals/TextModal";
import { ProfileModal } from "features/users/show-user-info";
import { AddAdminModal } from "features/admins/add-new-admin";
import { PostMediaFilesModal } from "features/post-media-files";
import { PostCommentsModal } from "features/posts/open-comments/ui";
import { AddUserModal } from "features/users/add-new-user/ui/modal";
import { MarketplaceItemModal } from "features/marketplaces/show-item-info";
import { ComplaintInfoModal } from "features/complaints/show-complaint-info";
import { VerificationModal } from "features/users-verification/verification-modal";
import { ImageModal } from "entities/Modals/ImageModal";
import { AddNewPaymentLinkModal } from "features/payments-links/add-new-payment-link";

export const GetModal = () => {
  const { isOpen, type } = useAppSelector((state) => state.modal);

  if (!isOpen || type === null) {
    return null;
  }

  switch (type) {
    case ModalType.photoModal:
      return <LoadPhotoModal />;
    case ModalType.profileModal:
      return <ProfileModal />;
    case ModalType.addUserModal:
      return <AddUserModal />;
    case ModalType.verificationModal:
      return <VerificationModal />;
    case ModalType.addAdminModal:
      return <AddAdminModal />;
    case ModalType.textModal:
      return <TextModal />;
    case ModalType.complaintModal:
      return <ComplaintInfoModal />;
    case ModalType.documentModal:
      return <PostMediaFilesModal />;
    case ModalType.postCommentsModal:
      return <PostCommentsModal />;
    case ModalType.marketplaceItemModal:
      return <MarketplaceItemModal />;
    case ModalType.showImageModal:
      return <ImageModal />;
    case ModalType.addNewPaymentLink:
      return <AddNewPaymentLinkModal />;
    default:
      return null;
  }
};
