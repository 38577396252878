import { PropsWithChildren, Ref } from "react";
import { Table, TableHeader, TableBody, TableData } from "shared/ui/Table";

interface UsersTableProps extends PropsWithChildren {
  headerRef?: Ref<HTMLTableCellElement>;
  dataLoading?: boolean;
}

export const UsersTable = ({
  children,
  headerRef,
  dataLoading,
}: UsersTableProps) => {
  return (
    <Table animationKey={children?.toString().length} withLoader={dataLoading}>
      <TableHeader>
        <tr>
          <TableData />
          <TableData ref={headerRef}>логин</TableData>
          <TableData>электронная почта</TableData>
          <TableData>номер телефона</TableData>
          <TableData>забанен</TableData>
          <TableData>верифицирован</TableData>
          <TableData width={300} />
        </tr>
      </TableHeader>
      {dataLoading ? null : <TableBody>{children}</TableBody>}
    </Table>
  );
};
