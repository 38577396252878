import { getRuLocaleDateString } from "shared/lib";
import { useGetPostCommentsQuery, useGetPostQuery } from "shared/api";
import { ModalsSelector } from "shared/store";
import { Modal } from "shared/ui/Modal";
import { ScrollBar } from "shared/ui/ScrollBar";
import { DescriptionBlock } from "shared/ui/DescriptionBlock";
import { PostCommentCard } from "entities/comments/PostCommentCard/PostCommentCard";
import { useAppSelector } from "shared/hooks/shared";
import styles from "./PostCommentsModal.module.scss";

export const PostCommentsModal = () => {
  const { id, username, date } = useAppSelector(ModalsSelector.postComments);

  const postQuery = useGetPostQuery(
    { id: id as number },
    {
      skip: !id,
    }
  );

  const commentsQuery = useGetPostCommentsQuery(
    { id: id as number, username: username as string },
    { skip: !id || !username }
  );

  const comments = commentsQuery.isSuccess ? commentsQuery.data : null;
  const post = postQuery.isSuccess ? postQuery.data : null;

  return (
    <Modal>
      <div className={styles.postCommentsModal}>
        <ScrollBar height={"80vh"}>
          <div className={styles.postCommentsModal__list}>
            <div className={styles.postCommentsModal__info}>
              <div className={styles.postCommentsModal__infoRow}>
                <DescriptionBlock
                  title={"имя пользователя"}
                  content={username || ""}
                />
                <DescriptionBlock
                  title={"дата публикации"}
                  content={date && getRuLocaleDateString(date)}
                />
              </div>
              {post?.title && (
                <div className={styles.postCommentsModal__infoRow}>
                  <DescriptionBlock
                    title={"заголовок"}
                    content={post?.title || ""}
                  />
                </div>
              )}
              {post?.description && (
                <div className={styles.postCommentsModal__infoRow}>
                  <DescriptionBlock
                    title={"описание"}
                    content={post?.description || ""}
                  />
                </div>
              )}
            </div>
            <div className={styles.postCommentsModal__comments}>
              <p className={styles.postCommentsModal__title}>комментарии</p>

              {comments &&
                comments.results.map((comment) => (
                  <PostCommentCard key={comment.id} comment={comment} />
                ))}
            </div>
          </div>
        </ScrollBar>
      </div>
    </Modal>
  );
};
