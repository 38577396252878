import { IUserVerification } from "shared/types";
import { Checkbox } from "shared/ui/Checkbox";
import { useSelectVerefication } from "../model";

interface SelectVereficationProps {
  user: IUserVerification;
}

export const SelectVerefication = ({ user }: SelectVereficationProps) => {
  const { selected, selectVerefication } = useSelectVerefication(user);
  return <Checkbox checked={selected} onChange={selectVerefication} />;
};
