import { ReactNode } from "react";
import styles from "./style.module.scss";

interface BannerCardProps {
  title: string;
  subtitle: string;
  options: ReactNode;
}

export const BannerCard = ({ title, subtitle, options }: BannerCardProps) => {
  return (
    <div className={styles.bannerCard}>
      <div className={styles.bannerCard__info}>
        <div className={styles.bannerCard__title}>{title}</div>
        <div className={styles.bannerCard__subtitle}>{subtitle}</div>
      </div>
      <div className={styles.bannerCard__options}>{options}</div>
    </div>
  );
};
