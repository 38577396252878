import { useState } from "react";
import { AlertIcon, DocumentIcon, ImagesIcon } from "shared/ui/Icons";
import { ComplaintType, IComplaint } from "shared/types";
import { Pagination } from "shared/ui/Pagination";

import { SearchComplaintsByPublicationDate } from "features/complaints/search-by-publication-date";
import { SearchComplaintsByReason } from "features/complaints/search-by-reason";
import { SearchComplaintsByAuthor } from "features/complaints/search-by-author";
import { useShowComplaintInfo } from "features/complaints/show-complaint-info";
import { SearchComplaintsByDate } from "features/complaints/search-by-date";
import { useShowPostMediaModal } from "features/post-media-files/model";
import { useApproveComplaint } from "features/complaints/approve";
import { useDeclineComplaint } from "features/complaints/decline";
import { useShowUserPosts } from "features/users/show-user-posts";
import * as Layout from "shared/ui/layout";
import { Tooltip } from "shared/ui/Tooltip";
import { Button } from "shared/ui/Buttons";

import {
  ComplaintsTable,
  ComplaintsTableRow,
} from "entities/complaints/ComplaintsTable";
import { CallDataModal } from "entities/calls/CallDataModal";

import { useComplaints, useComplaintsPagination } from "./model";
import styles from "./complaints.module.scss";

interface ComplaintsProps {
  type?: ComplaintType;
}
interface ComplaintsControlsProps {
  complaint: IComplaint;
}

const ComplaintsTableRowOptions = ({ complaint }: ComplaintsControlsProps) => {
  const { approveComplaint } = useApproveComplaint({ id: complaint.id });
  const { declineComplaint } = useDeclineComplaint({ id: complaint.id });
  const { openComplaintInfoModal } = useShowComplaintInfo(complaint);
  const { openDocumentModal } = useShowPostMediaModal({
    id: complaint.object_id,
  });
  const { showUserPosts } = useShowUserPosts(complaint.accused.username);

  const withDocument =
    complaint.object_type === ComplaintType.POST ||
    complaint.object_type === ComplaintType.STORY ||
    complaint.object_type === ComplaintType.CALL;

  const withPublications = complaint.object_type === ComplaintType.PROFILE;

  const isCall = complaint.object_type === ComplaintType.CALL;

  const [modalData, setModalData] = useState({
    isOpen: false,
    isVideo: false,
    recordData: {
      id: 0,
      user: "",
    },
  });

  const openCallModal = (id: number, user: string) => {
    setModalData((data) => ({
      ...data,
      isOpen: true,
      recordData: { id, user },
    }));
  };

  const closeModal = () => {
    setModalData((data) => ({
      ...data,
      isOpen: false,
      recordData: { id: 0, user: "" },
    }));
  };

  return (
    <>
      <div className={styles.complaintOptions}>
        <div className={styles.complaintOptions__icon}>
          <Tooltip tooltip="Информация">
            <AlertIcon onClick={openComplaintInfoModal} />
          </Tooltip>
        </div>
        {withPublications && (
          <div className={styles.complaintOptions__icon}>
            <Tooltip tooltip="Публикации">
              <ImagesIcon onClick={showUserPosts} />
            </Tooltip>
          </div>
        )}
        {withDocument && (
          <div className={styles.complaintOptions__icon}>
            <Tooltip tooltip="Материалы">
              <DocumentIcon
                size={18}
                onClick={
                  isCall
                    ? () =>
                        openCallModal(complaint.id, complaint.accused.username)
                    : openDocumentModal
                }
              />
            </Tooltip>
          </div>
        )}

        <Button
          type="solid"
          className={styles.complaintOptions__button}
          onClick={approveComplaint}
        >
          принять
        </Button>
        <Button
          className={styles.complaintOptions__button}
          onClick={declineComplaint}
        >
          отклонить
        </Button>
      </div>
      {modalData.isOpen && (
        <CallDataModal
          id={modalData.recordData.id}
          user={modalData.recordData.user}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export const Complaints = ({ type }: ComplaintsProps) => {
  const { complaints, complaintType, loadingStatus } = useComplaints(type);
  const { pagination } = useComplaintsPagination();

  const withPublicationDateSort =
    complaintType !== ComplaintType.CALL &&
    complaintType !== ComplaintType.PROFILE;

  return (
    <div className={styles.complaints}>
      <div className={styles.complaints__paginationTop}>
        <div className={styles.complaintsSort}>
          <SearchComplaintsByAuthor />
          <SearchComplaintsByReason />
          <SearchComplaintsByDate />
          {withPublicationDateSort && <SearchComplaintsByPublicationDate />}
        </div>
        <Pagination isShort withSelector {...pagination} />
      </div>

      <ComplaintsTable
        complaintType={complaintType}
        dataLoading={loadingStatus.isLoading}
      >
        {complaints &&
          complaints.map((complaint, ind) => (
            <ComplaintsTableRow
              even={!!(ind % 2)}
              data={complaint}
              key={complaint.id}
              controls={<ComplaintsTableRowOptions complaint={complaint} />}
            />
          ))}
      </ComplaintsTable>

      {!loadingStatus.isLoading && complaints && complaints.length === 0 && (
        <Layout.PlaceholderWithOptions text={"Нет данных для отображения"} />
      )}
      {loadingStatus.isSuccess && <Pagination {...pagination} />}
    </div>
  );
};
