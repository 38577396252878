import { IUserVerification } from "shared/types";
import { Button } from "shared/ui/Buttons";
import { Tooltip } from "shared/ui/Tooltip";
import { Pagination } from "shared/ui/Pagination";
import { AlertIcon, ImagesIcon } from "shared/ui/Icons";
import { SearchVerefication } from "features/users-verification/search-by-author";
import { SearchVereficationByDate } from "features/users-verification/search-by-date";
import {
  UsersVerificationTable,
  UsersVerificationTableRow,
} from "entities/users-verification/UsersVerificationTable";
import { useApproveVerification } from "features/users-verification/approve";
import { useDeclineVerification } from "features/users-verification/decline";
import { useVerificationModal } from "features/users-verification/verification-modal";
import { useShowUserPosts } from "features/users/show-user-posts";
import { useUserVerificationsPagination, useVerifications } from "./model";
import { ApproveSelectedUsers } from "features/users-verification/approve-selected";
import { DeclineSelectedUsers } from "features/users-verification/decline-selected";
import { useStickyMenu } from "shared/hooks/shared";
import { TableStickyMenu } from "shared/ui/TableStickyMenu";
import styles from "./verifications.module.scss";

interface VereficationControlsProps {
  user: IUserVerification;
}

const VerificationControls = ({ user }: VereficationControlsProps) => {
  const { openVerificationModal } = useVerificationModal({ id: user.id });
  const { approve } = useApproveVerification({ id: user.id });
  const { decline } = useDeclineVerification({ id: user.id });
  const { showUserPosts } = useShowUserPosts(user.user.username);

  return (
    <div className={styles.verificationControls}>
      <div className={styles.verificationControls__icons}>
        <div className={styles.verificationControls__icon}>
          <Tooltip tooltip="Информация">
            <AlertIcon onClick={openVerificationModal} />
          </Tooltip>
        </div>
        <div className={styles.verificationControls__icon}>
          <Tooltip tooltip="Публикации">
            <ImagesIcon onClick={showUserPosts} />
          </Tooltip>
        </div>
      </div>

      <Button
        type="solid"
        className={styles.verificationControls__button}
        onClick={() => approve()}
      >
        принять
      </Button>
      <Button
        className={styles.verificationControls__button}
        onClick={() => decline()}
      >
        отклонить
      </Button>
    </div>
  );
};

export const Verifications = () => {
  const { verefications, selectedItemsCount, loadingStatus } =
    useVerifications();
  const { pagination } = useUserVerificationsPagination();

  const { ref, wrapperRef, size, isStickyMenuVisible } = useStickyMenu();

  return (
    <div className={styles.verifications} ref={wrapperRef}>
      <div className={styles.verifications__paginationTop}>
        <div className={styles.verificationSort}>
          <SearchVerefication />
          <SearchVereficationByDate />
          <ApproveSelectedUsers />
          <DeclineSelectedUsers />
        </div>
        <Pagination isShort withSelector {...pagination} />
      </div>

      <TableStickyMenu
        isVisible={!!(isStickyMenuVisible && selectedItemsCount > 0)}
        width={size.clientWidth}
        label={`Выбрано заявок: ${selectedItemsCount}`}
        controls={
          <>
            <ApproveSelectedUsers />
            <DeclineSelectedUsers />
          </>
        }
      />

      <UsersVerificationTable
        headerRef={ref}
        dataLoading={loadingStatus.isLoading}
      >
        {verefications &&
          verefications.map((user, ind) => (
            <UsersVerificationTableRow
              even={!!(ind % 2)}
              data={user}
              controls={<VerificationControls user={user} />}
              key={user.id}
            />
          ))}
      </UsersVerificationTable>
      {loadingStatus.isSuccess && <Pagination {...pagination} />}
    </div>
  );
};
