import { PropsWithChildren } from "react";
import { Table, TableHeader, TableRow, TableBody } from "shared/ui/Table";

export const MarketplacesTable = ({ children }: PropsWithChildren) => {
  return (
    <Table>
      <TableHeader>
        <TableRow even>
          <td></td>
          <td>ID товара</td>
          <td>название</td>
          <td>логин владельца</td>
          <td>электронная почта</td>
          <td>забанен</td>
          <td>верифицированный</td>
          <td></td>
        </TableRow>
      </TableHeader>
      <TableBody>{children}</TableBody>
    </Table>
  );
};
