import { ChangeEvent } from "react";
import { Checkbox } from "shared/ui/Checkbox";
import { useSelectCall } from "../model";

interface SelectCallProps {
  id: number;
}

export const SelectCall = ({ id }: SelectCallProps) => {
  const { isSelected, selectAdmin } = useSelectCall(id);

  const changeSelectedCall = (event: ChangeEvent<HTMLInputElement>) => {
    selectAdmin(event.target.checked);
  };

  return <Checkbox checked={isSelected} onChange={changeSelectedCall} />;
};
