import classNames from "classnames";
import { WithConfirmModal } from "entities/Modals/ConfirmModal";
import { ReactNode, useRef, useState } from "react";
import { useDeleteCommentMutation } from "shared/api";
import { ArrowDirection, ArrowIcon, DeleteIcon } from "shared/ui/Icons";
import styles from "./CommentCard.module.scss";

interface CommentCardProps {
  username?: string;
  avatar?: string;
  text?: string;
  id?: number;

  nestedComments?: ReactNode[];
  isNestedComment?: boolean;
}

export const CommentCard = ({
  avatar,
  username,
  text,
  id,
  nestedComments,
  isNestedComment,
}: CommentCardProps) => {
  const [isOpenComment, setIsOpenComment] = useState(isNestedComment);
  const [deleteComment] = useDeleteCommentMutation();

  const textWrapperRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const handleOpenComment = () => {
    setIsOpenComment((isOpen) => !isOpen);
  };

  const handleDeleteComment = () => {
    id && deleteComment({ id });
  };

  if (textWrapperRef.current && textRef.current) {
    // console.log(textWrapperRef.current?.scrollWidth, textRef.current?.scrollWidth);
  }

  return (
    <div
      className={classNames(styles.commentBlock, {
        [styles.commentBlockNested]: isNestedComment,
      })}
    >
      <div className={classNames(styles.commentBlock__header)}>
        <div className={styles.commentBlock__avatar}>
          {avatar && <img src={avatar} alt="" />}
        </div>
        <div className={styles.commentBlock__username}>
          <span>{username}</span>
        </div>
        <div className={classNames(styles.commentBlock__text)}>{text}</div>
        <div className={styles.commentBlock__controls}>
          <div className={styles.commentBlock__icon}>
            {nestedComments && nestedComments?.length > 0 && (
              <ArrowIcon
                onClick={handleOpenComment}
                direction={
                  isOpenComment ? ArrowDirection.up : ArrowDirection.down
                }
              />
            )}
          </div>
          <div className={styles.commentBlock__icon}>
            <WithConfirmModal
              title="Удалить комментарий?"
              buttonText="Удалить"
              onConfirmCallback={handleDeleteComment}
            >
              <DeleteIcon fill=" #4E51BF" color="#ffffff" size={"1.25em"} />
            </WithConfirmModal>
          </div>
        </div>
      </div>
      {isOpenComment && nestedComments && nestedComments?.length > 0 && (
        <div className={styles.commentBlock__body}>{nestedComments}</div>
      )}
    </div>
  );
};
