import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { useDeleteAdminMutation } from "shared/api";
import { decOfAdmins } from "shared/lib";
import { adminsActions, adminsSelector } from "shared/store";

export const useDeleteAdmins = () => {
  const dispatch = useAppDispatch();
  const [deleteAdmin] = useDeleteAdminMutation();
  const { selectedItems } = useAppSelector(adminsSelector);
  const { open } = useShowConfirmModal();
  const { show, hide } = useNotification();

  const deleteSelectedAdmins = async () => {
    show({
      text: "Администраторы удаляются",
      withLoader: true,
      autoClose: false,
    });

    const promises = selectedItems.map((admin) =>
      deleteAdmin({ id: admin.id }).unwrap()
    );
    const results = await Promise.allSettled(promises);

    if (results.every((promise) => promise.status === "fulfilled")) {
      hide();
      show({ text: "Администраторы удалены!" });
    }

    dispatch(adminsActions.resetSelectedAdmins());
  };

  const deleteSelectedAdminsWithConfirm = () => {
    open({
      title: `Удалить ${selectedItems.length} ${decOfAdmins(
        selectedItems.length
      )}?`,
      buttonText: "Удалить",
      onConfirmCallback: deleteSelectedAdmins,
    });
  };

  return {
    deleteAdmins: deleteSelectedAdminsWithConfirm,
    selectedAdminsCount: selectedItems.length,
  };
};
