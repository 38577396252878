import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { postsActions, postsSelector } from "shared/store";

export const useSearchPostByDate = () => {
  const dispatch = useAppDispatch();

  const { postDate } = useAppSelector(postsSelector);

  const setDate = (date: Date) => {
    dispatch(postsActions.setPostDate(date));
  };

  return {
    postDate,
    setDate,
  };
};
