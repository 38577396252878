import classNames from "classnames";
import { StatsCardProps, StatsCardWithHintProps } from "./StatsCard.interface";
import styles from "./StatsCard.module.scss";

export const StatsCard = ({
  type,
  color,
  title,
  value,
  icon,
  width,
}: StatsCardProps) => {
  const sign = type === "percent" ? "%" : "";
  const textValue =
    type === "currency"
      ? `${Number(value).toLocaleString("ru-RU", {
          style: "currency",
          currency: "RUB",
          currencyDisplay: "symbol",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}`
      : `${Number(value).toFixed(0)}${sign}`;

  return (
    <div className={styles.statisticCard} style={{ maxWidth: width || 500 }}>
      {icon ? (
        <div
          className={classNames(styles.statisticCard__icon, {
            [styles.statisticCard__iconPink]: color === "pink",
            [styles.statisticCard__iconBlue]: !color || color === "blue",
          })}
        >
          {icon}
        </div>
      ) : (
        <div
          className={classNames(styles.statisticCard__line, {
            [styles.statisticCard__linePink]: color === "pink",
            [styles.statisticCard__lineBlue]: !color || color === "blue",
          })}
        />
      )}
      <div className={styles.statisticCard__title}>{title}</div>
      <div
        className={classNames(styles.statisticCard__value, {
          [styles.statisticCard__valuePink]:
            type === "percent" && color === "pink",
          [styles.statisticCard__valueBlue]:
            type === "percent" && (!color || color === "blue"),
        })}
      >
        {textValue}
      </div>
    </div>
  );
};

export const StatsCardWithHint = (props: StatsCardWithHintProps) => {
  return (
    <div className={styles.statisticCardWithHint}>
      <StatsCard {...props} />
      <p className={styles.statisticCardWithHint__hint}>{props.hint}</p>
    </div>
  );
};
