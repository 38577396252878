import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { useGetWalletApplicationsQuery } from "shared/api";
import { walletActions, walletSelector } from "shared/store";
import { getFullDateFormat } from "shared/lib";

export const useWallet = () => {
  const dispatch = useAppDispatch();
  const {
    isActiveApplications,
    searchTerm,
    currentPage,
    applicationsPerPage,
    applicationDate,
  } = useAppSelector(walletSelector);

  const walletQuery = useGetWalletApplicationsQuery({
    is_active: isActiveApplications,
    page: currentPage,
    page_size: applicationsPerPage,
    search: searchTerm || undefined,
    created_at: applicationDate
      ? getFullDateFormat(applicationDate)
      : undefined,
  });

  useEffect(() => {
    if (walletQuery.isSuccess) {
      dispatch(walletActions.setQueryData(walletQuery.data));
      dispatch(walletActions.setApplicationsPerPage(walletQuery.data.total));
    }
  }, [walletQuery, dispatch]);

  return {
    applications: walletQuery.isSuccess ? walletQuery.data.results : [],
    is_active: isActiveApplications,
  };
};
