import classNames from "classnames";
import { useState } from "react";
import styles from "./Toggle.module.scss";

interface ToggleProps {
  isActive?: boolean;
  onChange?: (value: boolean) => void;
}

export const Toggle = ({ isActive: defaultActive, onChange }: ToggleProps) => {
  const [isActive, setIsActive] = useState(!!defaultActive);

  const handleClick = () => {
    onChange && onChange(!isActive);
    setIsActive((active) => !active);
  };

  return (
    <div className={styles.toggle} onClick={handleClick}>
      <div
        className={classNames(styles.toggle__thumb, {
          [styles.toggle__thumbActive]: isActive,
        })}
      />
    </div>
  );
};
