import { Calendar } from "shared/ui/Calendar";
import { useSearchByDate } from "../model";

export const SearchVereficationByDate = () => {
  const { setApplicationDate, applicationDate } = useSearchByDate();

  return (
    <Calendar
      value={applicationDate}
      setDate={setApplicationDate}
      withClearIcon={!!applicationDate}
      placeholderText={"дата регистрации"}
    />
  );
};
