import { Api } from "../..";
import {
  IAuthResponseDto,
  IAuthRequestDto,
  IRefreshRequestDto,
  IRequestResetPasswordDto,
  IResponseResetPasswordDto,
  IResetPasswordDto,
  IResponseResetPasswordErrorDto,
} from "shared/api/dto";

export const authApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    authUser: builder.mutation<IAuthResponseDto, IAuthRequestDto>({
      query: (data) => ({
        url: "api/admin-auth/login/",
        method: "POST",
        body: data,
      }),
    }),
    refreshToken: builder.mutation<IAuthResponseDto, IRefreshRequestDto>({
      query: (data) => ({
        url: "api/auth/refresh/",
        method: "POST",
        body: data,
      }),
    }),
    requestResetPassword: builder.mutation<
      IResponseResetPasswordDto | IResponseResetPasswordErrorDto,
      IRequestResetPasswordDto
    >({
      query: (data) => ({
        url: "api/auth/request_reset_password/",
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation<
      IResponseResetPasswordErrorDto,
      IResetPasswordDto
    >({
      query: (data) => ({
        url: "api/auth/reset_password/",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useAuthUserMutation,
  useRefreshTokenMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
} = authApi;
