import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const DeleteIcon = ({
  height,
  width,
  className,
  size = DEFAULT_ICON_SIZE,
  color = "black",
  fill = "none",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="2 2 18 18"
      width={width || size}
      height={height || size}
      className={className}
      fill={fill}
      cursor="pointer"
      onClick={onClick}
    >
      <path
        d="M19.25 11C19.25 6.44531 15.5547 2.75 11 2.75C6.44531 2.75 2.75 6.44531 2.75 11C2.75 15.5547 6.44531 19.25 11 19.25C15.5547 19.25 19.25 15.5547 19.25 11Z"
        stroke={color}
        strokeWidth="1.45037"
        strokeMiterlimit="10"
      />
      <path
        d="M8.25 13.75L13.75 8.25M13.75 13.75L8.25 8.25L13.75 13.75Z"
        stroke={color}
        strokeWidth="1.45037"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
