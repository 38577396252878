import { PropsWithChildren } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableData,
  TableBody,
} from "shared/ui/Table";

interface AdminsTableProps extends PropsWithChildren {
  dataLoading?: boolean;
}

export const AdminsTable = ({ children, dataLoading }: AdminsTableProps) => {
  return (
    <Table withLoader={dataLoading}>
      <TableHeader>
        <TableRow even>
          <TableData width={70} />
          <TableData width={50} />
          <TableData>ID</TableData>
          <TableData>логин</TableData>
          <TableData>электронная почта</TableData>
          <TableData>дата регистрации</TableData>
          <TableData />
        </TableRow>
      </TableHeader>
      {dataLoading ? null : <TableBody>{children}</TableBody>}
    </Table>
  );
};
