import { PropsWithChildren, Ref } from "react";
import { Table, TableHeader, TableBody, TableData } from "shared/ui/Table";

interface PostsTableProps extends PropsWithChildren {
  headerRef?: Ref<HTMLTableCellElement>;
  dataLoading?: boolean;
}

export const PostsTable = ({
  children,
  headerRef,
  dataLoading,
}: PostsTableProps) => {
  return (
    <Table animationKey={children?.toString().length} withLoader={dataLoading}>
      <TableHeader>
        <tr>
          <TableData />
          <TableData ref={headerRef}>ID публикации</TableData>
          <TableData width={240}>логин пользователя</TableData>
          <TableData>дата публикации</TableData>
          <TableData>кол-во комментариев</TableData>
          <TableData>кол-во жалоб</TableData>
          <TableData width={220} />
        </tr>
      </TableHeader>
      {dataLoading ? null : <TableBody>{children}</TableBody>}
    </Table>
  );
};
