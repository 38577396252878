import { Api, tags } from "../..";
import { ID, IPost } from "shared/types";
import {
  IChangePostRequestDto,
  IPostsListRequestDto,
  IPostsListResponseDto,
} from "shared/api/dto";

export const postsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getPostsList: builder.query<IPostsListResponseDto, IPostsListRequestDto>({
      query: (data) => ({ url: `api/admin-posts/`, params: data }),
      providesTags: [tags.posts],
    }),
    getPost: builder.query<IPost, ID>({
      query: (data) => ({ url: `api/admin-posts/${data.id}/` }),
      providesTags: [tags.post],
    }),
    hidePost: builder.mutation<void, ID>({
      query: (data) => ({
        url: `api/admin-posts/${data.id}/hide/`,
        method: "POST",
      }),
      invalidatesTags: [tags.posts],
    }),
    deletePost: builder.mutation<void, ID>({
      query: (data) => ({
        url: `api/admin-posts/${data.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [tags.posts],
    }),
    changePost: builder.mutation<void, IChangePostRequestDto>({
      query: (data) => ({
        url: `api/admin-posts/${data.id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [tags.post],
    }),
  }),
});

export const {
  useGetPostQuery,
  useGetPostsListQuery,
  useHidePostMutation,
  useDeletePostMutation,
  useChangePostMutation,
} = postsApi;
