import { EditCategories } from "widgets/EditCategories";
import { useTags } from "shared/hooks/core";
import * as Layout from "shared/ui/layout";

export const Tags = () => {
  const { tags, addTag, deleteTag, editTag } = useTags();
  return (
    <Layout.Content
      title={
        <>
          Редактирование <br /> категорий пользователей (тегов)
        </>
      }
    >
      <EditCategories
        categories={tags}
        addCategory={addTag}
        deleteCategory={deleteTag}
        editCategory={editTag}
      />
    </Layout.Content>
  );
};
