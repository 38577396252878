import { ID, IPostItem } from "shared/types";
import { LoaderHooks } from "shared/hooks/shared";
import { useEffect, useState } from "react";
import { useChangePostMutation, useGetPostQuery } from "shared/api";
import { ContentType } from "shared/api/dto";
import { useNotification } from "shared/hooks/shared";

export const usePost = ({ id }: ID) => {
  const postQuery = useGetPostQuery({ id }, { skip: !id });
  const { loadMedia } = LoaderHooks.useImageLoader();
  const [changePostMutation] = useChangePostMutation();

  const { show } = useNotification();

  const [currentPostItem, setCurrentPostItem] = useState(0);

  const post = postQuery.isSuccess ? postQuery.data : null;
  const items = post ? post.items : [];

  useEffect(() => {
    if (currentPostItem >= items.length) {
      setCurrentPostItem(0);
    }
  }, [currentPostItem, items.length]);

  const changePostDescription = (newDescription: string) => {
    if (post && post.description !== newDescription) {
      changePostMutation({
        id: post.id,
        description: newDescription,
        items: post.items,
        is_hidden: post.is_hidden,
        comments_disable: post.comments_disable,
      })
        .unwrap()
        .then(() => {
          show({ text: "Описание изменено" });
        });
    }
  };

  const addMediaInPost = async (file: File) => {
    const type = file.type.split("/").at(0);
    const id = await loadMedia(file, type as ContentType);
    if (id && post && type) {
      changePostMutation({
        id: post.id,
        description: post?.description,
        items: [
          ...post.items,
          { content_type: type, content_id: id },
        ] as IPostItem[],
        is_hidden: post.is_hidden,
        comments_disable: post.comments_disable,
      });
    }
  };

  const deleteMediaFromPost = (postForDelete: IPostItem) => {
    if (items.length === 1) {
      show({
        text: "Нельзя удалить единственный файл в публикации!",
        isError: true,
      });
    } else if (id && post && postForDelete) {
      changePostMutation({
        id: post.id,
        description: post?.description,
        items: post.items.filter(
          (post) => post.content_id !== postForDelete.content_id
        ),
        is_hidden: post.is_hidden,
        comments_disable: post.comments_disable,
      })
        .unwrap()
        .then(() => {
          show({ text: "Медиа-файл удален" });
        });
    }
  };

  const nextMedia = () => {
    if (items.length > currentPostItem) {
      setCurrentPostItem((post) => post + 1);
    }
  };

  const prevMedia = () => {
    if (currentPostItem > 0) {
      setCurrentPostItem((post) => post - 1);
    }
  };

  return {
    post,
    changePostDescription,
    addMediaInPost,
    deleteMediaFromPost,
    items: {
      isLast: currentPostItem >= items.length - 1,
      isFirst: currentPostItem === 0,
      nextMedia,
      prevMedia,
      item: items[currentPostItem],
    },
  };
};
