import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useHidePostMutation } from "shared/api";
import { IPost } from "shared/types";

export const useHidePost = ({ is_hidden, id }: IPost) => {
  const { open } = useShowConfirmModal();
  const { show } = useNotification();

  const [hideMutation] = useHidePostMutation();

  const hidePost = () => {
    const action = is_hidden ? "Показать" : "Скрыть";
    open({
      title: `${action} публикацию?`,
      buttonText: action,
      onConfirmCallback: () => {
        hideMutation({ id }).then(() => {
          const action = is_hidden ? "Пост доступен!" : "Пост скрыт!";

          show({ text: action });
        });
      },
    });
  };

  return {
    hidePost,
  };
};
