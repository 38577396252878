import { useMemo, useCallback, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { usersSelector, usersActions } from "shared/store";
import { IUser } from "shared/types";

export const useSelectUser = (user: IUser) => {
  const dispatch = useAppDispatch();

  const { selectedUsers } = useAppSelector(usersSelector);

  const selected = useMemo(() => {
    return selectedUsers.some((selectedUser) => user.id === selectedUser.id);
  }, [user, selectedUsers]);

  const selectUser = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && user) {
        dispatch(usersActions.addUserInSelected(user));
      } else if (!event.target.checked && user) {
        dispatch(usersActions.deleteUserFromSelected(user));
      }
    },
    [dispatch, user]
  );

  return { selected, selectUser };
};
