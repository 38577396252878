import { useState } from "react";
import * as Layout from "shared/ui/layout";
import { Tooltip } from "shared/ui/Tooltip";
import { AlertIcon, DocumentIcon } from "shared/ui/Icons";
import { CallsTable, CallsTableRow } from "entities/calls/CallsTable";
import { CallDataModal } from "entities/calls/CallDataModal";
import { DeleteCall } from "features/calls/delete-call";
import { SearchCall } from "features/calls/seacrh-call";
import { SearchCallByType } from "features/calls/search-call-by-type";
import { SearchCallByDate } from "features/calls/search-call-by-date";
import { SelectCall } from "features/calls/select-call";
import { useCalls } from "./model";
import styles from "./Calls.module.scss";
import { useCallsPagination } from "./model/useCallsPagination";
import { Pagination } from "shared/ui/Pagination";
import { CallInfoModal } from "entities/calls/CallInfoModal";

export const CallsPage = () => {
  const { calls, loadingStatus } = useCalls();
  const { pagination } = useCallsPagination();

  const [modalData, setModalData] = useState({
    isCallRecordOpen: false,
    isCallInfoOpen: false,
    infoData: {
      id: 0,
    },
    recordData: {
      id: 0,
      user: "",
    },
  });

  const openRecordModal = (id: number, user: string) => {
    setModalData((data) => ({
      ...data,
      isCallRecordOpen: true,
      recordData: { id, user },
    }));
  };

  const closeRecordModal = () => {
    setModalData((data) => ({
      ...data,
      isCallRecordOpen: false,
      recordData: { id: 0, user: "" },
    }));
  };

  const openInfoModal = (id: number) => {
    setModalData((data) => ({
      ...data,
      isCallInfoOpen: true,
      infoData: { id },
    }));
  };

  const closeInfoModal = () => {
    setModalData((data) => ({
      ...data,
      isCallInfoOpen: false,
      infoData: { id: 0 },
    }));
  };

  return (
    <>
      <Layout.Content title="Управление записями звонков">
        <div className={styles.calls}>
          <div>
            <div className={styles.callsSort}>
              <SearchCallByType />
              <div className={styles.callsSort__paginationTop}>
                <div className={styles.callsSort__controls}>
                  <SearchCall />
                  <DeleteCall />
                  <SearchCallByDate />
                </div>
                <Pagination isShort withSelector {...pagination} />
              </div>
            </div>
          </div>

          <CallsTable dataLoading={loadingStatus.isLoading}>
            {calls?.map((call) => (
              <CallsTableRow
                call={call}
                key={call.id}
                selectOption={<SelectCall id={call.id} />}
                infoOption={
                  <AlertIcon onClick={() => openInfoModal(call.call_id)} />
                }
                controlsOptions={
                  <Tooltip tooltip="Материалы">
                    <DocumentIcon
                      onClick={() => openRecordModal(call.call_id, call.user)}
                      className={styles.calls__openCallDataButton}
                    />
                  </Tooltip>
                }
              />
            ))}
          </CallsTable>
          {loadingStatus.isSuccess && <Pagination {...pagination} />}
        </div>
      </Layout.Content>

      {modalData.isCallRecordOpen && (
        <CallDataModal
          id={modalData.recordData.id}
          user={modalData.recordData.user}
          closeModal={closeRecordModal}
        />
      )}

      {modalData.isCallInfoOpen && (
        <CallInfoModal id={modalData.infoData.id} closeModal={closeInfoModal} />
      )}
    </>
  );
};
