export enum DashboardStats {
  new_users = "Новые пользователи",
  unique_users = "Уникальные пользователи",
  active_users = "Активные пользователи",
  posts_likes_activity = "Лайки на публикации",
  profiles_likes_activity = "Лайки на профили",
  comments_activity = "Комментарии к публикациям",
}

export interface IFinanceStat {
  key: string;
  value: number | string;
}

export type IDashboardStats = {
  simple_stat: {
    new_users: number;
    unique_users: number;
    active_users: number;
    posts_likes: number;
    profiles_likes: number;
    comments: number;
  };
  rows_stat: {
    visits: IFinanceStat[];
  };
};

export interface IDashboardFinances {
  simple_stat: {
    total_refill: number;
    total_call_users_earnings: number;
  };
  rows_stat: {
    average_refill: IFinanceStat[];
    average_call_user_earning: IFinanceStat[];
    profit_from_calls: IFinanceStat[];
    profit_from_ads: IFinanceStat[];
  };
}
