import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

interface ICameraIcon extends SVGIconProps {
  withBackground?: boolean;
}

export const CameraIcon = ({
  height,
  width,
  size = DEFAULT_ICON_SIZE,
  withBackground = false,
  className,
  color = "#515151",
  onClick,
}: ICameraIcon) => {
  return withBackground ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <rect width="100%" height="100%" rx="10" fill="#EDEEFF" />
      <path
        d="M30.125 27.0152C29.95 27.0151 29.777 26.9782 29.6172 26.907C29.58 26.8905 29.5445 26.8704 29.5113 26.8469L26.2805 24.5727C26.1166 24.4573 25.9829 24.3043 25.8906 24.1264C25.7983 23.9486 25.75 23.7512 25.75 23.5508V20.4492C25.75 20.2488 25.7983 20.0514 25.8906 19.8736C25.9829 19.6957 26.1166 19.5427 26.2805 19.4273L29.5113 17.1531C29.5445 17.1296 29.58 17.1095 29.6172 17.093C29.8075 17.0084 30.0159 16.9726 30.2236 16.9891C30.4312 17.0055 30.6314 17.0735 30.8061 17.187C30.9807 17.3005 31.1242 17.4558 31.2236 17.6389C31.323 17.8219 31.375 18.0269 31.375 18.2352V25.7648C31.375 26.0964 31.2433 26.4143 31.0089 26.6487C30.7745 26.8831 30.4565 27.0148 30.125 27.0148V27.0152ZM22.4688 27.625H15.2812C14.577 27.6243 13.9018 27.3442 13.4038 26.8462C12.9058 26.3482 12.6257 25.673 12.625 24.9688V19.0312C12.6257 18.327 12.9058 17.6518 13.4038 17.1538C13.9018 16.6558 14.577 16.3757 15.2812 16.375H22.4875C23.1868 16.3758 23.8571 16.654 24.3516 17.1484C24.846 17.6429 25.1242 18.3132 25.125 19.0125V24.9688C25.1243 25.673 24.8442 26.3482 24.3462 26.8462C23.8482 27.3442 23.173 27.6243 22.4688 27.625Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 12"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
    >
      <path
        d="M18.125 11.0152C17.95 11.0151 17.777 10.9782 17.6172 10.907C17.58 10.8905 17.5445 10.8704 17.5113 10.8469L14.2805 8.57266C14.1166 8.45731 13.9829 8.30427 13.8906 8.12643C13.7983 7.94859 13.75 7.75116 13.75 7.55078V4.44922C13.75 4.24884 13.7983 4.05141 13.8906 3.87357C13.9829 3.69573 14.1166 3.54269 14.2805 3.42734L17.5113 1.15312C17.5445 1.12963 17.58 1.10948 17.6172 1.09297C17.8075 1.00836 18.0159 0.97264 18.2236 0.989063C18.4312 1.00549 18.6314 1.07353 18.8061 1.18701C18.9807 1.30049 19.1242 1.45581 19.2236 1.63886C19.323 1.82191 19.375 2.02688 19.375 2.23516V9.76484C19.375 10.0964 19.2433 10.4143 19.0089 10.6487C18.7745 10.8831 18.4565 11.0148 18.125 11.0148V11.0152ZM10.4688 11.625H3.28125C2.57699 11.6243 1.90178 11.3442 1.4038 10.8462C0.90581 10.3482 0.625724 9.67301 0.625 8.96875V3.03125C0.625724 2.32699 0.90581 1.65178 1.4038 1.1538C1.90178 0.655811 2.57699 0.375724 3.28125 0.375H10.4875C11.1868 0.375827 11.8571 0.653971 12.3516 1.14842C12.846 1.64287 13.1242 2.31325 13.125 3.0125V8.96875C13.1243 9.67301 12.8442 10.3482 12.3462 10.8462C11.8482 11.3442 11.173 11.6243 10.4688 11.625Z"
        fill={color}
      />
    </svg>
  );
};
