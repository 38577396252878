import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { useHidePostMutation } from "shared/api";
import { decOfPosts } from "shared/lib";
import { postsSelector, postsActions } from "shared/store";

export const useHideSelectedPosts = () => {
  const dispatch = useAppDispatch();

  const [hidePost] = useHidePostMutation();

  const { open } = useShowConfirmModal();
  const { show, hide } = useNotification();

  const { selectedPosts } = useAppSelector(postsSelector);

  const hideSelectedPosts = () => {
    const hiddenPostsCount = selectedPosts.filter(
      (post) => post.is_hidden
    ).length;
    const visiblePostsCount = selectedPosts.filter(
      (post) => !post.is_hidden
    ).length;

    open({
      title: (
        <>
          {hiddenPostsCount > 0 &&
            `Опубликовать ${hiddenPostsCount} ${decOfPosts(hiddenPostsCount)}?`}
          <br />
          <br />
          {visiblePostsCount > 0 &&
            `Скрыть ${visiblePostsCount} ${decOfPosts(visiblePostsCount)}?`}
        </>
      ),
      buttonText: "Подтвердить",
      onConfirmCallback: async () => {
        show({ text: "Изменяется видимость публикаций", autoClose: false });
        let promises = selectedPosts.map((post) =>
          hidePost({ id: post.id }).unwrap()
        );
        let results = await Promise.allSettled(promises);

        if (results.every((promise) => promise.status === "fulfilled")) {
          hide();
          show({ text: "Видимость изменена!", timeout: 2500 });
        }

        dispatch(postsActions.resetSelectedPosts());
      },
    });
  };

  return {
    hideSelectedPosts,
    selectedPostsCount: selectedPosts.length,
  };
};
