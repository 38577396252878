import { memo } from "react";
import { IFeedbackMessage } from "shared/types";
import { FeedbackMessageBody } from "entities/feedback/FeedbackMessageBody";
import { FeedbackReply } from "entities/feedback/FeedbackReply";
import { useShowMessageAuthorInfo } from "features/feedback/show-message-author-info";
import { FeedbackAnswer } from "entities/feedback/FeedbackAnswer";
import styles from "./FeedbackMessage.module.scss";

interface FeedbackMessageProps {
  message: IFeedbackMessage;
  reply: (value: string, id: number) => void;
}

export const FeedbackMessage = memo(
  ({ message, reply }: FeedbackMessageProps) => {
    const { showFullMessageText, showMessageAuthorInfo } =
      useShowMessageAuthorInfo(message);

    const handleReply = (value: string) => {
      reply(value, message.id);
    };

    return (
      <div className={styles.feedbackMessage}>
        <FeedbackMessageBody
          message={message}
          openFullText={showFullMessageText}
          openUserInfo={showMessageAuthorInfo}
        />
        {!message.reply && <FeedbackReply submit={handleReply} />}
        {message.reply && (
          <FeedbackAnswer description={message.reply.description} />
        )}
      </div>
    );
  }
);
