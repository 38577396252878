import { useAppSelector } from "shared/hooks/shared";
import { useState, useEffect } from "react";
import { useGetPostQuery } from "shared/api";
import { ModalsSelector } from "shared/store";
import { IPostItem } from "shared/types";

export const usePostMediaFiles = () => {
  const { id } = useAppSelector(ModalsSelector.postMedia);
  const [posts, setPosts] = useState<IPostItem[]>([]);
  const [currentPost, setCurrentPost] = useState(0);
  const postQuery = useGetPostQuery(
    { id: id as number },
    {
      skip: !id,
    }
  );

  const nextPost = () => {
    if (posts.length > currentPost) {
      setCurrentPost((post) => post + 1);
    }
  };

  const prevPost = () => {
    if (currentPost > 0) {
      setCurrentPost((post) => post - 1);
    }
  };

  useEffect(() => {
    if (postQuery.isSuccess) {
      if (postQuery.data.items.length) {
        setPosts(postQuery.data.items);
      }
    }
  }, [postQuery]);

  return {
    post: posts[currentPost],
    isLastPost: currentPost >= posts.length - 1,
    isFirstPost: currentPost === 0,
    nextPost,
    prevPost,
  };
};
