import { Api, tags } from "../..";
import { IContentCategory } from "shared/types";

export const tagsCommonApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getInterestsCategories: builder.query<IContentCategory[], void>({
      query: () => "api/tags/",
      providesTags: [tags.interests],
    }),
  }),
});

export const { useGetInterestsCategoriesQuery } = tagsCommonApi;
