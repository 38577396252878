import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const LensIcon = ({
  height,
  width,
  size = DEFAULT_ICON_SIZE,
  className,
  color = "#4E51BF",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <path
        d="M17.75 16.0757L12.6794 11.0043C13.4966 9.8814 13.9361 8.52805 13.9348 7.13928C13.9348 3.5043 10.9774 0.546875 7.3424 0.546875C3.70743 0.546875 0.75 3.5043 0.75 7.13928C0.75 10.7743 3.70743 13.7317 7.3424 13.7317C8.73118 13.733 10.0845 13.2934 11.2075 12.4763L16.2788 17.5469L17.75 16.0757ZM7.3424 11.6496C6.45023 11.6497 5.57807 11.3852 4.83623 10.8896C4.09438 10.394 3.51617 9.68948 3.17471 8.86524C2.83325 8.04099 2.74389 7.134 2.91793 6.25897C3.09196 5.38394 3.52157 4.58017 4.15243 3.94931C4.78329 3.31845 5.58706 2.88884 6.4621 2.7148C7.33713 2.54077 8.24412 2.63013 9.06836 2.97159C9.89261 3.31304 10.5971 3.89126 11.0927 4.6331C11.5883 5.37495 11.8528 6.24711 11.8527 7.13928C11.8513 8.33506 11.3757 9.48145 10.5301 10.327C9.68458 11.1725 8.53818 11.6482 7.3424 11.6496Z"
        fill={color}
      />
    </svg>
  );
};
