import { useAppDispatch } from "shared/hooks/shared";
import { modalActions, ModalType } from "shared/store";
import { ID } from "shared/types";

/**
 *
 * @param id - идентификатор публикации, из которой будут взяты медиа материалы
 * @returns - открывает модьное окно с матриалами поста
 */
export const useShowPostMediaModal = ({ id }: ID) => {
  const dispatch = useAppDispatch();

  const openDocumentModal = () => {
    dispatch(modalActions.openModal(ModalType.documentModal));
    dispatch(modalActions.setDocumentData(id));
  };

  return {
    openDocumentModal,
  };
};
