import {
  ArrowDirection,
  DEFAULT_ICON_SIZE,
  IArrowIcon,
} from "./icon.interface";

export const ArrowIcon = ({
  height,
  width,
  size = DEFAULT_ICON_SIZE,
  className,
  color = "#4E51BF",
  direction = ArrowDirection.down,
  onClick,
}: IArrowIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 18"
      width={width || size}
      height={height || size}
      className={className}
      style={{ transform: `rotate(${direction})` }}
      fill="none"
      onClick={onClick}
    >
      <path
        opacity="0.8"
        d="M2.5 2.25L16 15.75L29.5 2.25"
        stroke={color}
        strokeWidth="4.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
