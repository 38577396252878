import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryData, IUserVerification } from "shared/types";

interface IInitialState {
  info: {
    total: number;
    pages: number;
    is_last: boolean;
  };
  selectedItems: IUserVerification[];
  currentPage: number;
  usersPerPage: number;
  searchTerm: string;
  applicationDate: Date | null;
}

const initialState: IInitialState = {
  info: {
    total: 0,
    pages: 0,
    is_last: true,
  },
  selectedItems: [],
  currentPage: 1,
  usersPerPage: 50,
  searchTerm: "",
  applicationDate: null,
};

export const verificationsSlice = createSlice({
  name: "verifications",
  initialState,
  reducers: {
    setQueryData: (state, action: PayloadAction<QueryData<unknown>>) => {
      state.info.is_last = action.payload.is_last;
      state.info.pages = action.payload.pages;
      state.info.total = action.payload.total;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setUsersPerPage: (state, action: PayloadAction<number>) => {
      state.usersPerPage = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setApplicationDate: (state, action: PayloadAction<Date | null>) => {
      state.applicationDate = action.payload;
    },
    addVereficationInSelected: (
      state,
      action: PayloadAction<IUserVerification>
    ) => {
      state.selectedItems.push(action.payload);
    },
    removeVereficationFromSelected: (
      state,
      action: PayloadAction<IUserVerification>
    ) => {
      state.selectedItems = state.selectedItems.filter(
        (item) => item.id !== action.payload.id
      );
    },
    resetSelectedVerefications: (state) => {
      state.selectedItems = [];
    },
  },
});

export const verificationsActions = verificationsSlice.actions;

export default verificationsSlice.reducer;
