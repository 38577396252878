import classNames from "classnames";
import {
  BanIcon,
  CameraIcon,
  EyeIcon,
  MicrophoneIcon,
  MinusIcon,
  PlusIcon,
} from "shared/ui/Icons";
import { Button, ButtonProps } from "shared/ui/Buttons";
import styles from "./OptionPannelButtons.module.scss";

export const OptionButton = (
  props: Omit<ButtonProps, "icon" | "className">
) => {
  return (
    <Button {...props} type="solid" className={styles.optionPannelButton}>
      {props.children}
    </Button>
  );
};

export const DeleteButton = (
  props: Omit<ButtonProps, "icon" | "className">
) => {
  return (
    <Button
      {...props}
      type="solid"
      icon={<MinusIcon color="#fff" />}
      className={styles.optionPannelButton}
    >
      {props.children}
    </Button>
  );
};

export const AddButton = (props: Omit<ButtonProps, "icon" | "className">) => {
  return (
    <Button
      {...props}
      type="solid"
      icon={<PlusIcon color="#fff" />}
      className={styles.optionPannelButton}
    >
      {props.children}
    </Button>
  );
};

export const BanButton = (props: Omit<ButtonProps, "icon" | "className">) => {
  return (
    <Button
      {...props}
      type="solid"
      icon={<BanIcon color="#fff" />}
      className={styles.optionPannelButton}
    >
      {props.children}
    </Button>
  );
};

export const HideButton = (
  props: Omit<ButtonProps, "icon" | "className"> & { isClose?: boolean }
) => {
  return (
    <Button
      {...props}
      type="solid"
      icon={<EyeIcon isClose={props.isClose} color="#fff" />}
      className={styles.optionPannelButton}
    >
      {props.children}
    </Button>
  );
};

export const CameraButton = ({
  disabled,
  onClick,
}: Pick<ButtonProps, "onClick" | "disabled">) => {
  return (
    <div
      className={classNames(styles.iconButton, {
        [styles.iconButtonSelected]: disabled,
      })}
      onClick={onClick}
    >
      <CameraIcon size={24} />
    </div>
  );
};

export const MicrophoneButton = ({
  disabled,
  onClick,
}: Pick<ButtonProps, "onClick" | "disabled">) => {
  return (
    <div
      className={classNames(styles.iconButton, {
        [styles.iconButtonSelected]: disabled,
      })}
      onClick={onClick}
    >
      <MicrophoneIcon size={24} />
    </div>
  );
};
