import { memo } from "react";
import classNames from "classnames";
import { useSearchActiveApplications } from "../model";
import styles from "./SearchActiveApplications.module.scss";
import { Tooltip } from "shared/ui/Tooltip";

interface WalletSortButtonProps {
  isActive: boolean;
  onClick: () => void;
  title: string;
}

const WalletSortButton = memo(
  ({ isActive, onClick, title }: WalletSortButtonProps) => {
    return (
      <button
        onClick={onClick}
        className={classNames(styles.button, {
          [styles.buttonInactive]: !isActive,
        })}
      >
        {title}
      </button>
    );
  }
);

export const SearchActiveApplications = () => {
  const { showActive, showInactive, isActive } = useSearchActiveApplications();

  return (
    <>
      <div style={{ cursor: "not-allowed" }}>
        <Tooltip tooltip="В разработке">
          <div style={{ pointerEvents: "none" }}>
            <WalletSortButton
              title="эквайринг №1"
              isActive={true}
              onClick={() => {}}
            />
          </div>
        </Tooltip>
      </div>
      <div style={{ cursor: "not-allowed" }}>
        <Tooltip tooltip="В разработке">
          <div style={{ pointerEvents: "none" }}>
            <WalletSortButton
              title="эквайринг №2"
              isActive={true}
              onClick={() => {}}
            />
          </div>
        </Tooltip>
      </div>
      <WalletSortButton
        title="выполненные"
        isActive={isActive}
        onClick={showInactive}
      />
      <WalletSortButton
        title="актуальные"
        isActive={!isActive}
        onClick={showActive}
      />
    </>
  );
};
