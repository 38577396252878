import Color from "color";
import { useMemo } from "react";
import { BarChart, Bar, ResponsiveContainer, Tooltip, Cell } from "recharts";
import styles from "./BarsGroupGraph.module.scss";
import "../RechartStyles.scss";
import { CustomTooltip } from "./BarsGroupTooltip";

interface BarsGroupProps {
  data: { date: string; values: number[] }[];
}

interface BarsGroupGraphProps {
  data: { date: string; values: number[] };
  maxValue: number;
}

export const BarsGroupGraph = ({ data, maxValue }: BarsGroupGraphProps) => {
  const mainColor = Color("#4E51BF");

  const params = data.values.map((item, ind) => ({
    name: ind,
    value: item,
    fill: mainColor.lighten(Math.min(1.0 - item / maxValue, 0.4)).hex(),
  }));

  return (
    <div className={styles.graphBar}>
      <ResponsiveContainer height="100%">
        <BarChart width={160} height={40} data={params}>
          <Tooltip
            wrapperStyle={{ outline: "none", zIndex: 1000 }}
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          <Bar
            barSize={18}
            minPointSize={10}
            radius={8}
            dataKey="value"
            fill="#4E51BF"
            cursor={"pointer"}
          >
            {params.map((entry, index) => {
              const height = Math.max((entry.value / maxValue) * 140, 10);
              return (
                <Cell
                  fill={entry.fill}
                  height={height}
                  y={150 - height}
                  key={`cell-${index}`}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <span className={styles.graphBar__label}>{data.date}</span>
    </div>
  );
};

export const BarsGroup = ({ data }: BarsGroupProps) => {
  const maxValue = useMemo(
    () => Math.max(...data.map((item) => item.values).flat()),
    [data]
  );

  return (
    <div className={styles.graphBars}>
      {data &&
        data?.map((bar, ind) => (
          <BarsGroupGraph data={bar} maxValue={maxValue} key={ind} />
        ))}
    </div>
  );
};
