import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserState {
  accessToken: string;
  refreshToken: string;
}

const initialState: IUserState = {
  accessToken: "",
  refreshToken: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTokens: (
      state,
      action: PayloadAction<{
        access: string;
        refresh: string;
      }>
    ) => {
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
      localStorage.setItem("access", action.payload.access);
      localStorage.setItem("refresh", action.payload.refresh);
    },
    restoreTokens: (state) => {
      const refresh = localStorage.getItem("refresh");

      if (refresh) {
        state.refreshToken = refresh;
      }
    },
    logOut: (state) => {
      state.accessToken = "";
      state.refreshToken = "";
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
