import { Api, tags } from "../..";
import { IAgreement, ICommission, IWarning, ID } from "shared/types";
import { IChangeOnboardingRequestDto } from "shared/api/dto";

const agreementPath = "api/admin-base/agreement/";
const onboardingsPath = "api/admin-base/onboardings/";
const onboardingsWithId = (id: number | string) =>
  `api/admin-base/onboardings/${id}/`;
const onboardingsTextsWithId = (id: number | string) =>
  `api/admin-base/onboardings/${id}/set-texts/`;
const commissionWithId = (id: number | string) =>
  `api/admin-base/commission/${id}/`;
const warningWithId = (id: number | string) => `api/admin-base/warning/${id}/`;

export const baseAdminApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    setAgreements: builder.mutation<void, IAgreement>({
      query: (data) => ({ url: agreementPath, method: "POST", body: data }),
      invalidatesTags: [tags.agreement],
    }),

    addNewOnboarding: builder.mutation<
      {
        id: number;
      },
      FormData
    >({
      query: (data) => ({ url: onboardingsPath, method: "POST", body: data }),
      invalidatesTags: [tags.onboarding],
    }),

    changeOnboarding: builder.mutation<void, IChangeOnboardingRequestDto>({
      query: (data) => ({
        url: onboardingsWithId(data.id),
        method: "PATCH",
        body: data.formData,
      }),
      invalidatesTags: [tags.onboarding],
    }),

    changeOnboardingTexts: builder.mutation<
      void,
      { id: number | string; texts: unknown[] }
    >({
      query: (data) => ({
        url: onboardingsTextsWithId(data.id),
        method: "POST",
        body: { texts: data.texts },
      }),
      invalidatesTags: [tags.onboarding],
    }),

    deleteOnboarding: builder.mutation<void, ID>({
      query: (data) => ({ url: onboardingsWithId(data.id), method: "DELETE" }),
      invalidatesTags: [tags.onboarding],
    }),

    changeCommission: builder.mutation<void, ICommission>({
      query: (data) => ({
        url: commissionWithId(data.id),
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [tags.commission],
    }),

    changeWarning: builder.mutation<void, IWarning>({
      query: (data) => ({
        url: warningWithId(data.id),
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [tags.warning],
    }),
  }),
});

export const {
  useSetAgreementsMutation,
  useAddNewOnboardingMutation,
  useChangeOnboardingMutation,
  useDeleteOnboardingMutation,
  useChangeCommissionMutation,
  useChangeWarningMutation,
  useChangeOnboardingTextsMutation,
} = baseAdminApi;
