import {
  useGetInterestsCategoriesQuery,
  useAddTagMutation,
  useDeleteTagMutation,
  useChangeTagMutation,
} from "shared/api";

export const useTags = () => {
  const tagsQuery = useGetInterestsCategoriesQuery();

  const [addTag] = useAddTagMutation();
  const [deleteTag] = useDeleteTagMutation();
  const [editTag] = useChangeTagMutation();

  const tags = tagsQuery.isSuccess ? tagsQuery.data : [];

  return { tags, addTag, deleteTag, editTag };
};
