import classNames from "classnames";
import { ButtonProps } from "./button.interface";
import styles from "./button.module.scss";

export const Button = ({
  children,
  onClick,
  className,
  type,
  icon,
  htmlType,
  disabled = false,
  overflow = true,
}: ButtonProps) => {
  const clickHandler = () => {
    onClick && onClick();
  };

  return (
    <button
      type={htmlType}
      className={classNames(styles.button, {
        [styles.buttonGray]: type === "gray",
        [styles.buttonSolid]: type === "solid",
        [styles.buttonDefault]: !type,
        [styles.buttonWithIcon]: icon,
        [styles.buttonDisabled]: disabled,
        [className as string]: className,
      })}
      onClick={clickHandler}
    >
      <>
        {children && (
          <div
            className={classNames(styles.button__text, {
              [styles.button__textWithoutOverflow]: !overflow,
            })}
          >
            {children}
          </div>
        )}
        {icon && <div className={styles.button__icon}>{icon}</div>}
      </>
    </button>
  );
};
