import {
  ChangeEvent,
  FormEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAppDispatch } from "shared/hooks/shared";
import { modalActions } from "shared/store";
import { Button } from "shared/ui/Buttons";
import { Modal } from "shared/ui/Modal";
import styles from "./LoadPhotoModal.module.scss";

export const LoadPhotoModal = () => {
  const dispatch = useAppDispatch();
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    dispatch(modalActions.resetPhotoData());
  }, [dispatch]);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;
    if (files && files.length) {
      const image = files[0];
      setImageFile(image);
      if (typeof image === "string") {
        setImageUrl(image);
      } else {
        setImageUrl(URL.createObjectURL(image));
      }
    }
  };

  const handleChangeImageClick = (event?: SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (ref.current) {
      ref.current.value = "";
      ref.current.click();
    }
  };

  const handleLoadImage = () => {
    if (imageUrl) {
      dispatch(
        modalActions.setPhotoData({ imageUrl, imageFile: imageFile as File })
      );
      dispatch(modalActions.closeModal());
    }
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  return (
    <Modal>
      <div className={styles.photoModal}>
        <div className={styles.photoModal__imageContainer}>
          {imageUrl && (
            <img src={imageUrl} alt="" className={styles.photoModal__image} />
          )}
        </div>
        <form onSubmit={onSubmit}>
          <input
            type="file"
            className={styles.photoModal__imageInput}
            accept={"image/*"}
            multiple={false}
            ref={ref}
            onChange={handleImageChange}
          />
          <Button
            className={styles.photoModal__button}
            onClick={handleChangeImageClick}
          >
            {imageUrl ? "Выбрать другое фото" : "Выбрать фото"}
          </Button>
          <Button
            disabled={!imageUrl}
            type="solid"
            className={styles.photoModal__button}
            onClick={handleLoadImage}
          >
            Загрузить
          </Button>
        </form>
      </div>
    </Modal>
  );
};
