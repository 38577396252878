import { BanButton } from "shared/ui/Buttons";
import { useBanSelectedUsers } from "../model";

export const BanSelectedUsers = () => {
  const { banUsers, selectedUsersCount } = useBanSelectedUsers();
  return (
    <BanButton disabled={!selectedUsersCount} onClick={banUsers}>
      Забанить
    </BanButton>
  );
};
