import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import {
  useGetVerificationsQuery,
  useSetVerificationsMutation,
} from "shared/api";
import { contentActions, ContentSelector } from "shared/store";

export const useVerefications = () => {
  const dispatch = useAppDispatch();
  const verificationsQuery = useGetVerificationsQuery();
  const [setVerifications] = useSetVerificationsMutation();

  const { requirements, flow } = useAppSelector(ContentSelector.verefications);

  useEffect(() => {
    if (verificationsQuery.isSuccess) {
      dispatch(contentActions.setVerifications(verificationsQuery.data));
    }
  }, [verificationsQuery, dispatch]);

  return { requirements, flow, setVerifications };
};
