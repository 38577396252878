export enum ComplaintType {
  CALL = "CALL",
  COMMENT = "COMMENT",
  POST = "POST",
  PROFILE = "PROFILE",
  STORY = "STORY",
}

export enum ComplaintReasonType {
  ADULTS_CONTENT = "ADULTS_CONTENT",
  ANIMAL_ABUSE = "ANIMAL_ABUSE",
  COPYRIGHT_INFRINGEMENT = "COPYRIGHT_INFRINGEMENT",
  EXTREMISM = "EXTREMISM",
  FRAUD = "FRAUD",
  INSULT = "INSULT",
  MISLEADING = "MISLEADING",
  SELF_HARM = "SELF_HARM",
  SHOCKING_CONTENT = "SHOCKING_CONTENT",
  SPAM = "SPAM",
}

export const ComplaintReasons = {
  [ComplaintReasonType.ADULTS_CONTENT]: "Материалы для взрослых",
  [ComplaintReasonType.ANIMAL_ABUSE]: "Жестокое обращение с животными",
  [ComplaintReasonType.COPYRIGHT_INFRINGEMENT]: "Нарушение авторских прав",
  [ComplaintReasonType.EXTREMISM]: "Экстремизм",
  [ComplaintReasonType.FRAUD]: "Мошенничество",
  [ComplaintReasonType.INSULT]: "Оскорбление/Враждебные высказывания",
  [ComplaintReasonType.MISLEADING]: "Введение в заблуждение",
  [ComplaintReasonType.SELF_HARM]: "Суицид, приченение вреда здоровью",
  [ComplaintReasonType.SHOCKING_CONTENT]: "Шокирующий контент",
  [ComplaintReasonType.SPAM]: "Спам",
};

export const ComplaintReasonKeys = Object.keys(ComplaintReasons) as Array<
  keyof typeof ComplaintReasons
>;

export interface IComplaint {
  id: number;
  author: string;
  accused: {
    username: string;
    is_verified: boolean;
  };
  post_url?: string;
  reason: ComplaintReasonType;
  object_type: ComplaintType;
  object_id: number;
  description: string;
  created_at: string;
  object_created_at: string;
  complained_count: number;
  complained_limit: number;
}
