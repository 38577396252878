import { ChangeEvent } from "react";
import { InputSearch } from "shared/ui/Inputs";
import { useSearchApplicationsByAuthor } from "../model";

export const SearchApplicationByAuthor = () => {
  const { setSearchText, searchTerm } = useSearchApplicationsByAuthor();

  const changeText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <InputSearch
      value={searchTerm}
      onChange={changeText}
      placeholder="Найти заявку"
    />
  );
};
