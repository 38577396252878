import { useContext, useState, useRef, useLayoutEffect } from "react";
import { useInView } from "react-intersection-observer";

import { MenuContext } from "shared/context/MenuContext";

export const useStickyMenu = () => {
  const { ref, inView, entry } = useInView({
    threshold: 1,
  });

  const { isMobileMenu } = useContext(MenuContext);

  const [size, setSize] = useState({ clientWidth: 0, clientHeight: 0 });
  const wrapperRef = useRef<HTMLDivElement>(null!);

  const isStickyMenuVisible = !inView && entry;

  useLayoutEffect(() => {
    const resizeHandler = () => {
      const { clientHeight, clientWidth } = wrapperRef.current || {};
      setSize({ clientHeight, clientWidth });
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [isMobileMenu]);

  return { ref, wrapperRef, size, isStickyMenuVisible };
};
