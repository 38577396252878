import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useApproveComplaintMutation } from "shared/api";
import { ID } from "shared/types";

export const useApproveComplaint = ({ id }: ID) => {
  const [approve] = useApproveComplaintMutation();

  const { open } = useShowConfirmModal();

  const { show } = useNotification();

  const approveComplaint = () => {
    approve({ id })
      .unwrap()
      .then(() => {
        show({ text: "Жалоба принята!" });
      });
  };

  const approveComplaintWithConfirm = () => {
    open({
      title: `Принять жалобу?`,
      buttonText: "Принять",
      onConfirmCallback: approveComplaint,
    });
  };

  return {
    approveComplaint: approveComplaintWithConfirm,
  };
};
