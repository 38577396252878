import {
  ICallInfoDto,
  ICallRecordReponseDto,
  ICallsRequestDto,
  ICallsResponseDto,
} from "shared/api/dto/calls.dto";
import { Api, tags } from "../..";

export const callsApi = Api.enhanceEndpoints({
  addTagTypes: [...Object.values(tags)],
}).injectEndpoints({
  endpoints: (builder) => ({
    getCalls: builder.query<ICallsResponseDto, ICallsRequestDto>({
      query: (data) => ({ url: "api/admin-calls/", params: data }),
      providesTags: [tags.calls],
    }),
    getCallRecord: builder.query<ICallRecordReponseDto, { id: number }>({
      query: (data) => ({ url: `/api/admin-calls/${data.id}/record/` }),
      providesTags: [tags.calls],
    }),
    getCallInfo: builder.query<ICallInfoDto, { id: number }>({
      query: (data) => ({ url: `/api/admin-calls/${data.id}/` }),
      providesTags: [tags.calls],
    }),
    deleteCall: builder.mutation<void, { id: number }>({
      query: (data) => ({
        url: `/api/admin-calls/${data.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [tags.calls],
    }),
    deleteCallRecord: builder.mutation<void, { id: number }>({
      query: (data) => ({
        url: `/api/admin-calls/${data.id}/record/delete/`,
        method: "DELETE",
      }),
      invalidatesTags: [tags.calls],
    }),
  }),
});

export const {
  useGetCallsQuery,
  useGetCallRecordQuery,
  useDeleteCallMutation,
  useDeleteCallRecordMutation,
  useGetCallInfoQuery,
} = callsApi;
