import { useCallback, useEffect } from "react";
import { useAppDispatch } from "shared/hooks/shared";
import { confirmModalActions, IConfirmModalData } from "shared/store";

export const useShowConfirmModal = () => {
  const dispatch = useAppDispatch();

  const open = useCallback(
    (data: IConfirmModalData) => {
      dispatch(confirmModalActions.setData(data));
      dispatch(confirmModalActions.openModal());
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(confirmModalActions.resetData());
    };
  }, [dispatch]);

  return { open };
};
