import { ReactNode } from "react";
import { Convert, SBCoin } from "shared/ui/Icons/Coins";
import styles from "./CurrencyRateCard.module.scss";

interface CurrencyRateProps {
  name: string;
  rate: string;
  icon?: ReactNode;
  disabled?: boolean;
  changeRate?: (value: string) => void;
}

interface CurrencyRateCardProps {
  code: string;
  name: string;
  rate: string;
  disabled?: boolean;
  icon?: string;
  changeRate?: (value: string) => void;
}

const CurrencyRate = ({
  name,
  rate,
  icon,
  disabled,
  changeRate,
}: CurrencyRateProps) => {
  return (
    <div className={styles["currencyRate"]}>
      <input
        type="number"
        disabled={disabled}
        defaultValue={rate}
        className={styles["currencyRate__input"]}
        onChange={(event) => changeRate && changeRate(event.target.value)}
      />
      <div>{icon}</div>
      <span>{name}</span>
    </div>
  );
};

export const CurrencyRateCard = ({
  name,
  rate,
  disabled,
  icon,
  changeRate,
}: CurrencyRateCardProps) => {
  return (
    <div className={styles["currencyRateCard"]}>
      <CurrencyRate name={"SB"} rate="1" icon={<SBCoin />} disabled={true} />
      <div className={styles["currencyRateCard__icon"]}>
        <Convert />
      </div>
      <CurrencyRate
        name={name}
        rate={rate}
        icon={<img src={icon} alt="" width={30} height={30} />}
        disabled={disabled}
        changeRate={changeRate}
      />
    </div>
  );
};
