import {
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "shared/hooks/shared";
import { marketplacesSelector, marketplacesActions } from "shared/store";

export const useMarketplacesPagination = () => {
  const dispatch = useAppDispatch();
  const { info, searchParams } = useAppSelector(marketplacesSelector);

  const setPage = (page: number) => {
    dispatch(marketplacesActions.setCurrentPage(page));
  };

  const setItemsPerPage = (itemsCount: number) => {
    dispatch(marketplacesActions.setMessagesPerPage(itemsCount));
  };

  const pagination = usePagination({
    info,
    setPage,
    currentPage: searchParams.currentPage,
    items: searchParams.itemsPerPage,
    setItemsPerPage,
  });

  return { pagination };
};
