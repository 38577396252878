import { memo, ReactNode } from "react";
import { getRuLocaleDateString } from "shared/lib";
import { TableData, TableRow } from "shared/ui/Table";
import { IAdmin } from "shared/types";
import styles from "./AdminTable.module.scss";

interface AdminsTableRow {
  admin: IAdmin;
  even: boolean;

  selectOption?: ReactNode;
  controls?: ReactNode;
}

export const AdminTableRow = memo(
  ({ admin, even, selectOption, controls }: AdminsTableRow) => {
    return (
      <TableRow even={even}>
        <TableData>{selectOption}</TableData>
        <TableData>
          <div className={styles.adminTableRow__avatar}>
            {admin.avatar && <img src={admin.avatar} alt="admin avatar" />}
          </div>
        </TableData>
        <TableData>{admin.id}</TableData>
        <TableData>{admin.username}</TableData>
        <TableData>{admin.email}</TableData>
        <TableData>{getRuLocaleDateString(admin?.created_at)}</TableData>
        <TableData>{controls}</TableData>
      </TableRow>
    );
  }
);
