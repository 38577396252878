import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { TextareaProps } from "./textarea.interface";
import styles from "./textarea.module.scss";

export const Textarea = ({
  className,
  textareaRef,
  placeholder,
  defaultValue,
  maxRows,
  minRows,
  value,
  onChange,
}: TextareaProps) => {
  return (
    <TextareaAutosize
      className={classNames(styles.textarea, {
        [className as string]: className,
      })}
      ref={textareaRef}
      placeholder={placeholder}
      maxRows={maxRows}
      minRows={minRows}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    />
  );
};
