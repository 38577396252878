import { memo } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import styles from "./NavigationLink.module.scss";

export interface NavigationLinkProps {
  link: string;
  text: string;
}

export const NavigationLink = memo(({ link, text }: NavigationLinkProps) => {
  const activeLinkStyle = ({ isActive }: { isActive: boolean }) =>
    classNames(styles.navlink, {
      [styles.navlinkActive]: isActive,
    });

  return (
    <NavLink className={activeLinkStyle} to={link}>
      {text}
    </NavLink>
  );
});
