import { Modal } from "shared/ui/Modal";
import { useGetCallInfoQuery } from "shared/api/endpoints/admin/calls.api";
import styles from "./CallInfoModal.module.scss";
import { getRuLocaleTimeString } from "shared/lib";
import { ScrollBar } from "shared/ui/ScrollBar";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { usersActions } from "shared/store";
import { useAppDispatch } from "shared/hooks/shared";

interface CallInfoModalProps {
  closeModal?: () => void;
  id: number;
}

interface InfoRowProps {
  label: string;
  value: string;
}

const InfoRow = ({ label, value }: InfoRowProps) => {
  return (
    <div className={styles.callDataModal__infoRow}>
      <span className={styles.callDataModal__infoLabel}>{label}</span>
      <span className={styles.callDataModal__infoValue}>{value}</span>
    </div>
  );
};

export const CallInfoModal = ({ id, closeModal }: CallInfoModalProps) => {
  const callData = useGetCallInfoQuery({ id });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const showMemberInfo = (username: string) => {
    dispatch(usersActions.setSearchTerm(username));
    navigate("/users/");
  };

  return (
    <Modal onClose={closeModal}>
      <div className={styles.callDataModal}>
        <p className={styles.callDataModal__title}>Информация о звонке</p>

        <div className={styles.callDataModal__list}>
          <InfoRow
            label="Встреча прошла:"
            value={getRuLocaleTimeString(callData.data?.start_time || "")}
          />
          <InfoRow
            label="Организатор встречи:"
            value={callData.data?.user || ""}
          />
          <InfoRow
            label="Продолжительность:"
            value={callData.data?.duration.split(".")[0] || ""}
          />
          <div>
            <InfoRow
              label={`Участники (${callData.data?.members_count || 0}):`}
              value={""}
            />

            <ScrollBar height={50}>
              <div className={styles.callDataModal__members}>
                {callData.isSuccess &&
                  callData.data.members.map((member, ind) => (
                    <Fragment key={member.id}>
                      <span onClick={() => showMemberInfo(member.username)}>
                        {member.username}
                      </span>
                      {ind < callData.data.members.length - 1 && ", "}
                    </Fragment>
                  ))}
              </div>
            </ScrollBar>
          </div>
          <InfoRow
            label="Заработано организатором звонка:"
            value={callData.data?.earned || "---"}
          />
        </div>
      </div>
    </Modal>
  );
};
