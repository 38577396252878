import { Api, tags } from "../..";
import { ID, IAdmin, IAdminPermissions } from "shared/types";
import {
  IAdminsRequestDto,
  IAdminsResponseDto,
  ICreateAdminRequestDto,
  IEditAdminProfileRequestDto,
} from "shared/api/dto";

const basePath = "api/admin-administrators";

export const adminsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getAdmins: builder.query<IAdminsResponseDto, IAdminsRequestDto>({
      query: (data) => ({
        url: `${basePath}/`,
        params: data,
      }),
      providesTags: [tags.admins],
    }),
    createAdmin: builder.mutation<void, ICreateAdminRequestDto>({
      query: (data) => ({
        url: `${basePath}/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [tags.admins],
    }),
    getAdminData: builder.query<IAdmin, ID>({
      query: (data) => ({ url: `${basePath}/${data.id}/` }),
      providesTags: [tags.admins],
    }),
    editAdminData: builder.mutation<
      void,
      { id: string; data: IEditAdminProfileRequestDto }
    >({
      query: (data) => ({
        url: `${basePath}/${data.id}/`,
        method: "PATCH",
        body: data.data,
      }),
      invalidatesTags: [tags.adminInfo, tags.admins],
    }),
    deleteAdmin: builder.mutation<void, ID>({
      query: (data) => ({
        url: `${basePath}/${data.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [tags.admins],
    }),
    getPermissions: builder.query<IAdminPermissions, void>({
      query: () => ({
        url: "api/admin-permissions/",
      }),
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useCreateAdminMutation,
  useGetAdminDataQuery,
  useEditAdminDataMutation,
  useDeleteAdminMutation,
  useGetPermissionsQuery,
} = adminsApi;
