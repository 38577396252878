import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { getRuLocaleDateString } from "shared/lib";
import { Button } from "shared/ui/Buttons";
import { Input, InputPassword, InputWithOptions } from "shared/ui/Inputs";
import { IEditAdmin, InfoInputProps } from "../EditProfile.interface";
import { AvatarLoadForm } from "widgets/AvatarLoadForm";
import { useEditAdmin } from "features/admins/edit-admin-profile";
import { Select } from "shared/ui/Select";
import { IAdminPermissions } from "shared/types";
import { ScrollBar } from "shared/ui/ScrollBar";
import styles from "../EditProfile.module.scss";
import { CloseIcon } from "shared/ui/Icons";

const AdminInfoInput = memo(
  ({ title, value, field, onChange, onDelete }: InfoInputProps<IEditAdmin>) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value, field);
    };

    const handleDelete = () => {
      onDelete(field);
    };

    return (
      <div className={styles.editProfile__row}>
        <span className={styles.editProfile__title}>{title}</span>
        <div className={styles.editProfile__inputWrapper}>
          <InputWithOptions
            value={value || ""}
            onChange={handleChange}
            deleteHandler={handleDelete}
            className={styles.editProfile__input}
          />
        </div>
      </div>
    );
  }
);

const AdminPasswordInput = memo(
  ({
    title,
    value,
    field,
    onChange,
  }: Omit<InfoInputProps<IEditAdmin>, "type" | "onDelete">) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value, field);
    };

    return (
      <div className={styles.editProfile__row}>
        <span className={styles.editProfile__title}>{title}</span>
        <div className={styles.editProfile__inputWrapper}>
          <InputPassword
            value={value || ""}
            onChange={handleChange}
            className={styles.editProfile__inputPassword}
          />
        </div>
      </div>
    );
  }
);

type PermissionsTranslate = { [key in keyof IAdminPermissions]: string };

const Permissions: PermissionsTranslate = {
  dashboards: "Главная",
  app_content: "Контент приложения",
  users: "Управление аккаунтами",
  wallet: "Управление заявками",
  feedback: "Обратная связь",
  complaints: "Жалобы",
  calls: "Звонки",
  publications: "Публикации",
  marketplaces: "Маркетплейс",
  advertisement: "Продвижение",
};

interface Permission {
  title: string;
  onClick: () => void;
}

const PermissionItem = ({ title, onClick }: Permission) => {
  return (
    <div className={classNames(styles.permission, styles.permissionItem)}>
      <span className={styles.permission__title}>{title}</span>
      <CloseIcon size={12} onClick={onClick} />
    </div>
  );
};

export const EditAdmin = () => {
  const { id } = useParams();
  const { userData, addAvatar, deleteAvatar, saveAdminInfo } = useEditAdmin({
    id,
  });

  const [data, setData] = useState<IEditAdmin>({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    avatars: [{ id: 0, image: "" }],
    permissions: {},
  });

  const [avatarFile, setAvateFile] = useState<File | FormData | null>(null);

  const activePermissions = data.permissions
    ? Object.keys(data.permissions).filter(
        // @ts-ignore
        (key) => data.permissions[key as keyof IAdminPermissions]
      )
    : [];

  const regDate = useMemo(
    () =>
      userData?.created_at ? getRuLocaleDateString(userData?.created_at) : "",
    [userData?.created_at]
  );

  const submit = () => {
    saveAdminInfo(data);
    if (id && avatarFile) {
      addAvatar(avatarFile as FormData);
    }
  };

  const selectPermission = (
    permission: keyof IAdminPermissions,
    value: boolean
  ) => {
    setData((data) => ({
      ...data,
      permissions: {
        ...data.permissions,
        [permission]: value,
      },
    }));
  };

  const changeDataField = useCallback(
    (value: string, field: keyof IEditAdmin) => {
      setData((data) => ({ ...data, [field]: value }));
    },
    []
  );

  const deleteDataField = useCallback((field: keyof IEditAdmin) => {
    setData((data) => ({ ...data, [field]: "" }));
  }, []);

  useEffect(() => {
    if (userData) {
      setData((data) => ({ ...data, ...userData }));
    }
  }, [userData]);

  return (
    <div className={styles.editProfile}>
      <div>
        <AvatarLoadForm
          image={data.avatars[0]?.image}
          setImageFile={setAvateFile}
          deleteImageFile={deleteAvatar}
        />
        <div className={styles.editProfile__controls}>
          <Button
            type="solid"
            className={styles.editProfile__button}
            onClick={submit}
          >
            Сохранить
          </Button>
        </div>
      </div>
      <div className={styles.editProfile__info}>
        <AdminInfoInput
          title="Имя пользователя"
          value={data.username}
          field="username"
          onChange={changeDataField}
          onDelete={deleteDataField}
        />
        <AdminInfoInput
          title="Имя"
          value={data.first_name}
          field="first_name"
          onChange={changeDataField}
          onDelete={deleteDataField}
        />
        <AdminInfoInput
          title="Фамилия"
          value={data.last_name}
          field="last_name"
          onChange={changeDataField}
          onDelete={deleteDataField}
        />
        <AdminInfoInput
          title="Электронная почта"
          value={data.email}
          field="email"
          onChange={changeDataField}
          onDelete={deleteDataField}
        />

        <div className={styles.editProfile__row}>
          <span>Дата регистрации</span>
          <div className={styles.editProfile__inputWrapper}>
            <Input
              value={regDate}
              className={styles.editProfile__input}
              disabled
            />
          </div>
        </div>
        <AdminPasswordInput
          title="Пароль"
          value={data.password}
          field="password"
          onChange={changeDataField}
        />
        <AdminPasswordInput
          title="Повторите пароль"
          value={data.confirm_password}
          field="confirm_password"
          onChange={changeDataField}
        />
        <div className={styles.editProfile__row}>
          <span
            className={classNames(
              styles.editProfile__title,
              styles.editProfile__titleOnTop
            )}
          >
            Доступные разделы
          </span>
          <div className={styles.editProfile__inputWrapper}>
            <Select
              title="Выбрать"
              className={styles.editProfile__select}
              iconSize={22}
              overflow
            >
              <ScrollBar height={200}>
                {Object.keys(Permissions).map((key) =>
                  activePermissions.includes(key) ? null : (
                    <p
                      className={styles.editProfile__option}
                      key={key}
                      onClick={() => {
                        //@ts-ignore
                        selectPermission(key, true);
                      }}
                    >
                      {Permissions[key as keyof IAdminPermissions]}
                    </p>
                  )
                )}
                {activePermissions.length === Object.keys(Permissions).length
                  ? "Доступны все категории"
                  : null}
              </ScrollBar>
            </Select>
            {activePermissions.map((permission) => (
              <PermissionItem
                title={Permissions[permission as keyof IAdminPermissions]}
                onClick={() => {
                  //@ts-ignore
                  selectPermission(permission, false);
                }}
                key={permission}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
