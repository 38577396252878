import { Calendar } from "shared/ui/Calendar";
import { useSearchComplaintsByDate } from "../model";
import styles from "./SearchComplaintsByDate.module.scss";

export const SearchComplaintsByDate = () => {
  const { complaintDate, setComplaintDate } = useSearchComplaintsByDate();
  return (
    <Calendar
      value={complaintDate}
      setDate={setComplaintDate}
      placeholderText="дата подачи"
      withClearIcon={!!complaintDate}
      className={styles.calendar}
    />
  );
};
