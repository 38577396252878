import { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { postsActions, postsSelector } from "shared/store";

export const useSearchPostByAuthor = () => {
  const dispatch = useAppDispatch();
  const { searchTerm } = useAppSelector(postsSelector);

  const setSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(postsActions.setSearchTerm(event.target.value));
    dispatch(postsActions.setCurrentPage(1));
  };

  return {
    setSearchText,
    searchTerm,
  };
};
