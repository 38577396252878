import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ComplaintType,
  ComplaintReasonType,
  IComplaint,
  QueryData,
} from "shared/types";
import { IComplaintsState } from "shared/store/reducers/types";

const initialState: IComplaintsState = {
  info: {
    total: 0,
    pages: 0,
    is_last: true,
  },
  searchParams: {
    currentPage: 1,
    itemsPerPage: 50,
    complaintType: ComplaintType.PROFILE,
  },
};

export const complaintsSlice = createSlice({
  name: "complaints",
  initialState,
  reducers: {
    setQueryData: (state, action: PayloadAction<QueryData<IComplaint[]>>) => {
      state.info.is_last = action.payload.is_last;
      state.info.pages = action.payload.pages;
      state.info.total = action.payload.total;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.searchParams.currentPage = action.payload;
    },
    setComplaintsPerPage: (state, action: PayloadAction<number>) => {
      state.searchParams.itemsPerPage = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchParams.searchText = action.payload;
    },
    setComplaintDate: (state, action: PayloadAction<Date | undefined>) => {
      state.searchParams.complaintDate = action.payload;
    },
    setPublicationDate: (state, action: PayloadAction<Date | undefined>) => {
      state.searchParams.publicationDate = action.payload;
    },
    setComplaintType: (state, action: PayloadAction<ComplaintType>) => {
      state.searchParams.complaintType = action.payload;
    },
    setReasonType: (
      state,
      action: PayloadAction<ComplaintReasonType | undefined>
    ) => {
      state.searchParams.reasonType = action.payload;
    },

    resetSearchParams: (state) => {
      state.searchParams = {
        currentPage: 1,
        itemsPerPage: state.searchParams.itemsPerPage,
        complaintType: state.searchParams.complaintType,
      };
    },
  },
});

export const complaintsActions = complaintsSlice.actions;

export default complaintsSlice.reducer;
