import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { walletSelector, walletActions } from "shared/store";

export const useSearchApplicationByDate = () => {
  const dispatch = useAppDispatch();
  const { applicationDate } = useAppSelector(walletSelector);

  const setDate = (date: Date) => {
    dispatch(walletActions.setApplicationDate(date));
  };

  return {
    setDate,
    date: applicationDate,
  };
};
