import { FinancesPeriod } from "pages/private/dashboards/finances/model";
import { Selector } from "shared/ui/Selector";
import { Calendar } from "shared/ui/Calendar";
import styles from "./PeriodSelector.module.scss";
import { useState } from "react";

const mockSelectorOptions: {
  title: string;
  value: FinancesPeriod;
  isDefaultOption?: boolean;
}[] = [
  {
    title: "неделя",
    value: "week",
  },
  {
    title: "месяц",
    value: "month",
  },
  {
    title: "год",
    value: "year",
    isDefaultOption: true,
  },
  {
    title: "другой",
    value: "custom",
  },
];

interface PeriodSelectorProps {
  defaultPeriod?: FinancesPeriod;
  selectPeriod: (period: FinancesPeriod, data?: any) => void;
}

export const PeriodSelector = ({ selectPeriod }: PeriodSelectorProps) => {
  const [period, setPeriod] = useState("year");
  const [customPeriod, setCustomPeriod] = useState<Record<string, Date | null>>(
    {
      start: null,
      end: null,
    }
  );

  const handleSelectPeriod = (period: FinancesPeriod) => {
    if (period !== "custom") selectPeriod(period);
    setPeriod(period);
  };

  const setStartDate = (date: Date) => {
    setCustomPeriod((period) => ({ ...period, start: date }));

    if (customPeriod.end && date) {
      selectPeriod("custom", { ...customPeriod, start: date });
    }
  };

  const setEndDate = (date: Date) => {
    setCustomPeriod((period) => ({ ...period, end: date }));

    if (customPeriod.start && date) {
      selectPeriod("custom", { ...customPeriod, end: date });
    }
  };

  return (
    <div className={styles.periodSelector}>
      <Selector<FinancesPeriod>
        options={mockSelectorOptions}
        setFunc={handleSelectPeriod}
        bodyClassname={styles.periodSelector__body}
        buttonClassname={styles.periodSelector__button}
      />
      {period === "custom" && (
        <div className={styles.periodSelector__selectors}>
          <div>
            <span>Начало периода</span>
            <Calendar
              setDate={setStartDate}
              value={customPeriod.start || null}
              withClearIcon={!!customPeriod.start}
              className={styles.periodSelector__calendar}
            />
          </div>
          <div>
            <span>Конец периода</span>
            <Calendar
              setDate={setEndDate}
              value={customPeriod.end || null}
              withClearIcon={!!customPeriod.end}
              className={styles.periodSelector__calendar}
            />
          </div>
        </div>
      )}
    </div>
  );
};
