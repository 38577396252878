import { Calendar } from "shared/ui/Calendar";
import { useSearchMessageByDate } from "../model";

export const SearchMessageByDate = () => {
  const { setDate, created_at } = useSearchMessageByDate();
  return (
    <Calendar
      value={created_at}
      setDate={setDate}
      withClearIcon={!!created_at}
      placeholderText={"дата обращения"}
    />
  );
};
