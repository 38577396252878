import {
  useChangeWalletHelpTextMutation,
  useGetWalletHelpTextQuery,
} from "shared/api";
import { WalletHelpTextType } from "shared/types";

export const useWalletHelpText = (type: WalletHelpTextType) => {
  const walletHelpTextQuery = useGetWalletHelpTextQuery(type);
  const [changeWalletHelpText] = useChangeWalletHelpTextMutation();

  const data = walletHelpTextQuery.isSuccess ? walletHelpTextQuery.data : null;

  const changeText = (text: string) => {
    if (data) {
      changeWalletHelpText({ description: text, id: data?.id });
    }
  };

  return { data, changeText };
};
