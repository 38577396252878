import { Api, tags } from "../..";
import { ID, IComment } from "shared/types";

const basePath = "api/admin-comments";

const pathWithId = (id: number | string) => `${basePath}/${id}/`;

export const commentsApi = Api.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getCommentData: builder.query<IComment, ID>({
      query: (data) => pathWithId(data.id),
    }),
    deleteComment: builder.mutation<void, ID>({
      query: (data) => ({ url: pathWithId(data.id), method: "DELETE" }),
      invalidatesTags: [tags.postComments, tags.posts],
    }),
  }),
});

export const { useGetCommentDataQuery, useDeleteCommentMutation } = commentsApi;
