import { EditUser } from "widgets/EditProfile";
import * as Layout from "shared/ui/layout";

export const UserProfile = () => {
  return (
    <Layout.Content title={"Редактирование пользователя"}>
      <EditUser />
    </Layout.Content>
  );
};
