import { useCallback, useEffect, useState } from "react";
import { getFullDateFormat } from "shared/lib";
import {
  useGetFeedbackMessagesMutation,
  useReplyFeedbackMutation,
} from "shared/api";
import { feedbackActions, feedbackSelector } from "shared/store";
import {
  useAppDispatch,
  useAppSelector,
  useNotification,
} from "shared/hooks/shared";
import { IFeedbackMessage } from "shared/types";

export const useFeedbackMessages = (replied = false) => {
  const dispatch = useAppDispatch();
  const { searchParams } = useAppSelector(feedbackSelector);
  const [getMessages] = useGetFeedbackMessagesMutation();
  const [replyFeedback] = useReplyFeedbackMutation();
  const { show } = useNotification();

  const [list, setList] = useState([] as IFeedbackMessage[]);
  const [page, setPage] = useState(1);

  const loadMoreMessages = useCallback(() => {
    let search = searchParams.searchText || undefined;
    let created_at = searchParams.createdAt
      ? getFullDateFormat(searchParams.createdAt)
      : undefined;

    getMessages({
      page: page + 1,
      page_size: searchParams.itemsPerPage,
      search,
      created_at,
      is_replied: replied,
    })
      .unwrap()
      .then((res) => {
        setPage((page) => page + 1);
        setList((list) => [...list, ...res.results]);

        dispatch(feedbackActions.setQueryData(res));
      });
  }, [
    searchParams.searchText,
    searchParams.createdAt,
    searchParams.itemsPerPage,
    getMessages,
    page,
    replied,
    dispatch,
  ]);

  useEffect(() => {
    let search = searchParams.searchText || undefined;
    let created_at = searchParams.createdAt
      ? getFullDateFormat(searchParams.createdAt)
      : undefined;

    getMessages({
      page: 1,
      page_size: searchParams.itemsPerPage,
      search,
      created_at,
      is_replied: replied,
    })
      .unwrap()
      .then((res) => {
        setPage(1);
        setList([...res.results]);
        dispatch(feedbackActions.setQueryData(res));
      });
  }, [
    dispatch,
    getMessages,
    replied,
    searchParams.createdAt,
    searchParams.itemsPerPage,
    searchParams.searchText,
  ]);

  const reply = (replyText: string, id: number) => {
    if (replyText) {
      replyFeedback({ id, description: replyText })
        .unwrap()
        .then(() => {
          show({ text: "Ответ отправлен!" });
          setList((list) => [...list.filter((item) => item.id !== id)]);
        });
    }
  };

  return { messages: list, loadMoreMessages, reply };
};
