import { useState } from "react";
import { useChangeCurrencyRateMutation } from "shared/api/endpoints/admin/currency.api";
import { useNotification } from "shared/hooks/shared";
import { ICurrencyRate } from "shared/api/dto";
import { Button } from "shared/ui/Buttons";
import { Modal } from "shared/ui/Modal";

import { CurrencyRateCard } from "../CurrencyRateCard";
import styles from "./ChangeCurrency.module.scss";

interface ChangeCurrencyRateModalProps {
  onClose: () => void;
  rate: ICurrencyRate;
}

export const ChangeCurrencyRateModal = ({
  onClose,
  rate,
}: ChangeCurrencyRateModalProps) => {
  const [changeRate] = useChangeCurrencyRateMutation();
  const { show } = useNotification();

  const [newRate, setNewRate] = useState("");

  const onSaveClick = () => {
    if (newRate) {
      changeRate({ ...rate, exchange_rate: newRate })
        .unwrap()
        .then(() => {
          show({ text: "Курс изменен!" });
          onClose();
        });
    }
  };

  return (
    <Modal onClose={onClose}>
      <div className={styles["changeCurrency"]}>
        <p className={styles["changeCurrency__title"]}>
          Изменить курс конвертации
        </p>
        <CurrencyRateCard
          changeRate={setNewRate}
          name={rate.name}
          code={rate.code}
          icon={rate.icon.image}
          rate={rate.exchange_rate}
        />
        <Button
          type="solid"
          onClick={onSaveClick}
          className={styles["changeCurrency__button"]}
        >
          сохранить
        </Button>
      </div>
    </Modal>
  );
};
