import { usersActions } from "shared/store";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";

export const useFilterVerifiedUsers = () => {
  const dispatch = useAppDispatch();
  const { isVerifiedUsers } = useAppSelector((state) => state.users);

  const showVerifiedUsers = (value: boolean | undefined) => {
    dispatch(usersActions.setCurrentPage(1));
    dispatch(usersActions.setIsVerifiedUsers(value));
  };

  return { isVerifiedUsers, showVerifiedUsers };
};
