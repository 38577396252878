import { Calendar } from "shared/ui/Calendar";
import { useSearchApplicationByDate } from "../model";

export const SearchApplicationByDate = () => {
  const { setDate, date } = useSearchApplicationByDate();
  return (
    <Calendar
      value={date || null}
      setDate={setDate}
      withClearIcon={!!date}
      placeholderText={"дата заявки"}
    />
  );
};
