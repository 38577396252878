import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { callsActions } from "shared/store/reducers/callsReducer";

export const useSelectCall = (callId: number) => {
  const dispatch = useAppDispatch();
  const { selectedItems } = useAppSelector((state) => state.calls);

  const isSelected = useMemo(() => {
    return selectedItems.some((id) => callId === id);
  }, [callId, selectedItems]);

  const selectAdmin = (isSelect: boolean) => {
    if (isSelect) {
      dispatch(callsActions.addCallInSelected({ id: callId }));
    } else if (!isSelect) {
      dispatch(callsActions.removeCallFromSelected({ id: callId }));
    }
  };
  return { isSelected, selectAdmin };
};
