import { useAppSelector } from "shared/hooks/shared";
import { Modal } from "shared/ui/Modal";
import { ScrollBar } from "shared/ui/ScrollBar";
import styles from "./TextModal.module.scss";

export const TextModal = () => {
  const { feedbackModalData } = useAppSelector((state) => state.modal);

  const blockHeight = feedbackModalData.reply ? "28vh" : "60vh";

  return (
    <Modal>
      <div className={styles.textModal}>
        <div>
          <h3 className={styles.textModal__title}>Сообщение от пользователя</h3>
          <ScrollBar height={blockHeight}>
            <div className={styles.textModal__text}>
              {feedbackModalData.message}
            </div>
          </ScrollBar>
        </div>

        {feedbackModalData.reply && (
          <div>
            <h3 className={styles.textModal__title}>Ответ администратора:</h3>
            <ScrollBar height={blockHeight}>
              <div className={styles.textModal__text}>
                {feedbackModalData.reply}
              </div>
            </ScrollBar>
          </div>
        )}
      </div>
    </Modal>
  );
};
