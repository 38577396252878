import classNames from "classnames";
import { useRef, useState, ChangeEvent } from "react";
import { Button } from "shared/ui/Buttons";
import { EditIcon } from "shared/ui/Icons";
import { TextEditorProps } from "./TextEditor.interface";
import styles from "./TextEditor.module.scss";

export const TextEditor = ({
  description,
  saveText,
  onChange,
  withControls = true,
  title,
  height,
}: TextEditorProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null!);
  const [text, setText] = useState(description);

  const changeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    setText(text);
    onChange && onChange(text);
  };

  const handleEditClick = () => {
    textareaRef.current.disabled = !textareaRef.current.disabled;
    textareaRef.current.focus();
  };

  const handleSaveClick = () => {
    if (text !== description) {
      saveText(text);
    }
  };

  return (
    <div className={styles.textEditor}>
      {title && <div className={styles.textEditor__title}>{title}</div>}
      <div className={styles.textEditor__content}>
        <div
          className={classNames(styles.textEditor__text, {
            [styles.textEditor__textWithControls]: withControls,
          })}
        >
          <textarea
            className={styles.textEditor__textContent}
            ref={textareaRef}
            disabled={withControls}
            defaultValue={description}
            onChange={changeText}
            style={{ height }}
          />
        </div>
        {withControls && (
          <div className={styles.textEditor__controls}>
            <Button
              type="gray"
              icon={<EditIcon />}
              onClick={handleEditClick}
              className={styles.textEditor__controlsEditButton}
            >
              Редактировать
            </Button>
            <Button
              type="solid"
              onClick={handleSaveClick}
              disabled={text === description || !text}
            >
              Сохранить
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
