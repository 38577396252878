import { useCallback } from "react";
import { IUser } from "shared/types";
import { usersActions } from "shared/store";
import { useBanUsersMutation } from "shared/api";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useAppDispatch } from "shared/hooks/shared";
import { useNotification } from "shared/hooks/shared";

export const useBanUser = (user: IUser) => {
  const dispatch = useAppDispatch();

  const { open } = useShowConfirmModal();
  const { show } = useNotification();

  const [banUsers] = useBanUsersMutation();

  const banUser = useCallback(() => {
    const action = user.is_active ? "Забанить" : "Разбанить";

    open({
      title: `${action} пользователя?`,
      buttonText: action,
      onConfirmCallback: () => {
        banUsers({ users: [user.id] }).then(() => {
          const action = user.is_active ? "забанен" : "разбанен";

          show({ text: `Пользователь ${action}!` });
        });
        dispatch(usersActions.resetSelectedUsers());
      },
    });
  }, [banUsers, dispatch, open, show, user.id, user.is_active]);

  return { banUser };
};
