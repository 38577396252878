import {
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "shared/hooks/shared";
import { callsActions } from "shared/store/reducers/callsReducer";

export const useCallsPagination = () => {
  const dispatch = useAppDispatch();
  const { info, searchParams } = useAppSelector((state) => state.calls);

  const setPage = (page: number) => {
    dispatch(callsActions.setCurrentPage(page));
  };

  const setItemsPerPage = (itemsCount: number) => {
    dispatch(callsActions.setCallsPerPage(itemsCount));
  };

  const pagination = usePagination({
    info,
    setPage,
    currentPage: searchParams.currentPage,
    items: searchParams.itemsPerPage,
    setItemsPerPage,
  });

  return { pagination };
};
