import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { useDeleteCallMutation } from "shared/api/endpoints/admin/calls.api";
import { callsActions } from "shared/store/reducers/callsReducer";

export const useDeleteCall = () => {
  const dispatch = useAppDispatch();
  const [deleteCall] = useDeleteCallMutation();
  const { selectedItems } = useAppSelector((state) => state.calls);
  const { open } = useShowConfirmModal();
  const { show, hide } = useNotification();

  const deleteSelectedCalls = async () => {
    show({ text: "Звонки удаляются", withLoader: true, autoClose: false });

    const promises = selectedItems.map((id) => deleteCall({ id }).unwrap());
    const results = await Promise.allSettled(promises);

    if (results.every((promise) => promise.status === "fulfilled")) {
      hide();
      show({ text: "Звонки удалены!" });
    }

    dispatch(callsActions.resetSelectedCalls());
  };

  const deleteSelectedAdminsWithConfirm = () => {
    open({
      title: `Удалить ${selectedItems.length} записей?`,
      buttonText: "Удалить",
      onConfirmCallback: deleteSelectedCalls,
    });
  };

  return {
    deleteCalls: deleteSelectedAdminsWithConfirm,
    selectedCallsCount: selectedItems.length,
  };
};
