import { DeleteButton } from "shared/ui/Buttons";
import { useDeleteAdmins } from "../model";

export const DeleteSelectedAdminsButton = () => {
  const { deleteAdmins, selectedAdminsCount } = useDeleteAdmins();

  return (
    <DeleteButton disabled={!selectedAdminsCount} onClick={deleteAdmins}>
      Удалить
    </DeleteButton>
  );
};
