import {
  ChangeEvent,
  InputHTMLAttributes,
  memo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import styles from "./input.module.scss";
import { EyeIcon } from "shared/ui/Icons";

type NativeInputProps = Partial<
  Omit<InputHTMLAttributes<HTMLInputElement>, "prefix">
>;

export interface InputProps extends NativeInputProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  icon?: React.ReactNode;
  prefix?: React.ReactNode;
}

export const Input = memo((props: InputProps) => {
  const {
    onChange,
    placeholder,
    icon,
    prefix,
    value,
    type,
    className,
    disabled = false,
    ...inputProps
  } = props;

  const changeTextHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  const inputRef = useRef<HTMLInputElement>(null!);

  const onWrapperClick = () => {
    inputRef?.current?.focus();
  };

  return (
    <div
      className={classNames(styles.input, { [className as string]: className })}
      onClick={onWrapperClick}
    >
      {prefix && <div className={styles.input__prefix}>{prefix}</div>}
      <input
        type={type}
        value={value}
        ref={inputRef}
        disabled={disabled}
        placeholder={placeholder}
        onChange={changeTextHandler}
        className={classNames(styles.input__input, {
          [styles.inputDisabled]: disabled,
        })}
        {...inputProps}
      />
      {icon && <div className={styles.input__icon}>{icon}</div>}
    </div>
  );
});

export const InputPassword = memo(
  ({
    onChange,
    placeholder,
    value,
    className,
    disabled = false,
  }: Omit<InputProps, "icon" | "type">) => {
    const [hide, setHide] = useState(true);

    const changeTextHandler = (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
    };

    const inputRef = useRef<HTMLInputElement>(null!);

    const onWrapperClick = () => {
      inputRef?.current?.focus();
    };

    return (
      <div
        className={classNames(styles.input, {
          [className as string]: className,
        })}
        onClick={onWrapperClick}
      >
        <input
          type={hide ? "password" : "text"}
          value={value}
          ref={inputRef}
          disabled={disabled}
          placeholder={placeholder}
          onChange={changeTextHandler}
          className={classNames(styles.input__input, {
            [styles.inputDisabled]: disabled,
          })}
        />
        {
          <div className={styles.input__icon}>
            {
              <EyeIcon
                isClose={!hide}
                size={32}
                color={"#aaaaaa"}
                onClick={() => setHide((hide) => !hide)}
              />
            }
          </div>
        }
      </div>
    );
  }
);
