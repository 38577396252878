import { useChangeCommissionMutation, useGetCommissionQuery } from "shared/api";

export const useCommission = () => {
  const commissionQuery = useGetCommissionQuery();
  const [changeCommission] = useChangeCommissionMutation();

  const commission = commissionQuery.isSuccess ? commissionQuery.data : null;

  const changeCommissionDescription = (text: string) => {
    if (commission && text !== commission.description) {
      changeCommission({ id: commission.id, description: text });
    }
  };

  return { commission, changeCommissionDescription };
};
