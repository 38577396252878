import SimpleBar from "simplebar-react";
import { ScrollbarProps } from "./ScrollBar.interface";
import "simplebar/dist/simplebar.min.css";
import "./ScrollBar.scss";

export const ScrollBar = ({
  children,
  height = 500,
  ...props
}: ScrollbarProps) => {
  return (
    <SimpleBar {...props} autoHide={false} style={{ maxHeight: height }}>
      {children}
    </SimpleBar>
  );
};
