import { ChangeEvent, useCallback } from "react";
import { usersSelector, usersActions } from "shared/store";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";

export const useSearchUser = () => {
  const dispatch = useAppDispatch();

  const { searchTerm } = useAppSelector(usersSelector);

  const setSearchText = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(usersActions.setCurrentPage(1));
      dispatch(usersActions.setSearchTerm(event.target.value));
    },
    [dispatch]
  );

  return {
    searchTerm,
    setSearchText,
  };
};
