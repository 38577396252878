import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { callsActions } from "shared/store/reducers/callsReducer";

export const useSearchCall = () => {
  const dispatch = useAppDispatch();

  const { searchParams } = useAppSelector((state) => state.calls);

  const search = (term: string) => {
    dispatch(callsActions.setSearchTerm(term));
  };

  return {
    term: searchParams.searchText,
    search,
  };
};
