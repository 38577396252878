import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "shared/ui/Buttons";
import { DownloadIconPng } from "shared/ui/Icons";
import { Textarea } from "shared/ui/Textarea";
import * as Layout from "shared/ui/layout";
import { useLoadPhotoModal } from "entities/Modals/LoadPhotoModal";
import { useAddCardBindingBenefit } from "features/card-binding/add-benefit";
import { useChangeCardBindingBenefit } from "features/card-binding/change-benefit";
import { useCardBindingBenefits } from "./model";
import styles from "./CardBindingBenefit.module.scss";

export const CardBindingBenefit = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { openPhotoModal, imageUrl, imageFile } = useLoadPhotoModal();
  const { connect_card_benefits } = useCardBindingBenefits();
  const {
    addNewBenefit,
    resultId,
    isLoading: isAddBenefitLoading,
  } = useAddCardBindingBenefit();
  const { changeBenefit, isLoading: isChangeBenefitLoading } =
    useChangeCardBindingBenefit();

  const [data] = connect_card_benefits.filter(
    (benefit) => id && benefit.id === Number(id)
  );
  const benefitIndex = data
    ? connect_card_benefits.findIndex((item) => item.id === Number(id)) + 1
    : null;

  const [bodyText, setBodyText] = useState("");
  const [headerText, setHeaderText] = useState("");

  const changeBodyText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setBodyText(event.target.value);
  };

  const changeHeaderText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setHeaderText(event.target.value);
  };

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const pageTitle =
    id && data?.id && benefitIndex !== null
      ? `Редактирование преимущества - ${benefitIndex}`
      : "Добавление преимуществa привязки карты";

  const handleSaveBenefit = () => {
    if (!id) {
      addNewBenefit({ imageFile, bodyText, headerText });
    } else {
      changeBenefit({ imageFile, bodyText, headerText, id: Number(id) });
    }
  };

  useEffect(() => {
    const buttonEnabled = () => {
      if (
        (imageFile && imageUrl) ||
        (data?.image &&
          bodyText &&
          headerText &&
          (bodyText !== data?.body || headerText !== data?.header))
      ) {
        return true;
      }
      return false;
    };

    if (buttonEnabled()) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [imageFile, imageUrl, bodyText, headerText, data]);

  useEffect(() => {
    if (data?.body && data?.header) {
      setBodyText(data.body);
      setHeaderText(data.header);
    }
  }, [data]);

  useEffect(() => {
    if (resultId) {
      navigate(`/app-content/`);
      navigate(`/app-content/card-binding/benefit/${resultId}`);
    }
  }, [navigate, resultId]);

  return (
    <Layout.Content>
      <div className={styles.connectCard}>
        <h1 className={styles.connectCard__title}>{pageTitle}</h1>
        <div className={styles.connectCard__body}>
          <div className={styles.connectCard__block}>
            <div className={styles.connectCard__label} onClick={openPhotoModal}>
              Загрузить изображение <DownloadIconPng />
            </div>
            {(data?.image || imageUrl) && (
              <img
                alt=""
                aria-label="benefit"
                src={imageUrl || data?.image}
                className={styles.connectCard__image}
              />
            )}
          </div>
          <div className={styles.connectCard__block}>
            <div className={styles.connectCard__textarea}>
              <p className={styles.connectCard__label}>Заголовок:</p>
              <Textarea
                defaultValue={data?.header}
                onChange={changeHeaderText}
              />
            </div>
            <div className={styles.connectCard__textarea}>
              <p className={styles.connectCard__label}>Содержание:</p>
              <Textarea defaultValue={data?.body} onChange={changeBodyText} />
            </div>
            <Button
              type="solid"
              className={styles.connectCard__button}
              onClick={handleSaveBenefit}
              disabled={
                !isButtonEnabled ||
                isAddBenefitLoading ||
                isChangeBenefitLoading
              }
            >
              Сохранить
            </Button>
          </div>
        </div>
      </div>
    </Layout.Content>
  );
};
