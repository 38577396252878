import { ReactNode } from "react";
import { getRuLocaleDateString } from "shared/lib";
import { IComplaint, ComplaintType, ComplaintReasons } from "shared/types";
import { TableRowDataProps, TableRow, TableData } from "shared/ui/Table";

interface ComplaintTableRowProps extends TableRowDataProps<IComplaint> {
  controls: ReactNode;
}

export const ComplaintsTableRow = ({
  even,
  data,
  controls,
}: ComplaintTableRowProps) => {
  const complaintDate = getRuLocaleDateString(data.created_at);
  const publicationDate =
    data.object_type !== ComplaintType.PROFILE
      ? getRuLocaleDateString(data.object_created_at)
      : null;

  return (
    <TableRow even={even} animationKey={data.id}>
      <TableData>{ComplaintReasons[data.reason] || "---"}</TableData>
      <TableData width={240}>{data.author}</TableData>
      <TableData width={240}>
        {data.accused.username} / {data.accused.is_verified ? "да" : <b>нет</b>}
      </TableData>
      <TableData>{complaintDate}</TableData>
      {publicationDate && <TableData>{publicationDate}</TableData>}
      <TableData width={380} withoutPadding>
        {controls}
      </TableData>
    </TableRow>
  );
};
