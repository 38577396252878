import { Navigate, Route, Routes } from "react-router-dom";
import { FinanceStatistics } from "./finances";
import { OverallStatistics } from "./overall";
import * as Layout from "shared/ui/layout";

const basePath = "/dashboards";

export const MainLinks = [
  { title: "Статистика", path: `${basePath}/` },
  { title: "Финансовые показатели", path: `${basePath}/finances` },
];

export const Router = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <Layout.Content
            title="Главная"
            navigation={<Layout.Navigation links={MainLinks} />}
          />
        }
      >
        <Route path="" element={<OverallStatistics />} />
        <Route path="finances" element={<FinanceStatistics />} />
        <Route path="*" element={<Navigate to="" />} />
      </Route>
    </Routes>
  );
};
