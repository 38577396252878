import classNames from "classnames";
import { IPost } from "shared/types";
import { useOpenPostComments } from "../../model";
import styles from "./PostComments.module.scss";

interface PostCommentsProps {
  post: IPost;
}

export const PostComments = ({ post }: PostCommentsProps) => {
  const { showCommentsModal } = useOpenPostComments({ post });
  return (
    <span
      onClick={showCommentsModal}
      className={classNames(styles.postComments__link, {
        [styles.postComments__linkInactive]: !post.comments_count,
      })}
    >
      {post.comments_count}
    </span>
  );
};
