import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryData, IAdmin } from "shared/types";
import { IAdminsState } from "shared/store/reducers/types";

const initialState: IAdminsState = {
  selectedItems: [],
  info: {
    total: 0,
    pages: 0,
    is_last: true,
  },
  searchParams: {
    currentPage: 1,
    itemsPerPage: 50,
  },
};

export const adminsSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    setQueryData: (state, action: PayloadAction<QueryData<IAdmin[]>>) => {
      state.info.is_last = action.payload.is_last;
      state.info.pages = action.payload.pages;
      state.info.total = action.payload.total;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.searchParams.currentPage = action.payload;
    },
    setItemsPerPage: (state, action: PayloadAction<number>) => {
      state.searchParams.itemsPerPage = action.payload;
    },
    addAdminInSelected: (state, action: PayloadAction<IAdmin>) => {
      state.selectedItems.push(action.payload);
    },
    removeAdminFromSelected: (state, action: PayloadAction<IAdmin>) => {
      state.selectedItems = state.selectedItems.filter(
        (admin) => admin.id !== action.payload.id
      );
    },
    resetSelectedAdmins: (state) => {
      state.selectedItems = [];
    },
    setSearchTerm: (state, action: PayloadAction<string | undefined>) => {
      state.searchParams.searchText = action.payload;
    },
  },
});

export const adminsActions = adminsSlice.actions;

export default adminsSlice.reducer;
