import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPost } from "shared/types";

export enum ModalType {
  photoModal,
  profileModal,
  addUserModal,
  verificationModal,
  addAdminModal,
  textModal,
  complaintModal,
  documentModal,
  postCommentsModal,
  marketplaceItemModal,
  showImageModal,
  addNewPaymentLink,
}

interface IModalState {
  isOpen: boolean;
  type: ModalType | null;
  photoModalData: {
    imageUrl?: string;
    imageFile?: File | string;
  };
  profileModalData: {
    id?: number;
  };
  verificationModalData: {
    id?: number;
  };
  feedbackModalData: {
    message?: string;
    reply?: string;
  };
  complaintModalData: {
    description?: string;
    type?: string;
    id?: number;
    post_url?: string;
  };
  documentModalData: {
    id?: number;
  };
  postCommentsModalData: Partial<IPost> & {
    id?: number;
    username?: string;
    date?: string;
  };
  showImageModalData: {
    image?: string;
  };
}

const initialState: IModalState = {
  isOpen: false,
  type: null,
  photoModalData: {},
  profileModalData: {},
  verificationModalData: {},
  feedbackModalData: {},
  complaintModalData: {},
  documentModalData: {},
  postCommentsModalData: {},
  showImageModalData: {},
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.isOpen = false;
      state.type = null;
    },
    openModal: (state, action: PayloadAction<ModalType>) => {
      state.type = action.payload;
      state.isOpen = true;
    },
    setPhotoData: (
      state,
      action: PayloadAction<IModalState["photoModalData"]>
    ) => {
      state.photoModalData.imageUrl = action.payload.imageUrl;
      state.photoModalData.imageFile = action.payload.imageFile;
    },
    resetPhotoData: (state) => {
      state.photoModalData = {};
    },
    setProfileData: (state, action: PayloadAction<number>) => {
      state.profileModalData.id = action.payload;
    },
    resetProfileData: (state) => {
      state.profileModalData = {};
    },
    setVerificationData: (state, action: PayloadAction<number>) => {
      state.verificationModalData.id = action.payload;
    },
    resetVerificationData: (state) => {
      state.verificationModalData = {};
    },
    setFeedbackData: (
      state,
      action: PayloadAction<{ message: string; reply: string }>
    ) => {
      state.feedbackModalData.message = action.payload.message;
      state.feedbackModalData.reply = action.payload.reply;
    },
    resetFeedbackData: (state) => {
      state.feedbackModalData = {};
    },
    setComplaintData: (
      state,
      action: PayloadAction<IModalState["complaintModalData"]>
    ) => {
      state.complaintModalData = {
        ...state.complaintModalData,
        ...action.payload,
      };
    },
    resetComplaintData: (state) => {
      state.complaintModalData = {};
    },
    setDocumentData: (state, action: PayloadAction<number>) => {
      state.documentModalData.id = action.payload;
    },
    resetDocumentData: (state) => {
      state.documentModalData = {};
    },
    setPostCommentsData: (
      state,
      action: PayloadAction<
        Partial<IPost> & { id: number; username: string; date: string }
      >
    ) => {
      state.postCommentsModalData = action.payload;
    },
    resetPostCommentsData: (state) => {
      state.postCommentsModalData = {};
    },
    setShowImageData: (state, action: PayloadAction<{ image: string }>) => {
      state.showImageModalData = action.payload;
    },
    resetShowImageData: (state) => {
      state.showImageModalData = {};
    },
  },
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;
