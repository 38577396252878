import { Calendar } from "shared/ui/Calendar";
import { useSearchCallByDate } from "../model";

export const SearchCallByDate = () => {
  const { setDate, callDate } = useSearchCallByDate();
  return (
    <Calendar
      value={callDate || null}
      withClearIcon={!!callDate}
      placeholderText="дата звонка"
      setDate={setDate}
    />
  );
};
