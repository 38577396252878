import { OptionButton } from "shared/ui/Buttons";
import { useApproveSelectedVerifications } from "./model";

export const ApproveSelectedUsers = () => {
  const { approveSelected, selectedItemsCount } =
    useApproveSelectedVerifications();
  return (
    <OptionButton disabled={!selectedItemsCount} onClick={approveSelected}>
      Принять заявки
    </OptionButton>
  );
};
