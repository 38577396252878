import { HideButton } from "shared/ui/Buttons";
import { useHideSelectedPosts } from "../model";

export const HideSelectedPosts = () => {
  const { hideSelectedPosts, selectedPostsCount } = useHideSelectedPosts();
  return (
    <HideButton
      isClose
      onClick={hideSelectedPosts}
      disabled={!selectedPostsCount}
    >
      Скрыть
    </HideButton>
  );
};
