import { Api, tags } from "../..";
import {
  IWalletConnectCardBenefit,
  WalletHelpText,
  WalletHelpTextType,
} from "shared/types";

export const walletCommonApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getConnectCardBenefits: builder.query<IWalletConnectCardBenefit[], void>({
      query: () => ({ url: "api/wallet/benefits/" }),
      providesTags: [tags.walletConnectCardBenefits],
    }),

    getWalletHelpText: builder.query<WalletHelpText, WalletHelpTextType>({
      query: (data) => ({
        url: "api/wallet/help-texts/",
        params: { category: data },
      }),
      providesTags: [tags.walletHelpText],
    }),
  }),
});

export const { useGetConnectCardBenefitsQuery, useGetWalletHelpTextQuery } =
  walletCommonApi;
