import { useCallback } from "react";
import { modalActions, ModalType } from "shared/store";
import { useAppDispatch } from "shared/hooks/shared";

export const useAddNewPaymentLinkModal = () => {
  const dispatch = useAppDispatch();

  const openAddNewPaymentLinkModal = useCallback(() => {
    dispatch(modalActions.openModal(ModalType.addNewPaymentLink));
  }, [dispatch]);

  const closeAddNewPaymentLinkModal = useCallback(() => {
    dispatch(modalActions.closeModal());
  }, [dispatch]);

  return {
    openAddNewPaymentLinkModal,
    closeAddNewPaymentLinkModal,
  };
};
