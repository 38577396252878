import { useAppDispatch } from "shared/hooks/shared";
import { modalActions, ModalType } from "shared/store";
import { IPost } from "shared/types";

interface useOpenPostCommentsProps {
  post: IPost;
}

export const useOpenPostComments = ({ post }: useOpenPostCommentsProps) => {
  const dispatch = useAppDispatch();

  const showCommentsModal = () => {
    dispatch(modalActions.openModal(ModalType.postCommentsModal));
    dispatch(
      modalActions.setPostCommentsData({
        id: post.id,
        username: post.author.username,
        date: post.created_at,
      })
    );
  };

  return {
    showCommentsModal,
  };
};
