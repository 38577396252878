import { Api, tags } from "../..";
import { IVerificationsRequirement } from "shared/types";

const basePath = "api/verifications/requirements/";

export const verificationsRequirementsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getVerifications: builder.query<IVerificationsRequirement[], void>({
      query: () => basePath,
      providesTags: [tags.verificationsRequirements],
    }),
  }),
});

export const { useGetVerificationsQuery } = verificationsRequirementsApi;
