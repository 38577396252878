export enum VerificationType {
  flow = "verification_flow",
  requirements = "verification_requirements",
}

export enum AgreementType {
  userAgreement = "user_agreement",
  sensitiveData = "processing_sensitive_data",
}

export type Image = { image: string };

export interface IContentCategory {
  id: number;
  name: string;
}

export interface IOnboarding {
  id: number;
  image: string;
  texts: {
    header: string;
    description: string;
  }[];
  short_description: string;
  description: string;
}

export interface ICommission {
  id: number;
  description: string;
}

export interface IWarning {
  id: number;
  description: string;
}

export interface IAgreement {
  type: AgreementType;
  description: string;
}

export interface ITag {
  name: string;
}

export interface IUserCategory {
  name: string;
}

export interface IVerificationsRequirement {
  name: VerificationType;
  description: string;
}
