import { HtmlEditor } from "shared/ui/HtmlEditor";
import { useVerefications } from "shared/hooks/core";
import * as Layout from "shared/ui/layout";

export const VerificationsFlow = () => {
  const { flow, setVerifications } = useVerefications();

  const handleSaveClick = (text: string) => {
    if (text !== flow.description) {
      setVerifications({ ...flow, description: text });
    }
  };

  return (
    <Layout.Content
      title={
        <>
          Редактирование <br /> процесса верификации
        </>
      }
    >
      <HtmlEditor
        description={flow.description || ""}
        saveText={handleSaveClick}
      />
    </Layout.Content>
  );
};
