import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

export const MenuContext = createContext({
  isMobileMenu: false,
  toggleMobileMenu: () => {},
});

export const MenuProvider = ({ children }: PropsWithChildren) => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);

  useLayoutEffect(() => {
    const menuMobile = localStorage.getItem("isMobileMenu");
    if (menuMobile) {
      setIsMobileMenu(true);
    }
  }, []);

  useEffect(() => {
    if (isMobileMenu) {
      localStorage.setItem("isMobileMenu", "true");
    } else {
      localStorage.removeItem("isMobileMenu");
    }
  }, [isMobileMenu]);

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenu((state) => !state);
  }, []);

  return (
    <MenuContext.Provider value={{ isMobileMenu, toggleMobileMenu }}>
      {children}
    </MenuContext.Provider>
  );
};
