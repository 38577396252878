import { RootState } from "shared/store";

export const adminsSelector = (state: RootState) => state.admins;
export const usersSelector = (state: RootState) => state.users;
export const tokensSelector = (state: RootState) => state.user;
export const walletSelector = (state: RootState) => state.wallet;
export const complaintsSelector = (state: RootState) => state.complaints;
export const verificationsSelector = (state: RootState) => state.verifications;
export const promotionSelector = (state: RootState) => state.promotion;
export const postsSelector = (state: RootState) => state.posts;
export const marketplacesSelector = (state: RootState) => state.marketplaces;
export const feedbackSelector = (state: RootState) => state.feedback;
export const confirmSelector = (state: RootState) => state.confirmModal;
export const notificationSelector = (state: RootState) => state.notification;

export namespace ModalsSelector {
  export const modal = (state: RootState) => state.modal;
  export const photo = (state: RootState) => state.modal.photoModalData;
  export const profile = (state: RootState) => state.modal.profileModalData;
  export const postMedia = (state: RootState) => state.modal.documentModalData;
  export const verification = (state: RootState) =>
    state.modal.verificationModalData;
  export const complaints = (state: RootState) =>
    state.modal.complaintModalData;
  export const postComments = (state: RootState) =>
    state.modal.postCommentsModalData;
}

export namespace ContentSelector {
  export const verefications = (state: RootState) =>
    state.content.verifications;
  export const categories = (state: RootState) => state.content.categories;
}
