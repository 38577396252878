import { useCallback } from "react";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { decOfCallsFeedback } from "shared/lib";

import { sleep } from "shared/lib/sleep";
import { usePublishCallsFeedbackMutation } from "shared/api";

interface PublishFeedbackParams {
  id: number;
}

export const usePublishCallsFeedback = () => {
  const { open } = useShowConfirmModal();
  const { show, hide } = useNotification();

  const [publishFeedback] = usePublishCallsFeedbackMutation();

  const publishCallsFeedback = useCallback(
    ({ id }: PublishFeedbackParams) => {
      open({
        title: `Опубликовать ${decOfCallsFeedback(1)}?`,
        buttonText: "Опубликовать",
        onConfirmCallback: async () => {
          show({
            text: "Публикация",
            withLoader: true,
            autoClose: false,
          });
          try {
            await Promise.all([publishFeedback({ id }).unwrap(), sleep(550)]);
            hide();
            show({ text: "Отзыв опубликован!" });
          } catch {
            hide();
            show({ text: "Ошибка публикации!", isError: true });
          }
        },
      });
    },
    [publishFeedback, hide, open, show]
  );

  return {
    publishCallsFeedback,
  };
};
