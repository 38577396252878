import { Routes, Route } from "react-router-dom";
import { LoginForm, RestoreForm } from "widgets/Login";
import * as Layout from "shared/ui/layout";

export const Router = () => {
  return (
    <Routes>
      <Route path="*" element={<Layout.Login />}>
        <Route path="*" element={<LoginForm />} />
        <Route path="reset" element={<RestoreForm />} />
      </Route>
    </Routes>
  );
};
