import { useCallback } from "react";
import { useDeleteUserMutation } from "shared/api";
import { usersSelector, usersActions } from "shared/store";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { useNotification } from "shared/hooks/shared";
import { decOfUsers } from "shared/lib";

export const useDeleteSelectedUsers = () => {
  const dispatch = useAppDispatch();

  const [deleteUser] = useDeleteUserMutation();
  const { selectedUsers } = useAppSelector(usersSelector);

  const { open } = useShowConfirmModal();
  const { show, hide } = useNotification();

  const deleteUsers = useCallback(() => {
    open({
      title: `Удалить ${selectedUsers.length} ${decOfUsers(
        selectedUsers.length
      )}?`,
      buttonText: "Удалить",
      onConfirmCallback: async () => {
        show({
          text: "Изменяется видимость постов",
          withLoader: true,
          autoClose: false,
        });
        let promises = selectedUsers.map((user) =>
          deleteUser({ id: user.id }).unwrap()
        );
        let results = await Promise.allSettled(promises);

        if (results.every((promise) => promise.status === "fulfilled")) {
          hide();
          show({ text: "Посты скрыты!" });
        }
        dispatch(usersActions.resetSelectedUsers());
      },
    });
  }, [deleteUser, dispatch, hide, open, selectedUsers, show]);

  return {
    selectedUsersCount: selectedUsers.length,
    deleteUsers,
  };
};
