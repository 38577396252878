import { IDashboardFinances, IDashboardStats } from "shared/types";
import { Api } from "../..";

const basePath = "api/admin-dashboards";

type Period = "DAY" | "WEEK" | "MONTH";

export const dashboardApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getSatistics: builder.query<
      IDashboardStats,
      { style: Period; start: string; finish: string }
    >({
      query: (data) => ({ url: `${basePath}/statistics/`, params: data }),
    }),
    getFinances: builder.query<
      IDashboardFinances,
      { style: Period; start: string; finish: string }
    >({
      query: (data) => ({ url: `${basePath}/finances/`, params: data }),
    }),
  }),
});

export const { useGetSatisticsQuery, useGetFinancesQuery } = dashboardApi;
