import { ID, IPostComment } from "shared/types";
import { Api, tags } from "../..";

export const commentsCommonApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query<{ results: IPostComment[] }, ID>({
      query: (data) => ({ url: `api/comments/${data.id}/` }),
      providesTags: [tags.postComments],
    }),
  }),
});

export const { useGetCommentsQuery } = commentsCommonApi;
