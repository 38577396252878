export const Convert = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.46967 22.0303C7.76256 22.3232 8.23744 22.3232 8.53033 22.0303C8.82322 21.7374 8.82322 21.2626 8.53033 20.9697L7.46967 22.0303ZM3.5 17L2.96967 16.4697C2.67678 16.7626 2.67678 17.2374 2.96967 17.5303L3.5 17ZM8.53033 13.0303C8.82322 12.7374 8.82322 12.2626 8.53033 11.9697C8.23744 11.6768 7.76256 11.6768 7.46967 11.9697L8.53033 13.0303ZM17.5303 2.96967C17.2374 2.67678 16.7626 2.67678 16.4697 2.96967C16.1768 3.26256 16.1768 3.73744 16.4697 4.03033L17.5303 2.96967ZM21.5 8L22.0303 8.53033C22.171 8.38968 22.25 8.19891 22.25 8C22.25 7.80109 22.171 7.61032 22.0303 7.46967L21.5 8ZM16.4697 11.9697C16.1768 12.2626 16.1768 12.7374 16.4697 13.0303C16.7626 13.3232 17.2374 13.3232 17.5303 13.0303L16.4697 11.9697ZM17 17.75C17.4142 17.75 17.75 17.4142 17.75 17C17.75 16.5858 17.4142 16.25 17 16.25V17.75ZM8 7.25C7.58579 7.25 7.25 7.58579 7.25 8C7.25 8.41421 7.58579 8.75 8 8.75V7.25ZM8.53033 20.9697L4.03033 16.4697L2.96967 17.5303L7.46967 22.0303L8.53033 20.9697ZM4.03033 17.5303L8.53033 13.0303L7.46967 11.9697L2.96967 16.4697L4.03033 17.5303ZM16.4697 4.03033L20.9697 8.53033L22.0303 7.46967L17.5303 2.96967L16.4697 4.03033ZM20.9697 7.46967L16.4697 11.9697L17.5303 13.0303L22.0303 8.53033L20.9697 7.46967ZM3.5 17.75H17V16.25H3.5V17.75ZM21.5 7.25L8 7.25V8.75L21.5 8.75V7.25Z"
        fill="#0F172A"
      />
    </svg>
  );
};
