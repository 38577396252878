import { EditAdmin } from "widgets/EditProfile";
import * as Layout from "shared/ui/layout";

export const AdminProfile = () => {
  return (
    <Layout.Content title={"Редактирование администратора"}>
      <EditAdmin />
    </Layout.Content>
  );
};
