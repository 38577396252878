import { ReactNode } from "react";
import styles from "./TableStickyMenu.module.scss";

interface TableStickyMenuProps {
  isVisible: boolean;
  width: number;
  label: string;
  controls: ReactNode | ReactNode[];
}

export const TableStickyMenu = ({
  isVisible,
  width,
  label,
  controls,
}: TableStickyMenuProps) => {
  return isVisible ? (
    <div className={styles.tableStickyMenu} style={{ width }}>
      <span className={styles.tableStickyMenu__label}>{label}</span>
      <div className={styles.tableStickyMenu__controls}>{controls}</div>
    </div>
  ) : null;
};
