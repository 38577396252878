import { ChatIcon, HeartIcon } from "shared/ui/Icons";
import { ScrollBar } from "shared/ui/ScrollBar";
import { StatsCard, StatsCardWithHint } from "entities/StatsCard";
import { DashboardStats } from "shared/types";
import { getFullDateFormat } from "shared/lib";
import { useOverallStats } from "./model";
import { BarsGroup } from "shared/ui/Graphs";
import styles from "./OverallStats.module.scss";
import { PeriodSelector } from "widgets/PeriodSelector";

export const OverallStatistics = () => {
  const { data, startDate, selectPeriod, customPeriod, period } =
    useOverallStats();

  const statCardsDesc = `*по сравнению с показателями за ${
    period === "custom" && customPeriod.start
      ? getFullDateFormat(customPeriod.start)
      : startDate
  }`;

  return (
    <ScrollBar height={"calc(100vh - 320px)"}>
      <div className={styles.overallStatistics}>
        <div className={styles.overallStatistics__periodSelector}>
          <PeriodSelector selectPeriod={selectPeriod} />
        </div>
        <ScrollBar height={260}>
          {/* @ts-ignore */}
          <BarsGroup data={data?.visits || []} />
        </ScrollBar>
        <div className={styles.overallStatistics__stats}>
          <div className={styles.overallStatistics__cards}>
            <div className={styles.overallStatistics__list}>
              <div className={styles.overallStatistics__listTitle}>
                Количество посещений
              </div>
              <StatsCard
                title={DashboardStats.new_users}
                value={data?.simple_stat?.new_users}
              />
              <StatsCard
                title={DashboardStats.unique_users}
                value={data?.simple_stat?.unique_users}
                color="pink"
              />
              <StatsCard
                title={DashboardStats.active_users}
                value={data?.simple_stat?.active_users}
              />
            </div>
            <div className={styles.overallStatistics__list}>
              <div className={styles.overallStatistics__listTitle}>
                Активность
              </div>

              <StatsCardWithHint
                title={DashboardStats.posts_likes_activity}
                value={data?.simple_stat?.posts_likes}
                color={"pink"}
                type={"percent"}
                icon={<HeartIcon />}
                hint={statCardsDesc}
              />
              <StatsCardWithHint
                title={DashboardStats.profiles_likes_activity}
                value={data?.simple_stat?.profiles_likes}
                icon={<HeartIcon />}
                type={"percent"}
                hint={statCardsDesc}
              />
              <StatsCardWithHint
                title={DashboardStats.comments_activity}
                value={data?.simple_stat?.comments}
                icon={<ChatIcon />}
                color={"pink"}
                type={"percent"}
                hint={statCardsDesc}
              />
            </div>
          </div>
        </div>
      </div>
    </ScrollBar>
  );
};
