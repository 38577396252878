import { useNotification } from "shared/hooks/shared";
import {
  useAddNewOnboardingMutation,
  useChangeOnboardingMutation,
  useChangeOnboardingTextsMutation,
  useGetOnboardingsQuery,
} from "shared/api";

export namespace OnboardingsHooks {
  export const useOnboarding = ({ id }: { id: string | undefined }) => {
    const [addNew, responseNewOnboarding] = useAddNewOnboardingMutation();
    const [change, responseChangeOnboarding] = useChangeOnboardingMutation();
    const [changeTexts] = useChangeOnboardingTextsMutation();

    const onboardingsQuery = useGetOnboardingsQuery();

    const { show, hide } = useNotification();

    const addNewOnboarding = (formData: FormData, texts?: unknown[]) => {
      show({
        text: "Onboarding добавляется",
        withLoader: true,
        autoClose: false,
      });
      addNew(formData)
        .unwrap()
        .then((data) => {
          hide();
          if (data.id && texts && texts.length > 0) {
            return changeTexts({ id: data.id, texts }).unwrap();
          }
        })
        .then(() => {
          show({ text: "Onboarding добавлен" });
        });
    };

    const changeOnboarding = (data: { id: string; formData: FormData }) => {
      show({
        text: "Onboarding изменяется",
        withLoader: true,
        autoClose: false,
      });
      change(data)
        .unwrap()
        .then(() => {
          hide();
          show({ text: "Onboarding изменен" });
        });
    };

    const changeOnboardingTexts = (data: { id: string; texts: unknown[] }) => {
      changeTexts(data);
    };

    const onboardings = onboardingsQuery.isSuccess ? onboardingsQuery.data : [];

    const [onboarding] = id
      ? onboardings.filter((item) => item.id === Number(id))
      : [];
    const onboardingIndex = onboarding
      ? onboardings.findIndex((item) => item.id === Number(id)) + 1
      : null;

    return {
      onboarding,
      addNewOnboarding,
      changeOnboarding,
      changeOnboardingTexts,
      onboardingIndex: onboardingIndex !== null ? onboardingIndex : null,
      isSuccess:
        responseNewOnboarding.isSuccess || responseChangeOnboarding.isSuccess,
      isLoading:
        responseNewOnboarding.isLoading || responseChangeOnboarding.isLoading,
      resultId: responseNewOnboarding.isSuccess
        ? responseNewOnboarding.data.id
        : null,
    };
  };

  export const useOnboardings = () => {
    const onboardingsQuery = useGetOnboardingsQuery();
    const onboardings = onboardingsQuery.isSuccess ? onboardingsQuery.data : [];

    return { onboardings };
  };
}
