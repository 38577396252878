import { memo } from "react";
import { Link } from "react-router-dom";
import { IAdmin } from "shared/types";
import { Pagination } from "shared/ui/Pagination";
import { EditIcon, DeleteIcon } from "shared/ui/Icons";
import { AdminsTable, AdminTableRow } from "entities/admins/AdminsTable";
import { AddAdminButton } from "features/admins/add-new-admin";
import { DeleteSelectedAdminsButton } from "features/admins/delete-selected-admins";
import { SearchAdminInput } from "features/admins/search-admins";
import { useDeleteAdmin } from "features/admins/delete-admin";
import { SelectAdminCheckbox } from "features/admins/select-admin";
import { useAdmins, useAdminsPagination } from "./model";
import styles from "./admins.module.scss";
import { Tooltip } from "shared/ui/Tooltip";

interface AdminControlsProps {
  admin: IAdmin;
}

const AdminControls = memo(({ admin }: AdminControlsProps) => {
  const deleteAdmin = useDeleteAdmin(admin);

  return (
    <div className={styles.adminsTableControls}>
      <div className={styles.adminsTableControls__icon}>
        <Tooltip tooltip="Удалить">
          <DeleteIcon onClick={deleteAdmin} />
        </Tooltip>
      </div>
      <Link to={`/users/edit-profile/admin/${admin.id}`}>
        <div className={styles.adminsTableControls__icon}>
          <Tooltip tooltip="Редактировать">
            <EditIcon />
          </Tooltip>
        </div>
      </Link>
    </div>
  );
});

export const Admins = () => {
  const { admins, loadingStatus } = useAdmins();
  const { pagination } = useAdminsPagination();

  return (
    <div className={styles.admins}>
      <div className={styles.admins__paginationTop}>
        <div className={styles.adminsSort}>
          <SearchAdminInput />
          <AddAdminButton />
          <DeleteSelectedAdminsButton />
        </div>
        <Pagination isShort withSelector {...pagination} />
      </div>

      <AdminsTable dataLoading={loadingStatus.isLoading}>
        {admins &&
          admins.map((admin, ind) => (
            <AdminTableRow
              even={!!(ind % 2)}
              admin={admin}
              key={ind}
              selectOption={<SelectAdminCheckbox admin={admin} />}
              controls={<AdminControls admin={admin} />}
            />
          ))}
      </AdminsTable>
      {loadingStatus.isSuccess && <Pagination {...pagination} />}
    </div>
  );
};
