import { useWarning } from "shared/hooks/core";
import { HtmlEditor } from "shared/ui/HtmlEditor";
import * as Layout from "shared/ui/layout";

export const Warning = () => {
  const { warning, changeWarningDescription } = useWarning();

  return (
    <Layout.Content
      title={
        <>
          Редактирование предупреждения <br /> от администрации
        </>
      }
    >
      {warning && (
        <HtmlEditor
          description={warning.description || ""}
          saveText={changeWarningDescription}
        />
      )}
    </Layout.Content>
  );
};
