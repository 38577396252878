import {
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "shared/hooks/shared";
import { postsSelector, postsActions } from "shared/store";

export const usePostsPagination = () => {
  const dispatch = useAppDispatch();
  const { info, currentPage, postsPerPage } = useAppSelector(postsSelector);

  const setPage = (page: number) => {
    dispatch(postsActions.setCurrentPage(page));
  };

  const setItemsPerPage = (itemsCount: number) => {
    dispatch(postsActions.setPostsPerPage(itemsCount));
  };

  const pagination = usePagination({
    info,
    setPage,
    currentPage,
    items: postsPerPage,
    setItemsPerPage,
  });

  return { pagination };
};
