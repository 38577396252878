import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWalletApplication, QueryData } from "shared/types";

interface IInitialState {
  info: {
    total: number;
    pages: number;
    is_last: boolean;
  };
  searchTerm: string;
  currentPage: number;
  applicationsPerPage: number;
  applicationDate: Date | undefined;
  isActiveApplications: boolean;
}

const initialState: IInitialState = {
  info: {
    total: 0,
    pages: 0,
    is_last: true,
  },
  searchTerm: "",
  currentPage: 1,
  applicationsPerPage: 50,
  applicationDate: undefined,
  isActiveApplications: true,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setQueryData: (
      state,
      action: PayloadAction<QueryData<IWalletApplication[]>>
    ) => {
      state.info.is_last = action.payload.is_last;
      state.info.pages = action.payload.pages;
      state.info.total = action.payload.total;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setApplicationsPerPage: (state, action: PayloadAction<number>) => {
      state.applicationsPerPage = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setApplicationDate: (state, action: PayloadAction<Date | undefined>) => {
      state.applicationDate = action.payload;
    },
    setApplicationsSatus: (state, action: PayloadAction<boolean>) => {
      state.isActiveApplications = action.payload;
    },
  },
});

export const walletActions = walletSlice.actions;

export default walletSlice.reducer;
