import * as Layout from "shared/ui/layout";
import { Tooltip } from "shared/ui/Tooltip";
import { Pagination } from "shared/ui/Pagination";
import { MarketplacesTable } from "entities/marketplaces/MarketplacesTable";
import { BanSelectedMarketplaceItems } from "features/marketplaces/ban-selected-items";
import { DeleteSelectedMarketplaceItems } from "features/marketplaces/delete-selected-items";
import { SearchBannedMarketplaceItems } from "features/marketplaces/search-banned-items";
import { SearchMarketplaceItems } from "features/marketplaces/search-item";
import { SearchVerifiedMarketplaceItems } from "features/marketplaces/search-verified-items";
import { useMerketplaceItemInfo } from "features/marketplaces/show-item-info";
import { MinusIcon, BanIcon, AlertIcon } from "shared/ui/Icons";
import { useMarketplacesPagination } from "./model";
import styles from "./Marketplaces.module.scss";
import { PlaceholderWithOptions } from "shared/ui/layout";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MarketplaceControls = () => {
  const { openInfo } = useMerketplaceItemInfo();

  return (
    <div className={styles.marketplacesRowOptions}>
      <div className={styles.marketplacesRowOptions__icon}>
        <Tooltip tooltip="Удалить">
          <MinusIcon />
        </Tooltip>
      </div>
      <div className={styles.marketplacesRowOptions__icon}>
        <Tooltip tooltip="Забанить">
          <BanIcon />
        </Tooltip>
      </div>
      <div className={styles.marketplacesRowOptions__icon}>
        <Tooltip tooltip="Информация">
          <AlertIcon onClick={openInfo} />
        </Tooltip>
      </div>
    </div>
  );
};

export const MarketplacesPage = () => {
  const { pagination } = useMarketplacesPagination();

  return (
    <Layout.Content title="Управление маркетплейсами">
      <div className={styles.marketplaces}>
        <div className={styles.marketplaces__paginationTop}>
          <div className={styles.marketplacesSort}>
            <div className={styles.marketplacesSort__row}>
              <SearchMarketplaceItems />
              <DeleteSelectedMarketplaceItems />
              <BanSelectedMarketplaceItems />
            </div>
            <div className={styles.marketplacesSort__row}>
              <SearchBannedMarketplaceItems />
              <SearchVerifiedMarketplaceItems />
            </div>
          </div>
          <Pagination isShort withSelector {...pagination} />
        </div>
        <MarketplacesTable>
          {/* <MarketplacesTableRow controls={<MarketplaceControls />} /> */}
        </MarketplacesTable>
        <PlaceholderWithOptions text={"Нет данных для отображения"} />
        <Pagination {...pagination} />
      </div>
    </Layout.Content>
  );
};
