import { Api, tags } from "../..";
import {
  AgreementType,
  IAgreement,
  ICommission,
  IOnboarding,
  IWarning,
} from "shared/types";

const agreementPath = "api/base/agreement/";
const onboardingsPath = "api/base/onboardings/";
const commissionPath = "api/base/commission/";
const warningPath = "api/base/warning/";

export const baseCommonApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getAgreements: builder.query<IAgreement, AgreementType>({
      query: (type) => ({ url: agreementPath, params: { type } }),
      providesTags: [tags.agreement],
    }),
    getOnboardings: builder.query<IOnboarding[], void>({
      query: () => onboardingsPath,
      providesTags: [tags.onboarding],
    }),
    getCommission: builder.query<ICommission, void>({
      query: () => commissionPath,
      providesTags: [tags.commission],
    }),
    getWarning: builder.query<IWarning, void>({
      query: () => warningPath,
      providesTags: [tags.warning],
    }),
  }),
});

export const {
  useGetAgreementsQuery,
  useGetOnboardingsQuery,
  useGetCommissionQuery,
  useGetWarningQuery,
} = baseCommonApi;
