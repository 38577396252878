import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { usersActions } from "shared/store";

export const useFilterActiveUsers = () => {
  const dispatch = useAppDispatch();
  const { isActiveUsers } = useAppSelector((state) => state.users);

  const showActiveUsers = (value: boolean | undefined) => {
    dispatch(usersActions.setCurrentPage(1));
    dispatch(usersActions.setIsActiveUsers(value));
  };

  return { isActiveUsers, showActiveUsers };
};
