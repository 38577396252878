import { ConfirmModal } from "entities/Modals/ConfirmModal";
import { useAppSelector } from "shared/hooks/shared";
import { confirmSelector } from "shared/store";

export const GetConfirmModal = () => {
  const { isOpen } = useAppSelector(confirmSelector);

  if (isOpen) {
    return <ConfirmModal />;
  }

  return null;
};
