import { Calendar } from "shared/ui/Calendar";
import { useSearchComplaintsByPublicationDate } from "../model";

import styles from "./SearchComplaintsByPublicationDate.module.scss";

export const SearchComplaintsByPublicationDate = () => {
  const { publicationDate, setPublicationDate } =
    useSearchComplaintsByPublicationDate();
  return (
    <Calendar
      value={publicationDate}
      setDate={setPublicationDate}
      placeholderText="дата публикации"
      withClearIcon={!!publicationDate}
      className={styles.calendar}
    />
  );
};
