import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { complaintsSelector, complaintsActions } from "shared/store";
import { ComplaintReasonType } from "shared/types";

export const useSearchComplaintsByReason = () => {
  const dispatch = useAppDispatch();
  const { searchParams } = useAppSelector(complaintsSelector);

  const setComplaintReason = (reason: ComplaintReasonType | undefined) => {
    dispatch(complaintsActions.setReasonType(reason));
  };

  return {
    setComplaintReason,
    reasonType: searchParams.reasonType,
  };
};
