import { useAppDispatch } from "shared/hooks/shared";
import { postsActions } from "shared/store";

export const useSearchByParamsCount = () => {
  const dispatch = useAppDispatch();

  const sortByCommentsCount = (count: number) => {
    dispatch(postsActions.setCommentsCount(count));
  };

  const sortByComplaintsCount = (count: number) => {
    dispatch(postsActions.setComplaintsCount(count));
  };

  return {
    sortByCommentsCount,
    sortByComplaintsCount,
  };
};
