import { Route, Routes } from "react-router-dom";
import { Admins } from "pages/private/users/admins";
import { Users } from "pages/private/users/users";
import { Verifications } from "./verifications";
import * as Layout from "shared/ui/layout";

const basePath = "/users";

export const UsersLinks = [
  { title: "Пользователи приложения", path: `${basePath}/` },
  { title: "Верификация", path: `${basePath}/verifications` },
  { title: "Администраторы", path: `${basePath}/admins` },
];

export const Router = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <Layout.Content
            title={"Управление аккаунтами"}
            navigation={<Layout.Navigation links={UsersLinks} />}
          />
        }
      >
        <Route path="" element={<Users />} />
        <Route path="verifications" element={<Verifications />} />
        <Route path="admins" element={<Admins />} />
      </Route>
    </Routes>
  );
};
