import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const CheckIcon = ({
  height,
  width,
  className,
  size = DEFAULT_ICON_SIZE,
  color = "black",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 19"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <path
        d="M20.5244 2.09766L7.29268 17.2196L1.62195 11.5489"
        stroke={color}
        strokeWidth="2.64533"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
