import { Calendar } from "shared/ui/Calendar";
import { useSearchPostByDate } from "../model";

export const SeacrhPostsByDate = () => {
  const { postDate, setDate } = useSearchPostByDate();
  return (
    <Calendar
      value={postDate || null}
      setDate={setDate}
      placeholderText="дата публикации"
      withClearIcon={!!postDate}
    />
  );
};
