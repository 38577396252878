import { memo } from "react";
import { getRuLocaleDateString } from "shared/lib";
import { IFeedbackMessage } from "shared/types";
import { LongArrow } from "shared/ui/Icons";
import styles from "./FeedbackMessageBody.module.scss";

interface FeedbackBodyProps {
  message: IFeedbackMessage;
  openFullText: () => void;
  openUserInfo: () => void;
}

export const FeedbackMessageBody = memo(
  ({ message, openFullText, openUserInfo }: FeedbackBodyProps) => {
    return (
      <div className={styles.feedbackMessage__message}>
        <div className={styles.feedbackMessage__leftBlock}>
          <div className={styles.feedbackMessage__info}>
            <div className={styles.feedbackMessage__avatar}>
              {message.avatar && <img src={message.avatar} alt="" />}
            </div>
            <div
              className={styles.feedbackMessage__contacts}
              onClick={openUserInfo}
            >
              <span className={styles.feedbackMessage__name}>
                {message.username}
              </span>
              <span className={styles.feedbackMessage__email}>
                {message.email}
              </span>
            </div>
          </div>
          <div className={styles.feedbackMessage__date}>
            Дата обращения: <b>{getRuLocaleDateString(message.created_at)}</b>
          </div>
        </div>
        <div className={styles.feedbackMessage__rightBlock}>
          <p className={styles.feedbackMessage__title}>Сообщение</p>
          <p className={styles.feedbackMessage__text}>{message.description}</p>
          <div
            className={styles.feedbackMessage__fullLink}
            onClick={openFullText}
          >
            Просмотреть полностью{" "}
            <div className={styles.feedbackMessage__fullLinkIcon}>
              <LongArrow width={32} color={"black"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
