import { InputSearch } from "shared/ui/Inputs";
import { useSearchPostByAuthor } from "../model";

export const SearchPostByAuthor = () => {
  const { searchTerm, setSearchText } = useSearchPostByAuthor();
  return (
    <InputSearch
      value={searchTerm}
      placeholder="Найти публикацию"
      onChange={setSearchText}
    />
  );
};
