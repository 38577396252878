import { useAppDispatch } from "shared/hooks/shared";
import { adminsActions } from "shared/store";

export const useSearchAdmin = () => {
  const dispatch = useAppDispatch();

  const searchAdmins = (value: string) => {
    dispatch(adminsActions.setSearchTerm(value));
  };

  return searchAdmins;
};
