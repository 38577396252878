import { IUserFullInfo } from "shared/types";
import { useCallback, useEffect } from "react";
import {
  useGetUserDataQuery,
  useAddAvatarMutation,
  useDeleteAvatarMutation,
  useEditUserInfoMutation,
} from "shared/api";
import { useNotification } from "shared/hooks/shared";

export const useEditUserProfile = ({ id }: { id: string | undefined }) => {
  const userQuery = useGetUserDataQuery({ id: Number(id) });

  const [setUserAvatar] = useAddAvatarMutation();
  const [deleteUserAvatar, resultDeleteAvatar] = useDeleteAvatarMutation();
  const [setInfo, resultSetInfo] = useEditUserInfoMutation();

  const { show } = useNotification();

  const saveAvatar = (imageFile: FormData) => {
    if (id && imageFile) {
      setUserAvatar({ id, formData: imageFile });
    }
  };

  const deleteAvatar = useCallback(() => {
    if (id && userQuery.isSuccess && userQuery.data?.avatars?.length > 0) {
      deleteUserAvatar({ user_id: id, id: userQuery.data.avatars[0].id });
    }
  }, [deleteUserAvatar, id, userQuery?.data, userQuery.isSuccess]);

  const saveUserInfo = (data: IUserFullInfo) => {
    if (id && userQuery.isSuccess) {
      const oldData = userQuery.data;
      const newData = {
        avatars: [...data.avatars],
        username:
          oldData.username === data.username ? undefined : data.username,
        first_name:
          oldData.first_name === data.first_name ? undefined : data.first_name,
        last_name:
          oldData.last_name === data.last_name ? undefined : data.last_name,
        gender: data.gender,
        description:
          oldData.description === data.description
            ? undefined
            : data.description,
        email: oldData.email === data.email ? undefined : data.email,
        phone_number:
          oldData.phone_number === data.phone_number
            ? undefined
            : data.phone_number,
        birth_date:
          oldData.birth_date === data.birth_date ? undefined : data.birth_date,
        category: data?.category?.id || 0,
        created_at:
          oldData.created_at === data.created_at ? undefined : data.created_at,
      };

      setInfo({ id, data: newData });
    }
  };

  useEffect(() => {
    if (resultSetInfo.isSuccess) {
      show({ text: "Профиль изменен" });
    } else if (resultSetInfo.isError) {
      const {
        data: { errors },
      } = resultSetInfo.error as {
        data: {
          errors: {
            phone_number: string;
          };
        };
      };

      show({
        text: errors.phone_number || "Не удалось изменить профиль",
        isError: true,
      });
    }
  }, [resultSetInfo, show]);

  useEffect(() => {
    if (resultDeleteAvatar.isSuccess) {
      show({ text: "Изображение удалено" });
    } else if (resultDeleteAvatar.isError) {
      show({ text: "Не удалось удалить изображение", isError: true });
    }
  }, [resultDeleteAvatar, show]);

  return {
    userData: userQuery.isSuccess ? userQuery.data : null,
    saveAvatar,
    deleteAvatar,
    saveUserInfo,
  };
};
