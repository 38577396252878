import {
  forwardRef,
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Flipper, Flipped } from "react-flip-toolkit";
import classNames from "classnames";
import {
  TableDataProps,
  TableHeaderProps,
  TableProps,
  TableRowProps,
} from "./table.interface";
import { Spinner } from "shared/ui/Spinner";
import styles from "./table.module.scss";

export const Table = ({
  children,
  animationKey,
  alignStart,
  withLoader,
}: TableProps) => {
  const ref = useRef<HTMLTableElement>(null!);

  const [spinnerWrapperHeight, setSpinnerWrapperHeight] = useState(0);

  useLayoutEffect(() => {
    const resizeHandler = () => {
      const sizes = ref.current?.getBoundingClientRect();
      setSpinnerWrapperHeight(sizes ? sizes.bottom : 0);
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <>
      <Flipper flipKey={`table-${animationKey}`} spring="wooble">
        <table
          ref={ref}
          className={classNames(styles.table, {
            [styles.tableAlignStart]: alignStart,
          })}
        >
          {children}
        </table>
      </Flipper>
      {withLoader && (
        <div
          className={styles.table__loader}
          style={{ minHeight: spinnerWrapperHeight || "auto" }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export const TableHeader = ({
  children,
  headerRef,
  className,
}: TableHeaderProps) => {
  return (
    <thead
      ref={headerRef}
      className={classNames(styles.table__header, className)}
    >
      {children}
    </thead>
  );
};

export const TableBody = ({ children }: PropsWithChildren) => {
  return <tbody className={styles.table__body}>{children}</tbody>;
};

export const TableRow = ({
  even,
  children,
  animationKey,
  className,
}: TableRowProps) => {
  return (
    <Flipped flipId={`table-row-${animationKey}`} stagger>
      <tr
        className={classNames(
          styles.table__row,
          {
            [styles.table__rowEven]: even,
            [styles.table__rowOdd]: !even,
          },
          className
        )}
      >
        {children}
      </tr>
    </Flipped>
  );
};

export const TableData = forwardRef<HTMLTableCellElement, TableDataProps>(
  (
    {
      children,
      width,
      alignStart,
      alignCenter,
      withoutPadding,
      tag,
      className,
    },
    ref
  ) => {
    const Tag = tag || "td";

    return (
      <Tag
        ref={ref}
        className={classNames(styles.table__td, className, {
          [styles.table__tdAlignStart]: alignStart,
          [styles.table__tdAlignCenter]: alignCenter,
          [styles.table__tdWithoutPadding]: withoutPadding,
        })}
        style={{ width, minWidth: width, maxWidth: width }}
      >
        {children}
      </Tag>
    );
  }
);
