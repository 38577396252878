import { useAppDispatch } from "shared/hooks/shared";
import { modalActions, ModalType } from "shared/store";
import { ComplaintType, IComplaint } from "shared/types";

export const useShowComplaintInfo = ({
  object_id,
  object_type,
  post_url,
  reason,
  description,
}: IComplaint) => {
  const dispatch = useAppDispatch();

  const openComplaintInfoModal = () => {
    dispatch(modalActions.openModal(ModalType.complaintModal));
    dispatch(
      modalActions.setComplaintData({
        id: object_type === ComplaintType.COMMENT ? object_id : undefined,
        type: reason,
        description: description,
        post_url: post_url || undefined,
      })
    );
  };

  return {
    openComplaintInfoModal,
  };
};
