import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { AddButton, Button } from "shared/ui/Buttons";
import { DeleteIcon, DownloadIconPng } from "shared/ui/Icons";
import { OnboardingsHooks } from "shared/hooks/core";
import { useLoadPhotoModal } from "entities/Modals/LoadPhotoModal";
import { EditLabel } from "shared/ui/Label";
import { ErrorMessage } from "shared/ui/ErrorMessage";
import { HtmlEditor } from "shared/ui/HtmlEditor";
import * as Layout from "shared/ui/layout";
import styles from "./onboarding.module.scss";

interface OnboardingText {
  header: string;
  description: string;
  originalHeader: string;
  originalDescription: string;
}

export const Onboarding = () => {
  const { id } = useParams();
  const { imageUrl, imageFile, openPhotoModal } = useLoadPhotoModal();
  const {
    onboarding,
    onboardingIndex,
    addNewOnboarding,
    changeOnboarding,
    changeOnboardingTexts,
    isLoading,
    resultId,
  } = OnboardingsHooks.useOnboarding({ id });

  const navigate = useNavigate();

  const [texts, setTexts] = useState<OnboardingText[]>([]);

  const pageTitle =
    id && onboarding?.id && onboardingIndex
      ? `Редактирование Onboarding - ${onboardingIndex}`
      : "Добавление Onboarding";

  const descBlocksCount = texts.length || 0;

  const handleNewBlock = () => {
    setTexts((texts) => [
      ...texts,
      {
        header: "",
        description: "",
        originalHeader: "",
        originalDescription: "",
      },
    ]);
  };

  const handleDeleteBlock = (id: number) => {
    setTexts((texts) => [...texts.filter((_, ind) => ind !== id)]);
  };

  const setText = (id: number, field: "header" | "description") => {
    return (newText: string) => {
      setTexts((texts) => {
        const copy = texts.map((text) => ({ ...text }));
        copy[id][field] = newText;
        return copy;
      });
    };
  };

  const handleSaveOnboarding = () => {
    if (!id && imageUrl) {
      if (imageFile instanceof File) {
        const formData = new FormData();
        formData.append("image", imageFile);
        addNewOnboarding(formData, texts);
      }
    } else if (id && (imageUrl || textsWasChanged)) {
      if (textsWasChanged) {
        changeOnboardingTexts({ id: id, texts });
      }
      if (imageUrl && imageFile instanceof File) {
        const formData = new FormData();
        formData.append("image", imageFile);
        changeOnboarding({ id: id, formData });
      }
    }
  };

  useEffect(() => {
    if (resultId) {
      navigate(`/app-content/`);
      navigate(`/app-content/onboarding/${resultId}`);
    }
  }, [navigate, resultId]);

  useEffect(() => {
    if (onboarding?.texts.length > 0) {
      const onboardingTexts = onboarding.texts.map((item, id) => ({
        ...item,
        originalHeader: item.header,
        originalDescription: item.description,
      }));
      setTexts(onboardingTexts);
    }
  }, [onboarding]);

  const textsWasChanged =
    texts.some(
      (item) =>
        item.header !== item.originalHeader ||
        item.description !== item.originalDescription
    ) || onboarding?.texts.length !== texts.length;

  const textsNotEmpty = texts.every((item) => item.header && item.description);

  const saveButtonEnabled =
    !isLoading && textsNotEmpty && (imageFile || imageUrl || textsWasChanged);

  return (
    <Layout.Content>
      <div className={styles.onboarding}>
        <h1 className={styles.onboarding__title}>{pageTitle}</h1>

        <div className={styles.onboarding__content}>
          <div className={styles.onboarding__contentBlock}>
            <div className={styles.onboarding__label} onClick={openPhotoModal}>
              Загрузить изображение <DownloadIconPng size="46px" />
            </div>
            {(onboarding?.image || imageUrl) && (
              <img
                src={imageUrl || onboarding?.image}
                alt=""
                aria-label="onboarding"
                className={styles.onboarding__image}
              />
            )}
            <Button
              type="solid"
              className={styles.onboarding__button}
              onClick={handleSaveOnboarding}
              disabled={!saveButtonEnabled}
            >
              Сохранить
            </Button>
          </div>

          <div className={classNames(styles.onboarding__contentBlock)}>
            <div className={styles.onboarding__addDescBlock}>
              <span>Добавить описание </span>
              <AddButton
                onClick={handleNewBlock}
                disabled={descBlocksCount >= 2}
              />
            </div>

            {texts.map((item, index) => (
              <div key={index} className={styles.onboarding__descBlock}>
                <div className={styles.onboarding__addDescBlock}>
                  <h3 className={styles.onboarding__descBlockTitle}>
                    Описание №{index + 1}
                  </h3>
                  <DeleteIcon onClick={() => handleDeleteBlock(index)} />
                </div>
                <div>
                  <HtmlEditor
                    description={item.originalHeader || ""}
                    saveText={() => {}}
                    withControls={false}
                    height="80px"
                    title={<EditLabel text={`Короткое описание`} />}
                    onChange={setText(index, "header")}
                  />
                  {item.header.length > 50 ? (
                    <ErrorMessage>Текст длиннее 50 символов!</ErrorMessage>
                  ) : (
                    <div className={styles.onboarding__descTip}>
                      Максимальная длина 50 символов
                    </div>
                  )}
                </div>
                <div>
                  <HtmlEditor
                    description={item.originalDescription || ""}
                    saveText={() => {}}
                    withControls={false}
                    height="180px"
                    title={<EditLabel text={`Длинное описание`} />}
                    onChange={setText(index, "description")}
                  />

                  {item.description.length > 500 ? (
                    <ErrorMessage>Текст длиннее 500 символов!</ErrorMessage>
                  ) : (
                    <div className={styles.onboarding__descTip}>
                      Максимальная длина 500 символов
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout.Content>
  );
};
