import { ChangeEvent } from "react";
import { IAdmin } from "shared/types";
import { Checkbox } from "shared/ui/Checkbox";
import { useSelectAdmin } from "features/admins/select-admin/model";

interface SelectAdminCheckboxProps {
  admin: IAdmin;
}

export const SelectAdminCheckbox = ({ admin }: SelectAdminCheckboxProps) => {
  const { isSelected, selectAdmin } = useSelectAdmin(admin);

  const changeSelectedAdmin = (event: ChangeEvent<HTMLInputElement>) => {
    selectAdmin(event.target.checked);
  };

  return <Checkbox checked={isSelected} onChange={changeSelectedAdmin} />;
};
