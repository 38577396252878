import { memo, useContext } from "react";
import classNames from "classnames";
import { useAppDispatch } from "shared/hooks/shared";
import { userActions } from "shared/store";
import { Button } from "shared/ui/Buttons";
import { Logo } from "shared/ui/Logo";
import { MenuLink } from "entities/Menu/ui";
import { ScrollBar } from "shared/ui/ScrollBar";
import { ArrowDirection, ArrowShortIcon, LogoutIcon } from "shared/ui/Icons";
import { MenuContext } from "shared/context/MenuContext";
import { useNavbar } from "entities/navbar";
import styles from "./menu.module.scss";

export interface MenuProps {
  setMobileMenu?: () => void;
  isMobileMenu?: boolean;
}

export const Menu = memo(() => {
  const dispatch = useAppDispatch();
  const { links } = useNavbar();

  const { isMobileMenu, toggleMobileMenu } = useContext(MenuContext);

  const handleLogOut = () => {
    dispatch(userActions.logOut());
  };

  return (
    <ScrollBar height={"100vh"}>
      <div
        className={classNames(styles.menu, {
          [styles.menuMobile]: isMobileMenu,
        })}
      >
        <div
          className={classNames(styles.menu__hideButton, {
            [styles.menuMobile__hideButton]: isMobileMenu,
          })}
          onClick={toggleMobileMenu}
        >
          <ArrowShortIcon
            direction={
              isMobileMenu ? ArrowDirection.topRight : ArrowDirection.downLeft
            }
            color="#000"
            size={32}
          />
        </div>

        <div
          className={classNames(styles.menu__logo, {
            [styles.menuMobile__logo]: isMobileMenu,
          })}
        >
          <Logo full={!isMobileMenu} />
        </div>

        <ul className={styles.menu__links}>
          {links &&
            links.map((item) => (
              <li
                className={classNames(styles.menu__link, {
                  [styles.menuMobile__link]: isMobileMenu,
                })}
                key={item.path}
              >
                <MenuLink
                  link={item.path}
                  text={isMobileMenu ? "" : item.title}
                  tooltip={isMobileMenu ? item.title : ""}
                  Icon={item.icon}
                  isMenuLink
                />
              </li>
            ))}
        </ul>

        <div className={styles.menu__logout}>
          <Button
            className={classNames(styles.menu__button, {
              [styles.menuMobile__button]: isMobileMenu,
            })}
            onClick={handleLogOut}
            icon={isMobileMenu ? <LogoutIcon color="#fff" /> : undefined}
          >
            {isMobileMenu ? "" : "Выйти"}
          </Button>
        </div>
      </div>
    </ScrollBar>
  );
});
