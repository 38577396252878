import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useDeletePostMutation } from "shared/api";
import { ID } from "shared/types";

export const useDeletePost = ({ id }: ID) => {
  const { open } = useShowConfirmModal();
  const { show } = useNotification();

  const [deleteMutation] = useDeletePostMutation();

  const deletePost = () => {
    open({
      title: `Удалить публикацию?`,
      buttonText: "Удалить",
      onConfirmCallback: () => {
        deleteMutation({ id })
          .unwrap()
          .then(() => {
            show({ text: "Публикация удалена!" });
          });
      },
    });
  };

  return {
    deletePost,
  };
};
