import { BannerCard } from "entities/promotions/BannerCard";
import { DeleteBannerButton } from "features/promotions/banner/delete-banner";
import { EditBannerButton } from "features/promotions/banner/edit-banner";
import { useNavigate } from "react-router-dom";
import { AddButton } from "shared/ui/Buttons";
import { usePromotions, usePromotionsPagination } from "../model";
import styles from "./Banners.module.scss";
import { Pagination } from "shared/ui/Pagination";

export const PromotionsBannersPage = () => {
  const navigate = useNavigate();
  const { promotions, deletePromotion } = usePromotions();
  const { pagination } = usePromotionsPagination();

  return (
    <div className={styles.bannersPage}>
      <AddButton onClick={() => navigate(`/promotion/banner/edit/`)}>
        Добавить баннер
      </AddButton>
      <div className={styles.bannersPage__cardsList}>
        {promotions.map((promotion, ind) => (
          <BannerCard
            title={promotion.title}
            subtitle={promotion.url}
            options={
              <div className={styles.bannersPage__options}>
                <EditBannerButton
                  editHandler={() =>
                    navigate(
                      `/promotion/banner/edit/${promotion.id}/${ind + 1}/`
                    )
                  }
                />
                <DeleteBannerButton
                  deleteHandler={() => deletePromotion(promotion.id.toString())}
                />
              </div>
            }
            key={ind}
          />
        ))}
      </div>
      <div className={styles.bannersPage__pagination}>
        <Pagination {...pagination} />
      </div>
    </div>
  );
};
