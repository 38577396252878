import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { Config } from "shared/config";
import { RootState, userActions } from "shared/store";
import { IAuthResponseDto } from "shared/api/dto";

const baseQuery = fetchBaseQuery({
  baseUrl: Config.API_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = (getState() as RootState).user.accessToken;

    if (token && endpoint !== "/refresh") {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (
    (api.endpoint === "authUser" || api.endpoint === "refreshToken") &&
    result.meta?.response?.status === 200 &&
    result.data
  ) {
    api.dispatch(userActions.setTokens(result.data as IAuthResponseDto));
  }

  if (
    result.error &&
    result.error.status === 403 &&
    api.endpoint !== "authUser"
  ) {
    const refreshToken = (api.getState() as RootState).user.refreshToken;
    const refreshResponse = await baseQuery(
      {
        url: "api/auth/refresh/",
        method: "POST",
        body: {
          refresh: refreshToken,
        },
      },
      { ...api, endpoint: "/refresh" },
      extraOptions
    );

    const tokens = refreshResponse.data as IAuthResponseDto;

    if (tokens && tokens.access) {
      api.dispatch(userActions.setTokens(tokens));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(userActions.logOut());
    }
  }
  return result;
};

export const tags = {
  admins: "AdminsList",
  adminInfo: "AdminInfo",
  agreement: "Agreement",
  userCategory: "UserCategory",
  complaints: "ComplaintsList",
  feedbackList: "FeedbackList",
  onboarding: "Onboarding",
  posts: "PostsList",
  post: "Post",
  interests: "InterestsTags",
  users: "UsersList",
  userInfo: "UserInfo",
  commission: "Commission",
  warning: "Warning",
  verificationsRequirements: "Verifications",
  usersForVerification: "VerificationsList",
  postComments: "PostComments",
  walletApplications: "WalletApplications",
  walletConnectCardBenefits: "walletConnectCardBenefits",
  walletHelpText: "walletHelpText",
  advertisements: "advertisementList",
  commisions: "commisions",
  calls: "calls",
  currencyRates: "currencyRates",
  whitelistedDomains: "whitelistedDomainsList",
  callsFeedbackList: "CallsFeedbackList",
};

export const Api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [...Object.values(tags)],
  endpoints: () => ({}),
});
