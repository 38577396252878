import { memo, ReactNode } from "react";
import { IPost } from "shared/types";
import { getRuLocaleDateString } from "shared/lib";
import { TableRowDataProps, TableRow, TableData } from "shared/ui/Table";

interface PostsTableRowProps extends TableRowDataProps<IPost> {
  selectOption: ReactNode;
  commentsOption: ReactNode;
  controls: ReactNode;
}

export const PostsTableRow = memo(
  ({
    even,
    data,
    selectOption,
    controls,
    commentsOption,
  }: PostsTableRowProps) => {
    return (
      <TableRow even={even} animationKey={data.id}>
        <TableData>{selectOption}</TableData>
        <TableData>{data.id}</TableData>
        <TableData width={240}>{data.author.username}</TableData>
        <TableData>{getRuLocaleDateString(data.created_at)}</TableData>
        <TableData>{commentsOption}</TableData>
        <TableData>{data.complaints_count}</TableData>
        <TableData width={220}>{controls}</TableData>
      </TableRow>
    );
  }
);
