import { Routes, Route, Navigate } from "react-router-dom";
import { AppContentPage } from "./app-content";
import { ComplaintsPage } from "./complaints";
import { DashboardsPage } from "./dashboards";
import { PostEditPage } from "./edit-post";
import { EditProfilePage } from "./edit-profile";
import { CallsPage } from "./calls";
import { FeedbackPage } from "./feedback";
import { MarketplacesPage } from "./marketplaces";
import { PostsPage } from "./publications";
import { UsersPage } from "./users";
import { WalletPage } from "./wallet";
import * as Layout from "shared/ui/layout";
import { usePermissions } from "features/permissions";
import { PromotionsBannersPage } from "./promotion/banner";
import { EditBannerPage } from "./promotion/edit-banner";
import { ChecksPage } from "./wallet/checks";
import { PaymentsLinksPage } from "./payments-links";
import { ReviewsModeration } from "./reviews-moderation";

export const FeedbackLinks = [
  { title: "Актуальные", path: `/feedback/` },
  { title: "Завершенные", path: `/feedback/replies` },
];

export const PromotionsLinks = [
  { title: "Настройка баннера", path: `/promotion/` },
];

export const WalletLinks = [
  { title: "Эквайринг", path: `/wallet/` },
  { title: "Чеки", path: `/wallet/checks` },
];

export const Router = () => {
  const { permissions } = usePermissions();

  return (
    permissions && (
      <Routes>
        {permissions.app_content && (
          <Route path="app-content/*" element={<AppContentPage.Router />} />
        )}

        {permissions.users && (
          <Route
            path="users/edit-profile/*"
            element={<EditProfilePage.Router />}
          />
        )}
        {permissions.users && (
          <Route path="users/*" element={<UsersPage.Router />} />
        )}

        {permissions.complaints && (
          <Route path="complaints/*" element={<ComplaintsPage.Router />} />
        )}
        {permissions.publications && (
          <Route path="publications/*" element={<PostsPage.Router />} />
        )}

        {permissions.feedback && (
          <Route
            path="/feedback/*"
            element={
              <Layout.Content
                title={"Обратная связь"}
                navigation={<Layout.Navigation links={FeedbackLinks} />}
              />
            }
          >
            <Route path="" element={<FeedbackPage />} />
            <Route path="replies" element={<FeedbackPage is_replied_list />} />
          </Route>
        )}

        <Route path="publications/edit/:id" element={<PostEditPage />} />

        {permissions.dashboards && (
          <Route path="dashboards/*" element={<DashboardsPage.Router />} />
        )}
        {permissions.wallet && (
          <Route path="wallet/*" element={<WalletPage />} />
        )}

        {permissions.wallet && (
          <Route
            path="wallet/*"
            element={
              <Layout.Content
                title={"Эквайринг и чеки"}
                navigation={<Layout.Navigation links={WalletLinks} />}
              />
            }
          >
            <Route path="" element={<WalletPage />} />
            <Route path="checks" element={<ChecksPage />} />
          </Route>
        )}

        {permissions.calls && <Route path="calls/*" element={<CallsPage />} />}
        {permissions.marketplaces && (
          <Route path="marketplaces/*" element={<MarketplacesPage />} />
        )}
        {permissions.advertisement && (
          <Route path="promotion/*" element={<PromotionsBannersPage />} />
        )}
        {/* {permissions.advertisement && (
          <Route
            path='/promotion/*'
            element={
              <Layout.Content
                title={'Продвижение'}
                navigation={<Layout.Navigation links={PromotionsLinks} />}
              />
            }>
            <Route path='commission' element={<PromotionsCommisionPage />} />
            <Route path='*' element={<PromotionsBannersPage />} />
          </Route>
        )} */}
        {permissions.advertisement && (
          <Route
            path="/promotion/*"
            element={<Layout.Content title={"Продвижение"} />}
          >
            <Route path="*" element={<PromotionsBannersPage />} />
          </Route>
        )}
        {permissions.advertisement && (
          <Route
            path="/promotion/banner/edit/:id/:order/"
            element={<EditBannerPage />}
          />
        )}
        {permissions.advertisement && (
          <Route path="/promotion/banner/edit/" element={<EditBannerPage />} />
        )}
        <Route path="/payments-links" element={<PaymentsLinksPage />} />
        <Route path="/reviews-moderation" element={<ReviewsModeration />} />
        <Route path="*" element={<Navigate to="dashboards/" replace />} />
      </Routes>
    )
  );
};
