import { useMemo, useCallback, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { verificationsSelector, verificationsActions } from "shared/store";
import { IUserVerification } from "shared/types";

export const useSelectVerefication = (user: IUserVerification) => {
  const dispatch = useAppDispatch();

  const { selectedItems } = useAppSelector(verificationsSelector);

  const selected = useMemo(() => {
    return selectedItems.some((selected) => user.id === selected.id);
  }, [user, selectedItems]);

  const selectVerefication = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && user) {
        dispatch(verificationsActions.addVereficationInSelected(user));
      } else if (!event.target.checked && user) {
        dispatch(verificationsActions.removeVereficationFromSelected(user));
      }
    },
    [dispatch, user]
  );

  return { selected, selectVerefication };
};
