import { useLayoutEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { tokensSelector, userActions } from "shared/store";
import { useRefreshTokenMutation } from "shared/api";
import { GetModal, GetConfirmModal } from "widgets/Modals";
import { Spinner } from "shared/ui/Spinner";
import { PrivatePages, PublicPages } from "pages";
import { MenuProvider } from "shared/context/MenuContext";

export const App = () => {
  const dispatch = useAppDispatch();
  const [refresh, result] = useRefreshTokenMutation();
  const { accessToken, refreshToken } = useAppSelector(tokensSelector);

  useLayoutEffect(() => {
    dispatch(userActions.restoreTokens());
  }, [dispatch]);

  useLayoutEffect(() => {
    if (refreshToken && !accessToken) {
      refresh({ refresh: refreshToken });
    }
  }, [refreshToken, accessToken, refresh]);

  const isUserAuthorized = accessToken && refreshToken;
  const isRefreshTokens =
    result.isLoading || (refreshToken && result.isUninitialized);

  return (
    <MenuProvider>
      {isUserAuthorized ? (
        <PrivatePages />
      ) : isRefreshTokens ? (
        <Spinner fullscreen />
      ) : (
        <PublicPages.Router />
      )}
      <GetModal />
      <GetConfirmModal />

      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        autoClose={2500}
        newestOnTop={false}
        closeOnClick
        closeButton={false}
      />
    </MenuProvider>
  );
};
