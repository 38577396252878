import { useAppDispatch } from "shared/hooks/shared";
import { useNavigate } from "react-router-dom";
import { IFeedbackMessage } from "shared/types";
import { modalActions, ModalType, usersActions } from "shared/store";

export const useShowMessageAuthorInfo = ({
  description,
  email,
  reply,
}: IFeedbackMessage) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const showFullMessageText = () => {
    dispatch(
      modalActions.setFeedbackData({
        message: description,
        reply: reply?.description || "",
      })
    );
    dispatch(modalActions.openModal(ModalType.textModal));
  };

  const showMessageAuthorInfo = () => {
    dispatch(usersActions.setSearchTerm(email));
    navigate("/users/");
  };

  return { showFullMessageText, showMessageAuthorInfo };
};
