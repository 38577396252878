import { Modal } from "shared/ui/Modal";
import { DescriptionBlock } from "shared/ui/DescriptionBlock";
import styles from "./MarketplaceItemModal.module.scss";

export const MarketplaceItemModal = () => {
  return (
    <Modal>
      <div className={styles.marketplaceItemModal}>
        <p className={styles.marketplaceItemModal__title}>
          Информация о товаре
        </p>
        <div className={styles.marketplaceItemModal__info}>
          <div className={styles.marketplaceItemModal__avatar}>
            {/* <img src='' alt='' /> */}
          </div>
          <div className={styles.marketplaceItemModal__contacts}>
            <DescriptionBlock title="логин владельца" content="stacy" />
            <DescriptionBlock
              title="электронная почта"
              content="sivkova_s@list.ru"
            />
            <DescriptionBlock title="название товара" content="товар 1" />
            <DescriptionBlock title="ID товара" content="79286" />
          </div>
        </div>
        <div className={styles.marketplaceItemModal__description}>
          <DescriptionBlock
            title="описание товара"
            content="описание описание описание описание описание описание описание описание описание описание описание описание описание описание"
          />
        </div>
      </div>
    </Modal>
  );
};
