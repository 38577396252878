import { useAppDispatch } from "shared/hooks/shared";
import { feedbackActions } from "shared/store";

export const useSearchMessageByAuthor = () => {
  const dispatch = useAppDispatch();

  const setSearch = (value: string) => {
    dispatch(feedbackActions.setSearchText(value || undefined));
  };

  return { setSearch };
};
