import { useNotification } from "shared/hooks/shared";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { verificationsActions, verificationsSelector } from "shared/store";
import { useDeclineUserMutation } from "shared/api";
import { decOfVerefications } from "shared/lib";

export const useDeclineSelectedVerifications = () => {
  const [declineUser] = useDeclineUserMutation();

  const { show, hide } = useNotification();
  const { open } = useShowConfirmModal();

  const { selectedItems } = useAppSelector(verificationsSelector);
  const dispatch = useAppDispatch();

  const declineSelected = () => {
    open({
      title: `Отклонить ${selectedItems.length} ${decOfVerefications(
        selectedItems.length
      )}?`,
      buttonText: "Отклонить",
      onConfirmCallback: async () => {
        show({
          text: "Заявки отклоняются",
          withLoader: true,
          autoClose: false,
        });
        let promises = selectedItems.map((user) =>
          declineUser({ id: user.id, reply: "Отклонено" }).unwrap()
        );
        let results = await Promise.allSettled(promises);

        if (results.every((promise) => promise.status === "fulfilled")) {
          hide();
          show({ text: "Заявки отклонены!" });
        }
        dispatch(verificationsActions.resetSelectedVerefications());
      },
    });
  };

  return { declineSelected, selectedItemsCount: selectedItems.length };
};
