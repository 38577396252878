import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { promotionActions, promotionSelector } from "shared/store";
import {
  useDeleteAdvertisementMutation,
  useGetAdvertisementsQuery,
} from "shared/api/endpoints/admin/advertisements.api";
import { getFullDateFormat } from "shared/lib";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";

export const usePromotions = () => {
  const { currentPage, promotionsPerPage, searchTerm, created_at } =
    useAppSelector(promotionSelector);

  const { open } = useShowConfirmModal();

  const [deleteAdvertisementById] = useDeleteAdvertisementMutation();

  const advertisementsQuery = useGetAdvertisementsQuery({
    page: currentPage,
    page_size: promotionsPerPage,
    created_at: created_at ? getFullDateFormat(created_at) : undefined,
    search: searchTerm || undefined,
  });

  const dispacth = useAppDispatch();

  const promotions = advertisementsQuery.isSuccess
    ? advertisementsQuery.data.results
    : [];

  useEffect(() => {
    if (advertisementsQuery.isSuccess) {
      dispacth(promotionActions.setQueryData(advertisementsQuery.data));
    }
  }, [advertisementsQuery, dispacth]);

  const deletePromotion = (id: string) => {
    open({
      title: `Удалить баннер?`,
      buttonText: "Удалить",
      onConfirmCallback: async () => {
        deleteAdvertisementById({ id });
      },
    });
  };

  return { promotions, deletePromotion };
};
