import { usePermissions } from "features/permissions";
import { useMemo } from "react";
import { MenuIconProps, MenuIcons } from "shared/ui/Icons";

interface MenuItem {
  path: string;
  title: string;
  icon: (props: MenuIconProps) => JSX.Element;
}

export const useNavbar = () => {
  const { permissions } = usePermissions();

  const links = useMemo(() => {
    const navbar: MenuItem[] = [];

    if (!permissions) {
      return navbar;
    }

    if (permissions.dashboards) {
      navbar.push({
        path: "/dashboards/",
        title: "Главная",
        icon: MenuIcons.Dashboards,
      });
    }

    if (permissions.app_content) {
      navbar.push({
        path: "/app-content/",
        title: "Контент приложения",
        icon: MenuIcons.Content,
      });
    }

    if (permissions.users) {
      navbar.push({
        path: "/users/",
        title: "Управление аккаунтами",
        icon: MenuIcons.Accounts,
      });
    }

    if (permissions.wallet) {
      navbar.push({
        path: "/wallet/",
        title: "Эквайринг и чеки",
        icon: MenuIcons.Wallet,
      });
    }

    if (permissions.feedback) {
      navbar.push({
        path: "/feedback/",
        title: "Обратная связь",
        icon: MenuIcons.Chat,
      });
    }

    if (permissions.complaints) {
      navbar.push({
        path: "/complaints/",
        title: "Жалобы",
        icon: MenuIcons.Complaints,
      });
    }

    if (permissions.calls) {
      navbar.push({
        path: "/calls/",
        title: "Управление записями звонков",
        icon: MenuIcons.Calls,
      });
    }

    if (permissions.publications) {
      navbar.push({
        path: "/publications/",
        title: "Управление публикациями",
        icon: MenuIcons.Posts,
      });
    }

    if (permissions.marketplaces) {
      navbar.push({
        path: "/marketplaces/",
        title: "Управление маркетплейсами",
        icon: MenuIcons.Marketplace,
      });
    }

    navbar.push({
      path: "/payments-links/",
      title: "Управление платежными ссылками",
      icon: MenuIcons.WhiteList,
    });

    navbar.push({
      path: "/reviews-moderation/",
      title: "Модерация отзывов",
      icon: MenuIcons.ChatBubble,
    });

    if (permissions.advertisement) {
      navbar.push({
        path: "/promotion/",
        title: "Продвижение",
        icon: MenuIcons.Promotion,
      });
    }

    return navbar;
  }, [permissions]);

  return { links };
};
