import { Api } from "../..";
import { ILoaderResponse } from "shared/api/dto";

export const loadersApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    loadImage: builder.mutation<ILoaderResponse<"image">, FormData>({
      query: (data) => ({
        url: "api/loader/image/",
        method: "POST",
        body: data,
      }),
    }),
    loadVideo: builder.mutation<ILoaderResponse<"video">, FormData>({
      query: (data) => ({
        url: "api/loader/video/",
        method: "POST",
        body: data,
      }),
    }),
    loadAudio: builder.mutation<ILoaderResponse<"audio">, FormData>({
      query: (data) => ({
        url: "api/loader/audio/",
        method: "POST",
        body: data,
      }),
    }),
    loadFile: builder.mutation<ILoaderResponse<"file">, FormData>({
      query: (data) => ({
        url: "api/loader/file/",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoadImageMutation,
  useLoadAudioMutation,
  useLoadFileMutation,
  useLoadVideoMutation,
} = loadersApi;
