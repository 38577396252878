import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IContentCategory,
  IVerificationsRequirement,
  VerificationType,
} from "shared/types";
import { IContentState } from "shared/store/reducers/types";

const initialState: IContentState = {
  categories: [],
  verifications: {
    flow: { name: VerificationType.flow, description: "" },
    requirements: { name: VerificationType.requirements, description: "" },
  },
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setCategoriesOfUsers: (
      state,
      action: PayloadAction<IContentCategory[]>
    ) => {
      state.categories = action.payload;
    },
    setVerifications: (
      state,
      action: PayloadAction<IVerificationsRequirement[]>
    ) => {
      const [flow] = action.payload.filter(
        (item) => item.name === VerificationType.flow
      );
      const [requirements] = action.payload.filter(
        (item) => item.name === VerificationType.requirements
      );
      if (flow) {
        state.verifications.flow = flow;
      }
      if (requirements) {
        state.verifications.requirements = requirements;
      }
    },
  },
});

export const contentActions = contentSlice.actions;

export default contentSlice.reducer;
