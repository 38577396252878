import { ReactNode, useCallback, useRef } from "react";
import { Id, toast } from "react-toastify";
import { AlertIcon } from "shared/ui/Icons";
import "react-toastify/dist/ReactToastify.css";

interface ShowNotification {
  text: ReactNode;
  timeout?: number;
  autoClose?: boolean;
  withLoader?: boolean;
  isError?: boolean;
}

export const useNotification = () => {
  const toastId = useRef<Id>();

  const hide = () => {
    toast.dismiss(toastId.current);
  };

  const show = useCallback(
    ({
      text,
      timeout = 2000,
      autoClose = true,
      withLoader,
      isError,
    }: ShowNotification) => {
      if (isError) {
        toastId.current = toast(text, {
          icon: <AlertIcon size={32} color="#fff" />,
          className: "notification notificationError",
          bodyClassName: "notification__text",
          autoClose: autoClose ? timeout : false,
        });
      } else if (withLoader) {
        toastId.current = toast(text, {
          icon: <div className={"notification__loader"} />,
          className: "notification",
          bodyClassName: "notification__text",
          autoClose: autoClose ? timeout : false,
        });
      } else {
        toastId.current = toast(text, {
          icon: false,
          className: "notification",
          bodyClassName: "notification__text",
          autoClose: autoClose ? timeout : false,
        });
      }
    },
    []
  );

  return { show, hide };
};
