import { ChangeEvent } from "react";
import { useAppDispatch } from "shared/hooks/shared";
import { complaintsActions } from "shared/store";
import { useDebouncedCallback } from "use-debounce";

export const useSearchComplaintsByAuthor = () => {
  const dispatch = useAppDispatch();

  const setSearchTerm = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(complaintsActions.setSearchTerm(event.target.value));
    },
    500
  );

  return {
    setSearchTerm,
  };
};
