import classNames from "classnames";
import { useState } from "react";
import styles from "./RadioButton.module.scss";

export const RadioButton = () => {
  const [isSelected, setIsSelected] = useState(true);

  const handleSelect = () => {
    setIsSelected((selected) => !selected);
  };

  return (
    <div
      className={classNames(styles.radioButton, {
        [styles.radioButtonSelected]: isSelected,
      })}
      onClick={handleSelect}
    >
      <div />
    </div>
  );
};
