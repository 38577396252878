import { memo, useState } from "react";
import { EditIcon, MinusIcon } from "shared/ui/Icons";
import { MediaLoader } from "shared/ui/MediaLoader";
import { Tooltip } from "shared/ui/Tooltip";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import styles from "./AvatarLoadForm.module.scss";

interface AvatarLoadFormProps {
  image?: string;
  setImageFile: (file: File | FormData | null) => void;
  deleteImageFile?: () => void;
}

export const AvatarLoadForm = memo(
  ({ image, setImageFile, deleteImageFile }: AvatarLoadFormProps) => {
    const [imageUrl, setImageUrl] = useState("");

    const { open } = useShowConfirmModal();

    const handleLoadImageClick = (url: string, file: File | null) => {
      setImageUrl(url);

      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        setImageFile(formData);
      }
    };

    const handleClearImageClick = () => {
      if (imageUrl) {
        setImageUrl("");
      } else if (deleteImageFile && image) {
        open({
          title: `Удалить изображение?`,
          buttonText: "Удалить",
          onConfirmCallback: deleteImageFile,
        });
      }
    };

    return (
      <div className={styles.avatarLoader}>
        <div className={styles.avatarLoader__avatar}>
          {(imageUrl || image) && <img src={imageUrl || image} alt="" />}
        </div>
        <div className={styles.avatarLoader__icons}>
          <div className={styles.avatarLoader__icon}>
            <MediaLoader setImage={handleLoadImageClick}>
              <Tooltip tooltip="Добавить фото">
                <EditIcon color={"#4E51BF"} />
              </Tooltip>
            </MediaLoader>
          </div>
          <div className={styles.avatarLoader__icon}>
            <Tooltip tooltip="Удалить фото">
              <MinusIcon
                color={"#4E51BF"}
                height={20}
                onClick={handleClearImageClick}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
);
