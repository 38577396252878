import {
  useAppSelector,
  useAppDispatch,
  usePagination,
} from "shared/hooks/shared";
import { walletActions, walletSelector } from "shared/store";

export const useWalletPagination = () => {
  const { info, currentPage, applicationsPerPage } =
    useAppSelector(walletSelector);
  const dispatch = useAppDispatch();

  const setPage = (page: number) => {
    dispatch(walletActions.setCurrentPage(page));
  };

  const setItemsPerPage = (itemsCount: number) => {
    dispatch(walletActions.setApplicationsPerPage(itemsCount));
  };

  const pagination = usePagination({
    info,
    setPage,
    currentPage,
    items: applicationsPerPage,
    setItemsPerPage,
  });

  return { pagination };
};
