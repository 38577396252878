import { useAppSelector } from "shared/hooks/shared";
import { useGetUserDataQuery } from "shared/api";
import { getRuLocaleDateString } from "shared/lib";
import { ModalsSelector } from "shared/store";
import { DescriptionBlock } from "shared/ui/DescriptionBlock";
import { Modal } from "shared/ui/Modal";
import styles from "./ProfileModal.module.scss";

export const ProfileModal = () => {
  const { id } = useAppSelector(ModalsSelector.profile);
  const userData = useGetUserDataQuery({ id: id as number }, { skip: !id });

  return (
    <Modal>
      <div className={styles.profileModal}>
        <p className={styles.profileModal__title}>Информация о пользователе</p>
        <div className={styles.profileModal__userInfo}>
          <div className={styles.profileModal__avatar}>
            {userData.data?.avatars[0]?.image && (
              <img
                className={styles.profileModal__avatarImg}
                src={userData.data?.avatars[0]?.image || ""}
                alt=""
              />
            )}
          </div>
          <div className={styles.profileModal__info}>
            <div className={styles.profileModal__login}>
              <DescriptionBlock
                title="Логин"
                content={userData.data?.username || "--"}
              />
            </div>
            <div className={styles.profileModal__personalData}>
              <DescriptionBlock
                title="Имя"
                content={userData.data?.first_name || "--"}
              />
              <DescriptionBlock
                title="Фамилия"
                content={userData.data?.last_name || "--"}
              />
            </div>
          </div>
        </div>
        <div className={styles.profileModal__registrationData}>
          <DescriptionBlock
            title="Тип пользователя"
            content={
              userData.data
                ? userData.data?.is_verified
                  ? "верифицированый"
                  : "не верифицированый"
                : "--"
            }
          />
          <DescriptionBlock
            title="Дата регистрации"
            content={
              userData.data?.created_at
                ? getRuLocaleDateString(userData.data?.created_at)
                : "--"
            }
          />
        </div>
      </div>
    </Modal>
  );
};
