import { memo } from "react";
import { Link } from "react-router-dom";

import { SearchByParamsCount } from "features/posts/search-by-params-count";
import { DeleteSelectedPosts } from "features/posts/delete-selected-posts";
import { HideSelectedPosts } from "features/posts/hide-selected-posts";
import { SearchPostByAuthor } from "features/posts/search-by-author";
import { useShowPostMediaModal } from "features/post-media-files";
import { SeacrhPostsByDate } from "features/posts/search-by-date";
import { PostComments } from "features/posts/open-comments";
import { useDeletePost } from "features/posts/delete-post";
import { SelectPost } from "features/posts/select-post";
import { useHidePost } from "features/posts/hide-post";

import { MinusIcon, DocumentIcon, EyeIcon, EditIcon } from "shared/ui/Icons";
import { Pagination } from "shared/ui/Pagination";
import { Tooltip } from "shared/ui/Tooltip";
import { IPost } from "shared/types";

import { PostsTable, PostsTableRow } from "entities/posts/PostsTable";

import { usePosts, usePostsPagination } from "./model";
import styles from "./posts.module.scss";
import { useStickyMenu } from "shared/hooks/shared/useStickyMenu";
import { TableStickyMenu } from "shared/ui/TableStickyMenu";

interface PostControlsProps {
  post: IPost;
}

const PostControls = memo(({ post }: PostControlsProps) => {
  const { openDocumentModal } = useShowPostMediaModal({ id: post.id });
  const { deletePost } = useDeletePost({ id: post.id });
  const { hidePost } = useHidePost(post);

  return (
    <div className={styles.postOptions}>
      <Tooltip tooltip="Удалить">
        <MinusIcon onClick={deletePost} />
      </Tooltip>
      <Tooltip tooltip={post.is_hidden ? "Показать" : "Скрыть"}>
        <EyeIcon isClose={!post.is_hidden} color="#000" onClick={hidePost} />
      </Tooltip>
      <Tooltip tooltip="Материалы">
        <DocumentIcon onClick={openDocumentModal} />
      </Tooltip>
      <Tooltip tooltip="Редактировать">
        <Link to={`/publications/edit/${post.id}`}>
          <EditIcon />
        </Link>
      </Tooltip>
    </div>
  );
});

export const Posts = () => {
  const { posts, selectedPostsCount, loadingStatus } = usePosts();
  const { pagination } = usePostsPagination();

  const { ref, wrapperRef, size, isStickyMenuVisible } = useStickyMenu();

  return (
    <div className={styles.posts} ref={wrapperRef}>
      <div className={styles.posts__paginationTop}>
        <div className={styles.postsSearchPannel}>
          <SearchPostByAuthor />
          <DeleteSelectedPosts />
          <HideSelectedPosts />
          <SearchByParamsCount />
          <SeacrhPostsByDate />
        </div>
        <Pagination isShort withSelector {...pagination} />
      </div>

      <TableStickyMenu
        isVisible={!!(isStickyMenuVisible && selectedPostsCount > 0)}
        width={size.clientWidth}
        label={`Выбрано публикаций: ${selectedPostsCount}`}
        controls={
          <>
            <DeleteSelectedPosts />
            <HideSelectedPosts />
          </>
        }
      />

      <PostsTable headerRef={ref} dataLoading={loadingStatus.isLoading}>
        {posts &&
          posts.map((post, ind) => (
            <PostsTableRow
              selectOption={<SelectPost post={post} />}
              commentsOption={<PostComments post={post} />}
              controls={<PostControls post={post} />}
              even={!!(ind % 2)}
              data={post}
              key={post.id}
            />
          ))}
      </PostsTable>
      {loadingStatus.isSuccess && <Pagination {...pagination} />}
    </div>
  );
};
