import { Navigate, Route, Routes } from "react-router-dom";
import { Posts } from "./posts";
import * as Layout from "shared/ui/layout";

const basePath = "/publications";

export const PostsLinks = [
  { title: "Публикации", path: `${basePath}/` },
  { title: "Истории", path: `${basePath}/stories` },
];

export const Router = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <Layout.Content
            title={"Управление публикациями"}
            navigation={<Layout.Navigation links={PostsLinks} />}
          />
        }
      >
        <Route path="" element={<Posts />} />
        <Route path="stories" element={<Layout.Placeholder />} />
        <Route path="*" element={<Navigate to="" />} />
      </Route>
    </Routes>
  );
};
