import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import { SearchMessagesByAuthorInput } from "features/feedback/search-by-author";
import { SearchMessageByDate } from "features/feedback/search-by-date";

import { useAppSelector } from "shared/hooks/shared";
import { feedbackSelector } from "shared/store";

import { FeedbackMessage } from "widgets/FeedbackMessage";

import { useFeedbackMessages } from "./model";
import styles from "./feedback.module.scss";
import { Button } from "shared/ui/Buttons";

interface FeedbackPageProps {
  is_replied_list?: boolean;
}

export const FeedbackLinks = [
  { title: "Актуальные", path: `` },
  { title: "Завершенные", path: `replies` },
];

export const FeedbackPage = ({ is_replied_list }: FeedbackPageProps) => {
  const { messages, loadMoreMessages, reply } =
    useFeedbackMessages(is_replied_list);
  const { info } = useAppSelector(feedbackSelector);

  const fetchMore = () => {
    if (info.total > messages.length) {
      loadMoreMessages();
    }
  };

  return (
    <div className={styles.feedback}>
      <div className={styles.feedbackSort}>
        <SearchMessagesByAuthorInput />
        <SearchMessageByDate />
      </div>
      <SimpleBar style={{ maxHeight: "60vh" }} autoHide={false}>
        {messages.map((message) => (
          <div key={message.id}>
            <FeedbackMessage message={message} reply={reply} />
          </div>
        ))}
        {info.total > messages.length && (
          <Button onClick={fetchMore}>Следующие сообщения</Button>
        )}
      </SimpleBar>
    </div>
  );
};
