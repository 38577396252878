import { modalActions, ModalType } from "shared/store";
import { useAppDispatch } from "./reduxToolkitHooks";

export const useModal = () => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(modalActions.closeModal());
  };

  return { closeModal };
};

export const useOpenModal = (type: ModalType) => {
  const dispatch = useAppDispatch();

  const openModal = () => {
    dispatch(modalActions.openModal(type));
  };

  return openModal;
};
