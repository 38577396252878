import { Navigate, Route, Routes } from "react-router-dom";
import {
  Verifications,
  VerificationsFlow,
  VerificationsRequirements,
} from "./verefications";
import { CardBinding, CardBindingBenefit } from "./card-binding";
import { SensitiveData } from "./sensitive-data";
import { UserAgreement } from "./user-agreement";
import { Tags } from "./tags";
import { Onboarding } from "./onboarding";
import { Commission } from "./commission";
import { Warning } from "./warning";
import { Balance } from "./balance";
import { Menu } from "./menu";
import { СurrencyRate } from "./currency-rate";
import { FeedSettings } from "./feed-settings";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Menu />} />

      <Route path="/tags" element={<Tags />} />
      <Route path="/onboarding/" element={<Onboarding />} />
      <Route path="/onboarding/:id" element={<Onboarding />} />
      <Route path="/card-binding/benefit/" element={<CardBindingBenefit />} />
      <Route
        path="/card-binding/benefit/:id"
        element={<CardBindingBenefit />}
      />
      <Route path="/user-agreement" element={<UserAgreement />} />
      <Route path="/sensitive-data" element={<SensitiveData />} />
      <Route path="/verifications" element={<Verifications />} />
      <Route path="/verifications/flow" element={<VerificationsFlow />} />
      <Route
        path="/verifications/requirements"
        element={<VerificationsRequirements />}
      />
      <Route path="/commission" element={<Commission />} />
      <Route path="/warning" element={<Warning />} />
      <Route path="/feed-settings" element={<FeedSettings />} />

      {/* TODO: добавить api, когда появится */}
      <Route path="/balance" element={<Balance />} />
      <Route path="/card-binding" element={<CardBinding />} />
      {/* <Route path='/currency-exchange' element={<СurrencyExchange />} /> */}
      <Route path="/currency-rate" element={<СurrencyRate />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
