import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { useGetPostsListQuery } from "shared/api";
import { getFullDateFormat } from "shared/lib";
import { postsSelector, postsActions } from "shared/store";
import { useIsNewDataFetching } from "shared/hooks/shared";

export const usePosts = () => {
  const dispatch = useAppDispatch();
  const {
    currentPage,
    postsPerPage,
    searchTerm,
    postDate,
    complaintsCount,
    commentsCount,
    selectedPosts,
  } = useAppSelector(postsSelector);

  const postsQuery = useGetPostsListQuery({
    page: currentPage,
    page_size: postsPerPage,
    search: searchTerm || undefined,
    created_at: postDate ? getFullDateFormat(postDate) : undefined,
    comments_count: commentsCount || undefined,
    complaints_count: complaintsCount || undefined,
  });

  const posts = postsQuery.isSuccess ? postsQuery.data.results : null;

  useEffect(() => {
    if (postsQuery.isSuccess) {
      dispatch(postsActions.setQueryData(postsQuery.data));
    }
  }, [postsQuery, dispatch]);

  const loadingStatus = useIsNewDataFetching({
    ...postsQuery,
    params: {
      page: currentPage,
      page_size: postsPerPage,
      search: searchTerm || undefined,
      created_at: postDate ? getFullDateFormat(postDate) : undefined,
      comments_count: commentsCount || undefined,
      complaints_count: complaintsCount || undefined,
    },
    newParams: postsQuery.originalArgs,
  });

  return {
    posts,
    selectedPostsCount: selectedPosts.length,
    loadingStatus,
  };
};
