import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryData, IPost } from "shared/types";

interface IInitialState {
  posts?: IPost[];
  selectedPosts: IPost[];
  info: {
    total: number;
    pages: number;
    is_last: boolean;
  };
  searchTerm: string;
  currentPage: number;
  postsPerPage: number;
  postDate: Date | undefined;
  commentsCount: number;
  complaintsCount: number;
}

const initialState: IInitialState = {
  selectedPosts: [],
  info: {
    total: 0,
    pages: 0,
    is_last: true,
  },
  searchTerm: "",
  currentPage: 1,
  postsPerPage: 50,
  postDate: undefined,
  commentsCount: 0,
  complaintsCount: 0,
};

export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setPosts: (state, action: PayloadAction<IPost[]>) => {
      state.posts = action.payload;
    },
    setQueryData: (state, action: PayloadAction<QueryData<IPost[]>>) => {
      state.info.is_last = action.payload.is_last;
      state.info.pages = action.payload.pages;
      state.info.total = action.payload.total;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setPostsPerPage: (state, action: PayloadAction<number>) => {
      state.postsPerPage = action.payload;
    },
    addSelectedPost: (state, action: PayloadAction<IPost>) => {
      state.selectedPosts.push(action.payload);
    },
    removeSelectedPost: (state, action: PayloadAction<IPost>) => {
      state.selectedPosts = state.selectedPosts.filter(
        (post) => post.id !== action.payload.id
      );
    },
    resetSelectedPosts: (state) => {
      state.selectedPosts = [];
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setPostDate: (state, action: PayloadAction<Date | undefined>) => {
      state.postDate = action.payload;
    },
    setCommentsCount: (state, action: PayloadAction<number>) => {
      state.commentsCount = action.payload;
    },
    setComplaintsCount: (state, action: PayloadAction<number>) => {
      state.complaintsCount = action.payload;
    },
  },
});

export const postsActions = postsSlice.actions;

export default postsSlice.reducer;
