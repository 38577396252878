import { useCallback } from "react";
import { useBanUsersMutation } from "shared/api";
import { usersSelector, usersActions } from "shared/store";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { useNotification } from "shared/hooks/shared";
import { decOfUsers } from "shared/lib";

export const useBanSelectedUsers = () => {
  const dispatch = useAppDispatch();
  const [ban] = useBanUsersMutation();
  const { selectedUsers } = useAppSelector(usersSelector);

  const { open } = useShowConfirmModal();
  const { show, hide } = useNotification();

  const banUsers = useCallback(() => {
    const bannedUsersCount = selectedUsers.filter(
      (user) => !user.is_active
    ).length;
    const activeUsersCount = selectedUsers.filter(
      (user) => user.is_active
    ).length;

    open({
      title: (
        <>
          {activeUsersCount > 0 &&
            `Забанить ${activeUsersCount} ${decOfUsers(activeUsersCount)}?`}
          <br />
          <br />
          {bannedUsersCount > 0 &&
            `Разбанить ${bannedUsersCount} ${decOfUsers(bannedUsersCount)}?`}
        </>
      ),
      buttonText: "Подтвердить",
      onConfirmCallback: () => {
        show({ text: "Бан пользователей", withLoader: true, autoClose: false });
        ban({ users: selectedUsers.map((user) => user.id) })
          .unwrap()
          .then(() => {
            hide();
            show({ text: "Статус пользователей изменен!" });
          });
        dispatch(usersActions.resetSelectedUsers());
      },
    });
  }, [ban, dispatch, hide, open, selectedUsers, show]);

  return {
    selectedUsersCount: selectedUsers.length,
    banUsers,
  };
};
