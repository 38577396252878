import Img from "./assets/binary.svg";
import classNames from "classnames";
import { PlaceholderProps } from "./Placeholder.interface";
import styles from "./Placeholder.module.scss";

export const Placeholder = () => {
  return (
    <div className={styles.placeholder}>
      <img
        src={Img}
        width={140}
        height={140}
        alt=""
        className={styles.placeholder__img}
      />
      <p className={styles.placeholder__text}>
        Work <br />
        in progress
      </p>
    </div>
  );
};

export const PlaceholderWithOptions = ({ text, image }: PlaceholderProps) => {
  return (
    <div className={styles.placeholder}>
      {image && (
        <img
          src={image}
          width={140}
          height={140}
          alt=""
          className={styles.placeholder__img}
        />
      )}
      <p
        className={classNames(
          styles.placeholder__text,
          styles.placeholder__textLight
        )}
      >
        {text}
      </p>
    </div>
  );
};
