import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useDeleteConnectCardBenefitMutation } from "shared/api";

export const useDeleteCardBindingBenefit = () => {
  const [deleteBenefit] = useDeleteConnectCardBenefitMutation();
  const { open } = useShowConfirmModal();

  const deleteCardbindingBenefit = (id: number) => {
    deleteBenefit({ id });
  };

  const deleteWithConfirm = (title: string, id: number) => {
    open({
      title: `Удалить ${title}?`,
      buttonText: "Удалить",
      onConfirmCallback: () => {
        deleteCardbindingBenefit(id);
      },
    });
  };

  return {
    deleteCardbindingBenefit: deleteWithConfirm,
  };
};
