import { useEffect, useState } from "react";
import { Button } from "shared/ui/Buttons";
import { Input, InputPassword } from "shared/ui/Inputs";
import { Modal } from "shared/ui/Modal";
import { useAddAdmin } from "features/admins/add-new-admin/model";
import { useModal } from "shared/hooks/shared";
import styles from "./AddAdminModal.module.scss";

export const AddAdminModal = () => {
  const { closeModal } = useModal();

  const { addNewAdmin, isSuccess, errors } = useAddAdmin();
  const [passwordError, setPasswordError] = useState("");

  const [data, setData] = useState({
    email: "",
    username: "",
    password: "",
    confirm_password: "",
    first_name: "",
    last_name: "",
  });

  const submitButtonEnadled = Object.keys(data).every(
    (key) => data[key as keyof typeof data]
  );

  const handleSaveUser = () => {
    if (data.confirm_password !== data.password) {
      setPasswordError("Пароли не совпадают");
    } else {
      setPasswordError("");
      addNewAdmin(data);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [closeModal, isSuccess]);

  return (
    <Modal>
      <div className={styles.addAdminModal}>
        <p className={styles.addAdminModal__title}>Добавление администратора</p>
        <div className={styles.addAdminModal__inputs}>
          <div>
            <Input
              placeholder="Имя"
              value={data.first_name}
              onChange={(event) => {
                setData((data) => ({
                  ...data,
                  first_name: event.target.value,
                }));
              }}
              className={styles.addAdminModal__inputsInput}
            />
          </div>
          <div>
            <Input
              placeholder="Фамилия"
              value={data.last_name}
              onChange={(event) => {
                setData((data) => ({ ...data, last_name: event.target.value }));
              }}
              className={styles.addAdminModal__inputsInput}
            />
          </div>
          <div>
            <Input
              placeholder="Логин пользователя"
              value={data.username}
              onChange={(event) => {
                setData((data) => ({ ...data, username: event.target.value }));
              }}
              className={styles.addAdminModal__inputsInput}
            />
            {errors && (
              <p className={styles.addAdminModal__error}>{errors.username}</p>
            )}
          </div>
          <div>
            <Input
              placeholder="Электронная почта"
              value={data.email}
              onChange={(event) => {
                setData((data) => ({ ...data, email: event.target.value }));
              }}
              className={styles.addAdminModal__inputsInput}
            />
            {errors && (
              <p className={styles.addAdminModal__error}>{errors.email}</p>
            )}
          </div>
          <div>
            <InputPassword
              placeholder="Пароль"
              value={data.password}
              onChange={(event) => {
                setData((data) => ({ ...data, password: event.target.value }));
              }}
              className={styles.addAdminModal__inputsInputPassword}
            />
            {passwordError && (
              <p className={styles.addAdminModal__error}>{passwordError}</p>
            )}
            {errors && (
              <p className={styles.addAdminModal__error}>{errors.password}</p>
            )}
            {errors && (
              <p className={styles.addAdminModal__error}>
                {errors.non_field_errors}
              </p>
            )}
          </div>
          <div>
            <InputPassword
              placeholder="Повторить пароль"
              value={data.confirm_password}
              onChange={(event) => {
                setData((data) => ({
                  ...data,
                  confirm_password: event.target.value,
                }));
              }}
              className={styles.addAdminModal__inputsInputPassword}
            />
          </div>
        </div>
        <Button
          disabled={!submitButtonEnadled}
          type="solid"
          onClick={handleSaveUser}
        >
          Создать
        </Button>
      </div>
    </Modal>
  );
};
