import { PropsWithChildren, Ref } from "react";
import { Table, TableHeader, TableBody, TableData } from "shared/ui/Table";

interface UsersVereficationTableProps extends PropsWithChildren {
  headerRef?: Ref<HTMLTableCellElement>;
  dataLoading?: boolean;
}

export const UsersVerificationTable = ({
  children,
  headerRef,
  dataLoading,
}: UsersVereficationTableProps) => {
  return (
    <Table withLoader={dataLoading}>
      <TableHeader>
        <tr>
          <TableData />
          <TableData ref={headerRef}>ID</TableData>
          <TableData>логин</TableData>
          <TableData>электронная почта</TableData>
          <TableData>номер телефона</TableData>
          <TableData>дата регистрации</TableData>
          <TableData width={370} />
        </tr>
      </TableHeader>
      {dataLoading ? null : <TableBody>{children}</TableBody>}
    </Table>
  );
};
