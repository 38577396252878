import { memo } from "react";
import styles from "./FeedbackAnswer.module.scss";

interface FeedbackAnswerProps {
  description: string;
}

export const FeedbackAnswer = memo(({ description }: FeedbackAnswerProps) => {
  return (
    <div className={styles.feedbackMessage__answer}>
      <span>Ответ: </span>
      {description}
    </div>
  );
});
