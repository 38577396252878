import { Api, tags } from "../..";
import { ID, IUserFullInfo } from "shared/types";
import {
  IBanUsersRequestDto,
  ICreateUserRequestDto,
  IEditUserProfileRequestDto,
  IUsersRequestDto,
  IUsersResponseDto,
} from "shared/api/dto";

const basePath = "api/admin-users/";
const banUsersPath = basePath + "ban/";

const pathWithId = (id: number | string) => `${basePath}${id}/`;

export const usersApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<IUsersResponseDto, IUsersRequestDto>({
      query: (data) => ({ url: basePath, params: data }),
      providesTags: [tags.users],
    }),
    getUserData: builder.query<IUserFullInfo, ID>({
      query: (data) => pathWithId(data.id),
      providesTags: [tags.userInfo],
    }),
    addNewUser: builder.mutation<void, ICreateUserRequestDto>({
      query: (data) => ({ url: basePath, method: "POST", body: data }),
      invalidatesTags: [tags.users],
    }),
    editUserInfo: builder.mutation<
      void,
      { id: string; data: IEditUserProfileRequestDto }
    >({
      query: (data) => ({
        url: pathWithId(data.id),
        method: "PATCH",
        body: data.data,
      }),
      invalidatesTags: [tags.userInfo, tags.users],
    }),
    deleteUser: builder.mutation<void, ID>({
      query: (data) => ({
        url: pathWithId(data.id),
        method: "DELETE",
      }),
      invalidatesTags: [tags.users],
    }),
    banUsers: builder.mutation<void, IBanUsersRequestDto>({
      query: (data) => ({
        url: banUsersPath,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [tags.users],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserDataQuery,
  useAddNewUserMutation,
  useEditUserInfoMutation,
  useDeleteUserMutation,
  useBanUsersMutation,
} = usersApi;
