import { ReactNode } from "react";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { IConfirmModalData } from "shared/store";

interface ConfirmModalProps extends IConfirmModalData {
  children?: ReactNode;
}

export const WithConfirmModal = ({ children, ...props }: ConfirmModalProps) => {
  const { open } = useShowConfirmModal();

  const handleOpenModal = () => {
    open(props);
  };

  return <div onClick={handleOpenModal}>{children}</div>;
};
