import { useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { GraphCardProps } from "shared/ui/Graphs/GraphCard";
import { BarsGraph } from "shared/ui/Graphs/BarsGraph/BarsGraph";
import { LineGraph } from "shared/ui/Graphs/LineGraph";
import styles from "./GraphCard.module.scss";

const GraphErrorFallback = () => {
  return (
    <div className={styles.graphErrorFallback}>
      <p>Неккоректный формат данных, невозможно построить график</p>
    </div>
  );
};

export const GraphCard = ({
  title,
  type,
  color,
  data,
  formatter,
}: GraphCardProps) => {
  const graph = useMemo(() => {
    switch (type) {
      case "bars":
        return (
          <BarsGraph
            color={color}
            data={data}
            formatter={data ? formatter : undefined}
          />
        );
      case "line":
      default:
        return (
          <LineGraph
            color={color}
            data={data}
            formatter={data ? formatter : undefined}
          />
        );
    }
  }, [type, color, data, formatter]);

  return (
    <div className={styles.graphCard}>
      <p className={styles.graphCard__title}>{title}</p>
      <ErrorBoundary FallbackComponent={GraphErrorFallback}>
        <div
          className={styles.graphCard__graph}
          style={{ width: "100%", height: 300 }}
        >
          {graph}
        </div>
      </ErrorBoundary>
    </div>
  );
};
