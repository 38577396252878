import { MinusIcon } from "shared/ui/Icons";
import { Tooltip } from "shared/ui/Tooltip";
import styles from "./style.module.scss";

interface DeleteBannerButtonProps {
  deleteHandler?: () => void;
}

export const DeleteBannerButton = ({
  deleteHandler,
}: DeleteBannerButtonProps) => {
  return (
    <Tooltip tooltip="Удалить баннер">
      <div className={styles.deleteBannerButton}>
        <MinusIcon
          size={24}
          color={"#4E51BF"}
          className={styles.deleteBannerButton__icon}
          onClick={deleteHandler}
        />
      </div>
    </Tooltip>
  );
};
