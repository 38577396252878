import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const MinusIcon = ({
  height,
  width,
  size = DEFAULT_ICON_SIZE,
  className,
  color = "black",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="4 8 12 12"
      width={width || size}
      height={height || size}
      className={className}
      onClick={onClick}
    >
      <g>
        <path
          d="M16.4062 13.65H4.59375"
          stroke={color}
          strokeWidth="1.8375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
