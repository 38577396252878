import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./react-date-picker.css";

import { usePopper } from "react-popper";
import { createPortal } from "react-dom";
import { memo, useState } from "react";
import classNames from "classnames";
import DatePicker from "react-date-picker";
import { Value } from "react-date-picker/dist/cjs/shared/types";
import { CalendarIcon } from "shared/ui/Icons";
import { CalendarProps } from "./calendar.interface";
import styles from "./calendar.module.scss";

export const Calendar = memo((props: CalendarProps) => {
  const {
    value,
    setDate,
    withClearIcon = false,
    placeholderText,
    className,
    calendarIcon,
  } = props;

  // в react-date-picker нет функционала для общего placeholder-a, поэтому
  // применяем placeholder для дней и убираем для месяца и года
  const dateFormat = "dd.MM.y";
  const defaultPlaceholder = "--";

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: "bottom-start",
      modifiers: [
        {
          name: "flip",
          options: {
            fallbackPlacements: ["top-start"],
          },
        },
      ],
    }
  );

  const updateDate = (value: Value) => {
    if (value instanceof Date) {
      setDate(value);
    } else {
      setDate(null as any);
    }
  };

  return (
    <>
      <div ref={setReferenceElement}>
        <DatePicker
          portalContainer={popperElement}
          onChange={updateDate}
          value={value || null}
          clearIcon={withClearIcon ? undefined : null}
          calendarIcon={calendarIcon || <CalendarIcon width={22} height={22} />}
          format={placeholderText && !value ? "dd" : dateFormat}
          locale="ru-RU"
          className={classNames(styles.calendar, {
            [className as string]: className,
          })}
          calendarClassName={"react-calendar-custom"}
          dayPlaceholder={placeholderText || defaultPlaceholder}
          monthPlaceholder={placeholderText ? "" : defaultPlaceholder}
          yearPlaceholder={placeholderText ? "" : defaultPlaceholder}
        />
      </div>

      {createPortal(
        <div
          ref={setPopperElement}
          style={{ ...popperStyles.popper, zIndex: 10000, minHeight: 320 }}
          {...attributes.popper}
        />,
        document.body
      )}
    </>
  );
});
