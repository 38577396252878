import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { Api } from "shared/api";
import userReducer from "./reducers/userReducer";
import contentReducer from "./reducers/contentReducer";
import modalReducer from "./reducers/modalReducer";
import usersReducer from "./reducers/usersReducer";
import verificationsReducer from "./reducers/verificationsReducer";
import adminsReducer from "./reducers/adminsReducer";
import feedbackReducer from "./reducers/feedbackReducer";
import complaintsReducer from "./reducers/complaintsReducer";
import postsReducer from "./reducers/postsReducer";
import walletReducer from "./reducers/walletReducer";
import promotionReducer from "./reducers/promotionReducer";
import marketplacesReducer from "./reducers/marketplacesReducer";
import confirmModalReducer from "./reducers/confirmModalReducer";
import notificationReducer from "./reducers/notificationReducer";
import callsReducer from "./reducers/callsReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    users: usersReducer,
    verifications: verificationsReducer,
    content: contentReducer,
    feedback: feedbackReducer,
    complaints: complaintsReducer,
    posts: postsReducer,
    modal: modalReducer,
    admins: adminsReducer,
    wallet: walletReducer,
    promotion: promotionReducer,
    marketplaces: marketplacesReducer,
    confirmModal: confirmModalReducer,
    notification: notificationReducer,
    calls: callsReducer,
    [Api.reducerPath]: Api.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      Api.middleware
    );
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
