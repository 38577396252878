import { Outlet } from "react-router-dom";
import { GoBackButton } from "shared/ui/Buttons";
import { ContentLayoutProps } from "./Content.interface";
import styles from "./Content.module.scss";

export const Content = ({
  children,
  navigation,
  title,
}: ContentLayoutProps) => {
  return (
    <main className={styles.contentLayout}>
      <header className={styles.contentLayout__header}>
        <div className={styles.contentLayout__goBackButton}>
          <GoBackButton />
        </div>
        {title && <h1 className={styles.contentLayout__title}>{title}</h1>}
        {navigation && (
          <div className={styles.contentLayout__title}>{navigation}</div>
        )}
      </header>
      <div>{children || <Outlet />}</div>
    </main>
  );
};
