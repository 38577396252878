import { useGetCommentsQuery } from "shared/api/endpoints/common/comments.api";
import { IPostComment } from "shared/types";

interface useNestedCommentsProps extends IPostComment {}

export const useNestedComments = ({
  id,
  has_comments,
}: useNestedCommentsProps) => {
  const commentsQuery = useGetCommentsQuery({ id }, { skip: !has_comments });

  return {
    nestedComments: commentsQuery.isSuccess ? commentsQuery.data.results : [],
  };
};
