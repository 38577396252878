import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";
import DowloadPngIcon from "./assets/download-icon.png";
import DowloadWhitePngIcon from "./assets/download-white.png";

interface DownloadIconProps extends SVGIconProps {
  isWhite?: boolean;
}

export const DownloadIconPng = ({
  onClick,
  isWhite = false,
}: DownloadIconProps) => {
  return !isWhite ? (
    <img
      onClick={onClick}
      alt=""
      src={DowloadPngIcon}
      height={42}
      width={32}
      style={{ objectFit: "cover" }}
    />
  ) : (
    <img
      onClick={onClick}
      alt=""
      src={DowloadWhitePngIcon}
      height={42}
      width={32}
      style={{ objectFit: "cover" }}
    />
  );
};

export const DownloadIcon = ({
  height,
  width,
  size = DEFAULT_ICON_SIZE,
  className,
  color = "#4E51BF",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 46 46"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <path
        d="M30.1875 15.8123H33.7812C34.7344 15.8123 35.6485 16.1909 36.3224 16.8648C36.9964 17.5388 37.375 18.4529 37.375 19.406V38.0935C37.375 39.0466 36.9964 39.9607 36.3224 40.6347C35.6485 41.3086 34.7344 41.6873 33.7812 41.6873H12.2188C11.2656 41.6873 10.3515 41.3086 9.67759 40.6347C9.00363 39.9607 8.625 39.0466 8.625 38.0935V19.406C8.625 18.4529 9.00363 17.5388 9.67759 16.8648C10.3515 16.1909 11.2656 15.8123 12.2188 15.8123H15.8125"
        stroke={color}
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8125 24.4373L23 31.6248L30.1875 24.4373H23H15.8125Z"
        fill="#4E51BF"
      />
      <path
        d="M23 4.31226V24.4373M23 30.1873V24.4373M23 24.4373H30.1875L23 31.6248L15.8125 24.4373H23Z"
        stroke={color}
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
