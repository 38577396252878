import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { useModal } from "shared/hooks/shared";
import { Button } from "shared/ui/Buttons";
import { Input } from "shared/ui/Inputs";
import { Modal } from "shared/ui/Modal";
import { useAddNewWhitelistedDomainMutation } from "shared/api/endpoints/admin/whitelisted-domains.api";

import { useAddNewPaymentLinkModal } from "../model/useAddNewPaymentLinkModal";
import styles from "./AddNewPaymentLinkModal.module.scss";

interface FormState {
  domain: string;
}

export const AddNewPaymentLinkModal = () => {
  const { closeAddNewPaymentLinkModal } = useAddNewPaymentLinkModal();
  const [add] = useAddNewWhitelistedDomainMutation();
  const { closeModal } = useModal();

  const { control, handleSubmit, setError } = useForm<FormState>({
    defaultValues: { domain: "" },
  });

  const onSubmit: SubmitHandler<FormState> = async (data) => {
    try {
      await add(data).unwrap();
      closeAddNewPaymentLinkModal();
    } catch (err: any) {
      const errorMessage = err?.data?.errors?.domain || err?.data?.detail;
      setError("domain", { message: errorMessage || "Ошибка валидации" });
    }
  };

  return (
    <Modal onClose={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.modal}>
        <p className={styles.modal__title}>Добавление новой платежной ссылки</p>
        <div className={styles.modal__form}>
          <Controller
            control={control}
            name="domain"
            render={({ field, fieldState }) => (
              <div className={styles.modal__formField}>
                <Input
                  placeholder="URL"
                  value={field.value}
                  onChange={field.onChange}
                  className={styles.modal__formInput}
                />
                {fieldState.error && (
                  <p className={styles.modal__error}>
                    {fieldState.error.message}
                  </p>
                )}
              </div>
            )}
          />
          <Button htmlType="submit" type="solid">
            Создать
          </Button>
        </div>
      </form>
    </Modal>
  );
};
