import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConfirmModalData } from "./types";

interface IModalState {
  isOpen: boolean;
  data: IConfirmModalData;
}

const initialState: IModalState = {
  isOpen: false,
  data: {},
};

export const confirmModalSlice = createSlice({
  name: "confirmModal",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.isOpen = false;
    },
    openModal: (state) => {
      state.isOpen = true;
    },
    setData: (state, action: PayloadAction<IConfirmModalData>) => {
      state.data = action.payload;
    },
    resetData: (state) => {
      state.data = {};
    },
  },
});

export const confirmModalActions = confirmModalSlice.actions;

export default confirmModalSlice.reducer;
