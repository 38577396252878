import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { walletSelector, walletActions } from "shared/store";

export const useSearchActiveApplications = () => {
  const dispatch = useAppDispatch();
  const { isActiveApplications } = useAppSelector(walletSelector);

  const showActive = () => {
    dispatch(walletActions.setApplicationsSatus(true));
  };

  const showInactive = () => {
    dispatch(walletActions.setApplicationsSatus(false));
  };

  return {
    showActive,
    showInactive,
    isActive: isActiveApplications,
  };
};
