import { Button } from "shared/ui/Buttons";
import { Modal } from "shared/ui/Modal";
import { useConfirmModal } from "entities/Modals/ConfirmModal";
import styles from "./ConfirmModal.module.scss";

export const ConfirmModal = () => {
  const { title, buttonText, onConfirmCallback, closeModal } =
    useConfirmModal();

  const handleConfirm = () => {
    onConfirmCallback && onConfirmCallback();
    closeModal();
  };

  return (
    <Modal onClose={closeModal}>
      <div className={styles.confirmModal}>
        <div className={styles.confirmModal__title}>{title}</div>
        <Button
          type="solid"
          onClick={handleConfirm}
          className={styles.confirmModal__button}
        >
          {buttonText}
        </Button>
      </div>
    </Modal>
  );
};
