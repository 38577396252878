import { ChangeEvent } from "react";
import { verificationsActions } from "shared/store";
import { useAppSelector, useAppDispatch } from "shared/hooks/shared";

export const useSearchByAuthor = () => {
  const dispacth = useAppDispatch();
  const { applicationDate } = useAppSelector((state) => state.verifications);

  const setSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    dispacth(verificationsActions.setSearchTerm(event.target.value));
  };

  return { applicationDate, setSearchValue };
};
