import { useCallback } from "react";
import { IUser } from "shared/types";
import { modalActions, ModalType } from "shared/store";
import { useAppDispatch } from "shared/hooks/shared";

export const useShowUserInfo = (user: IUser) => {
  const dispatch = useAppDispatch();

  const openUserDataModal = useCallback(() => {
    dispatch(modalActions.openModal(ModalType.profileModal));
    dispatch(modalActions.setProfileData(user.id));
  }, [dispatch, user.id]);

  return { openUserDataModal };
};
