import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { callsActions } from "shared/store/reducers/callsReducer";

export const useSearchCallByDate = () => {
  const dispatch = useAppDispatch();

  const { searchParams } = useAppSelector((state) => state.calls);

  const setDate = (date: Date) => {
    dispatch(callsActions.setCallDate(date));
  };

  return {
    callDate: searchParams.callDate,
    setDate,
  };
};
