import { StatsCard } from "entities/StatsCard";
import { GraphCard } from "shared/ui/Graphs/GraphCard";
import { ScrollBar } from "shared/ui/ScrollBar";
import { PeriodSelector } from "widgets/PeriodSelector";
import { useFinancesStats } from "./model";
import styles from "./FinancesStats.module.scss";
import { getFormatter } from "../formaters";

export const FinanceStatistics = () => {
  const { data, selectPeriod, period } = useFinancesStats();

  return (
    <ScrollBar height={"calc(100vh - 320px)"}>
      <div className={styles.financeStatistics}>
        <PeriodSelector selectPeriod={selectPeriod} />
        {data && (
          <div className={styles.financeStatistics__charts}>
            <p className={styles.financeStatistics__title}>
              Бюджет доходов и расходов
            </p>

            <div className={styles.financeStatistics__list}>
              <StatsCard
                title={"Общая сумма пополнений"}
                value={data?.simple_stat?.total_refill || ""}
                color={"pink"}
                width={"100%"}
                type={"currency"}
              />
              <StatsCard
                title={"Заработано пользователями за звонки"}
                value={data?.simple_stat?.total_call_users_earnings || ""}
                color={"blue"}
                width={"100%"}
                type={"currency"}
              />
              <GraphCard
                title="Чистая прибыль"
                color="#D9DAFF"
                type="bars"
                data={data?.rows_stat?.average_refill}
                formatter={getFormatter(period)}
              />
              <GraphCard
                title="Прибыль с рекламных баннеров"
                color="#FFCFDB"
                type="bars"
                data={data?.rows_stat?.profit_from_ads}
                formatter={getFormatter(period)}
              />
              <GraphCard
                title="Среднее разовое пополнение"
                color="#FE85A2"
                data={data?.rows_stat?.average_call_user_earning}
                formatter={getFormatter(period)}
              />
              <GraphCard
                title="Средний заработок за звонок"
                color="#4E51BF"
                data={data?.rows_stat?.profit_from_calls}
                formatter={getFormatter(period)}
              />
            </div>
          </div>
        )}
      </div>
    </ScrollBar>
  );
};
