import { useDebouncedCallback } from "use-debounce";
import { Input } from "shared/ui/Inputs";
import { useSearchByParamsCount } from "../model";
import styles from "./SearchByParamsCount.module.scss";

export const SearchByParamsCount = () => {
  const { sortByCommentsCount, sortByComplaintsCount } =
    useSearchByParamsCount();

  const debouncedComplaintsCount = useDebouncedCallback((value: number) => {
    sortByComplaintsCount(value);
  }, 250);

  const debouncedCommentsCount = useDebouncedCallback((value: number) => {
    sortByCommentsCount(value);
  }, 250);

  return (
    <div className={styles.ranges}>
      <div className={styles.ranges__range}>
        <span>кол-во жалоб</span>
        <Input
          className={styles.ranges__input}
          prefix={"oт:"}
          defaultValue="0"
          type="number"
          min="0"
          onChange={(e) => debouncedComplaintsCount(Number(e.target.value))}
        />
      </div>
      <div className={styles.ranges__range}>
        <span>кол-во комментариев</span>
        <Input
          className={styles.ranges__input}
          prefix={"oт:"}
          defaultValue="0"
          type="number"
          min="0"
          onChange={(e) => debouncedCommentsCount(Number(e.target.value))}
        />
      </div>
    </div>
  );
};
