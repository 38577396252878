import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const LongArrow = ({
  height,
  width,
  className,
  size = DEFAULT_ICON_SIZE,
  color = "#4E51BF",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 9"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <path
        d="M24.3645 4.85355C24.5598 4.65829 24.5598 4.34171 24.3645 4.14645L21.1826 0.964466C20.9873 0.769204 20.6707 0.769204 20.4755 0.964466C20.2802 1.15973 20.2802 1.47631 20.4755 1.67157L23.3039 4.5L20.4755 7.32843C20.2802 7.52369 20.2802 7.84027 20.4755 8.03553C20.6707 8.2308 20.9873 8.2308 21.1826 8.03553L24.3645 4.85355ZM0.989258 5H24.011V4H0.989258V5Z"
        fill={color}
      />
    </svg>
  );
};
