import classNames from "classnames";
import { useEffect, useState } from "react";
import { SelectorProps } from "./selector.interface";
import styles from "./selector.module.scss";

export const Selector = <T,>({
  options,
  setFunc,
  buttonClassname,
  bodyClassname,
  withBorder = false,
}: SelectorProps<T>) => {
  const [active, setActive] = useState(-1);

  const handleClick = (value: T, index: number) => {
    setActive(index);
    setFunc(value);
  };

  useEffect(() => {
    if (active === -1) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].isDefaultOption) {
          setActive(i);
        }
      }
    }
  }, [options, active]);

  return (
    <div
      className={classNames({
        [styles.select]: !bodyClassname,
        [bodyClassname as string]: bodyClassname,
        [styles.selectWithBorder]: withBorder,
      })}
    >
      {options &&
        options.map((option, ind) => (
          <div
            key={ind}
            className={classNames({
              [styles.select__button]: !buttonClassname,
              [buttonClassname as string]: buttonClassname,
            })}
            data-active={ind === active}
            onClick={() => {
              handleClick(option.value, ind);
            }}
          >
            {option.title}
          </div>
        ))}
    </div>
  );
};
