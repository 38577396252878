import { useMemo, useState } from "react";
import { useGetFinancesQuery } from "shared/api";
import {
  daysDiff,
  getCurrentDateInterval,
  getFullDateFormat,
} from "shared/lib";
import { IDashboardFinances } from "shared/types";

export type FinancesPeriod =
  | "week"
  | "month"
  | "year"
  | "custom"
  | "custom-week"
  | "custom-year";

export const useFinancesStats = () => {
  const dates = useMemo(() => getCurrentDateInterval(), []);

  const weekData = useGetFinancesQuery(
    { style: "DAY", start: dates.week, finish: dates.now },
    {
      selectFromResult: ({ data }) => data ?? ({} as IDashboardFinances),
    }
  );

  const monthData = useGetFinancesQuery(
    { style: "WEEK", start: dates.month, finish: dates.now },
    {
      selectFromResult: ({ data }) => data ?? ({} as IDashboardFinances),
    }
  );

  const yearData = useGetFinancesQuery(
    { style: "MONTH", start: dates.year, finish: dates.now },
    {
      selectFromResult: ({ data }) => data ?? ({} as IDashboardFinances),
    }
  );

  const [period, setPeriod] = useState<FinancesPeriod>("year");
  const [customPeriod, setCustomPeriod] = useState<Record<string, Date | null>>(
    {
      start: null,
      end: null,
    }
  );

  const customData = useGetFinancesQuery(
    {
      style:
        period === "custom"
          ? "DAY"
          : period === "custom-week"
          ? "WEEK"
          : "MONTH",
      start: getFullDateFormat(customPeriod.start || ""),
      finish: getFullDateFormat(customPeriod.end || ""),
    },
    {
      skip: !customPeriod.start || !customPeriod.end,
      selectFromResult: ({ data }) => data ?? ({} as IDashboardFinances),
    }
  );

  const selectPeriod = (
    period: FinancesPeriod,
    custom?: { start: Date | null; end: Date | null }
  ) => {
    if (period === "custom" && custom) {
      const diff = daysDiff(customPeriod.start, customPeriod.end);
      if (diff > 30) {
        setPeriod("custom-year");
      } else if (diff > 7) {
        setPeriod("custom-week");
      }
      setCustomPeriod(custom);
    } else {
      setPeriod(period);
    }
  };

  const data = useMemo(() => {
    switch (period) {
      case "week":
        return weekData;
      case "month":
        return monthData;
      case "year":
        return yearData;
      case "custom":
      case "custom-year":
      case "custom-week":
        return customData;
      default:
        return yearData;
    }
  }, [customData, monthData, period, weekData, yearData]);

  return { data: data as IDashboardFinances, selectPeriod, period };
};
