import { useAgreements } from "shared/hooks/core";
import { AgreementType } from "shared/types";
import { HtmlEditor } from "shared/ui/HtmlEditor";
import * as Layout from "shared/ui/layout";

export const SensitiveData = () => {
  const { sensitiveData, setAgreements } = useAgreements();

  const handleSaveClick = (text: string) => {
    if (text !== sensitiveData.description) {
      setAgreements({ type: AgreementType.sensitiveData, description: text });
    }
  };

  return (
    <Layout.Content title="Редактирование политики обработки конфиденциальных данных">
      <HtmlEditor
        description={sensitiveData.description || ""}
        saveText={handleSaveClick}
      />
    </Layout.Content>
  );
};
