import { Routes, Route, Navigate } from "react-router-dom";
import { AdminProfile } from "./admin";
import { UserProfile } from "./user";

export const Router = () => {
  return (
    <Routes>
      <Route path="user/:id" element={<UserProfile />} />
      <Route path="admin/:id" element={<AdminProfile />} />

      <Route path="*" element={<Navigate to="/dashboards/" replace />} />
    </Routes>
  );
};
