import { SpinnerProps } from "./Spinner.interface";
import styles from "./Spinner.module.scss";

export const Spinner = ({ fullscreen }: SpinnerProps) => {
  return (
    <div className={fullscreen ? styles.wrapper : ""}>
      <div className={styles.spinner}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
