import { Button } from "shared/ui/Buttons";
import { Modal } from "shared/ui/Modal";
import { DescriptionBlock } from "shared/ui/DescriptionBlock";
import { useAppSelector, useModal } from "shared/hooks/shared";
import { useGetUserVerificationInfoQuery } from "shared/api";
import { ModalsSelector } from "shared/store";
import { useApproveVerification } from "features/users-verification/approve";
import { useDeclineVerification } from "features/users-verification/decline";
import styles from "./VerificationModal.module.scss";

export const VerificationModal = () => {
  const { id } = useAppSelector(ModalsSelector.verification);
  const userInfo = useGetUserVerificationInfoQuery(
    { id: id as number },
    { skip: !id }
  );
  const { approve } = useApproveVerification({ id: Number(id) });
  const { decline } = useDeclineVerification({ id: Number(id) });

  const { closeModal } = useModal();

  const approveClick = () => {
    approve(() => {
      setTimeout(() => {
        closeModal();
      }, 250);
    });
  };

  const declineClick = () => {
    decline(() => {
      setTimeout(() => {
        closeModal();
      }, 250);
    });
  };

  return (
    <Modal>
      <div className={styles.verificationModal}>
        <p className={styles.verificationModal__title}>
          Информация о пользователе
        </p>
        {userInfo.isSuccess && (
          <div className={styles.verificationModal__body}>
            <div className={styles.verificationModal__info}>
              <DescriptionBlock
                title="ФИО"
                content={userInfo.data?.full_name || ""}
              />
              <DescriptionBlock
                title="удобный способ для связи"
                content={userInfo.data?.contact || ""}
              />
              <DescriptionBlock
                title="логин"
                content={userInfo.data?.user.username}
              />
            </div>
            <div className={styles.verificationModal__controls}>
              <Button
                type="solid"
                className={styles.verificationModal__button}
                onClick={approveClick}
              >
                принять
              </Button>
              <Button
                className={styles.verificationModal__button}
                onClick={declineClick}
              >
                отклонить
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
