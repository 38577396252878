import { DeleteButton } from "shared/ui/Buttons";
import { useDeleteSelectedPosts } from "../model";

export const DeleteSelectedPosts = () => {
  const { deleteSelectedPosts, selectedPostsCount } = useDeleteSelectedPosts();
  return (
    <DeleteButton onClick={deleteSelectedPosts} disabled={!selectedPostsCount}>
      Удалить
    </DeleteButton>
  );
};
