import { useEffect, useState } from "react";
import { useAppDispatch, useModal } from "shared/hooks/shared";
import { useAddNewUserMutation } from "shared/api";
import { modalActions } from "shared/store";
import { Button } from "shared/ui/Buttons";
import { Input, InputPassword } from "shared/ui/Inputs";
import { Modal } from "shared/ui/Modal";
import styles from "./AddUserModal.module.scss";

export const AddUserModal = () => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();

  const [addNewUser, result] = useAddNewUserMutation();
  const [data, setData] = useState({
    email: "",
    username: "",
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    username: "",
    non_field_errors: "",
    password: "",
    confirm_password: "",
  });

  const handleSaveUser = () => {
    if (data.confirm_password !== data.password) {
      setErrors((errors) => ({
        ...errors,
        confirm_password: "Пароли не совпадают",
      }));
    } else {
      addNewUser(data);
    }
  };

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(modalActions.closeModal());
    }
    if (result.isError) {
      const errors = result.error as {
        data: {
          errors: {
            non_field_errors: string;
            email: string;
            username: string;
            password: string;
            confirm_password: string;
          };
        };
      };

      setErrors(errors.data.errors);
    }
  }, [result, dispatch]);

  return (
    <Modal onClose={closeModal}>
      <div className={styles.addUserModal}>
        <p className={styles.addUserModal__title}>Добавление пользователя</p>
        <div className={styles.addUserModal__inputs}>
          <div>
            <Input
              placeholder="Логин пользователя"
              value={data.username}
              onChange={(event) => {
                setData((data) => ({ ...data, username: event.target.value }));
              }}
              className={styles.addUserModal__inputsInput}
            />
            {errors && (
              <p className={styles.addUserModal__error}>{errors.username}</p>
            )}
          </div>
          <div>
            <Input
              placeholder="Электронная почта"
              value={data.email}
              onChange={(event) => {
                setData((data) => ({ ...data, email: event.target.value }));
              }}
              className={styles.addUserModal__inputsInput}
            />
            {errors && (
              <p className={styles.addUserModal__error}>{errors.email}</p>
            )}
          </div>
          <InputPassword
            placeholder="Пароль"
            value={data.password}
            onChange={(event) => {
              setData((data) => ({ ...data, password: event.target.value }));
            }}
            className={styles.addUserModal__inputsInputPassword}
          />
          <InputPassword
            placeholder="Повторить пароль"
            value={data.confirm_password}
            onChange={(event) => {
              setData((data) => ({
                ...data,
                confirm_password: event.target.value,
              }));
            }}
            className={styles.addUserModal__inputsInputPassword}
          />
          <div className={styles.addUserModal__inputsFull}>
            {errors && (
              <p className={styles.addUserModal__error}>{errors.password}</p>
            )}
            {errors && (
              <p className={styles.addUserModal__error}>
                {errors.non_field_errors}
              </p>
            )}
            {errors && errors.confirm_password !== errors.password && (
              <p className={styles.addUserModal__error}>
                {errors.confirm_password}
              </p>
            )}
          </div>
        </div>
        <Button
          disabled={
            !(
              data.confirm_password &&
              data.email &&
              data.password &&
              data.username
            )
          }
          type="solid"
          onClick={handleSaveUser}
        >
          Создать
        </Button>
      </div>
    </Modal>
  );
};
