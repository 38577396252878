import { Api, tags } from "../..";
import { ID } from "shared/types";
import { IComplaintsRequestDto, IComplaintsResponseDto } from "shared/api/dto";

const basePath = "api/admin-complaint/";

const pathWithId = (id: number | string) => `${basePath}${id}/`;
const approvePath = (id: number | string) => pathWithId(id) + "approve/";
const declinePath = (id: number | string) => pathWithId(id) + "decline/";

export const complaintsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getComplaints: builder.query<IComplaintsResponseDto, IComplaintsRequestDto>(
      {
        query: (data) => ({ url: basePath, params: data }),
        providesTags: [tags.complaints],
      }
    ),

    approveComplaint: builder.mutation<void, ID>({
      query: (data) => ({
        url: approvePath(data.id),
        method: "POST",
      }),
      invalidatesTags: [tags.complaints],
    }),

    declineComplaint: builder.mutation<void, ID>({
      query: (data) => ({
        url: declinePath(data.id),
        method: "POST",
      }),
      invalidatesTags: [tags.complaints],
    }),
  }),
});

export const {
  useApproveComplaintMutation,
  useDeclineComplaintMutation,
  useGetComplaintsQuery,
} = complaintsApi;
