import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

interface IMicrophoneIcon extends SVGIconProps {
  withBackground?: boolean;
}

export const MicrophoneIcon = ({
  height,
  width,
  size = DEFAULT_ICON_SIZE,
  withBackground = false,
  className,
  color = "#515151",
  onClick,
}: IMicrophoneIcon) => {
  return withBackground ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <rect width="100%" height="100%" rx="10" fill="#EDEEFF" />
      <path
        d="M22.5 28.2501V32.3126M19.25 32.3126H25.75H19.25ZM29 20.1251V21.7501C29 25.3251 26.075 28.2501 22.5 28.2501C18.925 28.2501 16 25.3251 16 21.7501V20.1251H29Z"
        stroke={color}
        strokeWidth="1.625"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M22.5 25.8125C21.9638 25.8113 21.4333 25.7023 20.94 25.4921C20.4467 25.2818 20.0006 24.9746 19.6284 24.5887C18.8662 23.8201 18.4383 22.7817 18.4375 21.6992V16.0625C18.4354 15.5285 18.5391 14.9992 18.7425 14.5054C18.946 14.0116 19.2451 13.5629 19.6228 13.1853C20.0004 12.8076 20.4491 12.5085 20.9429 12.305C21.4367 12.1016 21.966 11.9979 22.5 12C24.7781 12 26.5625 13.7845 26.5625 16.0625V21.6992C26.5625 23.9671 24.74 25.8125 22.5 25.8125Z"
        fill="#515151"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 22"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
    >
      <path
        d="M7.5 16.2501V20.3126M4.25 20.3126H10.75H4.25ZM14 8.12512V9.75012C14 13.3251 11.075 16.2501 7.5 16.2501C3.925 16.2501 1 13.3251 1 9.75012V8.12512H14Z"
        stroke={color}
        strokeWidth="1.625"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M7.50003 13.8125C6.9638 13.8113 6.43329 13.7023 5.93998 13.4921C5.44668 13.2818 5.00064 12.9746 4.62835 12.5887C3.86621 11.8201 3.43826 10.7817 3.43753 9.69925V4.06253C3.43545 3.52845 3.53911 2.99924 3.74253 2.50542C3.94595 2.01159 4.24511 1.56292 4.62277 1.18527C5.00042 0.807615 5.44909 0.508453 5.94292 0.30503C6.43674 0.101608 6.96595 -0.00205093 7.50003 3.07417e-05C9.77808 3.07417e-05 11.5625 1.78448 11.5625 4.06253V9.69925C11.5625 11.9671 9.73999 13.8125 7.50003 13.8125Z"
        fill={color}
      />
    </svg>
  );
};
