import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryData } from "shared/types";

interface IInitialState {
  info: {
    total: number;
    pages: number;
    is_last: boolean;
  };
  currentPage: number;
  promotionsPerPage: number;
  searchTerm: string;
  created_at: Date | null;
  duration: undefined;
}

const initialState: IInitialState = {
  info: {
    total: 0,
    pages: 0,
    is_last: true,
  },
  currentPage: 1,
  promotionsPerPage: 50,
  searchTerm: "",
  created_at: null,
  duration: undefined,
};

export const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    setQueryData: (state, action: PayloadAction<QueryData<unknown>>) => {
      state.info.is_last = action.payload.is_last;
      state.info.pages = action.payload.pages;
      state.info.total = action.payload.total;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setPromotionsPerPage: (state, action: PayloadAction<number>) => {
      state.promotionsPerPage = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSearchDate: (state, action: PayloadAction<Date>) => {
      state.created_at = action.payload;
    },
  },
});

export const promotionActions = promotionSlice.actions;

export default promotionSlice.reducer;
