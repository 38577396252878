import { motion } from "framer-motion";
import { useModal } from "shared/hooks/shared";
import { CloseIcon } from "shared/ui/Icons";
import styles from "./modal.module.scss";

export interface ModalProps {
  children?: React.ReactNode;
  onClose?: () => void;
}

export const Modal = ({ children, onClose }: ModalProps) => {
  const { closeModal } = useModal();

  const handleCloseClick = () => {
    if (onClose) {
      onClose();
    } else {
      closeModal();
    }
  };

  const handleBodyClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.1 }}
      className={styles.modal}
    >
      <div className={styles.modal__back} onClick={handleCloseClick}></div>
      <div className={styles.modal__body} onClick={handleBodyClick}>
        <div className={styles.modal__closeButton}>
          <CloseIcon
            color="#515151"
            height={22}
            width={22}
            onClick={handleCloseClick}
          />
        </div>
        {children}
      </div>
    </motion.div>
  );
};
