import { useChangeWarningMutation, useGetWarningQuery } from "shared/api";

export const useWarning = () => {
  const warningQuery = useGetWarningQuery();
  const [changeWarning] = useChangeWarningMutation();

  const warning = warningQuery.isSuccess ? warningQuery.data : null;

  const changeWarningDescription = (text: string) => {
    if (warning && text !== warning.description) {
      changeWarning({ id: warning.id, description: text });
    }
  };

  return { warning, changeWarningDescription };
};
