import {
  ICreateWhitelistedDomainRequestDto,
  IWhitelistedDomainsDto,
  IWhitelistedDomainsRequestDto,
} from "shared/api/dto/whitelisted-domains.dto";
import { Api, tags } from "../..";

import {} from "shared/api/dto";
import { ID } from "shared/types";

const basePath = "api/admin-whitelisted-domains/";

const pathWithId = (id: number | string) => `${basePath}${id}/`;

export const whitelistedDomainsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getWhitelistedDomains: builder.query<
      IWhitelistedDomainsDto,
      IWhitelistedDomainsRequestDto
    >({
      query: (data) => ({ url: basePath, params: data }),
      providesTags: [tags.whitelistedDomains],
    }),
    addNewWhitelistedDomain: builder.mutation<
      any,
      ICreateWhitelistedDomainRequestDto
    >({
      query: (data) => ({ url: basePath, method: "POST", body: data }),
      invalidatesTags: [tags.whitelistedDomains],
    }),
    deleteWhitelistedDomain: builder.mutation<void, ID>({
      query: (data) => ({
        url: pathWithId(data.id),
        method: "DELETE",
      }),
      invalidatesTags: [tags.whitelistedDomains],
    }),
  }),
});

export const {
  useGetWhitelistedDomainsQuery,
  useAddNewWhitelistedDomainMutation,
  useDeleteWhitelistedDomainMutation,
} = whitelistedDomainsApi;
