import { memo, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Tooltip } from "shared/ui/Tooltip";
import styles from "./MenuLink.module.scss";

export interface NavigationLinkProps {
  link: string;
  text: string;
  Icon?: React.ElementType;
  isMenuLink?: boolean;
  tooltip?: string;
}

export const MenuLink = memo(
  ({ link, text, tooltip, Icon, isMenuLink }: NavigationLinkProps) => {
    const location = useLocation();

    const isActiveMenuLink = useMemo(() => {
      return isMenuLink && location.pathname.startsWith(link) && link !== "/";
    }, [isMenuLink, location.pathname, link]);

    const activeLinkStyle = ({ isActive }: { isActive: boolean }) =>
      classNames(styles.navlink, {
        [styles.navlinkActive]: isActive && !isMenuLink,
        [styles.navlinkMenuLinkActive]: isActiveMenuLink,
      });

    return (
      <NavLink className={activeLinkStyle} to={link}>
        {Icon && (
          <div className={styles.navlink__icon}>
            {tooltip ? (
              <Tooltip tooltip={tooltip || ""} isMenuTooltip={isMenuLink}>
                <Icon isActive={isActiveMenuLink} />
              </Tooltip>
            ) : (
              <Icon isActive={isActiveMenuLink} />
            )}
          </div>
        )}
        {text && <div className={styles.navlink__element}>{text}</div>}
      </NavLink>
    );
  }
);
