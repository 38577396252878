import { Selector } from "shared/ui/Selector";
import styles from "./SearchVerifiedMarketplaceItems.module.scss";

const verifiedOptions = [
  {
    title: "да",
    value: true,
  },
  {
    title: "нет",
    value: false,
  },
  {
    title: "все",
    value: undefined,
    isDefaultOption: true,
  },
];

export const SearchVerifiedMarketplaceItems = () => {
  return (
    <div className={styles.selector}>
      <span>верифицированный</span>
      <Selector<boolean | undefined>
        withBorder
        options={verifiedOptions}
        setFunc={() => {}}
      />
    </div>
  );
};
