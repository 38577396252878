import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { complaintsSelector, complaintsActions } from "shared/store";

export const useSearchComplaintsByDate = () => {
  const dispatch = useAppDispatch();
  const { searchParams } = useAppSelector(complaintsSelector);

  const setComplaintDate = (date: Date) => {
    dispatch(complaintsActions.setComplaintDate(date));
  };

  return {
    setComplaintDate,
    complaintDate: searchParams.complaintDate,
  };
};
