import classNames from "classnames";
import { useEffect, useRef } from "react";
import { RangeHintDirection, RangeProps } from "./range.interface";
import styles from "./range.module.scss";

export const Range = ({
  min = 0,
  max = 0,
  step = 1,
  onChange,
  defaultValue = min,
  direction = RangeHintDirection.top,
}: RangeProps) => {
  const outputRef = useRef<HTMLOutputElement>(null!);
  const rangeRef = useRef<HTMLInputElement>(null!);

  const changeHint = () => {
    const val = Number(rangeRef.current.value);
    const min = Number(rangeRef.current.min ? rangeRef.current.min : 0);
    const max = Number(rangeRef.current.max ? rangeRef.current.max : 100);
    const newVal = Number(((val - min) * 100) / (max - min));
    outputRef.current.value = val.toString();
    outputRef.current.style.left = `calc(${newVal}% + (${
      8 - newVal * 0.15
    }px))`;

    onChange && onChange(val);
  };

  useEffect(() => {
    changeHint && changeHint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.range}>
      <input
        min={min}
        max={max}
        step={step}
        type="range"
        ref={rangeRef}
        onChange={changeHint}
        defaultValue={defaultValue}
        className={styles.range__input}
      />
      <output
        ref={outputRef}
        className={classNames(styles.hint, {
          [styles.hintDown]: direction === RangeHintDirection.down,
        })}
      >
        {min}
      </output>
    </div>
  );
};
