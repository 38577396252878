import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const BanIcon = ({
  height,
  width,
  className,
  size = DEFAULT_ICON_SIZE,
  color = "black",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 17"
      width={width || size}
      height={height || size}
      className={className}
      onClick={onClick}
    >
      <path
        d="M14.7358 2.62231C13.9653 1.84419 13.0486 1.22597 12.0385 0.803178C11.0283 0.380386 9.94461 0.161351 8.84956 0.158656C7.75451 0.15596 6.66971 0.369658 5.6575 0.787473C4.64528 1.20529 3.7256 1.81898 2.95128 2.5933C2.17695 3.36763 1.56326 4.28731 1.14544 5.29953C0.72763 6.31174 0.513932 7.39654 0.516627 8.49159C0.519322 9.58664 0.738357 10.6704 1.16115 11.6805C1.58394 12.6907 2.20216 13.6073 2.98028 14.3778C3.75078 15.1559 4.66743 15.7742 5.67758 16.1969C6.68773 16.6197 7.77146 16.8388 8.86651 16.8415C9.96156 16.8442 11.0464 16.6305 12.0586 16.2127C13.0708 15.7948 13.9905 15.1811 14.7648 14.4068C15.5391 13.6325 16.1528 12.7128 16.5706 11.7006C16.9884 10.6884 17.2021 9.60359 17.1994 8.50854C17.1967 7.41348 16.9777 6.32976 16.5549 5.31961C16.1321 4.30946 15.5139 3.39281 14.7358 2.62231ZM15.3893 8.50006C15.3907 9.92457 14.9239 11.3101 14.0608 12.4433L4.91479 3.29733C6.04804 2.43421 7.43353 1.96742 8.85803 1.96881C12.4595 1.96881 15.3893 4.90045 15.3893 8.50006ZM2.32678 8.50006C2.32539 7.07556 2.79218 5.69006 3.6553 4.55682L12.8013 13.7028C11.668 14.5659 10.2825 15.0327 8.85803 15.0313C5.25842 15.0313 2.32678 12.1015 2.32678 8.50006Z"
        fill={color}
      />
    </svg>
  );
};
