import { ReactNode } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
  showNotification: boolean;
  notificationText?: ReactNode;
  withLoader?: boolean;
  isError?: boolean;
}

const initialState: IInitialState = {
  showNotification: false,
  notificationText: "",
  withLoader: false,
  isError: false,
};

export const notificationSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<IInitialState>) => {
      state.showNotification = action.payload.showNotification;
      state.notificationText = action.payload.notificationText;
      state.withLoader = action.payload.withLoader;
      state.isError = action.payload.isError;
    },

    hideNotification: (state) => {
      state.showNotification = false;
      state.notificationText = "";
      state.withLoader = false;
      state.isError = false;
    },
  },
});

export const notificationActions = notificationSlice.actions;

export default notificationSlice.reducer;
