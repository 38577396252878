import { ReactElement } from "react";
import classNames from "classnames";
import Tippy from "@tippyjs/react/headless";
import "tippy.js/dist/tippy.css";
import styles from "./Tooltip.module.scss";

export interface TooltipProps {
  children: ReactElement;
  tooltip: string;
  isMenuTooltip?: boolean;
}

export const Tooltip = ({ children, tooltip, isMenuTooltip }: TooltipProps) => {
  return (
    <Tippy
      placement={isMenuTooltip ? "right" : "top"}
      render={() => (
        <div
          className={classNames(styles.tooltip, {
            [styles.tooltipMenu]: isMenuTooltip,
          })}
        >
          {tooltip}
        </div>
      )}
    >
      <div>{children}</div>
    </Tippy>
  );
};
