interface usePaginationProps {
  currentPage: number;
  items: number;
  info: {
    total: number;
    pages: number;
    is_last: boolean;
  };
  setPage: (page: number) => void;
  setItemsPerPage?: (itemsCount: number) => void;
}

export const usePagination = ({
  info,
  currentPage,
  items,
  setPage,
  setItemsPerPage,
}: usePaginationProps) => {
  const viewed = currentPage === info.pages ? info.total : currentPage * items;
  const total = info.total;

  const nextPage = () => {
    if (currentPage < info.pages) {
      setPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  const setNewItemsPerPage = (items: number) => {
    if (setItemsPerPage) {
      setPage(1);
      setItemsPerPage(items);
    }
  };

  return {
    viewed,
    total,
    itemsPerPage: items,
    pages: info.pages,
    prevPage,
    nextPage,
    setItemsPerPage: setNewItemsPerPage,
  };
};
