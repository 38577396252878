import { SelectVerefication } from "features/users-verification/select-verefication";
import { ReactNode } from "react";
import { getRuLocaleDateString } from "shared/lib";
import { IUserVerification } from "shared/types";
import { TableRowDataProps, TableRow, TableData } from "shared/ui/Table";

interface UsersVerificationTableRowProps
  extends TableRowDataProps<IUserVerification> {
  controls: ReactNode;
}

export const UsersVerificationTableRow = ({
  even,
  data,
  controls,
}: UsersVerificationTableRowProps) => {
  return (
    <TableRow even={even}>
      <TableData>
        <SelectVerefication user={data} />
      </TableData>
      <TableData>{data?.id}</TableData>
      <TableData width={200}>{data?.user.username}</TableData>
      <TableData width={200}>{data?.user.email}</TableData>
      <TableData>{data?.user.phone_number}</TableData>
      <TableData>
        {getRuLocaleDateString(data?.user.created_at || "")}
      </TableData>
      <TableData width={370} withoutPadding>
        {controls}
      </TableData>
    </TableRow>
  );
};
