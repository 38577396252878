import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from "recharts";
import { IFinanceStat } from "shared/types";
import { XAxisTick, YAxisTick } from "../Axis";
import { CustomTooltip } from "../Tooltip";
import "../RechartStyles.scss";
import { valueFormat } from "shared/lib";

const ActiveDot = ({ cx, cy, color }: any) => {
  return (
    <g transform={`translate(${cx},${cy})`}>
      <circle
        r={12}
        fill="white"
        stroke={color}
        strokeWidth={4}
        strokeOpacity={0.3}
        pointerEvents="none"
      />
      <circle
        r={4}
        fill={color}
        stroke={color}
        strokeWidth={2}
        pointerEvents="none"
      />
    </g>
  );
};

interface LineGraphProps {
  color?: string;
  data?: IFinanceStat[];
  formatter?: (value: string) => string;
}

export const LineGraph = ({
  data,
  color = "#FE85A2",
  formatter,
}: LineGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        height={260}
        data={data?.map((d) => ({ ...d, value: +d.value }))}
        margin={{ right: 14, bottom: 8 }}
        className="LineGraph"
      >
        <CartesianGrid strokeDasharray="12 6" fill="#FFFFFF" vertical={false} />
        <XAxis
          dataKey="key"
          axisLine={false}
          tickLine={false}
          tickFormatter={formatter}
          tick={<XAxisTick />}
        />
        <YAxis
          padding={{ top: 32, bottom: 32 }}
          axisLine={false}
          tickLine={false}
          tickFormatter={valueFormat}
          tick={<YAxisTick />}
        />
        <Tooltip
          wrapperStyle={{ outline: "none" }}
          content={<CustomTooltip />}
          cursor={false}
        />
        <Line
          type="monotone"
          dataKey="value"
          stroke={color}
          strokeWidth={"3"}
          dot={false}
          activeDot={<ActiveDot color={color} />}
          cursor={"pointer"}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
