import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { CallType } from "shared/store";
import { callsActions } from "shared/store/reducers/callsReducer";
import { CameraButton, MicrophoneButton } from "shared/ui/Buttons";
import styles from "./SearchCallByType.module.scss";
import classNames from "classnames";

export const SearchCallByType = () => {
  const { is_video } = useAppSelector((state) => state.calls);
  const dispatch = useAppDispatch();

  const changeCallType = (type: CallType) => {
    dispatch(callsActions.setCallType(type));
  };

  const selectAudio = () => {
    changeCallType("audio");
  };

  const selectVideo = () => {
    changeCallType("video");
  };

  return (
    <div className={styles.searchCallByType}>
      <div
        onClick={selectVideo}
        className={classNames(styles.searchCallByType__button, {
          [styles.searchCallByType__button_selected]: is_video,
        })}
      >
        <CameraButton />
        <span>Видеозвонок</span>
      </div>

      <div
        onClick={selectAudio}
        className={classNames(styles.searchCallByType__button, {
          [styles.searchCallByType__button_selected]: !is_video,
        })}
      >
        <MicrophoneButton />
        <span>Аудиозвонок</span>
      </div>
    </div>
  );
};
