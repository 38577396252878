import { ChangeEvent, useRef, useState } from "react";
import classNames from "classnames";
import { Button } from "shared/ui/Buttons";
import {
  ArrowDirection,
  ArrowIcon,
  DownloadIconPng,
  MinusIcon,
} from "shared/ui/Icons";
import { Textarea } from "shared/ui/Textarea";
import { MediaLoader } from "shared/ui/MediaLoader";
import { IPost, IPostItem } from "shared/types";
import styles from "./EditPost.module.scss";

export interface EditPostMediaProps {
  isLast: boolean;
  isFirst: boolean;
  nextMedia: () => void;
  prevMedia: () => void;
  item: IPostItem;
  addMedia: (file: File) => void;
  deleteMedia: (post: IPostItem) => void;
}
export interface EditPostProps {
  post: IPost;
  changeText: (value: string) => void;
}

const PostMedia = ({ item }: { item: IPostItem }) => {
  if (item.content_type === "image") {
    return <img src={item.content_url} alt="post content" />;
  }
  if (item.content_type === "video") {
    return <video controls src={item.content_url} />;
  }

  if (item.content_type === "audio") {
    return (
      <audio controls src={item.content_url}>
        Не удается воспроизветси файл. Тег <code>audio</code> не поддерживается
        вашим браузером.
      </audio>
    );
  }

  return null;
};

export const EditPostMediaFile = ({
  item,
  isFirst,
  isLast,
  prevMedia,
  nextMedia,
  addMedia,
  deleteMedia,
}: EditPostMediaProps) => {
  const setNewMediaFile = (url: string, file: File | null) => {
    if (url && file) {
      addMedia(file);
    }
  };

  const deleteMediaFile = () => {
    deleteMedia(item);
  };

  return (
    <div className={styles.editPostMedia}>
      <p className={styles.editPost__title}>Изменение медиа-файла</p>
      <div className={styles.editPostMedia__media}>
        <ArrowIcon
          direction={ArrowDirection.left}
          onClick={prevMedia}
          size={32}
          className={classNames(styles.editPostMedia__arrow, {
            [styles.editPostMedia__arrowDisabled]: isFirst,
          })}
        />

        {item ? <PostMedia item={item} /> : <div />}
        <ArrowIcon
          direction={ArrowDirection.right}
          onClick={nextMedia}
          size={32}
          className={classNames(styles.editPostMedia__arrow, {
            [styles.editPostMedia__arrowDisabled]: isLast,
          })}
        />
      </div>
      <div className={styles.editPostMedia__controls}>
        <Button
          type="gray"
          icon={<MinusIcon />}
          onClick={deleteMediaFile}
          className={styles.editPost__button}
        >
          Удалить
        </Button>
        <MediaLoader setImage={setNewMediaFile}>
          <Button
            type="solid"
            icon={<DownloadIconPng isWhite />}
            className={styles.editPost__button}
          >
            Загрузить
          </Button>
        </MediaLoader>
      </div>
    </div>
  );
};

export const EditPostText = ({ post, changeText }: EditPostProps) => {
  const [text, setText] = useState(post.description);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const submit = () => {
    if (text) {
      changeText(text);
    }
  };

  const onChangeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  return (
    <div className={styles.editPostText}>
      <p className={styles.editPost__title}>Описание публикации</p>
      <div className={styles.editPostText__description}>
        <div className={styles.editPost__controls}>
          <Textarea
            maxRows={24}
            value={text}
            textareaRef={textareaRef}
            onChange={onChangeText}
            className={styles.editPostText__textarea}
            placeholder="Введите описание"
          />
          <Button
            type="solid"
            onClick={submit}
            disabled={!text || text === post.description}
            className={styles.editPost__button}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
};
