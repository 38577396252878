import { EditIcon } from "shared/ui/Icons";
import { Tooltip } from "shared/ui/Tooltip";
import styles from "./style.module.scss";

interface EditBannerButtonProps {
  editHandler?: () => void;
}

export const EditBannerButton = ({ editHandler }: EditBannerButtonProps) => {
  return (
    <Tooltip tooltip="Редактировать баннер">
      <div className={styles.editBannerButton}>
        <EditIcon
          size={32}
          color={"#4E51BF"}
          className={styles.editBannerButton__icon}
          onClick={editHandler}
        />
      </div>
    </Tooltip>
  );
};
