import { ChangeEvent, SyntheticEvent, useRef } from "react";
import styles from "./MediaLoader.module.scss";

export interface MediaLoaderProps {
  children: React.ReactNode;
  setImage: (url: string, file: File | null) => void;
}

export const MediaLoader = ({ children, setImage }: MediaLoaderProps) => {
  const ref = useRef<HTMLInputElement>(null!);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;
    if (files && files.length) {
      const image = files[0];
      setImage(URL.createObjectURL(image), image);
    }
  };

  const handleLoadImageClick = (event?: SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (ref.current) {
      ref.current.value = "";
      ref.current.click();
    }
  };

  return (
    <>
      <input
        type="file"
        className={styles.imageLoader__input}
        accept={"image/*"}
        multiple={false}
        ref={ref}
        onChange={handleImageChange}
      />
      <div onClick={handleLoadImageClick}>{children}</div>
    </>
  );
};
