import { useParams } from "react-router-dom";
import { EditPostMediaFile, EditPostText } from "widgets/EditPost";
import * as Layout from "shared/ui/layout";
import { usePost } from "./model";
import styles from "./EditPost.module.scss";

export const PostEditPage = () => {
  const { id } = useParams();
  const {
    post,
    changePostDescription,
    addMediaInPost,
    deleteMediaFromPost,
    items,
  } = usePost({
    id: Number(id),
  });

  return (
    <Layout.Content title={"Редактирование публикации"}>
      {post && (
        <div className={styles.editPost}>
          <EditPostMediaFile
            {...items}
            addMedia={addMediaInPost}
            deleteMedia={deleteMediaFromPost}
          />
          <EditPostText post={post} changeText={changePostDescription} />
        </div>
      )}
    </Layout.Content>
  );
};
