import classNames from "classnames";
import { ComplaintReasons, ComplaintReasonKeys } from "shared/types";
import { ScrollBar } from "shared/ui/ScrollBar";
import { Select } from "shared/ui/Select";
import { useSearchComplaintsByReason } from "../model";
import styles from "./SearchComplaintsByReason.module.scss";

export const SearchComplaintsByReason = () => {
  const { reasonType, setComplaintReason } = useSearchComplaintsByReason();

  const reasonText = reasonType ? ComplaintReasons[reasonType] : "тип жалобы";

  return (
    <Select
      title={reasonText}
      className={styles.complaintsReasons__select}
      overflow
    >
      <ScrollBar height={200}>
        <div className={styles.complaintsReasons__list}>
          <p
            className={classNames(
              styles.complaintsReasons__reason,
              styles.complaintsReasons__reasonAll
            )}
            onClick={() => setComplaintReason(undefined)}
          >
            Все жалобы
          </p>
          {ComplaintReasonKeys.map((item, ind) => (
            <p
              className={styles.complaintsReasons__reason}
              onClick={() => setComplaintReason(item)}
              key={ind}
            >
              {ComplaintReasons[item]}
            </p>
          ))}
        </div>
      </ScrollBar>
    </Select>
  );
};
