import { useApproveUserMutation } from "shared/api";
import { useNotification } from "shared/hooks/shared";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { verificationsActions, verificationsSelector } from "shared/store";
import { decOfVerefications } from "shared/lib";

export const useApproveSelectedVerifications = () => {
  const [approveUser] = useApproveUserMutation();
  const { show, hide } = useNotification();
  const { open } = useShowConfirmModal();

  const { selectedItems } = useAppSelector(verificationsSelector);
  const dispatch = useAppDispatch();

  const approveSelected = () => {
    open({
      title: `Принять ${selectedItems.length} ${decOfVerefications(
        selectedItems.length
      )}?`,
      buttonText: "Принять",
      onConfirmCallback: async () => {
        show({
          text: "Пользователи верифицируются",
          withLoader: true,
          autoClose: false,
        });
        let promises = selectedItems.map((user) =>
          approveUser({ id: user.id }).unwrap()
        );
        let results = await Promise.allSettled(promises);

        if (results.every((promise) => promise.status === "fulfilled")) {
          hide();
          show({ text: "Пользователи верифицированы!" });
        }
        dispatch(verificationsActions.resetSelectedVerefications());
      },
    });
  };

  return { approveSelected, selectedItemsCount: selectedItems.length };
};
