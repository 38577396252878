import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IFinanceStat } from "shared/types";
import { XAxisTick, YAxisTick } from "../Axis";
import { CustomTooltip } from "../Tooltip";
import "../RechartStyles.scss";
import { valueFormat } from "shared/lib";

interface BarsGraphProps {
  color?: string;
  data?: IFinanceStat[];
  formatter?: (value: string) => string;
}

export const BarsGraph = ({
  data,
  color = "#FFCFDB",
  formatter,
}: BarsGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data?.map((d) => ({ ...d, value: +d.value }))}
        className="BarsGraph"
      >
        <CartesianGrid strokeDasharray="12 6" vertical={false} fill="#FFFFFF" />
        <XAxis
          dataKey="key"
          padding={{ left: 24, right: 24 }}
          axisLine={false}
          tickLine={false}
          tickFormatter={formatter}
          tick={<XAxisTick />}
        />
        <YAxis
          padding={{ top: 32 }}
          axisLine={false}
          tickLine={false}
          tickFormatter={valueFormat}
          tick={<YAxisTick />}
        />
        <Tooltip
          wrapperStyle={{ outline: "none" }}
          content={<CustomTooltip />}
          cursor={{ fill: "transparent" }}
        />

        <Bar
          dataKey="value"
          barSize={48}
          fill={color}
          radius={[6, 6, 0, 0]}
          cursor={"pointer"}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
