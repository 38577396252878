import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { useDeletePostMutation } from "shared/api";
import { decOfPosts } from "shared/lib";
import { postsSelector, postsActions } from "shared/store";

export const useDeleteSelectedPosts = () => {
  const dispatch = useAppDispatch();

  const [deletePost] = useDeletePostMutation();

  const { open } = useShowConfirmModal();
  const { show, hide } = useNotification();

  const { selectedPosts } = useAppSelector(postsSelector);

  const deleteSelectedPosts = () => {
    open({
      title: `Удалить ${selectedPosts.length} ${decOfPosts(
        selectedPosts.length
      )}?`,
      buttonText: "Удалить",
      onConfirmCallback: async () => {
        show({
          text: "Публикации удаляются",
          withLoader: true,
          autoClose: false,
        });

        const promises = selectedPosts.map((post) =>
          deletePost({ id: post.id }).unwrap()
        );
        const results = await Promise.allSettled(promises);

        if (results.every((promise) => promise.status === "fulfilled")) {
          hide();
          show({ text: "Публикации удалены!", timeout: 2500 });
        }

        dispatch(postsActions.resetSelectedPosts());
      },
    });
  };

  return {
    deleteSelectedPosts,
    selectedPostsCount: selectedPosts.length,
  };
};
