import { HtmlEditor } from "shared/ui/HtmlEditor";
import { useVerefications } from "shared/hooks/core";
import * as Layout from "shared/ui/layout";

export const VerificationsRequirements = () => {
  const { requirements, setVerifications } = useVerefications();

  const handleSaveClick = (text: string) => {
    if (text !== requirements.description) {
      setVerifications({ ...requirements, description: text });
    }
  };

  return (
    <Layout.Content
      title={
        <>
          Редактирование требований <br /> к верификации
        </>
      }
    >
      <HtmlEditor
        description={requirements.description || ""}
        saveText={handleSaveClick}
      />
    </Layout.Content>
  );
};
