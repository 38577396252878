import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/shared";
import { adminsSelector, adminsActions } from "shared/store";
import { IAdmin } from "shared/types";

export const useSelectAdmin = (admin: IAdmin) => {
  const dispatch = useAppDispatch();
  const { selectedItems } = useAppSelector(adminsSelector);

  const isSelected = useMemo(() => {
    return selectedItems.some((selectedAdmin) => admin.id === selectedAdmin.id);
  }, [admin, selectedItems]);

  const selectAdmin = (isSelect: boolean) => {
    if (isSelect) {
      dispatch(adminsActions.addAdminInSelected(admin));
    } else if (!isSelect) {
      dispatch(adminsActions.removeAdminFromSelected(admin));
    }
  };
  return { isSelected, selectAdmin };
};
