import { useCallback } from "react";
import { useNotification } from "shared/hooks/shared";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useDeleteCallRecordMutation } from "shared/api/endpoints/admin/calls.api";

export const useDeleteRecord = (id: number) => {
  const [deleteRecord] = useDeleteCallRecordMutation();
  const { open } = useShowConfirmModal();
  const { show } = useNotification();

  const deleteRecordWithCofirm = useCallback(() => {
    open({
      title: `Удалить запись?`,
      buttonText: "Удалить",
      onConfirmCallback: () =>
        deleteRecord({ id })
          .unwrap()
          .then(() => {
            show({ text: "Запись удалена!" });
          }),
    });
  }, [open, deleteRecord, id, show]);

  return { deleteRecordWithCofirm };
};
