import { useEffect } from "react";
import { ComplaintType } from "shared/types";
import { getFullDateFormat } from "shared/lib";
import { useGetComplaintsQuery } from "shared/api";
import { complaintsSelector, complaintsActions } from "shared/store";
import {
  useAppDispatch,
  useAppSelector,
  useIsNewDataFetching,
} from "shared/hooks/shared";

export const useComplaints = (type: ComplaintType | undefined) => {
  const dispatch = useAppDispatch();
  const { searchParams } = useAppSelector(complaintsSelector);

  const complaintsQuery = useGetComplaintsQuery(
    {
      object_type: searchParams.complaintType,
      page: searchParams.currentPage,
      page_size: searchParams.itemsPerPage,
      reason: searchParams.reasonType,
      search: searchParams.searchText,
      created_at: searchParams.complaintDate
        ? getFullDateFormat(searchParams.complaintDate)
        : undefined,
      object_created_at: searchParams.publicationDate
        ? getFullDateFormat(searchParams.publicationDate)
        : undefined,
    },
    { pollingInterval: 60000 }
  );

  useEffect(() => {
    if (type && type !== searchParams.complaintType) {
      dispatch(complaintsActions.setComplaintType(type));
      dispatch(complaintsActions.resetSearchParams());
    }
  }, [dispatch, searchParams.complaintType, type]);

  useEffect(() => {
    if (complaintsQuery.isSuccess) {
      dispatch(complaintsActions.setQueryData(complaintsQuery.data));
    }
  }, [complaintsQuery, dispatch]);

  const prevData = complaintsQuery.currentData?.results || [];

  const loadingStatus = useIsNewDataFetching({
    ...complaintsQuery,
    params: {
      object_type: searchParams.complaintType,
      page: searchParams.currentPage,
      page_size: searchParams.itemsPerPage,
      reason: searchParams.reasonType,
      search: searchParams.searchText,
      created_at: searchParams.complaintDate
        ? getFullDateFormat(searchParams.complaintDate)
        : undefined,
      object_created_at: searchParams.publicationDate
        ? getFullDateFormat(searchParams.publicationDate)
        : undefined,
    },
    newParams: complaintsQuery.originalArgs,
  });

  return {
    complaints:
      loadingStatus.isSuccess && complaintsQuery.isSuccess
        ? complaintsQuery.data.results
        : prevData,
    complaintType: searchParams.complaintType,
    loadingStatus,
  };
};
