import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { useDeleteOnboardingMutation } from "shared/api";

export const useDeleteOnboarding = () => {
  const [deleteOnboardingMutation] = useDeleteOnboardingMutation();
  const { open } = useShowConfirmModal();
  const { show } = useNotification();

  const deleteOnboarding = (id: number) => {
    deleteOnboardingMutation({ id })
      .unwrap()
      .then(() => {
        show({ text: "Onboarding удален!" });
      });
  };

  const deleteWithConfirm = (title: string, id: number) => {
    open({
      title: `Удалить ${title}?`,
      buttonText: "Удалить",
      onConfirmCallback: () => {
        deleteOnboarding(id);
      },
    });
  };

  return {
    deleteOnboarding: deleteWithConfirm,
  };
};
