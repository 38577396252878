import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const ImagesIcon = ({
  height,
  width,
  className,
  size = DEFAULT_ICON_SIZE,
  color = "#242424",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <g>
        <path
          d="M16.875 4.37505V3.75005C16.8736 3.25322 16.6756 2.77714 16.3242 2.42582C15.9729 2.0745 15.4968 1.8765 15 1.87505H2.5C2.00316 1.8765 1.52709 2.0745 1.17577 2.42582C0.82445 2.77714 0.626443 3.25322 0.625 3.75005V13.7501C0.626443 14.2469 0.82445 14.723 1.17577 15.0743C1.52709 15.4256 2.00316 15.6236 2.5 15.6251H3.125"
          stroke={color}
          strokeWidth="1.3125"
          strokeLinejoin="round"
        />
        <path
          d="M17.5785 5H5.54648C4.55431 5 3.75 5.80431 3.75 6.79648V16.3285C3.75 17.3207 4.55431 18.125 5.54648 18.125H17.5785C18.5707 18.125 19.375 17.3207 19.375 16.3285V6.79648C19.375 5.80431 18.5707 5 17.5785 5Z"
          stroke={color}
          strokeWidth="1.3125"
          strokeLinejoin="round"
        />
        <path
          d="M14.5672 9.77299C15.231 9.77299 15.7691 9.23871 15.7691 8.57963C15.7691 7.92056 15.231 7.38627 14.5672 7.38627C13.9034 7.38627 13.3652 7.92056 13.3652 8.57963C13.3652 9.23871 13.9034 9.77299 14.5672 9.77299Z"
          stroke={color}
          strokeWidth="1.3125"
          strokeMiterlimit="10"
        />
        <path
          d="M10.3605 18.125L14.993 13.5262C15.2061 13.316 15.4896 13.1922 15.7887 13.1787C16.0878 13.1652 16.3813 13.263 16.6125 13.4531L19.375 15.7387M13.3652 14.5379L9.96094 11.1633C9.74331 10.9483 9.45222 10.8238 9.14642 10.8151C8.84062 10.8063 8.54287 10.9138 8.31328 11.116L3.75 15.1422L13.3652 14.5379Z"
          stroke={color}
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
