import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryData, IUser } from "shared/types";

interface IInitialState {
  selectedUsers: IUser[];
  info: {
    total: number;
    pages: number;
    is_last: boolean;
  };
  currentPage: number;
  usersPerPage: number;
  isActiveUsers?: boolean;
  isVerifiedUsers?: boolean;
  searchTerm?: string;
}

const initialState: IInitialState = {
  selectedUsers: [],
  info: {
    total: 0,
    pages: 0,
    is_last: true,
  },
  currentPage: 1,
  usersPerPage: 50,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setQueryData: (state, action: PayloadAction<QueryData<IUser[]>>) => {
      state.info.is_last = action.payload.is_last;
      state.info.pages = action.payload.pages;
      state.info.total = action.payload.total;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setUsersPerPage: (state, action: PayloadAction<number>) => {
      state.usersPerPage = action.payload;
    },
    addUserInSelected: (state, action: PayloadAction<IUser>) => {
      state.selectedUsers.push(action.payload);
    },
    deleteUserFromSelected: (state, action: PayloadAction<IUser>) => {
      state.selectedUsers = state.selectedUsers.filter(
        (user) => user.id !== action.payload.id
      );
    },
    resetSelectedUsers: (state) => {
      state.selectedUsers = [];
    },
    setIsActiveUsers: (state, action: PayloadAction<boolean | undefined>) => {
      state.isActiveUsers = action.payload;
    },
    setIsVerifiedUsers: (state, action: PayloadAction<boolean | undefined>) => {
      state.isVerifiedUsers = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
  },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
