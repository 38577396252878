import { DEFAULT_ICON_SIZE, SVGIconProps } from "./icon.interface";

export const SendIcon = ({
  height,
  width,
  className,
  size = DEFAULT_ICON_SIZE,
  color = "#4E51BF",
  onClick,
}: SVGIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 26"
      width={width || size}
      height={height || size}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <path
        d="M28.7869 11.1905L28.7769 11.1861L2.08438 0.114851C1.85986 0.0208813 1.61556 -0.0159725 1.37332 0.00758639C1.13107 0.0311453 0.898449 0.114382 0.69625 0.249851C0.482622 0.389831 0.307145 0.580727 0.185609 0.805361C0.0640733 1.03 0.000290684 1.28132 3.41257e-07 1.53673V8.61735C0.000119049 8.96651 0.122037 9.30468 0.344741 9.57359C0.567445 9.8425 0.876982 10.0253 1.22 10.0905L15.7781 12.7824C15.8353 12.7932 15.887 12.8237 15.9241 12.8685C15.9612 12.9134 15.9816 12.9698 15.9816 13.028C15.9816 13.0862 15.9612 13.1426 15.9241 13.1874C15.887 13.2323 15.8353 13.2628 15.7781 13.2736L1.22063 15.9655C0.877702 16.0305 0.568183 16.213 0.34538 16.4817C0.122576 16.7504 0.000437513 17.0883 3.41257e-07 17.4374V24.5192C-0.000165644 24.7631 0.0602214 25.0032 0.175743 25.218C0.291264 25.4328 0.458305 25.6155 0.661875 25.7499C0.90675 25.9125 1.19413 25.9995 1.48813 25.9999C1.69251 25.9997 1.89481 25.9587 2.08313 25.8792L28.775 14.8711L28.7869 14.8655C29.1462 14.7111 29.4523 14.4547 29.6674 14.1281C29.8825 13.8015 29.9972 13.419 29.9972 13.028C29.9972 12.6369 29.8825 12.2544 29.6674 11.9278C29.4523 11.6012 29.1462 11.3449 28.7869 11.1905Z"
        fill={color}
      />
    </svg>
  );
};
