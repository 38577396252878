import { HtmlEditor } from "shared/ui/HtmlEditor";
import { useWalletHelpText } from "features/wallet/help-texts";
import * as Layout from "shared/ui/layout";

export const CardBinding = () => {
  const { data, changeText } = useWalletHelpText("LINK_CARD_PROCESS");

  return (
    <Layout.Content
      title={
        <>
          Редактирование процесса <br /> привязки карты
        </>
      }
    >
      <HtmlEditor description={data?.description || ""} saveText={changeText} />
    </Layout.Content>
  );
};
