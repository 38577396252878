import { PropsWithChildren } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  TableData,
} from "shared/ui/Table";

interface CallsTableProps extends PropsWithChildren {
  dataLoading?: boolean;
}

export const CallsTable = ({ children, dataLoading }: CallsTableProps) => {
  return (
    <Table withLoader={dataLoading}>
      <TableHeader>
        <TableRow even>
          <TableData width={40} />
          <TableData width={240}>звезда</TableData>
          <TableData width={240}>инициатор</TableData>
          <TableData>дата и время</TableData>
          <TableData width={40} />
          <TableData>запись</TableData>
          <TableData width={220}>ссылка</TableData>
        </TableRow>
      </TableHeader>
      {dataLoading ? null : <TableBody>{children}</TableBody>}
    </Table>
  );
};
