import { useRef, useMemo } from "react";
import equal from "deep-equal";

interface UseIsNewDataFetchingProps {
  params: Object;
  newParams: Object | undefined;
  isFetching?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
}

/**
 * @info возвращает isLoading = true при получении новых данных
 * и isLoading = false при изменении загруженных данных в какой-либо мутации (скрыть, удалить и т.д.).
 * Нужно для корректного отображения лоадера в таблицах
 */
export const useIsNewDataFetching = ({
  params,
  newParams,
  isFetching,
  isLoading,
  isSuccess,
}: UseIsNewDataFetchingProps) => {
  const prevParams = useRef({ ...params });

  const isDataFetching = useMemo(() => {
    const paramsEqual = equal(prevParams.current, newParams);
    if (newParams && !paramsEqual && isFetching) {
      return true;
    } else if (newParams && !paramsEqual && !isFetching) {
      //@ts-ignore
      prevParams.current = { ...newParams };
    }
    return false;
  }, [newParams, isFetching]);

  return {
    isLoading: isLoading || isDataFetching,
    isSuccess: isSuccess && !(isLoading || isDataFetching),
  };
};
