import { ReactNode } from "react";
import { getFullDateFormat } from "shared/lib";
import { IWalletApplication } from "shared/types";
import styles from "./WalletTable.module.scss";

interface WalletTableRowProps {
  data: IWalletApplication;
  controls: ReactNode;
  is_active: boolean;
}

export const WalletTableRow = ({
  data,
  controls,
  is_active,
}: WalletTableRowProps) => {
  return (
    <tr className={styles.walletTable__row}>
      <td>{data.id}</td>
      <td className={styles.walletTable__bold}>{data.user.username}</td>
      <td>
        <span className={styles.walletTable__email}>{data.user.email}</span>
      </td>
      <td>
        <span className={styles.walletTable__name}>
          {`${data.user.first_name} ${data.user.last_name}`}
        </span>
      </td>
      <td>{data.last_digits}</td>
      <td>{data.amount}</td>
      {!is_active && (
        <td className={styles.walletTable__bold}>
          {getFullDateFormat(data.created_at)}
        </td>
      )}
      {is_active && <td>{controls}</td>}
    </tr>
  );
};
