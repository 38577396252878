import { ID } from "shared/types";
import { useDeclineUserMutation } from "shared/api";
import { useNotification } from "shared/hooks/shared";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";

export const useDeclineVerification = ({ id }: ID) => {
  const [declineUser] = useDeclineUserMutation();
  const { show } = useNotification();
  const { open } = useShowConfirmModal();

  const decline = (cb?: () => void) => {
    open({
      title: `Отклонить заявку?`,
      buttonText: "Отклонить",
      onConfirmCallback: () => {
        id &&
          declineUser({ id, reply: "Запрос отклонен" })
            .unwrap()
            .then(() => {
              show({ text: "Верификация отменена!" });
            })
            .finally(() => cb?.());
      },
    });
  };

  return { decline };
};
