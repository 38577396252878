import { useCallback } from "react";
import { modalActions, ModalType } from "shared/store";
import { useAppDispatch } from "shared/hooks/shared";

export const useAddNewUser = () => {
  const dispatch = useAppDispatch();

  const openAddUserModal = useCallback(() => {
    dispatch(modalActions.openModal(ModalType.addUserModal));
  }, [dispatch]);

  return {
    openAddUserModal,
  };
};
