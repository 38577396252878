import classNames from "classnames";
import { Modal } from "shared/ui/Modal";
import { ArrowIcon, ArrowDirection } from "shared/ui/Icons";
import { IPostItem } from "shared/types";
import { usePostMediaFiles } from "features/post-media-files/model";
import styles from "./PostMediaFilesModal.module.scss";

interface DocumentModalPostProps {
  post: IPostItem;
}

export const PostMediaFile = ({ post }: DocumentModalPostProps) => {
  if (post?.content_type === "image") {
    return (
      <img
        src={post.content_url}
        alt="post content"
        className={styles.documentModal__contentImage}
      />
    );
  }
  if (post?.content_type === "video") {
    return (
      <video
        controls
        src={post.content_url}
        className={styles.documentModal__contentVideo}
      />
    );
  }

  if (post?.content_type === "audio") {
    return (
      <audio controls src={post.content_url}>
        Не удается воспроизветси файл. Тег <code>audio</code> не поддерживается
        вашим браузером.
      </audio>
    );
  }

  return <div className={styles.documentModal__contentPlaceholder} />;
};

export const PostMediaFilesModal = () => {
  const { post, isFirstPost, isLastPost, prevPost, nextPost } =
    usePostMediaFiles();

  return (
    <Modal>
      <div className={styles.documentModal}>
        <div className={styles.documentModal__content}>
          <div
            className={classNames(styles.documentModal__icon, {
              [styles.documentModal__iconDisabled]: isFirstPost,
            })}
          >
            {!isFirstPost && (
              <ArrowIcon direction={ArrowDirection.left} onClick={prevPost} />
            )}
          </div>
          <div className={styles.documentModal__media}>
            <PostMediaFile post={post} />
          </div>
          <div
            className={classNames(styles.documentModal__icon, {
              [styles.documentModal__iconDisabled]: isLastPost,
            })}
          >
            {!isLastPost && (
              <ArrowIcon direction={ArrowDirection.right} onClick={nextPost} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
