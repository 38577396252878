import { IPost } from "shared/types";
import { Checkbox } from "shared/ui/Checkbox";
import { useSelectPost } from "../model";

interface SelectPostProps {
  post: IPost;
}

export const SelectPost = ({ post }: SelectPostProps) => {
  const { changeSelected, selected } = useSelectPost(post);
  return <Checkbox checked={selected} onChange={changeSelected} />;
};
