import classNames from "classnames";
import { ChangeEvent, memo, useEffect, useRef } from "react";
import { DeleteIcon, EditIcon } from "shared/ui/Icons";
import { Tooltip } from "shared/ui/Tooltip";
import styles from "./input.module.scss";

interface InputProps extends Partial<HTMLInputElement> {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  deleteHandler?: () => void;
  editHandler?: () => void;
}

export const InputWithOptions = memo(
  ({
    value,
    disabled,
    className,
    autofocus,
    placeholder,
    type = "text",
    onChange,
    editHandler,
    deleteHandler,
  }: InputProps) => {
    const ref = useRef<HTMLInputElement>(null!);

    const changeTextHandler = (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
    };

    const deleteClickHandler = () => {
      deleteHandler && deleteHandler();
    };

    const editClickHandler = () => {
      editHandler && editHandler();
    };

    useEffect(() => {
      if (!disabled && autofocus) {
        ref.current.focus();
      }
    }, [disabled, autofocus]);

    return (
      <div
        className={classNames(styles.input, {
          [className as string]: className,
        })}
      >
        <input
          ref={ref}
          type={type}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={changeTextHandler}
          className={classNames(styles.input__input, {
            [styles.inputDisabled]: disabled,
          })}
        />
        <div className={styles.input__options}>
          {deleteHandler && (
            <div className={styles.input__icon}>
              <Tooltip tooltip="Удалить">
                <DeleteIcon onClick={deleteClickHandler} />
              </Tooltip>
            </div>
          )}
          {editHandler && (
            <div className={styles.input__icon}>
              <Tooltip tooltip="Редактировать">
                <EditIcon onClick={editClickHandler} />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    );
  }
);
