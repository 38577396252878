import { Button, ButtonProps } from "shared/ui/Buttons";
import { EditIcon } from "shared/ui/Icons";
import styles from "./EditButton.module.scss";

export const EditButton = (props: Omit<ButtonProps, "icon">) => {
  return (
    <Button
      overflow={false}
      icon={<EditIcon />}
      type="gray"
      className={styles.editButton}
      {...props}
    >
      {props.children}
    </Button>
  );
};
