import { useCallback } from "react";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import { useNotification } from "shared/hooks/shared";
import { decOfCallsFeedback } from "shared/lib";

import { sleep } from "shared/lib/sleep";
import { useDeleteCallsFeedbackMutation } from "shared/api";

interface DeleteFeedbacksParams {
  ids: number[];
  onMutate: () => void;
}

interface DeleteFeedbackParams {
  id: number;
  onMutate: () => void;
}

export const useDeleteCallsFeedback = () => {
  const { open } = useShowConfirmModal();
  const { show, hide } = useNotification();

  const [deleteFeedback] = useDeleteCallsFeedbackMutation();

  const deleteCallsFeedbacks = useCallback(
    ({ ids, onMutate }: DeleteFeedbacksParams) => {
      open({
        title: `Удалить ${ids.length} ${decOfCallsFeedback(ids.length)}?`,
        buttonText: "Удалить",
        onConfirmCallback: async () => {
          show({
            text: "Удаление",
            withLoader: true,
            autoClose: false,
          });
          let promises = ids.map((id) => deleteFeedback({ id }).unwrap());
          let results = await Promise.allSettled([...promises, sleep(550)]);

          hide();
          if (results.every((promise) => promise.status === "fulfilled")) {
            show({ text: "Отзывы удалены!" });
          } else {
            show({ text: "Часть отзывов не была удалена" });
          }

          onMutate();
        },
      });
    },
    [deleteFeedback, hide, open, show]
  );

  const deleteCallsFeedback = useCallback(
    ({ id, onMutate }: DeleteFeedbackParams) => {
      open({
        title: `Удалить ${decOfCallsFeedback(1)}?`,
        buttonText: "Удалить",
        onConfirmCallback: async () => {
          show({
            text: "Удаление",
            withLoader: true,
            autoClose: false,
          });
          try {
            await Promise.all([deleteFeedback({ id }).unwrap(), sleep(550)]);
            hide();
            show({ text: "Отзыв удален!" });
          } catch {
            hide();
            show({ text: "Ошибка удаления!", isError: true });
          }

          onMutate();
        },
      });
    },
    [deleteFeedback, hide, open, show]
  );

  return {
    deleteCallsFeedbacks,
    deleteCallsFeedback,
  };
};
