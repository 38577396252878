import { Api, tags } from "../..";
import {
  IAdverstisementsRequestDto,
  IAdverstisementsResponseDto,
} from "shared/api/dto/adverstisements.dto";
import { IAdverstisement } from "shared/types/adverstisements.types";

const basePath = "api/admin-advertisement";

export const adverstisementsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getAdvertisements: builder.query<
      IAdverstisementsResponseDto,
      IAdverstisementsRequestDto
    >({
      query: (data) => ({
        url: `${basePath}/`,
        params: data,
      }),
      providesTags: [tags.advertisements],
    }),
    getAdvertisementById: builder.query<IAdverstisement, { id: string }>({
      query: (data) => ({
        url: `${basePath}/${data.id}/`,
      }),
      providesTags: [tags.advertisements],
    }),
    createAdvertisement: builder.mutation<IAdverstisement, FormData>({
      query: (data) => ({
        url: `${basePath}/`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: [tags.advertisements],
    }),
    updateAdvertisement: builder.mutation<
      IAdverstisement,
      { form: FormData; id: string }
    >({
      query: (data) => ({
        url: `${basePath}/${data.id}/`,
        body: data.form,
        method: "PATCH",
      }),
      invalidatesTags: [tags.advertisements],
    }),
    deleteAdvertisement: builder.mutation<IAdverstisement, { id: string }>({
      query: (data) => ({
        url: `${basePath}/${data.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [tags.advertisements],
    }),
  }),
});

export const {
  useGetAdvertisementsQuery,
  useCreateAdvertisementMutation,
  useGetAdvertisementByIdQuery,
  useUpdateAdvertisementMutation,
  useDeleteAdvertisementMutation,
} = adverstisementsApi;
