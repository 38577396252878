import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
  useIsNewDataFetching,
} from "shared/hooks/shared";
import { getFullDateFormat } from "shared/lib";
import { useGetCallsQuery } from "shared/api/endpoints/admin/calls.api";
import { callsActions } from "shared/store/reducers/callsReducer";

export const useCalls = () => {
  const dispatch = useAppDispatch();
  const { searchParams, is_video } = useAppSelector((state) => state.calls);

  const callsQuery = useGetCallsQuery({
    page: searchParams.currentPage,
    page_size: searchParams.itemsPerPage,
    search: searchParams.searchText || undefined,
    start_time: searchParams.callDate
      ? getFullDateFormat(searchParams.callDate)
      : undefined,
    is_video,
  });

  const calls = callsQuery.isSuccess ? callsQuery.data.results : null;

  useEffect(() => {
    if (callsQuery.isSuccess) {
      dispatch(callsActions.setQueryData(callsQuery.data));
    }
  }, [callsQuery, dispatch]);

  const loadingStatus = useIsNewDataFetching({
    ...callsQuery,
    params: {
      page: searchParams.currentPage,
      page_size: searchParams.itemsPerPage,
      search: searchParams.searchText || undefined,
      start_time: searchParams.callDate
        ? getFullDateFormat(searchParams.callDate)
        : undefined,
      is_video,
    },
    newParams: callsQuery.originalArgs,
  });

  return {
    calls,
    loadingStatus,
  };
};
