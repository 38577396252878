import { getMonthTitle, getDateOfISOWeek } from "shared/lib";
import { FinancesPeriod } from "./finances/model";

export const monthFormatter = (value: string) => {
  return getMonthTitle(new Date(value));
};

export const weekFormatter = (value: string) => {
  const [year, week] = value.split("-");

  if (year !== undefined && week !== undefined) {
    const start = getDateOfISOWeek(Number(week), Number(year), 0);
    const end = getDateOfISOWeek(Number(week), Number(year), 6);
    return `${start} - ${end}`;
  }

  return value;
};

export const dayFormatter = (value: string) => {
  const [, month, day] = value.split("-");

  return `${day}.${month}`;
};

export const getFormatter = (period: FinancesPeriod) => {
  switch (period) {
    case "year":
    case "custom-year":
      return monthFormatter;
    case "month":
    case "custom-week":
      return weekFormatter;
    case "week":
    case "custom":
      return dayFormatter;
    default:
      return undefined;
  }
};
